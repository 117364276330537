import React,{useState,useEffect} from "react";
import {Form, Input, message, Tooltip, Button} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import { InfoCircleOutlined } from "@ant-design/icons";

const CloseSavings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  // console.log("data:",data);

  const exchangeNameUpdated = data.exchangeName;
  const savingNameIDUpdate = data.savingNameID;
  const savingNameUpdate = data.savingName;
  const savingProcessIDUpdate = data.savingProcessID;
  const takeProfitUpdate =  data.takeProfitUpdate;
  const stopLossUpdate = data.stopLossUpdate;
  const investmentTypeUpdate = data.investmentType;

  const [loading,setLoading] = useState(false);

  //DropList Market
  const [allAgent, setAllAgent] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

  const [switchValue, setSwitchValue] = useState(takeProfitUpdate);

  const handleSwitchChange = (checked) => {
    setSwitchValue(checked);
  };

  const [switchSLValue, setSwitchSLValue] = useState(stopLossUpdate);

  const handleSwitchSLChange = (checked) => {
    setSwitchSLValue(checked);
  };
    //Handler Asset Class DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategory = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-category',
          {
            // userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          // console.log(res.data);
        }catch (error){
          setLoading(false);
        }
      };  
      getAllCategory();
    },[])

    // useEffect(() => {
    //   const getAllAgent = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/myagents/get-allAgent', {
    //         // _id:user._id
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAgent(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //     }
    //   };  
    //   getAllAgent();
    // },[])

    
  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/savings/edit-saving', {
      payload:{
        userid: user._id,
        reasonClosePortfolio: values.reasonClosePortfolio,
        closePortfolio: true,
      },
      _id: data.savingNameID,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/savingtransactions/edit-closeportfolio-savingtransaction', {
      payload:{
        userid: user._id,
        closePortfolio: true,
      },
      savingProcessId: parseInt(savingProcessIDUpdate),
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Portfolio closing successfully!');

    navigate('/ProcessingCloseSaving',
      {
        state:{
          savingProcessId: savingProcessIDUpdate,
          savingName: savingNameUpdate,
          exchangeName: exchangeNameUpdated,
          investmentType: investmentTypeUpdate,
          processing:"Processing Close Roboadvisor",
        }
      }
    );

    }catch (error){
      setLoading(false);
    }
  }

  return (
    <Layout>
      <div className="accountpage">
        <div></div>
        <div>
          <h1>Close Roboadvisor - {savingNameUpdate}</h1>
        </div>
        
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            onClick={() => navigate(-1)}
          >
            BACK
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitAccount} 
          >
            <Form.Item 
            label="Portfolio Name" 
            name="savingName"
            rules={[
              {
                max: 24,
                message: 'The name must be a maximum of 24 characters!',
              },
            ]}
            >
              <Input
              type="text"
              disabled
              defaultValue={savingNameUpdate}
              style={{ width: "100%", color:"black", textAlign: "left"}} 
              />
            </Form.Item>

            <Form.Item 
            label="Reason to close the portfolio" 
            name="reasonClosePortfolio"
            >
                <TextArea
                  showCount
                  maxLength={400}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                />
            </Form.Item>

            
            {/* {investmentTypeUpdate && (
            <Form.Item 
                label={
                  <span>
                    Maximum Investing&nbsp;
                    <Tooltip title="Enter the amount you want to maximum investing.">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  </span>
                } 
                name="initialValue"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value === undefined || value === null || value === '') {
                        return Promise.reject('Please enter an initial deposit amount!');
                      }
                      if (value < 100) {
                        return Promise.reject('The minimum investment amount is 100 USDT!');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  defaultValue={initialValueUpdate}
                  placeholder='0.00'
                  suffix="USDT"
                  type="number" 
                  size="large" 
                  style={{ fontSize: "24px", width: "100%", textAlign: "right" }}
                />
              </Form.Item>
            )}
            {investmentTypeUpdate && (
            <Form.Item
              label={
                <span>
                  Maximum investment for crypto&nbsp;
                  <Tooltip title="Enter the amount you want to invest maximum per crypto.">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                </span>
              } 
              name="maxInvestCrypto"
              rules={[
                { 
                  required: true, 
                  whitespace: true, 
                  message: 'Enter initial delivery value!' 
                },
                {
                  validator: (_, value) => {
                    if (value && value < 20) {
                      return Promise.reject('The minimum investment amount is 20 USDT!');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              
                <Input
                  defaultValue={maxInvestCryptoUpdate}
                  placeholder='0.00'
                  suffix="USDT"
                  type="number" 
                  size="large" 
                  style={{ fontSize: "24px", width: "100%", textAlign: "right" }}
                />
            </Form.Item>
            )} */}
            
            {/* <hr/>
            <hr/>
            <Form.Item label="Lucro Máximo" name="takeProfit" valuePropName="checked">
              <Switch
                checkedChildren="SIM"
                unCheckedChildren="NÃO"
                onChange={handleSwitchChange}
                defaultChecked={takeProfitUpdate}
              />
            </Form.Item>

            <Form.Item
              label="Lucro Máximo %"
              name="percentageTP"
              dependencies={['takeProfit']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('takeProfit')) {
                      if (!value || value.trim() === '') {
                        return Promise.reject(new Error('Please enter a value'));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type="number"
                suffix="%"
                disabled={!switchValue}
                defaultValue={percentageTPUpdate}
              />
            </Form.Item>

            <Form.Item label="Perda Máxima" name="stopLoss" valuePropName="checked">
              <Switch
                checkedChildren="SIM"
                unCheckedChildren="NÃO"
                onChange={handleSwitchSLChange}
                defaultChecked={stopLossUpdate}
              />
            </Form.Item>

            <Form.Item
              label="Perda Máxima %"
              name="percentageSL"
              dependencies={['stopLoss']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('stopLoss')) {
                      if (!value || value.trim() === '') {
                        return Promise.reject(new Error('Please enter a value'));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type="number"
                suffix="%"
                disabled={!switchSLValue}
                defaultValue={percentageSLUpdate}
              />
            </Form.Item>

            <hr/>
            <hr/> */}
            {/* Só aparece ser for investimentActive = true */}
            {/* <Form.Item label="Entrega ativa" name="isDepositActive" valuePropName="checked">
              <Switch 
                checkedChildren="SIM" unCheckedChildren="NÃO" defaultChecked={isDepositActiveUpdate}
              />
            </Form.Item> */}

            {/* <Form.Item label="Valor da entrega" name="monthlyAmount">
              <Input 
              type="number"
              suffix="€"
              defaultValue={monthlyAmountUpdate} 
              />
            </Form.Item>

            <Form.Item label="Data do pagamento" name="dayPayment">
              <Input 
              type="number"
              defaultValue={dayPaymentUpdate} 
              />
            </Form.Item>

            <Form.Item label="IBAN" name="IBAN">
              <Input 
              type="number"
              defaultValue={IBANUpdate}
              />
            </Form.Item>

            <Form.Item 
              label="Agente"
              name="agentName"
            >
              <Select defaultValue={agentNameUpdate}>
                {allAgent && allAgent.map((opts, i) => (
                  <Select.Option key={i} value={opts._id} style={{ textAlign: 'center' }}>
                    {opts.agentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
                CLOSE PORTFOLIO
              </Button>
              <Link to="/MySavings">
                <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
                  CANCEL
                </Button>
              </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default CloseSavings;