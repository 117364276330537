import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Button,
	Select,
	message,
	Table,
	Switch,
	Modal,
	Spin
} from "antd";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
// import Spin from "../components/Spinner";

import moment from "moment";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";

const MyContactsEmailAdmin = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const user = JSON.parse(localStorage.getItem("user"));

	const user_phoneNumber = user.phoneNumber;
	const user_agentName = user.agentName;
	const user_email = user.email;

	const [loading, setLoading] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);

	// //Handle Close button Form
	const [show, setShow] = useState(false);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	const [activeTab, setActiveTab] = useState("mobileContacts");

	const [allPhones, setAllPhones] = useState([]);
	const [allEmails, setAllEmails] = useState([]);

	//Filters
	const [statusFilter, setStatusFilter] = useState("false");

	//Fields from table to View
	const [subjectUpdate, setSubjectUpdate] = useState(null);
	const [messageUpdate, setMessageUpdate] = useState(null);
	const [replyMessageUpdate, setReplyMessageUpdate] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState(null);
	const [firstNameUpdate, setFirstNameUpdate] = useState(null);
	const [lastNameUpdate, setLastNameUpdate] = useState(null);
	const [nifUpdate, setNifUpdate] = useState(null);
	const [emailUpdate, setEmailUpdate] = useState(null);
	const [phoneNumberUpdate, setPhoneNumberUpdate] = useState(null);
	const [reasonUpdate, setReasonUpdate] = useState(null);
	const [toAdminUpdate, setToadminUpdate] = useState(null);

	const currentDate = new Date();
	const options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false, // Use 24-hour format
	};

	const formattedDate = currentDate.toLocaleDateString("sv-SE", options);

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	// //table accounts
	// const columns = [
	// 	{
	// 		key: "createdAt",
	// 		title: "Data do Contacto",
	// 		dataIndex: "createdAt",
	// 		defaultSortOrder: "descend",
	// 		align: "center",
	// 		render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
	// 		sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
	// 	},
	// 	{
	// 		key: "updatedAt",
	// 		title: "Última Atualização",
	// 		dataIndex: "updatedAt",
	// 		// defaultSortOrder: 'descend',
	// 		align: "center",
	// 		render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
	// 		sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
	// 	},
	// 	{
	// 		key: "contactDateTime",
	// 		title: "Data/Hora de Contacto",
	// 		dataIndex: "contactDateTime",
	// 		// defaultSortOrder: 'descend',
	// 		align: "center",
	// 		render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
	// 		sorter: (a, b) =>
	// 			new Date(a.contactDateTime) - new Date(b.contactDateTime),
	// 	},
	// 	{
	// 		key: "updatedUserAt",
	// 		title: "Utilizador Atualização",
	// 		dataIndex: "updatedUserAt",
	// 		// defaultSortOrder: 'descend',
	// 		align: "center",
	// 		sorter: (a, b) => a.updatedUserAt.localeCompare(b.updatedUserAt),
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.updatedUserAt.toLowerCase().includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "isAgent",
	// 		title: "Agente?",
	// 		dataIndex: ["userid", "isAgent"],
	// 		align: "center",
	// 		sorter: (a, b) => {
	// 			const isAgentA =
	// 				a.userid && a.userid.isAgent !== undefined ? a.userid.isAgent : -1;
	// 			const isAgentB =
	// 				b.userid && b.userid.isAgent !== undefined ? b.userid.isAgent : -1;
	// 			return isAgentA - isAgentB;
	// 		},
	// 		render: (value, record) => {
	// 			if (!record.userid || record.userid.isAgent === undefined) {
	// 				return <span style={{}}>N/D</span>;
	// 			}
	// 			if (record.userid.isAgent === true) {
	// 				return <span style={{ color: "#39A939" }}>Sim</span>;
	// 			}
	// 			return <span style={{}}>Não</span>;
	// 		},
	// 	},
	// 	{
	// 		key: "firstName",
	// 		title: "Nome",
	// 		dataIndex: ["userid", "firstName"],
	// 		sorter: (a, b) => a.userid.firstName.localeCompare(b.userid.firstName),
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.userid.firstName
	// 				.toLowerCase()
	// 				.includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "lastName",
	// 		title: "Apelido",
	// 		dataIndex: ["userid", "lastName"],
	// 		sorter: (a, b) => a.userid.lastName.localeCompare(b.userid.lastName),
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.userid.lastName
	// 				.toLowerCase()
	// 				.includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "nif",
	// 		title: "NIF",
	// 		dataIndex: ["userid", "nif"],
	// 		sorter: (a, b) => a.userid.nif.localeCompare(b.userid.nif),
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.userid.nif.toLowerCase().includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "phoneNumber",
	// 		title: "Telefone",
	// 		dataIndex: ["userid", "phoneNumber"],
	// 		sorter: (a, b) =>
	// 			a.userid.phoneNumber.localeCompare(b.userid.phoneNumber),
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.userid.phoneNumber
	// 				.toLowerCase()
	// 				.includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "reason",
	// 		title: "Razão do Contacto",
	// 		dataIndex: ["reason"],
	// 		filterDropdown: ({
	// 			setSelectedKeys,
	// 			selectedKeys,
	// 			confirm,
	// 			clearFilters,
	// 		}) => {
	// 			return (
	// 				<>
	// 					<Input
	// 						autoFocus
	// 						placeholder="Type text here"
	// 						value={selectedKeys[0]}
	// 						onChange={(e) => {
	// 							setSelectedKeys(e.target.value ? [e.target.value] : []);
	// 							confirm({ closeDropdown: false });
	// 						}}
	// 						onPressEnter={() => {
	// 							confirm();
	// 						}}
	// 						onBlur={() => {
	// 							confirm();
	// 						}}
	// 					></Input>
	// 					<Button
	// 						onClick={() => {
	// 							confirm();
	// 						}}
	// 						type="primary"
	// 					>
	// 						Search
	// 					</Button>
	// 					<Button
	// 						onClick={() => {
	// 							clearFilters();
	// 						}}
	// 						type="danger"
	// 					>
	// 						Reset
	// 					</Button>
	// 				</>
	// 			);
	// 		},
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			return record.reason.toLowerCase().includes(value.toLowerCase());
	// 		},
	// 	},
	// 	{
	// 		key: "status",
	// 		title: "Estado",
	// 		dataIndex: "status",
	// 		align: "center",
	// 		sorter: (a, b) => a.status - b.status,
	// 		render: (value, record) => {
	// 			if (record.status === true)
	// 				return <span style={{ color: "#39A939" }}>Fechado</span>;

	// 			return <span style={{ color: "#FF3234" }}>Em aberto</span>;
	// 		},
	// 		filters: [
	// 			{ text: "Fechado", value: true },
	// 			{ text: "Em aberto", value: false },
	// 		],
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			// console.log(typeof value)
	// 			return record.status === value;
	// 		},
	// 	},
	// 	{
	// 		key: "toAdmin",
	// 		title: "Escalar Pedido",
	// 		dataIndex: "toAdmin",
	// 		align: "center",
	// 		sorter: (a, b) => a.toAdmin - b.toAdmin,
	// 		render: (value, record) => {
	// 			if (record.toAdmin === true)
	// 				return <span style={{ color: "#39A939" }}>Sim</span>;

	// 			return <span style={{ color: "black" }}>Não</span>;
	// 		},
	// 		filters: [
	// 			{ text: "Sim", value: true },
	// 			{ text: "Não", value: false },
	// 		],
	// 		filterIcon: () => {
	// 			return <SearchOutlined />;
	// 		},
	// 		onFilter: (value, record) => {
	// 			// console.log(typeof value)
	// 			return record.toAdmin === value;
	// 		},
	// 	},
	// 	{
	// 		title: "Acções",
	// 		dataIndex: "type",
	// 		align: "center",
	// 		render: (type, record) => {
	// 			// if(type === "Income" )
	// 			return (
	// 				<div className="mx-4">
	// 					<EditOutlined
	// 						onClick={() => {
	// 							setEditable(record);
	// 							setShowModal(true);
	// 							setFirstNameUpdate(record.userid.firstName);
	// 							setLastNameUpdate(record.userid.lastName);
	// 							setNifUpdate(record.userid.nif);
	// 							setPhoneNumberUpdate(record.userid.phoneNumber);
	// 							setReasonUpdate(record.reason);
	// 							setToadminUpdate(record.toAdmin);
	// 							setStatusUpdate(record.status);
	// 						}}
	// 					/>
	// 				</div>
	// 			);
	// 		},
	// 	},
	// ];

	const Emailcolumns = [
		{
			key: "createdAt",
			title: "Data do Contacto",
			dataIndex: "createdAt",
			defaultSortOrder: "descend",
			align: "center",
			width: "1%",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		},
		{
			key: "updatedAt",
			title: "Última Atualização",
			dataIndex: "updatedAt",
			width: "1%",
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
		},
		{
			key: "updatedUserAt",
			title: "Utilizador Atualização",
			dataIndex: "updatedUserAt",
			width: "3%",
			// defaultSortOrder: 'descend',
			align: "center",
			sorter: (a, b) => a.updatedUserAt.localeCompare(b.updatedUserAt),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.updatedUserAt.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "isAgent",
			title: "Agente?",
			dataIndex: ["userid", "isAgent"],
			align: "center",
			sorter: (a, b) => {
				const isAgentA =
					a.userid && a.userid.isAgent !== undefined ? a.userid.isAgent : -1;
				const isAgentB =
					b.userid && b.userid.isAgent !== undefined ? b.userid.isAgent : -1;
				return isAgentA - isAgentB;
			},
			render: (value, record) => {
				if (!record.userid || record.userid.isAgent === undefined) {
					return <span style={{}}>N/D</span>;
				}
				if (record.userid.isAgent === true) {
					return <span style={{ color: "#39A939" }}>Sim</span>;
				}
				return <span style={{}}>Não</span>;
			},
		},
		{
			key: "name",
			title: "Cliente",
			dataIndex: ["userid", "name"],
			width: "2%",
			sorter: (a, b) => a.userid.name.localeCompare(b.userid.name),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.userid.name.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "nif",
			title: "NIF",
			dataIndex: ["userid", "nif"],
			width: "2%",
			sorter: (a, b) => a.userid.nif.localeCompare(b.userid.nif),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.userid.nif.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "phoneNumber",
			title: "Telefone",
			dataIndex: ["userid", "phoneNumber"],
			width: "2%",
			sorter: (a, b) =>
				a.userid.phoneNumber.localeCompare(b.userid.phoneNumber),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.userid.phoneNumber
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "subject",
			title: "Assunto",
			dataIndex: ["subject"],
			width: "6%",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.subject.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "message",
			title: "Mensagem",
			dataIndex: ["message"],
			width: "15%",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.message.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "replyMessage",
			title: "Resposta",
			dataIndex: ["replyMessage"],
			width: "15%",
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.replyMessage.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "status",
			title: "Estado",
			dataIndex: "status",
			align: "center",
			width: "1%",
			sorter: (a, b) => a.status - b.status,
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Fechado</span>;

				return <span style={{ color: "#FF3234" }}>Em aberto</span>;
			},
			filters: [
				{ text: "Fechado", value: true },
				{ text: "Em aberto", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.status === value;
			},
		},
		{
			key: "toAdmin",
			title: "Escalar Pedido",
			dataIndex: "toAdmin",
			align: "center",
			width: "1%",
			sorter: (a, b) => a.toAdmin - b.toAdmin,
			render: (value, record) => {
				if (record.toAdmin === true)
					return <span style={{ color: "#39A939" }}>Sim</span>;

				return <span style={{ color: "black" }}>Não</span>;
			},
			filters: [
				{ text: "Sim", value: true },
				{ text: "Não", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.toAdmin === value;
			},
		},
		{
			title: "Acções",
			dataIndex: "type",
			align: "center",
			width: "1%",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-4">
						<EditOutlined
							onClick={() => {
								setEditable(record);
								setShowEmailModal(true);
								setFirstNameUpdate(record.userid.firstName);
								setLastNameUpdate(record.userid.lastName);
								setNifUpdate(record.userid.nif);
								setEmailUpdate(record.userid.email);
								setPhoneNumberUpdate(record.userid.phoneNumber);
								setSubjectUpdate(record.subject);
								setMessageUpdate(record.message);
								setReplyMessageUpdate(record.replyMessage);
								setToadminUpdate(record.toAdmin);
								setStatusUpdate(record.status);
							}}
						/>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		const getallSavingsTransactions = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/phonecontacts/get-allphonecontact",
					{
						agentName: user.agentName,
						status: statusFilter,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllPhones(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getallSavingsTransactions();
	}, [statusFilter]);

	useEffect(() => {
		const getallSavingsTransactions = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/emailcontacts/get-allemailcontact",
					{
						agentName: user.agentName,
						status: statusFilter,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllEmails(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getallSavingsTransactions();
	}, [statusFilter]);

	const handleSubmitMobile = (values) => {
		// console.log('Submitted Mobile:', values);
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/phonecontacts/add-phonecontact",
				{
					agentName: user_agentName,
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Obrigado, contataremos com maior brevidade possível!");
			setLoading(false);
			navigate("/");
		} catch (error) {
			setLoading(false);
		}
	};

	const handleSubmitEmail = (values) => {
		// console.log('Submitted Email:', values);
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/emailcontacts/add-emailcontact",
				{
					...values,
					agentName: user_agentName,
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Obrigado, contataremos com a maior brevidade possível!");
			setLoading(false);
			navigate("/");
		} catch (error) {
			setLoading(false);
		}
	};

	const handleSubmitPhoneContact = async (values) => {
		try {
			// const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/phonecontacts/edit-phonecontact",
				{
					payload: {
						...values,
						isAgent: true,
						updatedUserAt: user.firstName + " " + user.lastName,
					},
					_id: editable._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Pedido de contacto atualizado com sucesso");

			setShowModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
			// message.error('Failed to edit User');
		}
	};

	const handleSubmitEmailContact = async (values) => {
		try {
			// const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/emailcontacts/edit-emailcontact",
				{
					payload: {
						...values,
						isAgent: true,
						updatedUserAt: user.firstName + " " + user.lastName,
					},
					_id: editable._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Pedido de contacto atualizado com sucesso");

			setShowEmailModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
			// message.error('Failed to edit User');
		}
	};

	//Handler Reset Values to modal
	const handleCancelModal = () => {
		setShowModal(false);
		setShowEmailModal(false);
		setEditable(null);
		setFirstNameUpdate(null);
		setLastNameUpdate(null);
		setNifUpdate(null);
		setEmailUpdate(null);
		setPhoneNumberUpdate(null);
		setReasonUpdate(null);
		setSubjectUpdate(null);
		setMessageUpdate(null);
		setReplyMessageUpdate(null);
		setToadminUpdate(null);
		setStatusUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
		// setShowModalAddUser(false);
	};

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	return (
		<Layout>
			{loading && <Spin />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Gestão de Emails</h1>
				</div>
				<div></div>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div>
				<Form layout="vertical" name="emailContactForm">
					<div className="accountpage">
						<div></div>
						<div className="vertical-select">
							<h6>Estados do Pedido</h6>
							<Select
								style={{
									width: "150px",
								}}
								value={statusFilter}
								onChange={(values) => setStatusFilter(values)}
							>
								<Select.Option value="all">Todos</Select.Option>
								<Select.Option value="false">Em aberto</Select.Option>
								<Select.Option value="true">Fechados</Select.Option>
							</Select>
						</div>
						<div></div>
					</div>
					<hr></hr>
					{/* <div><pre>{JSON.stringify(allEmails, null, 2) }</pre></div> */}
					<div className="content">
						<Table
							size="small"
							columns={Emailcolumns}
							dataSource={allEmails}
							pagination={{
								position: ["bottomCenter"],
								defaultPageSize: 25,
								showSizeChanger: true,
								pageSizeOptions: ["10", "25", "50"],
							}}
							scroll={{ x: "max-content" }}
							className="custom-table" // Apply custom CSS class for styling
						/>
					</div>
				</Form>
			</div>

			<Modal
				destroyOnClose={true}
				title={"Pedido de contacto por email"}
				open={showEmailModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitEmailContact}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Cliente" name="client">
						<Input
							disabled
							type="text"
							defaultValue={`${firstNameUpdate} ${lastNameUpdate}`} // Concatenate first and last name
							style={{ color: "black" }}
						/>
					</Form.Item>

					<Form.Item label="NIF" name="nif">
						<Input
							disabled
							type="number"
							defaultValue={nifUpdate}
							style={{ color: "black" }}
						/>
					</Form.Item>

					<Form.Item label="Email" name="email">
						<Input
							disabled
							type="text"
							defaultValue={emailUpdate}
							style={{ color: "black" }}
						/>
					</Form.Item>

					<Form.Item label="Assunto" name="subject">
						<Input
							disabled
							type="text"
							defaultValue={subjectUpdate}
							style={{ color: "black" }}
						/>
					</Form.Item>

					<Form.Item label="" name="message">
						<TextArea
							disabled
							defaultValue={messageUpdate}
							showCount
							maxLength={500}
							style={{
								color: "black",
								height: 200,
								marginBottom: 12,
							}}
							type="text"
						/>
					</Form.Item>

					<Form.Item label="Resposta à Mensagem" name="replyMessage">
						<TextArea
							showCount
							maxLength={500}
							style={{
								height: 200,
								marginBottom: 12,
								color: "black",
							}}
							type="text"
							defaultValue={replyMessageUpdate}
						/>
					</Form.Item>

					{/* <Form.Item label="Escalar pedido" name="toAdmin" valuePropName="checked">
              <Switch 
                checkedChildren="SIM" unCheckedChildren="NÃO" defaultChecked={toAdminUpdate}
              />
            </Form.Item> */}

					<Form.Item label="Estado" name="status" valuePropName="checked">
						<Switch
							checkedChildren="Fechado"
							unCheckedChildren="Em Aberto"
							defaultChecked={statusUpdate}
						/>
					</Form.Item>

					<button
						type="submit"
						style={{
							width: "100%",
							background: "green",
							color: "white",
							marginTop: 30,
							marginBottom: 30,
						}}
						className="btn btn-success"
						onClick={handleClose}
					>
						{" "}
						EDITAR
					</button>
				</Form>
			</Modal>
		</Layout>
	);
};

export default MyContactsEmailAdmin;
