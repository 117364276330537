import { Routes, Route, Navigate } from "react-router-dom";

import HomePage from "./pages/HomePage";
import Users from "./pages/Users";
import UsersAgents from "./pages/UsersAgents";
import Login from "./pages/Login";
import Register from "./pages/Register";

import Categories from "./pages/Categories";
// import SubCategories from './pages/SubCategories';
import InvestorRiskProfiles from "./pages/InvestorRiskProfiles";
// import MyAssets from './pages/MyAssets';
// import AssetPrices from './pages/AssetPrices';
import MyProducts from "./pages/MyProducts";
// import ProductPrices from './pages/ProductPrices';
import PortfolioProducts from "./pages/PortfolioProducts";
import MyAgents from "./pages/MyAgents";
// import MyInvestings from './pages/MyInvestings';

import MySavings from "./pages/MySavings";
import MyAccountDetails from "./pages/MyAccountDetails";
import Settings from "./pages/Settings";
import Version from "./pages/Version";

import MySavingsAdmin from "./pages/MySavingsAdmin";
import MySavingsAgent from "./pages/MySavingsAgent";
import NewSavings from "./pages/NewSavings";
import NewExchanges from "./pages/NewExchanges";
import SavingDeposit from "./pages/SavingDeposit";
import ProcessingSaving from "./pages/ProcessingSaving";
import ProcessingCloseSaving from "./pages/ProcessingCloseSaving";
import EditSavings from "./pages/EditSavings";
import CloseSavings from "./pages/CloseSavings";
import SavingPortfolio from "./pages/SavingPortfolio";

import InvestorProfiles from "./pages/InvestorProfiles";
import InvestorProfilesDetail from "./pages/InvestorProfilesDetail";
import MyClients from "./pages/MyClients";
import MyClientsAgent from "./pages/MyClientsAgent";

import SavingAllTransactionsAdmin from "./pages/SavingAllTransactionsAdmin";
import SavingAllTransactionsAgent from "./pages/SavingAllTransactionsAgent";
import SavingAllTransactions from "./pages/SavingAllTransactions";
// import SavingUserOrderTransactionsAdmin from './pages/SavingUserOrderTransactionsAdmin';

import SavingUserPortfolioAdmin from "./pages/SavingUserPortfolioAdmin";
// import SavingUserTransactionsAdmin from './pages/SavingUserTransactionsAdmin';
// import SavingUserPortfolioPerformanceAdmin from './pages/SavingUserPortfolioPerformanceAdmin';

import SavingUserPortfolioAgent from "./pages/SavingUserPortfolioAgent";
// import SavingUserPortfolioPerformanceAgent from './pages/SavingUserPortfolioPerformanceAgent';
// import SavingUserTransactionsAgent from './pages/SavingUserTransactionsAgent';

// import OrderTransactions from './pages/OrderTransactions';

import FinanceLiteracy from "./pages/FinanceLiteracy";
import FinanceLiteracySetup from "./pages/FinanceLiteracySetup";

import MyContacts from "./pages/MyContacts";
import MyEmails from "./pages/MyEmails";
import MySettings from "./pages/MySettings";
import DeleteAccount from "./pages/DeleteAccount";

import MyContactsAgent from "./pages/MyContactsAgent";
import MyContactsAdmin from "./pages/MyContactsAdmin";

import CurrencyAssets from "./pages/CurrencyAssets";
import CurrencyRates from "./pages/CurrencyRates";

import CryptoAssets from "./pages/CryptoAssets";
import CryptoPrices from "./pages/CryptoPrices";
import CryptoSmarts from "./pages/CryptoSmarts";
import CryptoRisks from "./pages/CryptoRisks";

import MyInvestments from "./pages/MyInvestments";
import MyHistory from "./pages/MyHistory";
import Disclaimer from "./pages/Disclaimer";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import TermsService from "./pages/TermsService";

import ProcessingExchanges from "./pages/ProcessingExchanges";
import ProcessingRefreshExchange from "./pages/ProcessingRefreshExchange";
import OrderExchanges from "./pages/OrderExchanges";
import PortfolioExchanges from "./pages/PortfolioExchanges";
import EditExchanges from "./pages/EditExchanges";

import ProcessingProduct from "./pages/ProcessingProduct";
import ProcessingDepositSaving from "./pages/ProcessingDepositSaving";
import ProcessingUpdateAgent from "./pages/ProcessingUpdateAgent";
import ProcessingRefreshMySavings from "./pages/ProcessingRefreshMySavings";
import ProcessingRefreshAllSavings from "./pages/ProcessingRefreshAllSavings";
import ProcessingRefreshAllExchange from "./pages/ProcessingRefreshAllExchange";
import ProcessingRefreshCryptoRiskDaily from "./pages/ProcessingRefreshCryptoRiskDaily";
import ProcessingRefreshCryptoSmartDaily from "./pages/ProcessingRefreshCryptoSmartDaily";
import ProcessingRefreshCryptoPricesDaily from "./pages/ProcessingRefreshCryptoPricesDaily";
import ProcessingRefreshCurrecyRatesDaily from "./pages/ProcessingRefreshCurrecyRatesDaily";
import ProcessingRefreshCryptoBasketDaily from "./pages/ProcessingRefreshCryptoBasketDaily";

import HelpBinanceAccount from "./pages/HelpBinanceAccount";

// import ProcessingRefreshEtfs from './pages/ProcessingRefreshEtfs';
// import ProcessingRefreshFunds from './pages/ProcessingRefreshFunds';
// import ProcessingRefreshWallets from './pages/ProcessingRefreshWallets';

// import Accounts from './pages/Accounts';
// import Subscriptions from './pages/Subscriptions';
// import Utilities from './pages/Utilities';
// import Insurances from './pages/Insurances';
// import Loans from './pages/Loans';
// import Investments from './pages/Investments';
// import CreditCards from './pages/CreditCards';
// import Taxes from './pages/Taxes';
// import Wallets from './pages/Wallets';
// import Exchanges from './pages/Exchanges';

// import OrderAllExchanges from './pages/old/OrderAllExchanges';

// import PortfolioStocks from './pages/PortfolioStocks';
// import PortfolioEtfs from './pages/PortfolioEtfs';
// import PortfolioFundFamilys from './pages/PortfolioFundFamilys';
// import PortfolioWallets from './pages/PortfolioWallets';

// import NewAccounts from './pages/NewAccounts';
// import NewExpenses from './pages/NewExpenses';
// import NewIncomes from './pages/NewIncomes';
// import NewSubscriptions from './pages/NewSubscriptions';
// import NewUtilities from './pages/NewUtilities';
// import NewInsurances from './pages/NewInsurances';
// import NewLoans from './pages/NewLoans';
// import NewInvestments from './pages/NewInvestments';
// import NewCreditCards from './pages/NewCreditCards';
// import NewInvestmentsReforces from './pages/NewInvestmentsReforces';
// import NewInvestmentsWithdraws from './pages/NewInvestmentsWithdraws';
// import NewTaxes from './pages/NewTaxes';
// import NewWallets from './pages/NewWallets';
// import NewExchanges from './pages/NewExchanges';

//DESCONTINUAR PROGRESSIVAMENTE
// import ArchiveAccounts from './pages/ArchiveAccounts';
// import ArchiveSubscriptions from './pages/ArchiveSubscriptions';
// import ArchiveUtilities from './pages/ArchiveUtilities';
// import ArchiveInsurances from './pages/ArchiveInsurances';
// import ArchiveLoans from './pages/ArchiveLoans';
// import ArchiveTaxes from './pages/ArchiveTaxes';
// import ArchiveCreditCardsActive from './pages/ArchiveCreditCardsActive';
// import ArchiveAccountsActive from './pages/ArchiveAccountsActive';
// import ArchiveWallets from './pages/ArchiveWallets';
// import ArchiveExchanges from './pages/ArchiveExchanges';

// import EditAccounts from './pages/EditAccounts';
// import NewBalanceInitial from './pages/NewBalanceInitial';
// import EditSubscriptions from './pages/EditSubscriptions';
// import EditUtilities from './pages/EditUtilities';
// import EditInsurances from './pages/EditInsurances';
// import EditLoans from './pages/EditLoans';
// import EditInvestments from './pages/EditInvestments';
// import EditInvestmentStocks from './pages/EditInvestmentStocks';
// import EditCreditCards from './pages/EditCreditCards';
// import EditTaxes from './pages/EditTaxes';
// import EditWallets from './pages/EditWallets';

// import EditInvestmentEtfs from './pages/EditInvestmentEtfs';
// import EditInvestmentFundFamilys from './pages/EditInvestmentFundFamilys';

// import AccountsArchive from './pages/AccountsArchive';
// import SubscriptionsArchive from './pages/SubscriptionsArchive';
// import UtilitiesArchive from './pages/UtilitiesArchive';
// import InsurancesArchive from './pages/InsurancesArchive';
// import LoansArchive from './pages/LoansArchive';
// import InvestmentsArchive from './pages/InvestmentsArchive';
// import CreditCardsArchive from './pages/CreditCardsArchive';
// import TaxesArchive from './pages/TaxesArchive';
// import WalletsArchive from './pages/WalletsArchive';
// import ExchangesArchive from './pages/ExchangesArchive';
// import InvestorRiskTypes from './pages/InvestorRiskTypes';
// import Transactions from './pages/Transactions';
// import TransactionsAll from './pages/TransactionsAll';
// import TransactionsProduct from './pages/TransactionsProduct';
// import TransactionsProductsAll from './pages/TransactionsProductsAll';
// import TransactionsUtilitiesAll from './pages/TransactionsUtilitiesAll';
// import TransactionsInsurancesAll from './pages/TransactionsInsurancesAll';
// import TransactionsLoansAll from './pages/TransactionsLoansAll';
// import TransactionsInvestment from './pages/TransactionsInvestment';
// import TransactionsInvesmentAll from './pages/TransactionsInvesmentAll';
// import TransactionsWallet from './pages/TransactionsWallet';
// import TransactionsWalletAll from './pages/TransactionsWalletAll';
// import TransactionsTax from './pages/TransactionsTax';
// import TransactionsTaxesAll from './pages/TransactionsTaxesAll';

import CategoryAssets from "./pages/CategoryAssets";
// import Countries from './pages/Countries';
// import Sectors from './pages/Sectors';
// import Industries from './pages/Industries';
// import Markets from './pages/Markets';
// import FundFamilies from './pages/FundFamilies';
// import StockAssets from './pages/StockAssets';
// import ETFAssets from './pages/ETFAssets';
// import FutureAssets from './pages/FutureAssets';

// import Explorer from './pages/old/Explorer';
import ExplorerSettings from "./pages/ExplorerSettings";
// import Settings from './pages/Settings';
// import Archives from './pages/Archives';

// import StockPrices from './pages/StockPrices';
// import ETFPrices from './pages/ETFPrices';

// import FundFamilyPrices from './pages/FundFamilyPrices';
// import PPRPrices from './pages/PPRPrices';

// import FuturePrices from './pages/FuturePrices';

// import StockSmarts from './pages/StockSmarts';
// import ETFSmarts from './pages/ETFSmarts';

// import TransactionsGraphs from './pages/TransactionsGraphs';
// import TransactionsStats from './pages/TransactionsStats';

// import StockNews from './pages/StockNews';

function App() {
	return (
		<>
			<Routes>
				<Route
					path="/"
					element={
						<ProtectedRoutes>
							<HomePage />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/Users"
					element={
						<ProtectedRoutes>
							<Users />
						</ProtectedRoutes>
					}
				/>

				<Route path="/register" element={<Register />} />

				<Route path="/login" element={<Login />} />

				<Route
					path="/UsersAgents"
					element={
						<ProtectedRoutes>
							<UsersAgents />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyAccountDetails"
					element={
						<ProtectedRoutes>
							<MyAccountDetails />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/Categories"
					element={
						<ProtectedRoutes>
							<Categories />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/Settings"
					element={
						<ProtectedRoutes>
							<Settings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/Version"
					element={
						<ProtectedRoutes>
							<Version />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/SubCategories" element={
        <ProtectedRoutes>
          <SubCategories/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/InvestorRiskProfiles"
					element={
						<ProtectedRoutes>
							<InvestorRiskProfiles />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/MyAssets" element={
        <ProtectedRoutes>
          <MyAssets/>
        </ProtectedRoutes>
        }/>

        <Route path="/AssetPrices" element={
        <ProtectedRoutes>
          <AssetPrices/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/MyProducts"
					element={
						<ProtectedRoutes>
							<MyProducts />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/ProductPrices" element={
        <ProtectedRoutes>
          <ProductPrices/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/PortfolioProducts"
					element={
						<ProtectedRoutes>
							<PortfolioProducts />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyAgents"
					element={
						<ProtectedRoutes>
							<MyAgents />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/MyInvestings" element={
        <ProtectedRoutes>
          <MyInvestings/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/MySavings"
					element={
						<ProtectedRoutes>
							<MySavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MySavingsAdmin"
					element={
						<ProtectedRoutes>
							<MySavingsAdmin />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MySavingsAgent"
					element={
						<ProtectedRoutes>
							<MySavingsAgent />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/NewSavings"
					element={
						<ProtectedRoutes>
							<NewSavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/NewExchanges"
					element={
						<ProtectedRoutes>
							<NewExchanges />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingDeposit"
					element={
						<ProtectedRoutes>
							<SavingDeposit />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingSaving"
					element={
						<ProtectedRoutes>
							<ProcessingSaving />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingCloseSaving"
					element={
						<ProtectedRoutes>
							<ProcessingCloseSaving />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingUpdateAgent"
					element={
						<ProtectedRoutes>
							<ProcessingUpdateAgent />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/EditSavings"
					element={
						<ProtectedRoutes>
							<EditSavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CloseSavings"
					element={
						<ProtectedRoutes>
							<CloseSavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingPortfolio"
					element={
						<ProtectedRoutes>
							<SavingPortfolio />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/SavingPortfolioPerformance" element={
        <ProtectedRoutes>
          <SavingPortfolioPerformance/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/InvestorProfiles"
					element={
						<ProtectedRoutes>
							<InvestorProfiles />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/InvestorProfilesDetail"
					element={
						<ProtectedRoutes>
							<InvestorProfilesDetail />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyClients"
					element={
						<ProtectedRoutes>
							<MyClients />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyClientsAgent"
					element={
						<ProtectedRoutes>
							<MyClientsAgent />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/FinanceLiteracy"
					element={
						<ProtectedRoutes>
							<FinanceLiteracy />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingAllTransactionsAdmin"
					element={
						<ProtectedRoutes>
							<SavingAllTransactionsAdmin />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingAllTransactionsAgent"
					element={
						<ProtectedRoutes>
							<SavingAllTransactionsAgent />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingAllTransactions"
					element={
						<ProtectedRoutes>
							<SavingAllTransactions />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/SavingUserOrderTransactionsAdmin" element={
        <ProtectedRoutes>
          <SavingUserOrderTransactionsAdmin/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/SavingUserPortfolioAdmin"
					element={
						<ProtectedRoutes>
							<SavingUserPortfolioAdmin />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/SavingUserPortfolioAgent"
					element={
						<ProtectedRoutes>
							<SavingUserPortfolioAgent />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/SavingUserTransactionsAdmin" element={
        <ProtectedRoutes>
          <SavingUserTransactionsAdmin/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/SavingUserTransactionsAgent" element={
        <ProtectedRoutes>
          <SavingUserTransactionsAgent/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/SavingUserPortfolioPerformanceAdmin" element={
        <ProtectedRoutes>
          <SavingUserPortfolioPerformanceAdmin/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/SavingUserPortfolioPerformanceAgent" element={
        <ProtectedRoutes>
          <SavingUserPortfolioPerformanceAgent/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/OrderTransactions" element={
        <ProtectedRoutes>
          <OrderTransactions/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/FinanceLiteracySetup"
					element={
						<ProtectedRoutes>
							<FinanceLiteracySetup />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyContacts"
					element={
						<ProtectedRoutes>
							<MyContacts />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyEmails"
					element={
						<ProtectedRoutes>
							<MyEmails />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MySettings"
					element={
						<ProtectedRoutes>
							<MySettings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyHistory"
					element={
						<ProtectedRoutes>
							<MyHistory />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/DeleteAccount"
					element={
						<ProtectedRoutes>
							<DeleteAccount />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyContactsAgent"
					element={
						<ProtectedRoutes>
							<MyContactsAgent />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyContactsAdmin"
					element={
						<ProtectedRoutes>
							<MyContactsAdmin />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CurrencyRates"
					element={
						<ProtectedRoutes>
							<CurrencyRates />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CurrencyAssets"
					element={
						<ProtectedRoutes>
							<CurrencyAssets />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CryptoPrices"
					element={
						<ProtectedRoutes>
							<CryptoPrices />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CryptoAssets"
					element={
						<ProtectedRoutes>
							<CryptoAssets />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CryptoSmarts"
					element={
						<ProtectedRoutes>
							<CryptoSmarts />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/CryptoRisks"
					element={
						<ProtectedRoutes>
							<CryptoRisks />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/MyInvestments"
					element={
						<ProtectedRoutes>
							<MyInvestments />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingProduct"
					element={
						<ProtectedRoutes>
							<ProcessingProduct />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingDepositSaving"
					element={
						<ProtectedRoutes>
							<ProcessingDepositSaving />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshMySavings"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshMySavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshAllSavings"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshAllSavings />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshExchange"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshExchange />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshAllExchange"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshAllExchange />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshCryptoRiskDaily"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshCryptoRiskDaily />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshCryptoSmartDaily"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshCryptoSmartDaily />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshCryptoPricesDaily"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshCryptoPricesDaily />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshCurrecyRatesDaily"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshCurrecyRatesDaily />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/ProcessingRefreshCryptoBasketDaily"
					element={
						<ProtectedRoutes>
							<ProcessingRefreshCryptoBasketDaily />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/EditExchanges"
					element={
						<ProtectedRoutes>
							<EditExchanges />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/HelpBinanceAccount"
					element={
						<ProtectedRoutes>
							<HelpBinanceAccount />
						</ProtectedRoutes>
					}
				/>

				{/* 
        <Route path="/Accounts" element={
        <ProtectedRoutes>
          <Accounts/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/NewAccounts" element={
        <ProtectedRoutes>
          <NewAccounts/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/EditAccounts" element={
        <ProtectedRoutes>
          <EditAccounts/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveAccounts" element={
        <ProtectedRoutes>
          <ArchiveAccounts/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveAccountsActive" element={
          <ProtectedRoutes>
            <ArchiveAccountsActive/>
          </ProtectedRoutes>
        }/>

        <Route path="/ArchiveTaxes" element={
          <ProtectedRoutes>
            <ArchiveTaxes/>
          </ProtectedRoutes>
        }/>

        <Route path="/AccountsArchive" element={
        <ProtectedRoutes>
          <AccountsArchive/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewBalanceInitial" element={
        <ProtectedRoutes>
          <NewBalanceInitial/>
        </ProtectedRoutes>
        }/>

        <Route path="/Subscriptions" element={
        <ProtectedRoutes>
          <Subscriptions/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewSubscriptions" element={
        <ProtectedRoutes>
          <NewSubscriptions/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditSubscriptions" element={
        <ProtectedRoutes>
          <EditSubscriptions/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveSubscriptions" element={
        <ProtectedRoutes>
          <ArchiveSubscriptions/>
        </ProtectedRoutes>
        }/>

        <Route path="/SubscriptionsArchive" element={
        <ProtectedRoutes>
          <SubscriptionsArchive/>
        </ProtectedRoutes>
        }/>

        <Route path="/Taxes" element={
        <ProtectedRoutes>
          <Taxes/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewTaxes" element={
        <ProtectedRoutes>
          <NewTaxes/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditTaxes" element={
        <ProtectedRoutes>
          <EditTaxes/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveTaxes" element={
        <ProtectedRoutes>
          <ArchiveTaxes/>
        </ProtectedRoutes>
        }/>

        <Route path="/TaxesArchive" element={
        <ProtectedRoutes>
          <TaxesArchive/>
        </ProtectedRoutes>
        }/>

        <Route path="/Wallets" element={
        <ProtectedRoutes>
          <Wallets/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewWallets" element={
        <ProtectedRoutes>
          <NewWallets/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditWallets" element={
        <ProtectedRoutes>
          <EditWallets/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveWallets" element={
        <ProtectedRoutes>
          <ArchiveWallets/>
        </ProtectedRoutes>
        }/>

        <Route path="/WalletsArchive" element={
        <ProtectedRoutes>
          <WalletsArchive/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/Exchanges" element={
        <ProtectedRoutes>
          <Exchanges/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/NewExchanges" element={
        <ProtectedRoutes>
          <NewExchanges/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/ExchangesArchive" element={
        <ProtectedRoutes>
          <ExchangesArchive/>
        </ProtectedRoutes>
        }/>
 
        <Route path="/ArchiveExchanges" element={
        <ProtectedRoutes>
          <ArchiveExchanges/>
        </ProtectedRoutes>
        }/>

        <Route path="/Utilities" element={
        <ProtectedRoutes>
          <Utilities/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewUtilities" element={
        <ProtectedRoutes>
          <NewUtilities/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditUtilities" element={
        <ProtectedRoutes>
          <EditUtilities/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveUtilities" element={
        <ProtectedRoutes>
          <ArchiveUtilities/>
        </ProtectedRoutes>
        }/>

        <Route path="/UtilitiesArchive" element={
        <ProtectedRoutes>
          <UtilitiesArchive/>
        </ProtectedRoutes>
        }/> 

        <Route path="/Insurances" element={
        <ProtectedRoutes>
          <Insurances/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewInsurances" element={
        <ProtectedRoutes>
          <NewInsurances/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditInsurances" element={
        <ProtectedRoutes>
          <EditInsurances/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveInsurances" element={
        <ProtectedRoutes>
          <ArchiveInsurances/>
        </ProtectedRoutes>
        }/>

        <Route path="/InsurancesArchive" element={
        <ProtectedRoutes>
          <InsurancesArchive/>
        </ProtectedRoutes>
        }/> 

        <Route path="/Loans" element={
        <ProtectedRoutes>
          <Loans/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewLoans" element={
        <ProtectedRoutes>
          <NewLoans/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditLoans" element={
        <ProtectedRoutes>
          <EditLoans/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveLoans" element={
        <ProtectedRoutes>
          <ArchiveLoans/>
        </ProtectedRoutes>
        }/>

        <Route path="/LoansArchive" element={
        <ProtectedRoutes>
          <LoansArchive/>
        </ProtectedRoutes>
        }/> 

        <Route path="/Investments" element={
        <ProtectedRoutes>
          <Investments/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewInvestments" element={
        <ProtectedRoutes>
          <NewInvestments/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewInvestmentsReforces" element={
        <ProtectedRoutes>
          <NewInvestmentsReforces/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewInvestmentsWithdraws" element={
        <ProtectedRoutes>
          <NewInvestmentsWithdraws/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditInvestments" element={
        <ProtectedRoutes>
          <EditInvestments/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditInvestmentStocks" element={
        <ProtectedRoutes>
          <EditInvestmentStocks/>
        </ProtectedRoutes>
        }/>

        <Route path="/InvestmentsArchive" element={
        <ProtectedRoutes>
          <InvestmentsArchive/>
        </ProtectedRoutes>
        }/> 

        <Route path="/EditInvestmentEtfs" element={
        <ProtectedRoutes>
          <EditInvestmentEtfs/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditInvestmentFundFamilys" element={
        <ProtectedRoutes>
          <EditInvestmentFundFamilys/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewCreditCards" element={
        <ProtectedRoutes>
          <NewCreditCards/>
        </ProtectedRoutes>
        }/>

        <Route path="/CreditCards" element={
        <ProtectedRoutes>
          <CreditCards/>
        </ProtectedRoutes>
        }/>

        <Route path="/EditCreditCards" element={
        <ProtectedRoutes>
          <EditCreditCards/>
        </ProtectedRoutes>
        }/>

        <Route path="/CreditCardsArchive" element={
        <ProtectedRoutes>
          <CreditCardsArchive/>
        </ProtectedRoutes>
        }/>

        <Route path="/ArchiveCreditCardsActive" element={
        <ProtectedRoutes>
          <ArchiveCreditCardsActive/>
        </ProtectedRoutes>
        }/> 

        <Route path="/NewExpenses" element={
        <ProtectedRoutes>
          <NewExpenses/>
        </ProtectedRoutes>
        }/>

        <Route path="/NewIncomes" element={
        <ProtectedRoutes>
          <NewIncomes/>
        </ProtectedRoutes>
        }/>

        <Route path="/Transactions" element={
          <ProtectedRoutes>
          <Transactions/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsAll" element={
          <ProtectedRoutes>
          <TransactionsAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsProductsAll" element={
          <ProtectedRoutes>
          <TransactionsProductsAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsUtilitiesAll" element={
          <ProtectedRoutes>
          <TransactionsUtilitiesAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsInsurancesAll" element={
          <ProtectedRoutes>
          <TransactionsInsurancesAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsProduct" element={
          <ProtectedRoutes>
          <TransactionsProduct/>
          </ProtectedRoutes>
        }/>
        
        <Route path="/TransactionsLoansAll" element={
          <ProtectedRoutes>
          <TransactionsLoansAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsInvestment" element={
          <ProtectedRoutes>
          <TransactionsInvestment/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsInvesmentAll" element={
          <ProtectedRoutes>
          <TransactionsInvesmentAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsWallet" element={
          <ProtectedRoutes>
          <TransactionsWallet/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsWalletAll" element={
          <ProtectedRoutes>
          <TransactionsWalletAll/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsTax" element={
          <ProtectedRoutes>
          <TransactionsTax/>
          </ProtectedRoutes>
        }/>

        <Route path="/TransactionsTaxesAll" element={
          <ProtectedRoutes>
          <TransactionsTaxesAll/>
          </ProtectedRoutes>
        }/>
        
        <Route path="/InvestorRiskTypes" element={
        <ProtectedRoutes>
          <InvestorRiskTypes/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/CategoryAssets"
					element={
						<ProtectedRoutes>
							<CategoryAssets />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/Countries" element={
        <ProtectedRoutes>
          <Countries/>
        </ProtectedRoutes>
        }/>

        <Route path="/Sectors" element={
        <ProtectedRoutes>
          <Sectors/>
        </ProtectedRoutes>
        }/>

        <Route path="/Industries" element={
        <ProtectedRoutes>
          <Industries/>
        </ProtectedRoutes>
        }/>

        <Route path="/Markets" element={
        <ProtectedRoutes>
          <Markets/>
        </ProtectedRoutes>
        }/>

        <Route path="/FundFamilies" element={
        <ProtectedRoutes>
          <FundFamilies/>
        </ProtectedRoutes>
        }/>

        <Route path="/StockAssets" element={
        <ProtectedRoutes>
          <StockAssets/>
        </ProtectedRoutes>
        }/>

        <Route path="/ETFAssets" element={
        <ProtectedRoutes>
          <ETFAssets/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/FutureAssets" element={
        <ProtectedRoutes>
          <FutureAssets/>
        </ProtectedRoutes>
        }/>

        <Route path="/StockPrices" element={
        <ProtectedRoutes>
          <StockPrices/>
        </ProtectedRoutes>
        }/>

        <Route path="/ETFPrices" element={
        <ProtectedRoutes>
          <ETFPrices/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/FundFamilyPrices" element={
        <ProtectedRoutes>
          <FundFamilyPrices/>
        </ProtectedRoutes>
        }/>

        <Route path="/FuturePrices" element={
        <ProtectedRoutes>
          <FuturePrices/>
        </ProtectedRoutes>
        }/>

        <Route path="/PPRPrices" element={
        <ProtectedRoutes>
          <PPRPrices/>
        </ProtectedRoutes>
        }/>
 */}

				{/* <Route path="/Explorer" element={
        <ProtectedRoutes>
          <Explorer/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/ExplorerSettings"
					element={
						<ProtectedRoutes>
							<ExplorerSettings />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/Settings" element={
        <ProtectedRoutes>
          <Settings/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/Archives" element={
        <ProtectedRoutes>
          <Archives/>
        </ProtectedRoutes>
        }/>

        <Route path="/PortfolioStocks" element={
        <ProtectedRoutes>
          <PortfolioStocks/>
        </ProtectedRoutes>
        }/>

        <Route path="/PortfolioEtfs" element={
        <ProtectedRoutes>
          <PortfolioEtfs/>
        </ProtectedRoutes>
        }/>

        <Route path="/PortfolioFundFamilys" element={
        <ProtectedRoutes>
          <PortfolioFundFamilys/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/PortfolioExchanges"
					element={
						<ProtectedRoutes>
							<PortfolioExchanges />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/PortfolioWallets" element={
        <ProtectedRoutes>
          <PortfolioWallets/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/OrderExchanges"
					element={
						<ProtectedRoutes>
							<OrderExchanges />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/OrderAllExchanges" element={
        <ProtectedRoutes>
          <OrderAllExchanges/>
        </ProtectedRoutes>
        }/> */}

				<Route
					path="/ProcessingExchanges"
					element={
						<ProtectedRoutes>
							<ProcessingExchanges />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/Disclaimer"
					element={
						<ProtectedRoutes>
							<Disclaimer />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/PrivacyPolicies"
					element={
						<ProtectedRoutes>
							<PrivacyPolicies />
						</ProtectedRoutes>
					}
				/>

				<Route
					path="/TermsService"
					element={
						<ProtectedRoutes>
							<TermsService />
						</ProtectedRoutes>
					}
				/>

				{/* <Route path="/ProcessingRefreshExchanges" element={
        <ProtectedRoutes>
          <ProcessingRefreshExchanges/>
        </ProtectedRoutes>
        }/> */}

				{/* <Route path="/ProcessingRefreshStocks" element={
        <ProtectedRoutes>
          <ProcessingRefreshStocks/>
        </ProtectedRoutes>
        }/>

        <Route path="/ProcessingRefreshEtfs" element={
        <ProtectedRoutes>
          <ProcessingRefreshEtfs/>
        </ProtectedRoutes>
        }/>

        <Route path="/ProcessingRefreshFunds" element={
        <ProtectedRoutes>
          <ProcessingRefreshFunds/>
        </ProtectedRoutes>
        }/>

        <Route path="/ProcessingRefreshWallets" element={
        <ProtectedRoutes>
          <ProcessingRefreshWallets/>
        </ProtectedRoutes>
        }/>

        <Route path="/StockSmarts" element={
        <ProtectedRoutes>
          <StockSmarts/>
        </ProtectedRoutes>
        }/>

        <Route path="/ETFSmarts" element={
        <ProtectedRoutes>
          <ETFSmarts/>
        </ProtectedRoutes>
        }/>

        <Route path="/TransactionsGraphs" element={
        <ProtectedRoutes>
          <TransactionsGraphs/>
        </ProtectedRoutes>
        }/>

        <Route path="/TransactionsStats" element={
        <ProtectedRoutes>
          <TransactionsStats/>
        </ProtectedRoutes>
        }/>

        <Route path="/StockNews" element={
        <ProtectedRoutes>
          <StockNews/>
        </ProtectedRoutes>
        }/> */}
			</Routes>
		</>
	);
}

// export function ProtectedRoutes(props){
//   if(localStorage.getItem('user')){
//     return props.children
//   }else{
//     return (
//       // <h1>Hello World</h1>
//       <div>
//         <div>
//           {/* <!-- ======= Header ======= --> */}
//           <header id="header" className="fixed-top  header-transparent">
//             <div className="container d-flex align-items-center justify-content-between">

//               <div className="logo">
//                 <h1><a href="#hero">SAVINGS4U</a></h1>
//                 {/* <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}
//               </div>

//               <nav id="navbar" className="navbar">
//                 <ul>
//                   <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
//                   <li><a className="nav-link scrollto" href="#features">App Features</a></li>
//                   <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
//                   <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
//                   <li><a className="getstarted scrollto" href="/login">Login</a></li>
//                   <li><a className="getstarted scrollto" href="/register">Get Started</a></li>
//                 </ul>
//               </nav>
//               {/* <div class="container d-flex justify-content-center justify-content-md-between">
//                 <div class="social-links d-none d-md-block">
//                   <a href="https://instagram.com/fionic.systems?igshid=MzRlODBiNWFlZA==" class="instagram"><i class="bi bi-instagram"></i></a>
//                   <a href="https://www.linkedin.com/company/fionic-systems/about/" class="linkedin"><i class="bi bi-linkedin"></i></a>
//                 </div>
//               </div> */}
//               {/* <!-- .navbar --> */}
//             </div>
//           </header>
//           {/* <!-- End Header --> */}

//           {/* <!-- ======= Hero Section ======= --> */}
//           <section id="hero" className="d-flex align-items-center">

//           {/* <div className="container">
//             <div className="row">
//               <div className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
//                 <div>
//                   <h1>Smart Budgeting for Financial Efficiency
//                   </h1>
//                   <h2>Effective financial management is the key to personal and business success. Budgeting, smart investments, and strategic planning ensure stability, growth, and long-term prosperity.</h2>
//                 </div>
//               </div>
//               <div className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
//                 <img src="assets/img/hero-img.png" className="img-fluid" alt="smartbudget"/>
//               </div>
//             </div>
//           </div> */}

//           </section>
//           {/* <!-- End Hero --> */}
//         </div>

//         {/* <main id="main">

//             <section id="features" className="features">
//               <div className="container">

//                 <div className="section-title">
//                   <h2>App Features</h2>
//                   <p>SmartBudget4 is an application of intelligent financial management, offering an optimal platform for overseeing your financial portfolio. It goes beyond, securing the best rates for your credits and insurance. Elevate your financial well-being with SmartBudget4 — your comprehensive solution for astute wealth management and cost-effective financial decisions.</p>
//                 </div>

//                 <div className="row no-gutters">
//                   <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
//                     <div className="content d-flex flex-column justify-content-center">
//                       <div className="row">
//                         <div className="col-md-6 icon-box" data-aos="fade-up">
//                           <i className="bx bx-receipt"></i>
//                           <h4>Manage all Bank Accounts, Credit Cards, and Loans</h4>
//                           <p>Manage all bank accounts, credit cards, and loans seamlessly with our comprehensive financial platform. Streamline your finances effortlessly</p>
//                         </div>
//                         <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
//                           <i className="bx bx-cube-alt"></i>
//                           <h4>Manage all Investiments</h4>
//                           <p>Maximize returns, minimize risks. Explore diverse investment opportunities for financial growth and stability. Your path to wealth creation starts here</p>
//                         </div>
//                         <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
//                           <i className="bx bx-images"></i>
//                           <h4>Manage Insurances</h4>
//                           <p>Safeguard your future with tailored insurances. From health to property, find comprehensive coverage for peace of mind and financial security</p>
//                         </div>
//                         <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
//                           <i className="bx bx-shield"></i>
//                           <h4>Manage Subscriptions</h4>
//                           <p>Effortlessly manage subscriptions for services you love. Stay in control of expenses with our user-friendly platform. Simplify your financial commitments</p>
//                         </div>
//                         <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
//                           <i className="bx bx-atom"></i>
//                           <h4>Utility and Cable expenses</h4>
//                           <p>Optimize utility and cable expenses. Efficiently manage communications costs for savings. Streamline your financial management with our user-friendly platform</p>
//                         </div>
//                         <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
//                           <i className="bx bx-id-card"></i>
//                           <h4>Manage Taxes</h4>
//                           <p>Navigate tax complexities effortlessly. Optimize deductions and credits for maximum savings. Simplify tax season with our comprehensive financial tools</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
//                     <img src="assets/img/details-4.png" className="img-fluid" alt="smartbudget"/>
//                   </div>
//                 </div>

//               </div>
//             </section>

//             <section id="details" className="details">
//               <div className="container">

//                 <div className="row no-gutters">
//                   <div className="col-md-4" data-aos="fade-right">
//                     <img src="assets/img/details-1.png" className="img-fluid" alt="smartbudget"/>
//                   </div>
//                   <div className="col-md-8 pt-4" data-aos="fade-up">
//                     <h3>Open Finance</h3>
//                     <br/>
//                     <p className="fst-italic">
//                       Financial providers harness the power of Open Finance data to enhance existing offerings and craft personalized products, revolutionizing the financial landscape.
//                     </p>
//                     <p>
//                       The benefits extend across various sectors, with potential savings reaching hundreds or even thousands of euros, particularly evident in housing credit scenarios. The utilization of Open Finance data not only optimizes cost efficiency for customers but also opens avenues for innovative solutions, making financial services more tailored, competitive, and ultimately, more aligned with the evolving needs of the market. In this era of data-driven insights, Open Finance emerges as a catalyst for positive change, ushering in a new era where financial providers can create unparalleled value for their clientes through informed decision-making and tailored financial solutions.
//                     </p>
//                   </div>
//                 </div>

//                 <div className="row no-gutters">
//                   <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
//                     <img src="assets/img/details-2.png" className="img-fluid" alt="smartbudget"/>
//                   </div>
//                   <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
//                     <h3>SmartBudget4U revolutionizes investment management with a single, user-friendly platform.</h3>
//                     <br/>
//                     <p>Simplify your financial portfolio oversight seamlessly. Tailor portfolios to minimize risk or maximize returns. Our application streamlines investment strategies, offering unparalleled ease and efficiency. Whether you aim for minimal risk or aspire to high returns, SmartBudget4U optimizes your investment portfolio.</p>
//                     <p>
//                       Experience the power of comprehensive financial management at your fingertips, enhancing your ability to make informed decisions and achieve your financial goals.
//                     </p>
//                   </div>
//                 </div>

//                 <div className="row no-gutters">
//                   <div className="col-md-4" data-aos="fade-right">
//                     <img src="assets/img/details-3.png" className="img-fluid" alt="smartbudget"/>
//                   </div>
//                   <div className="col-md-8 pt-5" data-aos="fade-up">
//                     <h3>Brokers and Exchanges All-in-One Solution</h3>
//                     <br/>
//                     <p className="fst-italic">
//                       Transform your investment experience with SmartBudget4U by seamlessly linking your exchanges and brokers.
//                     </p>
//                     <p>
//                       This revolutionary platform empowers you to craft personalized robo-advisors for ESG stocks, cryptocurrencies, investment funds, and ETFs. By centralizing your financial landscape, SmartBudget4U provides a unified hub for monitoring and managing various investment portfolios. The platform's user-friendly interface facilitates the creation and optimization of robo-advisors tailored to your specific preferences and risk appetite.
//                     </p>
//                     <p>
//                       Whether you're delving into ESG-focused stocks or exploring the dynamic world of cryptocurrencies, SmartBudget4U simplifies the process, offering a comprehensive solution for investors seeking versatility, efficiency, and informed decision-making. Embrace the future of financial management as you connect, strategize, and optimize your investment ventures effortlessly through SmartBudget4U's integrated platform.
//                     </p>
//                   </div>
//                 </div>

//               </div>
//             </section>

//             <section id="pricing" className="pricing">
//               <div className="container">

//                 <div className="section-title">
//                   <h2>Pricing</h2>
//                   <p>Experience simplicity with our flat fees at SmartBudget4U. No surprises, transparent pricing ensures a clear and straightforward financial experience tailored to your needs. Clarity and predictability in every transaction, empowering you with confidence in managing your finances.</p>
//                 </div>

//                 <div className="row no-gutters">

//                   <div className="col-lg-4 box" data-aos="fade-right">
//                     <h3>BASIC</h3>
//                     <h4>€0<span>per month</span></h4>
//                     <ul>
//                       <li><i className="bx bx-check"></i> Account Management</li>
//                       <li><i className="bx bx-check"></i> Loans</li>
//                       <li><i className="bx bx-check"></i> Insurances</li>
//                       <li><i className="bx bx-check"></i> <span>Utilities & Cable</span></li>
//                       <li ><i className="bx bx-check"></i> <span>Subscriptions</span></li>
//                       <li><i className="bx bx-check"></i> <span>Savings</span></li>
//                       <li><i className="bx bx-check"></i> <span>Taxes</span></li>
//                     </ul>
//                     <a href="#hero" className="get-started-btn">Coming Soon</a>
//                   </div>

//                   <div className="col-lg-4 box featured" data-aos="fade-up">
//                     <h3>INVESTOR</h3>
//                     <h4>€4.99<span>per month</span></h4>
//                     <ul>
//                       <li><i className="bx bx-check"></i> Plan Basic</li>
//                       <li><i className="bx bx-check"></i> Investiments</li>
//                       <li><i className="bx bx-check"></i> News</li>
//                       <li><i className="bx bx-check"></i> Investment Portfolio Optimization</li>
//                       <li><i className="bx bx-check"></i> Smart-Advisor</li>
//                       <li><i className="bx bx-check"></i> API Connections</li>
//                     </ul>
//                     <a href="#hero" className="get-started-btn">Coming Soon</a>
//                   </div>

//                   <div className="col-lg-4 box" data-aos="fade-left">
//                     <h3>ROBOADVISOR</h3>
//                     <h4>€9.99<span>per month</span></h4>
//                     <ul>
//                       <li><i className="bx bx-check"></i> Plan Investor</li>
//                       <li><i className="bx bx-check"></i> Stock-Roboadvisor</li>
//                       <li><i className="bx bx-check"></i> Mutual Funds-Roboadvisor</li>
//                       <li><i className="bx bx-check"></i> Cripto-Roboadvisor</li>
//                       <li><i className="bx bx-check"></i> ETF-Roboadvisor</li>
//                       <li><i className="bx bx-check"></i> Financials Highlights</li>
//                       <li><i className="bx bx-check"></i> Trading Information</li>
//                     </ul>
//                     <a href="#hero" className="get-started-btn">Coming Soon</a>
//                   </div>

//                 </div>

//               </div>
//             </section>

//             <section id="contact" className="contact">
//               <div className="container" data-aos="fade-up">

//                 <div className="section-title">
//                   <h2>Contact</h2>
//                   <p>For personalized solutions and dedicated support, contact us today. Our team is ready to assist you with any inquiries or information you may need. Connect with us for a seamless experience tailored to your financial needs.</p>
//                 </div>

//                 <div className="row">

//                   <div className="col-lg-12">
//                     <div className="row">
//                       <div className="col-lg-6 info">
//                         <i className="bx bx-map"></i>
//                         <h4>Address</h4>
//                         <p>Av. da República 25 0E,<br/>1050-186 Lisboa - Portugal</p>
//                       </div>
//                       <div className="col-lg-6 info">
//                         <i className="bx bx-phone"></i>
//                         <h4>Call Us</h4>
//                         <p>+351 211 909 540<br/>+351 964 307 782</p>
//                       </div>
//                       <div className="col-lg-6 info">
//                         <i className="bx bx-envelope"></i>
//                         <h4>Email Us</h4>
//                         <p>contact@smartbudget4u.com<br/>info@smartbudget4u.com</p>
//                       </div>
//                       <div className="col-lg-6 info">
//                         <i className="bx bx-time-five"></i>
//                         <h4>Working Hours</h4>
//                         <p>Mon - Fri: 9AM to 6PM<br/></p>
//                       </div>
//                     </div>
//                   </div>

//                   <div></div>

//                 </div>

//               </div>
//             </section>

//             </main> */}
//         {/* <!-- End #main --> */}

//         {/* <!-- ======= Footer ======= --> */}
//         {/* <footer id="footer">

//         <div className="container py-4">
//           <div className="copyright">
//             &copy; Copyright <strong><span>Appland</span></strong>. All Rights Reserved
//           </div>
//           <div className="credits">
//             Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
//           </div>
//         </div>
//         </footer> */}
//         {/* <!-- End Footer --> */}

//         <a href="#hero" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

//       </div>

//       )
//   }
// }

export function ProtectedRoutes(props) {
	if (localStorage.getItem("user")) {
		return props.children;
	} else {
		return <Navigate to="/login" />;
	}
}

export default App;
