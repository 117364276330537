import React, { useState, useEffect } from "react";
import { Form, Input, message, Button, Card, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { EditOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import axios from "axios";

const EditExchanges = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const exchangeDataUpdate = data;
	const exchangeIdUpdate = data.exchangeID;
	const exchangeNameUpdate = data.exchangeName;
	const exchangeDescriptionUpdate = data.exchangeDescription;
	const brokerNameUpdate = data.brokerName;
	const apiKeyUpdate = data.apiKey;
	const keySecretUpdate = data.keySecret;
	const currencyUpdate = data.currency;
	const frequencyUpdate = data.frequency;
	const goalNameUpdate = data.goalName;
	// const statusUpdate = data.status;
	const exchangeUpdate = data.codeSquare;

	// console.log("data:",data)

	const { Meta } = Card;

	const [showModal, setShowModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [allAccount, setAllAccount] = useState([]);

	// const [show, setShow] = useState(false);

	const { TextArea } = Input;

	//Handler Fund Family DropList ****************************************************************************************
	// useEffect(() => {
	//   const getAllAccounts = async (values) => {
	//     try{
	//       const user = JSON.parse(localStorage.getItem('user'))
	//       setLoading(true);
	//       const res = await axios.post('/accounts/get-oneaccount', {
	//         userid:user._id,
	//         _id: accountNameUpdate
	//       },
	//       {
	//         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//       }
	//       );
	//       setLoading(false);
	//       setAllAccount(res.data);
	//       console.log(res.data);
	//     }catch (error){
	//       setLoading(false);
	//       //message.error('Fetch Issue With sector');
	//       message.error('You are not Admin User');
	//     }
	//   };
	//   getAllAccounts();
	// },[accountNameUpdate])

	//Handlers User  **************************************************************************************************************************
	let now = new Date();
	let today = new Date(now.getTime());

	const handleSubmitEditExchanges = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);

			const res = await axios.post(
				"/exchanges/edit-exchange",
				{
					payload: {
						...values,
						userid: user._id,
					},
					_id: data.exchangeID,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);
			message.success("Exchange Account Updated Successfully!");
			navigate("/MyInvestments");
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};

	let brokerInformationHTML;
	if (brokerNameUpdate === "Binance") {
		brokerInformationHTML = (
			<div className="vertical-select">
				<Form.Item label="Api Key" name="apiKey">
					<Input
						defaultValue={apiKeyUpdate}
						style={{
							width: "100%",
						}}
						type="text"
						suffix={<EditOutlined twoToneColor="#52c41a" />}
					/>
				</Form.Item>
				<Form.Item label="Key Secret" name="keySecret">
					<Input
						defaultValue={keySecretUpdate}
						style={{
							width: "100%",
						}}
						type="text"
						suffix={<EditOutlined twoToneColor="#52c41a" />}
					/>
				</Form.Item>
			</div>
		);
	} else {
		brokerInformationHTML = <br></br>;
		// <p>Sem dados</p>;
		<Form.Item hidden="true">
			<Input type="text" />
		</Form.Item>;
	}

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<div className="accountpage">
						<div></div>
						<div>
							<h1>
								Edit Portfolio <br /> {exchangeNameUpdate}
							</h1>
						</div>
						<div></div>
					</div>
					<br></br>
					<div>
						<div></div>
						<div aling="center" className="registerAccount">
							<Form layout="vertical" onFinish={handleSubmitEditExchanges}>
								<h5 style={{ marginBottom: 10, marginTop: 30 }}>Portfolio</h5>

								<Form.Item label="" name="investmentName">
									<Input
										defaultValue={exchangeNameUpdate}
										type="text"
										suffix={<EditOutlined twoToneColor="#52c41a" />}
									/>
								</Form.Item>

								{brokerInformationHTML}

								<Form.Item>
									<Button
										style={{ width: "100%", marginTop: "20px" }}
										type="primary"
										htmlType="submit"
									>
										EDIT
									</Button>
									<Button
										style={{
											width: "100%",
											background: "white",
											color: "Blue",
											marginTop: 30,
											marginBottom: 30,
										}}
										type="primary"
										htmlType="submit"
										onClick={() => navigate("/MyInvestments")}
									>
										CANCEL
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div></div>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default EditExchanges;
