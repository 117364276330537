import React, { useState, useEffect } from "react";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import {
	Card,
	Typography,
	Row,
	Col,
	Switch,
	Table,
	Input,
	Button,
	Space,
	DatePicker,
	Tabs,
	Tooltip,
	Statistic,
	Divider,
	Collapse,
	Form,
	message,
	Popconfirm,
	Modal,
	Select,
	Menu,
	Dropdown,
	Slider,
	Progress,
	Layout,
	Spin,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { format, subDays } from "date-fns";

import axios from "axios";

import moment from "moment";

import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";

import Chart from "react-apexcharts";
import {
	ArrowLeftRight,
	DollarSign,
	Edit2,
	RefreshCwIcon,
	TrendingUp,
	XCircle,
	RocketIcon,
	Rocket,
} from "lucide-react";

const SavingPortfolio = ({ portfolio }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user.profileType;
	const currencyRate = user.currency;

	// const currencySymbol = user_currency === 'USD' ? '$' : '€';
	const currencySymbol = "$";

	const { Title, Text } = Typography;
	const { Meta } = Card;
	const { Option } = Select; // Extract Option from Select

	// const [showModal, setShowModal] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	// console.log("data:",data)
	const savingNameUpdate = data.savingName;
	const productNameUpdate = data.productName;
	const savingProcessIDUpdate = data.savingProcessID;
	const closePortfolioUpdate = data.closePortfolio;
	const addFundsUpdate = data.addFunds;
	const isActiveTradingUpdate = data.isActiveTrading;
	const isOwnPortfolioUpdate = data.isOwnPortfolio;

	console.log("data:", data);

	const [loading, setLoading] = useState(false);

	const [allSavingVar, setAllSavingVar] = useState([]);
	const [allSavingPortfolio, setAllSavingPortfolio] = useState([]);
	const [allTransactions, setAllTransactions] = useState([]);

	const [allSavingPerformancePortfolio, setAllSavingPerformancePortfolio] =
		useState([]);
	const [allSavingBalance, setAllSavingBalance] = useState([]);
	const [allSavingStats, setAllSavingStats] = useState([]);

	//CARDS

	//useEffect Hook
	useEffect(() => {
		const getAllSavingVar = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingvars/get-saving-var",
					{
						userid: user._id,
						savingProcessId: savingProcessIDUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSavingVar(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingVar();
	}, [savingProcessIDUpdate]);

	//Analytics
	// const VaR99 = allSavingVar.reduce(
	// 	(acc, transaction) => acc + transaction.VaR_pct_99,
	// 	0
	// );

	// const VaR95 = allSavingVar.reduce(
	// 	(acc, transaction) => acc + transaction.VaR_pct_95,
	// 	0
	// );

	//DASHOBOARD

	const balance = allSavingBalance[0] || {};

	const handleBack = () => {
		navigate("/");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	// Handler function for navigation
	const handleAddFunds = () => {
		// Check if required properties are present before navigating
		if (balance.savingName && balance.productName && balance.exchangeName) {
			navigate("/SavingDeposit", {
				state: {
					savingNameID: balance.savingName._id,
					savingName: balance.savingName.savingName,
					savingProcessID: balance.savingProcessId,
					productName: balance.productName.productName,
					productNameID: balance.productName._id,
					minimumInvestment: balance.productName.minimumInvestment,
					investmentType: balance.savingName.investmentType,
					exchangeName: balance.exchangeName.id,
				},
			});
		} else {
			console.log("Missing required properties in balance:", balance);
		}
	};

	// Handler function for navigation
	const handleBuyCrypto = () => {
		// Check if required properties are present before navigating
		if (balance.savingName && balance.productName && balance.exchangeName) {
			navigate("/BuyOwnSavings", {
				state: {
					savingNameID: balance.savingName._id,
					savingName: balance.savingName.savingName,
					savingProcessID: balance.savingProcessId,
					productName: balance.productName.productName,
					productNameID: balance.productName._id,
					minimumInvestment: balance.productName.minimumInvestment,
					investmentType: balance.savingName.investmentType,
					exchangeName: balance.exchangeName.id,
				},
			});
		} else {
			console.log("Missing required properties in balance:", balance);
		}
	};

	// Handler function for navigation
	const handleEditPortfolio = () => {
		navigate("/EditSavings", {
			state: {
				savingNameID: balance.savingName._id,
				savingName: balance.savingName.savingName,
				target: balance.savingName.target,
				savingProcessID: balance.savingProcessId,
				categoryNameUpdate: balance.savingName.categoryName,
				productNameUpdate: balance.productName.productNamePT,
				agentNameUpdate: balance.agentName,
				IBANUpdate: balance.savingName.IBAN,
				monthlyAmountUpdate: balance.savingName.monthlyAmount,
				dayPaymentUpdate: balance.savingName.dayPayment,
				isDepositActiveUpdate: balance.savingName.isDepositActive,
				takeProfitUpdate: balance.savingName.takeProfit,
				percentageTPUpdate: balance.savingName.percentageTP,
				stopLossUpdate: balance.savingName.stopLoss,
				percentageSLUpdate: balance.savingName.percentageSL,
				maxInvestCryptoUpdate: balance.savingName.maxInvestCrypto,
				investmentTypeUpdate: balance.savingName.investmentType,
				initialValueUpdate: balance.savingName.initialValue,
				closePortfolioUpdate: balance.savingName.closePortfolio,
				archivePortfolioUpdate: balance.savingName.archivePortfolio,
			},
		});
	};

	// Handler function for navigation
	const handleClosePortfolio = () => {
		navigate("/CloseSavings", {
			state: {
				savingNameID: balance.savingName._id,
				savingName: balance.savingName.savingName,
				savingProcessID: balance.savingProcessId,
				productName: balance.productName.productName,
				productNameID: balance.productName._id,
				minimumInvestment: balance.productName.minimumInvestment,
				investmentType: balance.savingName.investmentType,
				exchangeName: balance.exchangeName.id,
			},
		});
	};

	const [isVisible, setIsVisible] = useState(false);
	const [showDailyReturns, setShowDailyReturns] = useState(false);
	const [isUSD, setIsUSD] = useState(true);
	const [exchangeRate, setExchangeRate] = useState({ usd: 1, eur: 1 });

	const [selectedPeriod, setSelectedPeriod] = useState("7d");

	const convertReturnsToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;

		// Determine the number of decimal places based on thresholds
		let decimalPlaces;

		decimalPlaces = 2; // Limit to 2 decimals for large amounts

		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(convertedAmount);
	};

	const convertToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;

		// Determine the number of decimal places based on thresholds
		let decimalPlaces;

		// For large amounts like Bitcoin, set a maximum number of decimal places
		if (convertedAmount >= 1) {
			decimalPlaces = 2; // Limit to 2 decimals for large amounts
		} else if (convertedAmount >= 0.1) {
			decimalPlaces = 2; // 3 decimals for values >= 0.01
		} else if (convertedAmount >= 0.01) {
			decimalPlaces = 3; // 3 decimals for values >= 0.001
		} else if (convertedAmount >= 0.001) {
			decimalPlaces = 4; // 3 decimals for values >= 0.001
		} else {
			decimalPlaces = 6; // 6 decimals for very small values (less than 0.00001)
		}

		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(convertedAmount);
	};

	// Toggle visibility handler
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	// Toggle return type handler
	const toggleReturnType = (checked) => {
		setShowDailyReturns(checked);
	};

	// Toggle currency type handler
	const toggleCurrency = (checked) => {
		setIsUSD(checked);
	};

	// Fetch exchange rates from CoinGecko API
	const fetchExchangeRate = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=" +
					user.currency.toLowerCase() +
					",usd&vs_currencies=" +
					user.currency.toLowerCase() +
					",usd"
			);
			const data = await response.json();
			setExchangeRate({
				usd: data.usd[user.currency.toLowerCase()], // USD to EUR rate
				eur: 1, // Base currency EUR
			});
			console.log(data);
		} catch (error) {
			console.error("Error fetching exchange rate:", error);
		}
	};

	// Call the fetch function when the component mounts
	useEffect(() => {
		fetchExchangeRate();
	}, []);

	useEffect(() => {
		const getAllSavingBalance = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingbalances/get-savingbalance",
					{
						userid: user._id,
						savingProcessId: savingProcessIDUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSavingBalance(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingBalance();
	}, [savingProcessIDUpdate]);

	//Analytics
	const currentBalance = allSavingBalance.reduce(
		(acc, transaction) => acc + transaction.today_value,
		0
	);

	const returns = allSavingBalance.reduce(
		(acc, transaction) => acc + transaction.diffAccum,
		0
	);

	const dailyReturn = allSavingBalance.reduce(
		(acc, transaction) => acc + transaction.diffDaily,
		0
	);

	const invested = currentBalance - returns;

	const returnPercentage = ((returns / invested) * 100).toFixed(2);
	const dailyReturnPercentage = ((dailyReturn / invested) * 100).toFixed(2);

	// const currentBalance = allSavingBalance.reduce(
	// 	(acc, transaction) => acc + transaction.today_value,
	// 	0
	// );

	// const returns = allSavingBalance
	// 	.reduce(
	// 		(acc, transaction) => acc + parseFloat(transaction.diffAccum.toFixed(2)),
	// 		0
	// 	)
	// 	.toFixed(2);

	// const dailyReturn = allSavingBalance
	// 	.reduce(
	// 		(acc, transaction) => acc + parseFloat(transaction.diffDaily.toFixed(2)),
	// 		0
	// 	)
	// 	.toFixed(2);

	// const invested = (parseFloat(currentBalance) - parseFloat(returns)).toFixed(
	// 	2
	// );

	// const returnPercentage = (
	// 	(parseFloat(returns) / parseFloat(invested)) *
	// 	100
	// ).toFixed(2);
	// const dailyReturnPercentage = (
	// 	(parseFloat(dailyReturn) / parseFloat(invested)) *
	// 	100
	// ).toFixed(2);

	const returnColor = returns >= 0 ? "green" : "red";
	const dailyReturnColor = dailyReturn >= 0 ? "green" : "red";

	// CHART

	const [allSavingPerformances, setSavingPerformances] = useState([]);

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllSavingPerformances = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingperformances/get-savingperformance",
					{
						userid: user._id,
						savingProcessId: savingProcessIDUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setSavingPerformances(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingPerformances();
	}, []);

	// Helper function to filter the data by a given range
	const filterDataByPeriod = (data, days) => {
		const endDate = new Date();
		const startDate = subDays(endDate, days); // Calculate the start date

		return data.filter((item) => {
			const itemDate = new Date(item.dateSavingPerformance);
			return itemDate >= startDate && itemDate <= endDate; // Include only the items within the range
		});
	};

	// Calculate the max value
	const getMaxValue = (data) => {
		return Math.max(...data.map((item) => item.today_value)); // Return the highest value
	};

	// Helper function to get min value
	const getMinValue = (data) =>
		Math.min(...data.map((item) => item.today_value));

	// Helper function to calculate cumulative return
	const getCumulativeReturn = (data) => {
		const initialValue = data[0]?.today_value || 0;
		const finalValue = data[data.length - 1]?.today_value || 0;
		return initialValue
			? ((finalValue - initialValue) / initialValue) * 100
			: 0;
	};

	// Data for different periods
	const periodData = {
		"7d": filterDataByPeriod(allSavingPerformances, 7),
		"1mo": filterDataByPeriod(allSavingPerformances, 30),
		"3mo": filterDataByPeriod(allSavingPerformances, 90),
		"1yr": filterDataByPeriod(allSavingPerformances, 365),
		allTime: allSavingPerformances, // For MAX, use all-time data
	};

	// Get the data for the selected period
	const dataToUse = periodData[selectedPeriod];

	// Get the max value for the selected period
	const maxValue = getMaxValue(dataToUse);

	// Get the min value for the selected period
	const minValue = getMinValue(dataToUse);

	// Get the cumulative return for the selected period
	const cumulativeReturn = getCumulativeReturn(dataToUse);

	// Calculate the midpoint of minValue and maxValue
	const midValue = (maxValue + minValue) / 2;

	// Prepare chart data
	const chartData = dataToUse.map((item) => ({
		x: item.dateSavingPerformance,
		y: item.today_value,
	}));

	const options = {
		chart: {
			id: "portfolio-performance-chart",
			type: "area",
			toolbar: { show: false },
		},
		xaxis: {
			type: "datetime",
		},
		yaxis: {
			labels: {
				formatter: (value) => `$${value.toLocaleString()}`,
			},
		},
		stroke: {
			curve: "smooth",
			width: 2,
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 0.4,
		},
		tooltip: {
			x: {
				formatter: (value) => new Date(value).toLocaleDateString(),
			},
		},
		annotations: {
			yaxis: [
				{
					y: maxValue,
					borderColor: "#FF4560",
					label: {
						text: `Max: $${maxValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#FF4560",
						},
					},
				},
				{
					y: minValue,
					borderColor: "#775DD0",
					label: {
						text: `Min: $${minValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#775DD0",
						},
					},
				},
				{
					y: midValue, // Positioning Cumulative Return at the midpoint
					borderColor: "#00E396",
					label: {
						text: `Cumulative Return: ${cumulativeReturn.toFixed(2)}%`,
						style: {
							color: "#fff",
							background: "#00E396",
						},
					},
				},
			],
		},
	};

	//MY PORTFOLIO

	useEffect(() => {
		const getAllSavingPortfolio = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingportfolios/get-savingportfolio",
					{
						userid: user._id,
						savingProcessId: savingProcessIDUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSavingPortfolio(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingPortfolio();
	}, [savingProcessIDUpdate]);

	// Helper function to generate image URLs dynamically
	const generateImageURL = (symbol) => {
		if (symbol.toLowerCase() === "matic") {
			symbol = "matic"; // Adjust to "maticusdt" if it's "polusdt"
		}
		return `https://static.monnos.com/coins/${symbol.toUpperCase()}.svg`;
	};

	// Function to extract the coin symbol (e.g., "btc" from "btcusdt")
	const extractCoinSymbol = (symbol) => {
		if (symbol.toLowerCase() === "polusdt") {
			return "matic"; // Replace "pol" with "matic"
		}
		return symbol.slice(0, -4).toLowerCase(); // Remove "usdt" from the end
	};

	// Define columns for the Ant Design table
	const columnsMyPortfolio = [
		{
			title: "",
			dataIndex: "image",
			render: (text, record) => {
				const imageURL = generateImageURL(
					extractCoinSymbol(record.cryptoAssetName.binanceTicker)
				);

				return (
					<img
						src={imageURL} // Dynamically generate the image URL
						alt={record.name}
						style={{ width: "30px", height: "30px", display: "inline-block" }} // Optional styling
						onError={(e) => {
							e.target.onerror = null; // Prevent infinite onError loops
							e.target.style.display = "none"; // Hide the image if it fails to load
						}}
					/>
				);
			},
		},
		{
			title: "Coin Info",
			dataIndex: "info",
			render: (text, record) => (
				<div>
					<p style={{ margin: 0 }} className="coin-symbol">
						{extractCoinSymbol(
							record.cryptoAssetName.binanceTicker
						).toUpperCase()}{" "}
						{/* Extracted and formatted symbol */}
					</p>
					<p style={{ margin: 0 }} className="coin-name">
						{record.cryptoAssetName.cryptoAssetName}
					</p>
				</div>
			),
		},
		{
			title: "Current Price",
			dataIndex: "today_price",
			render: (text, record) => {
				const convertedPrice = convertToCurrency(record.today_price, "USD");
				return <span>{convertedPrice.toLocaleString()}</span>;
			},
		},
		{
			title: "Value",
			dataIndex: "today_value",
			render: (text, record) => {
				const convertedTodayValue = convertToCurrency(
					record.today_value,
					"USD"
				);
				return <span>{convertedTodayValue.toLocaleString()}</span>;
			},
		},
		{
			title: "Weights %",
			dataIndex: "weights_pct",
			defaultSortOrder: "descend",
			sorter: (a, b) => a.weights_pct - b.weights_pct,
			render: (text) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<span>{(text * 100).toFixed(2)}%</span>
				</div>
			),
		},
	];

	//My Activities

	//useEffect Hook
	useEffect(() => {
		const getAllTransactions = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/ordertransactions/get-order-transactions",
					{
						userid: user._id,
						savingProcessId: savingProcessIDUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllTransactions(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllTransactions();
	}, [savingProcessIDUpdate]);

	const columnsOrders = [
		{
			key: "datePrice",
			title: "Trading Date",
			dataIndex: "datePrice",
			defaultSortOrder: "descend",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.datePrice) - new Date(b.datePrice),
		},
		{
			key: "cryptoAssetDescription",
			title: "Crypto Name",
			dataIndex: ["cryptoAssetDescription"],
			sorter: (a, b) =>
				a.cryptoAssetDescription.localeCompare(b.cryptoAssetDescription),
		},
		// {
		//   key: "binanceTicker",
		//   title: "Binance Ticker",
		//   dataIndex: ["cryptoAssetName","binanceTicker"],
		//   sorter: (a, b) => a.cryptoAssetName.binanceTicker.localeCompare(b.cryptoAssetName.binanceTicker),
		//   filterDropdown: ({
		//     setSelectedKeys,
		//     selectedKeys,
		//     confirm,
		//     clearFilters,
		//   }) => {
		//     return (
		//       <>
		//         <Input
		//           autoFocus
		//           placeholder="Type text here"
		//           value={selectedKeys[0]}
		//           onChange={(e) => {
		//             setSelectedKeys(e.target.value ? [e.target.value] : []);
		//             confirm({ closeDropdown: false });
		//           }}
		//           onPressEnter={() => {
		//             confirm();
		//           }}
		//           onBlur={() => {
		//             confirm();
		//           }}
		//         ></Input>
		//         <Button
		//           onClick={() => {
		//             confirm();
		//           }}
		//           type="primary"
		//         >
		//           Search
		//         </Button>
		//         <Button
		//           onClick={() => {
		//             clearFilters();
		//           }}
		//           type="danger"
		//         >
		//           Reset
		//         </Button>
		//       </>
		//     );
		//   },
		//   filterIcon: () => {
		//     return <SearchOutlined />;
		//   },
		//   onFilter: (value, record) => {
		//     return record.cryptoAssetName.binanceTicker.toLowerCase().includes(value.toLowerCase());
		//   },
		// },
		{
			key: "action",
			title: "BUY / SELL",
			dataIndex: ["action"],
			align: "center",
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (status, record) => {
				if (record.today_value >= 0)
					return <span style={{ color: "#39A939" }}>BUY</span>;
				return <span style={{ color: "#FF3234" }}>SELL</span>;
			},
		},
		// {
		//   key: "qty",
		//   title: "Quantity",
		//   dataIndex: "qty",
		//   align: "center",
		//   sorter: (a, b) => a.qty-b.qty,
		//   render: (qty, record) => {
		//       return (
		//           <span>
		//               {qty.toLocaleString('de-DE', {
		//                   maximumFractionDigits: 5
		//               })}
		//           </span>
		//       );
		//   },
		// },
		{
			key: "today_price",
			title: "Price",
			dataIndex: "today_price",
			align: "right",
			sorter: (a, b) => a.today_price - b.today_price,
			render: (today_price, record) => {
				const color = record.today_price >= 0 ? "#39A939" : "#FF3234";
				return (
					<span style={{ color }}>
						{convertToCurrency(today_price, record.currency)}
					</span>
				);
			},
		},
		{
			key: "today_value",
			title: "Value",
			dataIndex: "today_value",
			align: "right",
			sorter: (a, b) => a.today_value - b.today_value,
			render: (today_value, record) => {
				const color = record.today_value >= 0 ? "#39A939" : "#FF3234";
				return (
					<span style={{ color }}>
						{convertToCurrency(today_value, record.currency)}
					</span>
				);
			},
		},
	];

	//Responsive Button Rendering
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	const [selectedPeriodAdmin, setSelectedPeriodAdmin] = useState("7d");

	const periodLabels = {
		"7d": "7 Days",
		"1mo": "1 Month",
		"3mo": "3 Months",
		"1yr": "1 Year",
		allTime: "All Time",
	};

	return (
		<div className="container">
			{loading && <Spin />}

			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>

				{/* Section 2: Financial Summary Card */}
				<section>
					<Card className="card-financial-summary">
						<Row
							justify="space-between"
							align="middle"
							className="daily-returns-toggle"
						>
							<Title level={4} className="section-title">
								{savingNameUpdate}
							</Title>
							<Switch
								checked={isUSD}
								onChange={toggleCurrency}
								checkedChildren="USD"
								unCheckedChildren={currencyRate}
							/>
						</Row>
						<Row justify="center" className="balance-section">
							<Col onClick={toggleVisibility} className="balance-text">
								<h1>
									{isVisible
										? convertToCurrency(currentBalance)
										: "Tap to see balance"}
								</h1>
							</Col>
						</Row>
						<Row justify="space-between" className="summary-row">
							<Col>Net Contributions</Col>
							<Col>Returns</Col>
						</Row>
						<Row justify="space-between" className="summary-row">
							<Col>{isVisible ? convertToCurrency(invested) : "****"}</Col>
							<Col style={{ textAlign: "left" }}>
								<Text
									style={{
										color: isVisible
											? showDailyReturns
												? dailyReturnColor
												: returnColor
											: "#999",
										fontWeight: "bold",
									}}
								>
									{isVisible
										? showDailyReturns
											? dailyReturn && dailyReturnPercentage
												? `${convertToCurrency(
														dailyReturn
												  )} (${dailyReturnPercentage}%)`
												: "0.00 (0.00%)"
											: returns && returnPercentage
											? `${convertToCurrency(returns)} (${returnPercentage}%)`
											: "0.00 (0.00%)"
										: "****"}
								</Text>
							</Col>
						</Row>

						<Row
							justify="center"
							className="daily-returns-toggle"
							style={{ marginTop: "20px" }}
						>
							<Text>Show Daily Returns</Text>
							<Switch
								checked={showDailyReturns}
								onChange={toggleReturnType}
								checkedChildren="Yes"
								unCheckedChildren="No"
							/>
						</Row>
					</Card>
				</section>

				{/* Section 2: Buttons*/}
				<section>
					<Row
						gutter={[10, 10]}
						justify="center"
						style={{ textAlign: "center" }}
					>
						<Col xs={6} sm={6} md={6}>
							<Tooltip
								title={
									!isOwnPortfolioUpdate
										? "Not available for this product!"
										: "Buy Crypto"
								}
							>
								<Button
									icon={<Rocket size={24} color="white" />}
									block
									shape="square"
									size="large"
									style={{
										height: "60px",
										width: "60px",
										backgroundColor: isOwnPortfolioUpdate
											? "#7600ec"
											: "#cccccc", // Enabled color when true
										borderColor: isOwnPortfolioUpdate ? "#7600ec" : "#cccccc",
										color: isOwnPortfolioUpdate ? "white" : "#666666",
										cursor: isOwnPortfolioUpdate ? "pointer" : "not-allowed",
									}}
									disabled={!isOwnPortfolioUpdate} // Button enabled when isMyOwnSaving is true
									onClick={() => {
										if (isOwnPortfolioUpdate) {
											handleBuyCrypto(); // Triggers only when enabled
										}
									}}
								/>
							</Tooltip>
						</Col>

						<Col xs={6} sm={6} md={6}>
							<Tooltip
								title={
									!isActiveTradingUpdate
										? "Add Funds"
										: "Not available for this product!"
								}
							>
								<Button
									icon={<DollarSign size={24} color="white" />}
									block
									shape="square"
									size="large"
									style={{
										height: "60px",
										width: "60px",
										backgroundColor: !isActiveTradingUpdate
											? "#7600ec" // Active color when false
											: "#cccccc", // Disabled color when true
										borderColor: !isActiveTradingUpdate ? "#7600ec" : "#cccccc",
										color: !isActiveTradingUpdate ? "white" : "#666666",
										cursor: !isActiveTradingUpdate ? "pointer" : "not-allowed",
									}}
									disabled={isActiveTradingUpdate} // Disabled if true
									onClick={() => {
										if (!isActiveTradingUpdate) {
											handleAddFunds(); // Only triggers if false
										}
									}}
								/>
							</Tooltip>
						</Col>

						<Col xs={6} sm={6} md={6}>
							<Tooltip title="Configure Portfolio">
								<Button
									icon={<Edit2 size={24} color="white" />}
									block
									shape="square"
									size="large"
									style={{
										height: "60px",
										width: "60px",
										backgroundColor: "#7600ec", // Set background color
										borderColor: "#7600ec", // Set border color to match
										color: "white", // Set text/icon color to white
									}}
									onClick={() => handleEditPortfolio()} // Pass portfolio to the handler
								/>
							</Tooltip>
						</Col>
						<Col xs={6} sm={6} md={6}>
							<Tooltip title="Close Portfolio">
								<Button
									icon={<XCircle size={24} color="white" />}
									block
									shape="square"
									size="large"
									style={{
										height: "60px",
										width: "60px",
										backgroundColor: "#7600ec", // Set background color
										borderColor: "#7600ec", // Set border color to match
										color: "white", // Set text/icon color to white
									}}
									onClick={() => handleClosePortfolio()} // Pass portfolio to the handler
								/>
							</Tooltip>
						</Col>
					</Row>
				</section>

				{/* Section 4: Graph */}
				<section>
					<Card>
						<Chart
							options={options}
							series={[{ name: "Portfolio Value", data: chartData }]}
							type="area"
							height={350}
						/>
						{/* 
						<div className="tabs-container">
							<Tabs
								defaultActiveKey="1mo"
								onChange={(key) => setSelectedPeriod(key)}
								items={[
									{ label: "7d", key: "7d" },
									{ label: "1M", key: "1mo" },
									{ label: "3M", key: "3mo" },
									{ label: "1Y", key: "1yr" },
									{ label: "MAX", key: "allTime" },
								]}
							/>
						</div> */}
					</Card>
				</section>

				<section>
					<div
						className="buttons-container"
						style={{ display: "flex", gap: "10px" }}
					>
						{["7d", "1mo", "3mo", "1yr", "allTime"].map((key) => (
							<Button
								key={key}
								onClick={() => {
									setSelectedPeriod(key);
									setSelectedPeriodAdmin(key);
								}}
								style={{
									flex: 1, // Makes each button expand to fill the available space equally
									padding: "5px", // Add padding to buttons for consistency
									margin: "5px",
									backgroundColor: selectedPeriodAdmin === key ? "#7600ec" : "", // Highlight active button
									color: selectedPeriodAdmin === key ? "white" : "", // Change text color for active button
									textAlign: "center", // Ensures the text is centered
									borderColor: selectedPeriodAdmin === key ? "#7600ec" : "", // Change text color for active button// Set border color to match
								}}
							>
								{periodLabels[key]}
							</Button>
						))}
					</div>
				</section>

				{/* Section 2: TWO Cards */}
				{/* <section>
					<Row
						gutter={[16, 16]}
						justify="center"
						style={{ textAlign: "center" }}
					>
						<Col span={12}>
							<Card bordered={false}>
								<Statistic
									title="VaR 99%"
									value={VaR99 * 100}
									precision={2}
									suffix="%"
								/>
							</Card>
						</Col>
						<Col span={12}>
							<Card bordered={false}>
								<Statistic
									title="VaR 95%"
									value={VaR95 * 100}
									precision={2}
									suffix="%"
								/>
							</Card>
						</Col>
					</Row>
				</section> */}

				{/* Section 2: My Portfolio */}
				<section style={{ marginTop: "10px" }}>
					<Title level={4}>My Portfolio</Title>
					<Table
						columns={columnsMyPortfolio}
						dataSource={allSavingPortfolio}
						pagination={false} // You can adjust the number of items per page
						scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
						responsive={true} // Makes the table responsive
						className="custom-table" // Apply the custom class here
					/>
				</section>

				{/* Section 2: My Activities */}
				<section>
					<Title level={4}>My Activities</Title>

					{/* Responsive Table */}
					<Table
						columns={columnsOrders}
						dataSource={allTransactions}
						pagination={{ pageSize: 10 }}
						bordered
						rowKey="key"
						scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
						responsive={true} // Makes the table responsive
						className="custom-table" // Apply the custom class here
					/>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default SavingPortfolio;
