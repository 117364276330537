import React,{useState,useEffect} from "react";
import {Table,Input, Button} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined } from "@ant-design/icons";

import Chart  from "react-apexcharts";

const SavingPortfolio = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency

  // const currencySymbol = user_currency === 'USD' ? '$' : '€';
  const currencySymbol = '$';

  // const { Meta } = Card;

  // const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  // console.log("data:",data)
  const savingNameUpdate = data.savingName;
  const productNameUpdate = data.productName;
  const savingProcessIDUpdate = data.savingProcessID;
  const closePortfolioUpdate = data.closePortfolio;


  const [loading,setLoading] = useState(false);

  const [allSavingVar, setAllSavingVar] = useState([]);
  const [allSavingPortfolio, setAllSavingPortfolio] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);

  const [allSavingPerformancePortfolio, setAllSavingPerformancePortfolio] = useState([]);
  const [allSavingBalance, setAllSavingBalance] = useState([]);

  //table accounts
  const columns =[
    // {
    //   key:"isPending",
    //   title: "",
    //   dataIndex: "isPending",
    //   align: 'center',
    //   render: (value, record) => {
    //     if(record.isPending === false )
        
    //       return (
    //       <Tooltip title="Confirmada">
    //         <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
    //       </Tooltip>
    //       );

    //       return (
    //       <Tooltip title="Pendente">
    //         <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
    //       </Tooltip>
    //       );
    //   },
    // },
    // {
    //   key: "date_refreshed",
    //   title: "Refresh Date",
    //   dataIndex: "date_refreshed",
    //   render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    // },
    // {
    //   key: "datePrice",
    //   title: "Data da Cotação",
    //   dataIndex: "datePrice",
    //   render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
    // },
    {
      key: "cryptoAssetDescription",
      title: "Crypto Name",
      dataIndex: "cryptoAssetDescription",
      sorter: (a, b) => a.cryptoAssetDescription.localeCompare(b.cryptoAssetDescription),
    },
    {
      key: "binanceTicker",
      title: "Binance Ticker",
      dataIndex: ["cryptoAssetName","binanceTicker"],
      sorter: (a, b) => a.cryptoAssetName.binanceTicker.localeCompare(b.cryptoAssetName.binanceTicker),
    },
    // {
    //   key: "qty",
    //   title: "Quantity",
    //   dataIndex: "qty",
    //   align: "center",
    //   sorter: (a, b) => a.qty-b.qty,
    //   render: (qty, record) => {
    //       return (
    //           <span>
    //               {qty.toLocaleString('de-DE', {
    //                   maximumFractionDigits: 5
    //               })}
    //           </span>
    //       );
    //   },
    // },
    // {
    //   key: "locked",
    //   title: "Quantidade Cativa",
    //   dataIndex: "locked",
    //   width: "2%",
    //   sorter: (a, b) => a.locked-b.locked,
    // },
    {
      key: "today_price",
      title: "Average Price USDT",
      dataIndex: "today_price",
      align: "right",
      sorter: (a, b) => a.today_price -b.today_price,
      render: (today_price, record) => {
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(today_price).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: record.cryptoAssetName.ticketSize})}</span>
          );
      },
    },
    {
      key: "today_price_ctr",
      title: "Average Base",
      dataIndex: "today_price_ctr",
      align: "right",
      sorter: (a, b) => a.today_price_ctr -b.today_price_ctr,
      render: (today_price_ctr, record) => {
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(today_price_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: record.cryptoAssetName.ticketSize})}</span>
          );
      },
    },
    {
      key: "today_value",
      title: "Value USDT",
      dataIndex: "today_value",
      align: "right",
      sorter: (a, b) => a.today_value-b.today_value,
      render: (today_value, record) => {
          if(record.today_value >= 0 )
            return (
            <span style={{color: "#39A939"}}>{(today_value).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: 2})}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{(today_value).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: 2})}</span>
            );
        },
    },
    {
      key: "today_value_ctr",
      title: "Value",
      dataIndex: "today_value_ctr",
      align: "right",
      sorter: (a, b) => a.today_value_ctr-b.today_value_ctr,
      render: (today_value_ctr, record) => {
        if(record.today_value_ctr >= 0 )
          return (
            <span style={{color: "#39A939"}}>{(today_value_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: 2})}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{(today_value_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: 2})}</span>
            );
      },
    },
    // {
    //   key: "qty",
    //   title: "Quantidade",
    //   dataIndex: "qty",
    //   align: "right",
    //   sorter: (a, b) => a.qty-b.qty,
    //   render: (qty, record) => {
    //     if(record.qty >= 0 )
    //       return (
    //       <span style={{color: "black"}}>{parseFloat(qty).toFixed(5)}</span>
    //       );
    //   },
    // },
    // {
    //   key: "today_price",
    //   title: "Cotação Base",
    //   dataIndex: "today_price",
    //   align: "right",
    //   sorter: (a, b) => a.today_price -b.today_price,
    //   render: (today_price, record) => {
    //     if(record.today_price >= 0 )
    //       return (
    //       // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
    //       <span> {parseFloat(today_price).toFixed(4)} €</span>
    //       );
    //   },
    // },
    // {
    //   key: "today_value",
    //   title: "Valor Base",
    //   dataIndex: "today_value",
    //   align: "right",
    //   sorter: (a, b) => a.today_value-b.today_value,
    //   render: (today_value, record) => {
    //     if(record.today_value >= 0 )
    //       return (
    //       // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
    //       <span>{(today_value).toLocaleString('de-DE', { style: 'currency', currency: user_currency })}</span>
    //       );
    // },
    // },
    {
      key:"weights_pct",
      title: "Weights %",
      dataIndex: "weights_pct",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.weights_pct - b.weights_pct,
      render: (weights_pct, record) => {
        if(record.weights_pct >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(weights_pct*100).toFixed(2)} %</span>
          );
      },
  },
  ];

  const columnsOrders =[
    {
      key: "datePrice",
      title: "Trading Date",
      dataIndex: "datePrice",
      defaultSortOrder: 'descend',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.datePrice) - new Date(b.datePrice)
    },
    {
      key: "cryptoAssetDescription",
      title: "Crypto Name",
      dataIndex: ["cryptoAssetDescription"],
      sorter: (a, b) => a.cryptoAssetDescription.localeCompare(b.cryptoAssetDescription),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.cryptoAssetDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "binanceTicker",
      title: "Binance Ticker",
      dataIndex: ["cryptoAssetName","binanceTicker"],
      sorter: (a, b) => a.cryptoAssetName.binanceTicker.localeCompare(b.cryptoAssetName.binanceTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.cryptoAssetName.binanceTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "action",
      title: "BUY / SELL",
      dataIndex: ["action"],
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => {
        if(record.today_value >=0  )
          return (
          <span style={{color: "#39A939"}}>BUY</span>
          );
          return (
          <span style={{color: "#FF3234"}}>SELL</span>
          );
      },
    },
    {
      key: "qty",
      title: "Quantity",
      dataIndex: "qty",
      align: "center",
      sorter: (a, b) => a.qty-b.qty,
      render: (qty, record) => {
          return (
              <span>
                  {qty.toLocaleString('de-DE', {
                      maximumFractionDigits: 5
                  })}
              </span>
          );
      },
    },
    // {
    //   key: "locked",
    //   title: "Quantidade Cativa",
    //   dataIndex: "locked",
    //   width: "2%",
    //   sorter: (a, b) => a.locked-b.locked,
    // },
    {
      key: "today_price",
      title: "Average Price USDT",
      dataIndex: "today_price",
      align: "right",
      sorter: (a, b) => a.today_price -b.today_price,
      render: (today_price, record) => {
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(today_price).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: record.cryptoAssetName.ticketSize})}</span>
          );
      },
    },
    {
      key: "today_price_ctr",
      title: "Average Base",
      dataIndex: "today_price_ctr",
      align: "right",
      sorter: (a, b) => a.today_price_ctr -b.today_price_ctr,
      render: (today_price_ctr, record) => {
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(today_price_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: record.cryptoAssetName.ticketSize})}</span>
          );
      },
    },
    {
      key: "today_value",
      title: "Value USDT",
      dataIndex: "today_value",
      align: "right",
      sorter: (a, b) => a.today_value-b.today_value,
      render: (today_value, record) => {
          if(record.today_value >= 0 )
            return (
            <span style={{color: "#39A939"}}>{(today_value).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: 2})}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{(today_value).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: 2})}</span>
            );
        },
    },
    {
      key: "today_value_ctr",
      title: "Value",
      dataIndex: "today_value_ctr",
      align: "right",
      sorter: (a, b) => a.today_value_ctr-b.today_value_ctr,
      render: (today_value_ctr, record) => {
        if(record.today_value_ctr >= 0 )
          return (
            <span style={{color: "#39A939"}}>{(today_value_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: 2})}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{(today_value_ctr).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: 2})}</span>
            );
      },
    },
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllSavingVar = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingvars/get-saving-var', {
          userid: user._id,
          savingProcessId: savingProcessIDUpdate,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingVar(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllSavingVar();
  },[savingProcessIDUpdate]) 

    //Analytics
    const VaR99 = allSavingVar
    .reduce((acc, transaction) => acc + transaction.VaR_pct_99, 0);

    const VaR95 = allSavingVar
    .reduce((acc, transaction) => acc + transaction.VaR_pct_95, 0);

    const VaR99_money = allSavingVar
    .reduce((acc, transaction) => acc + transaction.VaR_99_money, 0);

    const VaR95_money = allSavingVar
    .reduce((acc, transaction) => acc + transaction.VaR_95_money, 0);

  //useEffect Hook
  useEffect(() => {
    const getAllSavingPortfolio = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingportfolios/get-savingportfolio', {
          userid: user._id,
          savingProcessId: savingProcessIDUpdate,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingPortfolio(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllSavingPortfolio();
  },[savingProcessIDUpdate])

  //useEffect Hook
  useEffect(() => {
    const getAllSavingPortfolio = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingportfolios/get-savingportfolio', {
          userid: user._id,
          savingProcessId: savingProcessIDUpdate,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingPortfolio(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllSavingPortfolio();
  },[savingProcessIDUpdate])

  //Analytics
  const totalAllSavingPortfolio = allSavingPortfolio
  .filter((transaction) => transaction.isPending === false)
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  const numberAllSavingPortfolio = allSavingPortfolio.length

    //useEffect Hook
    useEffect(() => {
      const getAllTransactions = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/ordertransactions/get-order-transactions', {
            userid:user._id,
            savingProcessId: savingProcessIDUpdate
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }       
          );
          setLoading(false);
          setAllTransactions(res.data);
          // console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With account');
        }
      };  
      
      // Clean up the interval when the component unmounts
      return () => getAllTransactions();
    },[savingProcessIDUpdate])

  //Table & Plots #######################################################################
  const [chartData, setChartData]= useState([]);
  useEffect(() => {
    const getAllSavingPortfolio = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingperformances/get-savingperformance', {
          userid: user._id,
          savingProcessId: savingProcessIDUpdate,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingPerformancePortfolio(res.data);
        setChartData({
          options:{
          chart:{
            id: "basic-bar",
            type: 'area',
            stacked: false,
          },
          xaxis:{
            categories: res.data
            // Sort the data by 'dateSavingPerformance'
            .sort((a, b) => new Date(a.dateSavingPerformance) - new Date(b.dateSavingPerformance))
            // Map the sorted data to categories
            .map(item => {
              // Parse date string to Date object
              const date = new Date(item?.dateSavingPerformance);
              // Format date as YYYY-MM-DD
              return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            })
          },
          stroke: {
            curve: "straight",
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          },
          series:[
          {
            name: "Return USDT",
            data: res.data.map(item=> parseFloat(item?.today_value).toFixed(2)),
          },
          ],
        },);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllSavingPortfolio();
  },[savingProcessIDUpdate])

  //Analytics
  const totalTurnover = allSavingPerformancePortfolio
  .reduce((acc, transaction) => acc + transaction.difTodayValue, 0);

  //useEffect Hook
  useEffect(() => {
    const getAllSavingBalance = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingbalances/get-savingbalance', {
          userid: user._id,
          savingProcessId: savingProcessIDUpdate,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingBalance(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllSavingBalance();
  },[savingProcessIDUpdate])

  // //Analytics
  const diffDaily = allSavingBalance
  .reduce((acc, transaction) => acc + transaction.diffDaily, 0);

  const diffAccum = allSavingBalance
  .reduce((acc, transaction) => acc + transaction.diffAccum, 0);

  const pctDailyChange = allSavingBalance
  .reduce((acc, transaction) => acc + transaction.pctDailyChange*100, 0);

  const pctTodayValueAccum = allSavingBalance
  .reduce((acc, transaction) => acc + transaction.pctTodayValueAccum*100, 0);

  const isPositive_diffDaily = diffDaily >= 0;
  const formatted_isPositive_diffDaily = diffDaily.toLocaleString('de-DE', { style: 'currency', currency: 'USD' });

  const formatted_isPositive_pctDailyChange = pctDailyChange.toLocaleString('de-DE',{maximumFractionDigits: 3});

  const isPositive_diffAccum = diffAccum >= 0;
  const formatted_isPositive_diffAccum = diffAccum.toLocaleString('de-DE', { style: 'currency', currency: 'USD' });

  const formatted_isPositive_pctTodayValueAccum = pctTodayValueAccum.toLocaleString('de-DE',{maximumFractionDigits: 3});

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap', // Allow items to wrap on small screens
    },
    h4: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px', // Add margin for spacing
    },
    // Media queries for responsive design
    '@media (max-width: 600px)': {
      container: {
        flexDirection: 'column',
        alignItems: 'flex-start', // Align items to the start on small screens
      },
      h4: {
        width: '100%', // Make each h4 take full width
        justifyContent: 'space-between',
      },
    },
  };    

  return (
    <Layout>
      {loading && <Spinner/>}
      {/* <div className="accountpage">
          <div>
          </div>
          <div>
            <h3> {savingNameUpdate}</h3>
            <br></br>
            <h4>{productNameUpdate}</h4>
          </div>
          <div></div>
        </div> */}
        <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            BACK
          </Button>
        </div>

        <div><h4 align="left">Your Portfolio</h4></div>

        <div style={styles.container}>
          {!closePortfolioUpdate && (
            <h1 align="left" style={{marginLeft:"10px"}}>{(totalAllSavingPortfolio).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</h1>
          )}
        </div>

        <div style={styles.container}>
          {!closePortfolioUpdate && (
          <h4 style={{ 
              ...styles.h4, 
              color: isPositive_diffDaily ? '#39A939' : '#FF3234',
              fontSize: '18px'
            }}>
            Daily {isPositive_diffDaily ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {formatted_isPositive_diffDaily}
            {' '}
            ({formatted_isPositive_pctDailyChange}%)
          </h4>
          )}
          <h4 style={{ 
              ...styles.h4, 
              color: isPositive_diffAccum ? '#39A939' : '#FF3234',
              fontSize: '18px'
            }}>
            All {isPositive_diffAccum ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {formatted_isPositive_diffAccum}
            {' '}
            ({formatted_isPositive_pctTodayValueAccum}%)
          </h4>
        </div>
        {!closePortfolioUpdate && chartData && chartData?.series && (
                  <div className="chart-container area-chart">
                    <Chart
                      type="area"
                      align="center"
                      width={"100%"}
                      height={"400px"}
                      series={chartData.series}
                      options={{
                        ...chartData.options,
                        dataLabels: {
                          enabled: false, // Adiciona esta linha para ocultar os rótulos de dados
                        },
                        yaxis: {
                          labels: {
                            formatter: (value) => `${currencySymbol}${value.toFixed(2)}`,
                          },
                        },
                        xaxis: {
                          labels: {
                            show: false, // Disables the x-axis labels
                          },
                        },
                        tooltip: {
                          y: {
                            formatter: (value) => `${currencySymbol}${value.toFixed(2)}`,
                          },
                        },
                      }}
                    />
                  </div>
                )}

      {!closePortfolioUpdate && (      
      <p style={{marginTop:"20px"}}><b>Value at Risk (VaR) 99%:</b> A 1% chance losses exceed <b>{(VaR99 * 100).toFixed(2)} %</b> or <b>{VaR99_money.toFixed(2)}$</b> over a single day.</p>
      )}
      {!closePortfolioUpdate && (      
      <p style={{marginTop:"20px"}}><b>Value at Risk (VaR) 95%:</b> A 5% chance losses exceed <b>{(VaR95 * 100).toFixed(2)}%</b> or <b>{VaR95_money.toFixed(2)}$</b> over a single day.</p>
      )}

      {!closePortfolioUpdate && ( 
      <hr></hr>
      )}

      {!closePortfolioUpdate && (      
      <h4 style={{marginTop:"20px"}}>My Portfolio</h4>
      )}

      {!closePortfolioUpdate && ( 
      <hr></hr>
      )}

      {!closePortfolioUpdate && ( 
      <br></br>
      )}

      <div className="content">
        
        {!closePortfolioUpdate && ( 
        <Table
            size="small"
            columns={columns}
            dataSource={allSavingPortfolio}
            pagination={false}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
        )}
        <br></br>
        <h4 style={{marginTop:"20px"}}>My Activities</h4>
        <hr></hr>
        <br></br>
        <Table 
          size="small"
          columns={columnsOrders}
          dataSource={allTransactions}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
          scroll={{ x: 'max-content'}}
          className="custom-table" // Apply custom CSS class for styling
        />

      </div>

    </Layout>
  );
};

export default SavingPortfolio;