import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Table,
	Select,
	message,
	Button,
	Tooltip,
	Spin,
	Card,
} from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import moment from "moment";
import {
	ArrowLeftOutlined,
	CheckCircleTwoTone,
	InfoCircleOutlined,
} from "@ant-design/icons";

const SavingDeposit = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	console.log("data:", data);
	const savingNameUpdate = data.savingName;
	const productNameUpdate = data.productName;
	const savingProcessIDUpdate = data.savingProcessID;
	const investmentTypeUpdate = data.investmentType;
	const minimumInvestmentUpdate = data.minimumInvestment;
	const exchangeNameUpdate = data.exchangeName;
	const productNameIDUpdate = data.productNameID;

	const [loading, setLoading] = useState(false);

	const [allCashExchanges, setCashExchanges] = useState([]);

	const [form] = Form.useForm();

	const user = JSON.parse(localStorage.getItem("user"));

	const columns = [
		{
			key: "statusApi",
			title: "Status API",
			dataIndex: "status",
			align: "center",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Active</span>;

				return <span style={{ color: "#FF3234" }}>Disable</span>;
			},
		},
		{
			key: "cash",
			title: "Cash Available",
			dataIndex: "cash",
			align: "center",
			defaultSortOrder: "descend",
			sorter: (a, b) => a.cash - b.cash,
			render: (text) => `${text} USDT`,
		},
		{
			key: "investmentName",
			title: "Exchange Name",
			dataIndex: ["exchangeName", "investmentName"],
			align: "center",
			sorter: (a, b) =>
				a.exchangeName.investmentName.localeCompare(
					b.exchangeName.investmentName
				),
		},
		{
			key: "brokerName",
			title: "Broker",
			dataIndex: ["exchangeName", "brokerName"],
			align: "center",
			sorter: (a, b) =>
				a.exchangeName.brokerName.localeCompare(b.exchangeName.brokerName),
		},

		// {
		// 	key: "status",
		// 	title: "Status",
		// 	dataIndex: ["exchangeName", "status"],
		// 	align: "center",
		// 	render: (value, record) => {
		// 		if (record.exchangeName.status === true)
		// 			return <span style={{ color: "#39A939" }}>Active</span>;

		// 		return <span style={{ color: "#FF3234" }}>Disable</span>;
		// 	},
		// },
	];

	// Assuming targetDetails.targetDate is the date you want to calculate the difference from
	const currentDate = moment();

	// Extract the day
	const dayPayment = currentDate.date();

	// const onFinish = (values) => {
	//   console.log({
	//     ...values,
	//     userid:user._id,
	//     savingProcessId:savingProcessIDUpdate,
	//     savingName:savingNameUpdate,
	//     exchangeName:exchangeNameUpdate,
	//     productName:productNameIDUpdate,
	//     cash: parseFloat(values.exchangeName),
	//     agentName:user.agentName,
	//     amount:parseFloat(values.initialValue),
	//     savingDate: moment(currentDate).format('YYYY-MM-DD'),
	//     monthYear: moment(currentDate).format("MMM YYYY"),
	//     monthYearNumber: moment(currentDate).format("YYYYMM"),
	//     dayMonthYearNumber: moment(currentDate).format("YYYYMMDD"),
	//     dayPayment: dayPayment,
	//     investmentType:investmentTypeUpdate
	//   });
	// };

	const submitHandler = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/savingtransactions/add-savingtransaction",
				{
					userid: user._id,
					savingProcessId: savingProcessIDUpdate,
					savingName: savingNameUpdate,
					exchangeName: exchangeNameUpdate,
					productName: productNameIDUpdate,
					agentName: user.agentName,
					amount: parseFloat(values.initialValue),
					savingDate: moment(currentDate).format("YYYY-MM-DD"),
					dayPayment: dayPayment,
					monthYear: moment(currentDate).format("MMM YYYY"),
					monthYearNumber: moment(currentDate).format("YYYYMM"),
					dayMonthYearNumber: moment(currentDate).format("YYYYMMDD"),
					investmentType: investmentTypeUpdate,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			message.success("Successful deposit roboadvisor");
			setLoading(false);

			navigate("/ProcessingDepositSaving", {
				state: {
					savingName: savingNameUpdate,
					exchangeName: exchangeNameUpdate,
					savingProcessID: savingProcessIDUpdate,
					investmentType: investmentTypeUpdate,
					processing: "Processing deposit Roboadvisor",
				},
			});
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	useEffect(() => {
		const getAllCashExchanges = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/cash-exchanges/get-cash-exchange",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setCashExchanges(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllCashExchanges();
	}, []);

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<div className="accountpage">
						<div></div>
						<div>
							<h1>
								Deposit <br /> {savingNameUpdate}
							</h1>
						</div>
						<div></div>
					</div>
					<br></br>
					<div>
						<div></div>
						<div aling="center" className="registerAccount">
							<Form layout="vertical" onFinish={submitHandler} form={form}>
								<Form.Item label="Owner of the account" name={"name"}>
									<Input
										defaultValue={`${user.firstName} ${user.lastName}`}
										disabled
										suffix={<CheckCircleTwoTone twoToneColor="#52c41a" />}
									/>
								</Form.Item>

								<Table
									size="small"
									columns={columns}
									dataSource={allCashExchanges}
									pagination={false}
									scroll={{ x: "max-content" }}
									className="custom-table" // Apply custom CSS class for styling
								/>

								<Form.Item
									style={{
										width: "100%",
										textAlign: "center",
										marginTop: "20px",
									}}
									label={<span>Choose your Exchange?&nbsp;</span>}
									name="exchangeName"
									rules={[
										{ required: true, message: "Please select your exchange!" },
									]}
								>
									<Select>
										{allCashExchanges &&
											allCashExchanges.map((opts, i) => (
												<Select.Option
													key={i}
													value={`${opts.cash}`}
													style={{
														width: "100%",
														textAlign: "center",
														marginTop: "20px",
													}}
												>
													{opts.exchangeName.investmentName} ({opts.cash} USDT)
												</Select.Option>
											))}
									</Select>
								</Form.Item>

								<Form.Item
									label={
										<span>
											Reforce Portfolio&nbsp;
											<Tooltip title="Enter the amount you want to reforce your portfolio.">
												<InfoCircleOutlined
													style={{ color: "rgba(0,0,0,.45)" }}
												/>
											</Tooltip>
										</span>
									}
									name="initialValue"
									rules={[
										{
											required: true,
											message: "Please enter an deposit amount!",
										},
										{
											validator: (_, value) => {
												if (
													value === undefined ||
													value === null ||
													value === ""
												) {
													return Promise.reject(
														"Please enter an initial deposit amount!"
													);
												}
												if (value < minimumInvestmentUpdate) {
													return Promise.reject(
														`The minimum investment amount is ${minimumInvestmentUpdate} USDT!`
													);
												}
												return Promise.resolve();
											},
										},
										{
											validator: (_, value) => {
												const exchangeCash = form.getFieldValue("exchangeName");
												if (value >= parseFloat(exchangeCash)) {
													return Promise.reject(
														`The deposit amount cannot exceed the available money, which is currently (${exchangeCash} USDT)!`
													);
												}
												return Promise.resolve();
											},
										},
									]}
								>
									<Input
										placeholder="0.00"
										suffix="USDT"
										type="number"
										size="large"
										style={{
											fontSize: "24px",
											width: "100%",
											textAlign: "right",
										}}
									/>
								</Form.Item>

								{/* {investmentTypeUpdate && (
          <Form.Item 
            label={
              <span>
                Maximum Investing&nbsp;
                <Tooltip title="Enter the amount you want to maximum investing.">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              </span>
            } 
            name="initialValue"
            rules={[
              {
                validator: (_, value) => {
                  if (value === undefined || value === null || value === '') {
                    return Promise.reject('Please enter an initial deposit amount!');
                  }
                  if (value <= minimumInvestmentUpdate) {
                    return Promise.reject(`The minimum investment amount is ${minimumInvestmentUpdate} USDT!`);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder='0.00'
              suffix="USDT"
              type="number" 
              size="large" 
              style={{ fontSize: "24px", width: "100%", textAlign: "right" }}
            />
          </Form.Item>
          )} */}

								<Button
									style={{
										width: "100%",
										textAlign: "center",
										marginTop: "20px",
										backgroundColor: "#7600ec",
										borderColor: "#7600ec",
										color: "#fff",
										borderRadius: "5px",
									}}
									htmlType="submit"
								>
									REFORCE
								</Button>
								<Button
									style={{
										width: "100%",
										background: "white",
										color: "Blue",
										marginTop: 30,
										marginBottom: 30,
									}}
									type="primary"
									onClick={handleBack}
								>
									CANCEL
								</Button>
							</Form>
						</div>
						<div></div>
					</div>
					{/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
				</Card>
			</div>
		</div>
	);
};

export default SavingDeposit;
