import React,{useState,useEffect} from "react";
import {Tabs, Form, Input, Col, Space ,Card,Row, message, Button, Select} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';
import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";

const Settings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [allSavingStats, setAllSavingStats] = useState([]);

  const [loading,setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('profile');

  const [form] = Form.useForm();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // Assuming contains the URL
  const openPopup = (url) => {
    const popup = window.open(url, 'popup', 'width=600,height=600');
    if (popup) {
      popup.focus();
      // console.log(url);
    }
  };

  //Handlers User  **************************************************************************************************************************
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const user = JSON.parse(localStorage.getItem('user'));
  //       setLoading(true);
  //       const res = await axios.post('/users/get-user', 
  //       { userid: user._id }, 
  //       { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` } });
  //       setAllSavingStats(res.data[0]);
  //       // Format birthdayDate using moment
  //       const formattedDate = moment(res.data[0].birthdayDate).format('YYYY-MM-DD');
  //       // Set form fields value        
  //       form.setFieldsValue({
  //         name: res.data[0].name,
  //         birthdayDate: formattedDate, // Set formatted date
  //         nif: res.data[0].nif,
  //         addressLiving: res.data[0].addressLiving,
  //         postalCodeLiving: res.data[0].postalCodeLiving,
  //         cityLiving: res.data[0].cityLiving,
  //         stateLiving: res.data[0].stateLiving,
  //         email: res.data[0].email,
  //         phoneNumber: res.data[0].phoneNumber,
  //         currency: res.data[0].currency,
  //       });
  //       console.log(res.data[0]);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       message.error('Fetch Issue With account');
  //     }
  //   };

  //   fetchUserData();
  // }, [form]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          message.error('No user found in localStorage');
          return;
        }

        setLoading(true);

        const res = await axios.post('/users/get-user', 
          { userid: user._id }, 
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` } });

        if (res.data && res.data.length > 0) {
          const userData = res.data[0];
          setAllSavingStats(userData);

          // Format birthdayDate using moment
          const formattedDate = moment(userData.birthdayDate).format('YYYY-MM-DD');

          // Set form fields value
          form.setFieldsValue({
            name: userData.firstName+" "+userData.lastName,
            countryCitizenship: userData.countryCitizenship,
            birthdayDate: formattedDate,
            nif: userData.nif,
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            currency: userData.currency,
          });
        } else {
          message.error('No user data found');
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error('Fetch Issue With account');
      }
    };

    fetchUserData();
  }, [form]);

  const handleSubmitPassword = async (values) => {

    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);
      const res = await axios.post('/users/edit-user', {
        payload:{
          ...values,
        },
        _id: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Password updated successfully!');
      navigate('/');
      // console.log(values)
  
      }catch (error){
        setLoading(false);
        // message.error('Failed to industry');
        // message.error('You are not Admin User');
      }
    }

  const handleSubmitProfile = async (values) => {

    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);
      const res = await axios.post('/users/edit-user', {
        payload:{
          ...values,
        },
        _id: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Personal Data updated successfully!');
      navigate('/MySettings');
      // console.log(values)
  
      }catch (error){
        setLoading(false);
        // message.error('Failed to industry');
        // message.error('You are not Admin User');
      }
    }
  // const onFinish = (values) => {
  //   console.log({...values.serviceRating
  //   });
  // };

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GBP', label: 'GBP' },
    { value: 'CHF', label: 'CHF' },
    { value: 'BRL', label: 'BRL' },
  ];

  return (
    <Layout>
      {loading && <Spinner/>}

      <div>
      <Tabs 
        defaultActiveKey="profileTabs" 
        onChange={handleTabChange}
        type="line"
      >
        <TabPane tab="Profile" key="profile" style={{ marginTop:"25px"}} >
        <Form
        layout="vertical" 
        form={form} 
        onFinish={handleSubmitProfile}
        >
          <Form.Item 
            name="name" 
            label="Name">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}} 
            />
          </Form.Item>
          <Form.Item 
            name="birthdayDate" 
            label="Date of birth">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item 
            name="nif" 
            label="Fiscal Number">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item 
            name="countryCitizenship" 
            label="Country Citizenship">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item name="currency" label="Currency">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
            {/* <Select disabled style={{ width: "100%", color:"black", textAlign: "left"}}>
              {currencyOptions.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
          <hr style={{ marginTop: "50px" , marginBottom: "50px"}}  ></hr>
          <Form.Item 
            name="email" 
            label="Email">
            <Input
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            suffix={<EditOutlined twoToneColor="#52c41a"/>}
            />
          </Form.Item>
          <Form.Item 
            name="phoneNumber" 
            label="Phone">
            <Input 
            style={{ width: "100%", color:"black", textAlign: "left"}}
            suffix={<EditOutlined twoToneColor="#52c41a"/>}  
            />
          </Form.Item>
          <Form.Item>
              <Button 
              style={{ width: "100%",marginTop: "20px"}} 
              type="primary" 
              htmlType="submit"
              >
                UPDATE PROFILE
              </Button>
              <Button
                style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}
                type="primary" 
                htmlType="submit"
                onClick={() => navigate('/MySettings')}
              >
                CANCEL
              </Button>
          </Form.Item>
        </Form>
      
        </TabPane>
      </Tabs>
    </div>

    </Layout>
  );
};

export default Settings;