import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space,Tooltip,Tag,DatePicker,Card,Row,Col,Typography} from 'antd';

import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined, CheckCircleFilled,ExclamationCircleFilled,DeleteOutlined } from "@ant-design/icons";

import moment from 'moment';

const SavingAllTransactionsAdmin = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allTransactions, setAllTransactions] = useState([]);

  const {RangePicker} = DatePicker;

  //Filters
  const [isReforceOrWithdrawFilter, setIsReforceOrWithdrawFilter] = useState('all');
  const [isPendingFilter, setPendingFilter] = useState('all');
  const [frequency, setFrequency] = useState('month');
  const [selectedDate, setSelectedDate] = useState([]);

  //table accounts
  const columns =[
    //column ispayed
    // {
    //   key:"isPending",
    //   title: "",
    //   dataIndex: "isPending",
    //   defaultSortOrder: 'descend',
    //   align: 'center',
    //   render: (value, record) => {
    //     if(record.isPending === false )
        
    //       return (
    //       <Tooltip title="Confirmada">
    //         <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
    //       </Tooltip>
    //       );

    //       return (
    //       <Tooltip title="Pendente">
    //         <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
    //       </Tooltip>
    //       );
    //   },
    //   filters: [
    //     { text: "Transacção Confirmada", value: true },
    //     { text: "Transacção Pendente", value: false },
    //   ],
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     console.log(typeof value)
    //     return record.isPending === value;
    //   },
    // },
    //column date
    {
      key: "savingDate",
      title: "Data Transacção",
      dataIndex: "savingDate",
      width: "5%",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.savingDate) - new Date(b.savingDate)
    },
    // {
    //   key: "name",
    //   title: "Cliente",
    //   dataIndex: ["userid","name"],
    //   width: "15%",
    //   sorter: (a, b) => a.userid.name.localeCompare(b.userid.name),
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => {
    //     return (
    //       <>
    //         <Input
    //           autoFocus
    //           placeholder="Type text here"
    //           value={selectedKeys[0]}
    //           onChange={(e) => {
    //             setSelectedKeys(e.target.value ? [e.target.value] : []);
    //             confirm({ closeDropdown: false });
    //           }}
    //           onPressEnter={() => {
    //             confirm();
    //           }}
    //           onBlur={() => {
    //             confirm();
    //           }}
    //         ></Input>
    //         <Button
    //           onClick={() => {
    //             confirm();
    //           }}
    //           type="primary"
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             clearFilters();
    //           }}
    //           type="danger"
    //         >
    //           Reset
    //         </Button>
    //       </>
    //     );
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     return record.userid.name.toLowerCase().includes(value.toLowerCase());
    //   },
    // },
    {
      key: "fullName",
      title: "Nome Completo",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fullName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "nif",
      title: "NIF",
      dataIndex: ["userid","nif"],
      sorter: (a, b) => a.userid.nif.localeCompare(b.userid.nif),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userid.nif.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "savingProcessId",
      title: "Process Id",
      dataIndex: ["savingProcessId"],
      align: "center",
      sorter: (a, b) => a.savingProcessId - b.savingProcessId,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type number here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              type="number" // Ensure input is numeric
            />
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.savingProcessId === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "productName",
      title: "Produto",
      dataIndex: ["productName","productName"],
      sorter: (a, b) => a.productName.productName.localeCompare(b.productName.productName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.productName.productName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "savingName",
      title: "Nome do Cesto",
      dataIndex: "savingName",
      width: "20%",
      sorter: (a, b) => a.savingName.localeCompare(b.savingName),
    },
    {
      key: "agentName",
      title: "Agente",
      dataIndex: ["agentName","agentName"],
      sorter: (a, b) => a.agentName.agentName.localeCompare(b.agentName.agentName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.agentName.agentName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "reforceOrWithdrawAction",
      title: "",
      dataIndex: "reforceOrWithdrawAction",
      align: 'center',
      render: (text, record) => {
        if (record.reforcePortfolio === true) {
          return (
            <span style={{color: "#39A939"}}>Reforço</span>
          );
        }
        if (record.withdrawPortfolio === true) {
          return (
            <span style={{color: "#FF3234"}}>Levantamento</span>
          );
        }
        return (
          <span style={{color: "#39A939"}}>Depósito</span>
        );
      },
      filters: [
        { text: "Reforce", value: "Reforce" },
        { text: "Withdraw", value: "Withdraw" },
        { text: "Deposit", value: "Deposit" },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (value === "Reforce") {
          return record.reforcePortfolio === true;
        }
        if (value === "Withdraw") {
          return record.withdrawPortfolio === true;
        }
        if (value === "Deposit") {
          return !record.reforcePortfolio && !record.withdrawPortfolio;
        }
        return false;
      },
    },
    {
      key: "repeatNextDay",
      title: "Corre amanhã",
      dataIndex: "repeatNextDay",
      align: 'center',
      width: "10%",
      sorter: (a, b) => a.repeatNextDay - b.repeatNextDay,
      render: (value, record) => {
        if(record.repeatNextDay === true )
          return (
            <span style={{color: "#39A939"}}>Sim</span>
          );

          return (
            <span style={{color: "#FF3234"}}></span>
          );
      },
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.repeatNextDay === value;
      },
    },
    //column amount
    {
        key:"amount",
        title: "Valor da Transacção",
        dataIndex: "amount",
        align: 'right',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount, record) => {
          if(record.isReforceOrWithdraw === true )
            return (
            <span style={{color: "#39A939"}}>{(amount).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</span>
            );
            return (
            <span style={{color: "#FF3234"}}>{(amount).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</span>
            );
        },
    },
  ];

  useEffect(() => {
    const getAllTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingtransactions/get-allsavingtransaction',
        {
          frequency,
          selectedDate,
          isReforceOrWithdraw: isReforceOrWithdrawFilter,
          isPending: isPendingFilter,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllTransactions(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllTransactions();

    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();
  },[isReforceOrWithdrawFilter,isPendingFilter,frequency,selectedDate])

    // Add a combined name field
    allTransactions.forEach(item => {
      item.fullName = `${item.userid.firstName} ${item.userid.lastName}`;
    });

    //Analytics
    const totalAllSavingPortfolio = allTransactions
    .filter((transaction) => transaction.isReforceOrWithdraw === true)
    .reduce((acc, transaction) => acc + transaction.amount, 0);

    const totalAllWidthdrawSavingPortfolio = allTransactions
    .filter((transaction) => transaction.isReforceOrWithdraw === false)
    .reduce((acc, transaction) => acc + transaction.amount, 0);

    const AUM = allTransactions
    .reduce((acc, transaction) => acc + transaction.amount, 0);
    
    const totalTransactions = allTransactions.length;

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Todas os Movimentos</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >Voltar
          </Button>
        </div>

      <div className="accountpage">
        <div className="vertical-select">
          <h6>Deposito/Levantamento</h6>
          <Select 
          style={{
            width: '200px',
          }} 
          value={isReforceOrWithdrawFilter} onChange={(values) => setIsReforceOrWithdrawFilter(values)}>
            <Select.Option value='all'>Todos</Select.Option>
            <Select.Option value='true'>Depósito/Reforço</Select.Option>
            <Select.Option value='false'>Levantamento</Select.Option>
          </Select>
        </div>
        <div className="vertical-select">
          <h6>Valor Total Compras</h6>
          <p className="asset-info">{(totalAllSavingPortfolio).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</p>
        </div>
        <div className="vertical-select">
          <h6>Valor Total Vendas</h6>
          <p className="asset-info">{(totalAllWidthdrawSavingPortfolio).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</p>
        </div>
        <div className="vertical-select">
          <h6>Compras vs Vendas</h6>
          <p className="asset-info">{(AUM).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</p>
        </div>
        <div className="vertical-select">
          <h6>Número de Transacções</h6>
          <p className="asset-info">{(totalTransactions).toLocaleString('de-DE')}</p>
        </div>
        <div className="vertical-select">
        <h6>Escolha a Frequência</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={frequency} onChange={(values) => setFrequency(values)}>
            <Select.Option value='7'>Última semana</Select.Option>
            <Select.Option value='month'>Mês Actual</Select.Option>
            {/* <Select.Option value='nextmonth'>Próximo Mês</Select.Option> */}
            <Select.Option value='lastmonth'>Último Mês</Select.Option>
            <Select.Option value='yeartodate'>YTD</Select.Option>
            <Select.Option value='thisyear'>Este Ano</Select.Option>
            <Select.Option value='custom'>Personalizado</Select.Option>
          </Select>
          {frequency === 'custom' && (
          <RangePicker 
            value={selectedDate} 
            onChange={(values) => { setSelectedDate(values)}}
          />
          )}
        </div>
      </div>
      <hr></hr>
      <div className="content">
          <Table
            size="small"
            columns={columns}
            dataSource={allTransactions}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
          />
      </div>

      {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default SavingAllTransactionsAdmin;