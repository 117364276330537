import React from 'react';
import { Tabs, Table, Typography } from 'antd';
import Layout from "../components/Layout/Layout";

const { Title } = Typography;
const { TabPane } = Tabs;

// Sample data for tables, including new "Basket" field
const depositData = [
  { key: '1', date: '2024/01/12', amount: 500, status: 'Completed', basket: 'Retirement Fund' },
  { key: '2', date: '2024/01/15', amount: 200, status: 'Pending', basket: 'Education Savings' },
];

const withdrawalData = [
  { key: '1', date: '2024/01/20', amount: 100, status: 'Completed', basket: 'Emergency Fund' },
  { key: '2', date: '2024/01/22', amount: 300, status: 'Pending', basket: 'Vacation Fund' },
];

const feeData = [
  { key: '1', date: '2024/01/10', description: 'Transaction Fee', amount: 5 },
  { key: '2', date: '2024/01/11', description: 'Maintenance Fee', amount: 10 },
];

// Columns for each table, including the new "Basket" column for deposits and withdrawals
const depositColumns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Amount (€)', dataIndex: 'amount', key: 'amount' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Basket', dataIndex: 'basket', key: 'basket' }, // New Basket column
];

const withdrawalColumns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Amount (€)', dataIndex: 'amount', key: 'amount' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Basket', dataIndex: 'basket', key: 'basket' }, // New Basket column
];

const feeColumns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Description', dataIndex: 'description', key: 'description' },
  { title: 'Amount (€)', dataIndex: 'amount', key: 'amount' },
];

const MyHistory = () => {
  return (
    <Layout>
    <div style={{ padding: '24px' }}>
      <Title level={3}>Transaction Details</Title>
      
      <Tabs defaultActiveKey="1">
        <TabPane tab="Deposits" key="1">
          <Table 
            columns={depositColumns} 
            dataSource={depositData} 
            pagination={{ pageSize: 5 }} 
            bordered 
          />
        </TabPane>
        
        <TabPane tab="Withdrawals" key="2">
          <Table 
            columns={withdrawalColumns} 
            dataSource={withdrawalData} 
            pagination={{ pageSize: 5 }} 
            bordered 
          />
        </TabPane>
        
        <TabPane tab="Fees" key="3">
          <Table 
            columns={feeColumns} 
            dataSource={feeData} 
            pagination={{ pageSize: 5 }} 
            bordered 
          />
        </TabPane>
      </Tabs>
    </div>
    </Layout>
  );
};

export default MyHistory;
