import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	message,
	Modal,
	Table,
	Switch,
	Button,
	Spin,
	InputNumber,
} from "antd";

import Layout from "../components/Layout/Layout";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { SearchOutlined, EditOutlined } from "@ant-design/icons";

const PlanFees = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [allCategory, setAllSector] = useState([]);

	//Handle Close button Form
	const [show, setShow] = useState(false);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	//Fields from table to View
	const [orderUpdate, setOrderUpdate] = useState(null);
	const [titleUpdate, setTitleUpdate] = useState(null);
	const [featuresUpdate, setFeaturesUpdate] = useState(null);
	const [priceUpdate, setPriceUpdate] = useState(null);
	const [currencyOrPctUpdate, setCurrencyOrPctUpdate] = useState(null);
	const [iconUpdate, setIconUpdate] = useState(null);
	const [startIntervalUpdate, setStartIntervalUpdate] = useState(null);
	const [endIntervalUpdate, setEndIntervalUpdate] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState(true);

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "order",
			title: "Ordenação",
			dataIndex: "order",
			align: "center",
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.order - b.order,
		},
		{
			key: "title",
			title: "Plano de Subscrição",
			dataIndex: "title",
			sorter: (a, b) => a.title.localeCompare(b.title),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.title.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "features",
			title: "Funcionalidades",
			dataIndex: "features",
			sorter: (a, b) => a.features.localeCompare(b.features),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.features.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "startInterval",
			title: "Inicio Intervalo",
			dataIndex: "startInterval",
			align: "center",
			sorter: (a, b) => a.startInterval - b.startInterval,
		},
		{
			key: "endInterval",
			title: "Fim Intervalo",
			dataIndex: "endInterval",
			align: "center",
			sorter: (a, b) => a.endInterval - b.endInterval,
		},
		{
			key: "price",
			title: "Valor",
			dataIndex: "price",
			align: "center",
			sorter: (a, b) => parseFloat(a.price) - parseFloat(b.price),
			render: (_, record) => `${record.price} ${record.currencyOrPct || ""}`, // Merge values
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						/>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => <SearchOutlined />,
			onFilter: (value, record) => {
				return `${record.price} ${record.currencyOrPct || ""}`
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "icon",
			title: "Icon",
			dataIndex: "icon",
			sorter: (a, b) => a.icon.localeCompare(b.icon),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.icon.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "status",
			title: "Estado",
			dataIndex: "status",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Ativo</span>;

				return <span style={{ color: "#FF3234" }}>Desativo</span>;
			},
			filters: [
				{ text: "Ativo", value: true },
				{ text: "Desativo", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.status === value;
			},
		},
		{
			title: "Ações",
			dataIndex: "type",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-2">
						<EditOutlined
							onClick={() => {
								setEditable(record);
								setTitleUpdate(record.title);
								setFeaturesUpdate(record.features);
								setPriceUpdate(record.price);
								setCurrencyOrPctUpdate(record.currencyOrPct);
								setOrderUpdate(record.order);
								setIconUpdate(record.icon);
								setStartIntervalUpdate(record.startInterval);
								setEndIntervalUpdate(record.endInterval);
								setStatusUpdate(record.status);
								setShowModal(true);
							}}
						/>
						{/* <DeleteOutlined
                className="mx-2"
                onClick={() => {
                handleDelete(record);
            }}
            /> */}
					</div>
				);
			},
		},
	];

	const user = JSON.parse(localStorage.getItem("user"));

	//useEffects Hook **************************************************************************************************************************
	//List of Categories
	useEffect(() => {
		const getAllCategory = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/planfees/get-planfee",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSector(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllCategory();
	}, []);

	//Handlers Submit  **************************************************************************************************************************
	const handleSubmitCategory = async (values) => {
		try {
			const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			if (editable) {
				setLoading(true);
				const result = axios.post(
					"/planfees/edit-planfee",
					{
						payload: {
							...values,
							userid: user._id,
						},
						_id: editable._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Plano atualizada com sucesso!");
			} else {
				setLoading(true);
				const result = axios.post(
					"/planfees/add-planfee",
					{
						...values,
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Plano criada com Sucesso!");
			}
			setShowModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
		}
	};

	// //delete handler
	// const handleDelete = async (record) => {
	//   try {
	//       setLoading(true);
	//       await axios.post("/categories/delete-category", {
	//       _id: record._id,
	//       },
	//       {
	//         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//       }
	//       );
	//       setLoading(false);
	//       message.success("Categoria apagada com sucesso!");
	//       setTimeout(function () {
	//       window.location.reload(true);
	//       message.success('reload 1 segundo');
	//       }, 1000);
	//   } catch (error) {
	//       setLoading(false);
	//       console.log(error);
	//       message.error("unable to delete");
	//   }
	//   };

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	//Handler Reset Values to modal
	const handleCancelModal = () => {
		setShowModal(false);
		setEditable(null);
		setTitleUpdate(null);
		setFeaturesUpdate(null);
		setPriceUpdate(null);
		setCurrencyOrPctUpdate(null);
		setIconUpdate(null);
		setOrderUpdate(null);
		setStartIntervalUpdate(null);
		setEndIntervalUpdate(null);
		setStatusUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
	};

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spin />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Plano de Subscrições</h1>
				</div>
				<div></div>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					align="right"
					shape="round"
					className="btn btn-primary"
					onClick={() => setShowModal(true)}
				>
					+ Novo Plano de Subscrição
				</Button>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div className="content">
				<Table
					columns={columns}
					dataSource={allCategory}
					pagination={false}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>
			{/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}

			<Modal
				destroyOnClose={true}
				title={
					editable ? "Editar Plano de Subscrição" : "Novo Plano de Subscrição"
				}
				open={showModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitCategory}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Ordenação" name="order">
						<Input type="number" defaultValue={orderUpdate} />
					</Form.Item>

					<Form.Item name="title" label="Nome do Plano de Subscrição">
						<Input
							placeholder="Introduza o nome do plano de subscrição"
							defaultValue={titleUpdate}
						/>
					</Form.Item>

					<Form.Item name="price" label="Valor mensal da subscrição">
						<InputNumber
							style={{ width: "100%" }}
							placeholder="Introduza o Valor mensal da subscrição"
							defaultValue={priceUpdate}
						/>
					</Form.Item>

					<Form.Item name="currencyOrPct" label="Euro ou %">
						<Input
							style={{ width: "100%" }}
							placeholder="Introduza se a subscrição é valor fixo ou %"
							defaultValue={currencyOrPctUpdate}
						/>
					</Form.Item>

					<Form.Item name="features" label="Funcionalidades">
						<Input
							placeholder="Introduza as funcionalidades"
							defaultValue={featuresUpdate}
						/>
					</Form.Item>

					<Form.Item
						name="startInterval"
						label="Inicio do Intervalo de Investimento"
					>
						<InputNumber
							style={{ width: "100%" }}
							placeholder="Introduza o Inicio do intervalo"
							defaultValue={startIntervalUpdate}
						/>
					</Form.Item>

					<Form.Item
						name="endInterval"
						label="Fim do Intervalo de Investimento"
					>
						<InputNumber
							style={{ width: "100%" }}
							placeholder="Introduza o Inicio do intervalo"
							defaultValue={endIntervalUpdate}
						/>
					</Form.Item>

					<Form.Item label="Icon" name="icon">
						<Input type="text" defaultValue={iconUpdate} />
					</Form.Item>

					<Form.Item label="Estado" name="status" valuePropName="checked">
						<Switch
							checkedChildren="ACTIVE"
							unCheckedChildren="INACTIVE"
							defaultChecked={statusUpdate}
						/>
					</Form.Item>

					<button
						type="submit"
						style={{
							width: "100%",
							background: "green",
							color: "white",
							marginTop: 30,
							marginBottom: 30,
						}}
						className="btn btn-success"
						onClick={handleClose}
					>
						{" "}
						GRAVAR
					</button>

					{/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}
				</Form>
			</Modal>
		</Layout>
	);
};

export default PlanFees;
