import React,{useState,useEffect} from "react";
import {Input, Select,Button, Table,Space,Tooltip,Card} from 'antd';
import MySaving from "./../components/Assets/savings.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { PieChartOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SettingOutlined, TableOutlined, UnorderedListOutlined } from "@ant-design/icons";

import moment from 'moment';

const MySavingsAdmin = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   window.scrollTo(0, 0);
  // }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  // console.log(investor)

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allSavingsTransactions, setAllSavingsTransactions] = useState([]);

  //Filters
  const [statusFilter, setStatusFilter] = useState('all');

  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Data Criação",
      dataIndex: "createdAt",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    // {
    //   key: "updatedAt",
    //   title: "Última Atualização",
    //   dataIndex: "updatedAt",
    //   // defaultSortOrder: 'descend',
    //   align: 'center',
    //   render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    //   sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    // },
    // {
    //   key: "firstName",
    //   title: "Primeiro Nome",
    //   dataIndex: ["userid","firstName"],
    //   sorter: (a, b) => a.userid.firstName.localeCompare(b.userid.firstName),
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => {
    //     return (
    //       <>
    //         <Input
    //           autoFocus
    //           placeholder="Type text here"
    //           value={selectedKeys[0]}
    //           onChange={(e) => {
    //             setSelectedKeys(e.target.value ? [e.target.value] : []);
    //             confirm({ closeDropdown: false });
    //           }}
    //           onPressEnter={() => {
    //             confirm();
    //           }}
    //           onBlur={() => {
    //             confirm();
    //           }}
    //         ></Input>
    //         <Button
    //           onClick={() => {
    //             confirm();
    //           }}
    //           type="primary"
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             clearFilters();
    //           }}
    //           type="danger"
    //         >
    //           Reset
    //         </Button>
    //       </>
    //     );
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     return record.userid.firstName.toLowerCase().includes(value.toLowerCase());
    //   },
    // },
    // {
    //   key: "lastName",
    //   title: "Ultimo Nome",
    //   dataIndex: ["userid","lastName"],
    //   sorter: (a, b) => a.userid.lastName.localeCompare(b.userid.lastName),
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => {
    //     return (
    //       <>
    //         <Input
    //           autoFocus
    //           placeholder="Type text here"
    //           value={selectedKeys[0]}
    //           onChange={(e) => {
    //             setSelectedKeys(e.target.value ? [e.target.value] : []);
    //             confirm({ closeDropdown: false });
    //           }}
    //           onPressEnter={() => {
    //             confirm();
    //           }}
    //           onBlur={() => {
    //             confirm();
    //           }}
    //         ></Input>
    //         <Button
    //           onClick={() => {
    //             confirm();
    //           }}
    //           type="primary"
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             clearFilters();
    //           }}
    //           type="danger"
    //         >
    //           Reset
    //         </Button>
    //       </>
    //     );
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     return record.userid.lastName.toLowerCase().includes(value.toLowerCase());
    //   },
    // },
    {
      key: "fullName",
      title: "Nome Completo",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fullName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "nif",
      title: "NIF",
      dataIndex: ["userid","nif"],
      sorter: (a, b) => a.userid.nif.localeCompare(b.userid.nif),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userid.nif.toLowerCase().includes(value.toLowerCase());
      },
    },
    // {
    //   key: "categoryName",
    //   title: "Categoria",
    //   dataIndex: ["categoryName","categoryName"],
    //   sorter: (a, b) => a.categoryName.categoryName.localeCompare(b.categoryName.categoryName),
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }) => {
    //     return (
    //       <>
    //         <Input
    //           autoFocus
    //           placeholder="Type text here"
    //           value={selectedKeys[0]}
    //           onChange={(e) => {
    //             setSelectedKeys(e.target.value ? [e.target.value] : []);
    //             confirm({ closeDropdown: false });
    //           }}
    //           onPressEnter={() => {
    //             confirm();
    //           }}
    //           onBlur={() => {
    //             confirm();
    //           }}
    //         ></Input>
    //         <Button
    //           onClick={() => {
    //             confirm();
    //           }}
    //           type="primary"
    //         >
    //           Search
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             clearFilters();
    //           }}
    //           type="danger"
    //         >
    //           Reset
    //         </Button>
    //       </>
    //     );
    //   },
    //   filterIcon: () => {
    //     return <SearchOutlined />;
    //   },
    //   onFilter: (value, record) => {
    //     return record.categoryName.categoryName.toLowerCase().includes(value.toLowerCase());
    //   },
    // },
    {
      key: "savingProcessId",
      title: "N.Processo",
      dataIndex: ["savingProcessId"],
      align: "center",
      sorter: (a, b) => a.savingProcessId - b.savingProcessId,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type number here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              type="number" // Ensure input is numeric
            />
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.savingProcessId === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "productName",
      title: "Produto",
      dataIndex: ["productName","productName"],
      sorter: (a, b) => a.productName.productName.localeCompare(b.productName.productName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.productName.productName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "agentName",
      title: "Agente",
      dataIndex: ["agentName","agentName"],
      sorter: (a, b) => a.agentName.agentName.localeCompare(b.agentName.agentName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.agentName.agentName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "savingName",
      title: "Nome do Cesto",
      dataIndex: "savingName",
      sorter: (a, b) => a.savingName.localeCompare(b.savingName),
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Ativo</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Desativo</span>
          );
      },
      filters: [
        { text: "Ativo", value: true },
        { text: "Desativo", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      key: "closePortfolio",
      title: "Cesto Fechado",
      dataIndex: "closePortfolio",
      width: "5%",
      align: 'center',
      sorter: (a, b) => a.closePortfolio - b.closePortfolio,
      render: (value, record) => {
        if(record.closePortfolio === true )
          return (
            <span style={{color: "#39A939"}}>Sim</span>
          );

          return (
            <span style={{color: "#FF3234"}}></span>
          );
      },
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.closePortfolio === value;
      },
    },
    {
      key: "archivePortfolio",
      title: "Cesto Arquivado",
      dataIndex: "archivePortfolio",
      width: "5%",
      align: 'center',
      sorter: (a, b) => a.archivePortfolio - b.archivePortfolio,
      render: (value, record) => {
        if(record.archivePortfolio === true )
          return (
            <span style={{color: "#39A939"}}>Sim</span>
          );

          return (
            <span style={{color: "#FF3234"}}></span>
          );
      },
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.archivePortfolio === value;
      },
    },
    {
      key: "repeatNextDay",
      title: "Corre amanhã",
      dataIndex: "repeatNextDay",
      width: "5%",
      align: 'center',
      sorter: (a, b) => a.repeatNextDay - b.repeatNextDay,
      render: (value, record) => {
        if(record.repeatNextDay === true )
          return (
            <span style={{color: "#39A939"}}>Sim</span>
          );

          return (
            <span style={{color: "#FF3234"}}></span>
          );
      },
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.repeatNextDay === value;
      },
    },
    {
      title: "Acções",
      dataIndex: "type",
      align: "center",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <Space size="middle">
            <Tooltip title="Portfólio">
            <PieChartOutlined
              onClick={() => {
                // console.log(record);
                navigate('/SavingUserPortfolioAdmin',
                {
                  state:{
                    userID: record.userid._id,
                    savingName: record.savingName,
                    savingProcessID:  record.savingProcessId        
                  }
                });
              }}
            />
            </Tooltip>
            {/* <Tooltip title="Performance">
            <LineChartOutlined
              onClick={() => {
                console.log(record);
                navigate('/SavingUserPortfolioPerformanceAdmin',
                {
                  state:{
                    userID: record.userid._id,
                    savingName: record.savingName,
                    savingProcessID:  record.savingProcessId            
                  }
                });
              }}
            />
            </Tooltip>
            <Tooltip title="Transacções">
            <UnorderedListOutlined
              onClick={() => {
                console.log(record);
                navigate('/SavingUserTransactionsAdmin',
                {
                  state:{
                    userID: record.userid._id,
                    savingName: record.savingName,
                    savingProcessID:  record.savingProcessId            
                  }
                });
              }}
            />
            </Tooltip> */}
            </Space>
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    const getallSavingsTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savings/get-allsaving', {
          status:statusFilter
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingsTransactions(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getallSavingsTransactions();
  },[statusFilter])

  const numberAllSavingPortfolio = allSavingsTransactions
  .filter((transaction) => transaction.status === true)
  .length;

  // Calculate the number of distinct NIFs
  const distinctNIFs = [...new Set(allSavingsTransactions.map(transaction => transaction.userid.nif))];
  const numberOfDistinctNIFs = distinctNIFs.length;

  // Add a combined name field
  allSavingsTransactions.forEach(item => {
    item.fullName = `${item.userid.firstName} ${item.userid.lastName}`;
  });

  return (
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>Meus Cestos de Criptomoedas</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/SavingAllTransactionsAdmin')}
        >
          Todos os Movimentos
        </Button>
      </div>
      
      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "green", borderColor: "green" }}
          onClick={() => navigate('/ProcessingRefreshAllSavings')}
        >
          Refresh Todos os Cestos
        </Button>
      </div>
      {/* Final Header */}

      <div className="accountpage">
          <div><h6>Número de Cestos</h6>
          <p className="asset-info">{numberAllSavingPortfolio}</p>
          </div>
          <div><h6>Número de Clientes</h6>
          <p className="asset-info">{numberOfDistinctNIFs}</p>
          </div>
      </div>

      {/* Start Graphs */}
      {/* End Graphs */}

      {/* <div><pre>{JSON.stringify(allSavingsTransactions, null, 2) }</pre></div> */}

      <hr></hr>
      <div className="content">
        <Table
        size="small"
        columns={columns}
        dataSource={allSavingsTransactions}
        pagination={{ position: ['bottomCenter'], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>

    </Layout>
  );
};

export default MySavingsAdmin;