import React,{useState,useEffect} from 'react'
import {Link,useNavigate} from "react-router-dom";
import { Space, Avatar, Button, Carousel } from 'antd';

// import {
//   SolutionOutlined,
//   LockOutlined,
//   TranslationOutlined,
//   PoweroffOutlined,
//   UserOutlined,
// } from "@ant-design/icons";

const Header = () => {

  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  return (

    <header className="header">

        {/* <Link style={{ color: 'white', paddingTop: '83px' }} className="navbar-brand" to="/">
          SAVINGS4U
        </Link> */}

        <Carousel autoplay>
        <div align="center" >
        <img src="https://via.placeholder.com/1790x125" alt="Image 1" />
        </div>
        <div align="center" >
          {/* <h3>Image 2</h3> */}
          <img src="https://via.placeholder.com/1790x125" alt="Image 2" />
        </div>
        <div align="center" >
          {/* <h3>Image 3</h3> */}
          <img src="https://via.placeholder.com/1790x125" alt="Image 3" />
        </div>
      </Carousel>

    </header>


      // <nav className="navbar navbar-expand-lg bg-dark">
      // <div className="container-fluid">
      //   <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
      //     <Link style={{ color: 'white' }} className="navbar-brand" to="/">
      //       SMARTBUDGET4U
      //     </Link>
      //     <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      //       <Space size="small" align="center">
      //         <li>
      //           <a style={{ color: 'white' }}> Benvindo, <b style={{ color: 'green' }}> {loginUser && loginUser.name}</b></a>
      //         </li>
      //         <li>
      //           <a style={{ color: 'white' }}>|</a>
      //         </li>
      //         <li className="nav-item">
      //           <a style={{ color: 'white' }} className="nav-link" href="/">
      //             Notificações
      //           </a>
      //         </li>
      //         <li>
      //           <a style={{ color: 'white' }}>|</a>
      //         </li>
      //         <li className="nav-item">
      //           <a style={{ color: 'white' }} className="nav-link" href="/">
      //             Feedback
      //           </a>
      //         </li>
      //         <li>
      //           <a style={{ color: 'white' }}>|</a>
      //         </li>
      //         <li className="nav-item">
      //           <a style={{ color: 'white' }} className="nav-link" href="/">
      //             Ajuda
      //           </a>
      //         </li>
      //         <li>
      //           <a></a>
      //         </li>
      //       </Space>
      //       <li>
      //         <Avatar icon={<UserOutlined />} />
      //       </li>
      //       <li className="nav-item"></li>
      //     </ul>
      //   </div>
      // </div>
      // </nav>

  );
}

export default Header