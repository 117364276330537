import React,{useState,useEffect} from "react";
import {Tabs, Form, Input, Col, Space ,Card,Row, message, Button, Popconfirm} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';
import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";

const DeleteAccount = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [allSavingStats, setAllSavingStats] = useState([]);

  const [loading,setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('profile');

  const [form] = Form.useForm();

  //Handlers User  **************************************************************************************************************************
  const handleDelete = async (values) => {
    try{
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'))

      const res1 = axios.post('/accounts/delete-account', {
        userid:user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );

      const res2 = axios.post('/accounts/delete-all-account/'+ user._id ,{
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }       
      );

      setLoading(false);
      
      localStorage.removeItem('user');
      localStorage.removeItem('investorProfile');

      navigate('/login');

    }catch (error){
      setLoading(false);
      message.error('Algo deu errado!');
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          message.error('No user found in localStorage');
          return;
        }

        setLoading(true);

        const res = await axios.post('/users/get-user', 
          { userid: user._id }, 
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` } });

        if (res.data && res.data.length > 0) {
          const userData = res.data[0];
          setAllSavingStats(userData);

          // Format birthdayDate using moment
          const formattedDate = moment(userData.birthdayDate).format('YYYY-MM-DD');

          // Set form fields value
          form.setFieldsValue({
            name: userData.firstName+" "+userData.lastName,
            countryCitizenship: userData.countryCitizenship,
            birthdayDate: formattedDate,
            nif: userData.nif,
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            currency: userData.currency,
          });
        } else {
          message.error('No user data found');
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error('Fetch Issue With account');
      }
    };

    fetchUserData();
  }, [form]);

  return (
    <Layout>
      {loading && <Spinner/>}

      <div>
        <Form
        layout="vertical" 
        form={form} 
        >
          <Form.Item 
            name="name" 
            label="Name">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}} 
            />
          </Form.Item>
          <Form.Item 
            name="birthdayDate" 
            label="Date of birth">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item 
            name="nif" 
            label="Fiscal Number">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item 
            name="countryCitizenship" 
            label="Country Citizenship">
            <Input 
            disabled
            style={{ width: "100%", color:"black", textAlign: "left"}}  
            />
          </Form.Item>
          <Form.Item>
          <Popconfirm
            title="Are you sure you want to delete your profile?"
            onConfirm={handleDelete} // Replace with your actual delete handler function
            okText="Yes"
            cancelText="No"
          >
            <Button 
              style={{ width: "100%", marginTop: "20px" }} 
              type="primary" 
              htmlType="submit"
            >
              DELETE YOUR PROFILE
            </Button>
          </Popconfirm>
          <Button
            style={{ width: "100%" , background: "white", color: "blue", marginTop: 30, marginBottom: 30 }}
            type="primary" 
            htmlType="submit"
            onClick={() => navigate('/')}
          >
            CANCEL
          </Button>
        </Form.Item>
        </Form>
        
    </div>

    </Layout>
  );
};

export default DeleteAccount;