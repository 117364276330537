import React,{useState,useEffect} from "react";
import {Input, Select,Button, Table,Space,Form,Switch,Modal} from 'antd';
import MySaving from "./../components/Assets/savings.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, LineChartOutlined, MinusOutlined, PieChartOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, SettingOutlined, TableOutlined, UnorderedListOutlined } from "@ant-design/icons";

import moment from 'moment';

const MyEmails = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  // console.log(investor)

  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);
 
  const [allEmails, setAllEmails] = useState([]);

  //Filters
  const [statusFilter, setStatusFilter] = useState('all');

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Fields from table to View
  const [subjectUpdate , setSubjectUpdate]  = useState(null);
  const [messageUpdate , setMessageUpdate]  = useState(null);
  const [replyMessageUpdate , setReplymessageUpdate]  = useState(null);
  const [statusUpdate , setStatusUpdate]  = useState(null);
  
  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

  //table accounts
  const columns =[
    {
      key: "createdAt",
      title: "Contact Date",
      dataIndex: "createdAt",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "updatedAt",
      title: "Last update",
      dataIndex: "updatedAt",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    },
    {
      key: "subject",
      title: "Subject",
      dataIndex: ["subject"],
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.subject.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "message",
      title: "Message",
      dataIndex: ["message"],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.message.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "agentName",
      title: "Agent",
      dataIndex: ["agentName","agentName"],
      sorter: (a, b) => a.agentName.agentName.localeCompare(b.agentName.agentName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.agentName.agentName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Closed</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Opened</span>
          );
      },
      filters: [
        { text: "Closed", value: true },
        { text: "Opened", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Actions",
      dataIndex: "type",
      align: "center",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-4">
            <EyeOutlined
              onClick={() => {
                setSubjectUpdate(record.subject);
                setMessageUpdate(record.message);
                setReplymessageUpdate(record.replyMessage);
                setStatusUpdate(record.status);
                setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    const getallSavingsTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/emailcontacts/get-emailcontact', {
          userid:user._id,
          status:statusFilter
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllEmails(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getallSavingsTransactions();
  },[statusFilter])

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setSubjectUpdate(null);
    setMessageUpdate(null);
    setReplymessageUpdate(null);
    setStatusUpdate(null);
  };

  return (
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>My Mailbox</h1></div>
        <div></div>
      </div>
      {/* Final Header */}

      {/* <div><pre>{JSON.stringify(allEmails, null, 2) }</pre></div> */}

      <hr></hr>
      <div className="content">
        <Table
        size="small"
        columns={columns}
        dataSource={allEmails}
        pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
      </div>

      <Modal
        destroyOnClose={true} 
        title={"Pedido de Contacto"}
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
        <Form 
          layout="vertical"
          style={{padding:"32px 16px"}}
        >

          <Form.Item label="Subject" name="subject">
            <Input
              disabled 
              type="text"
              style={{color:"black"}}
              defaultValue={subjectUpdate}
              />
          </Form.Item>

          <Form.Item label="Message" name="message">
            <TextArea
              disabled
              showCount
              maxLength={500}
              style={{
                height: 200,
                marginBottom: 12,
                color:"black"
              }}
              type="text" 
              defaultValue={messageUpdate}
              />
          </Form.Item>

          {replyMessageUpdate && (
            <Form.Item label="Reply to Message" name="message">
              <TextArea
                disabled
                showCount
                maxLength={500}
                style={{
                  height: 200,
                  marginBottom: 12,
                  color: "black"
                }}
                type="text" 
                defaultValue={replyMessageUpdate}
              />
            </Form.Item>
          )}
          
        </Form>
    </Modal>

    </Layout>
  );
};

export default MyEmails;