import React, { useState, useEffect } from "react";
import { Image, Form, Layout } from "antd";
import Binance from "./../components/Assets/binance.svg";
import Navbar from "../components/Layout/Navbar.js";
import { useNavigate } from "react-router-dom";
import { format, subDays } from "date-fns";
import axios from "axios";
import {
	ArrowDownOutlined,
	ArrowLeftOutlined,
	ArrowUpOutlined,
	CheckCircleFilled,
	ExclamationCircleFilled,
	HddOutlined,
	MinusOutlined,
	PieChartOutlined,
	PlusOutlined,
	ReloadOutlined,
	SettingOutlined,
	StockOutlined,
	TableOutlined,
	UnorderedListOutlined,
	UploadOutlined,
} from "@ant-design/icons";

import {
	BadgePercent,
	Star,
	Crown,
	Gem,
	ArrowRight,
	Database,
	MoreHorizontal,
	Edit,
	FileText,
	RefreshCcw,
	Trash2,
	RefreshCw,
	PlusCircleIcon,
	TrendingUp,
} from "lucide-react";

import moment from "moment";
import Chart from "react-apexcharts";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path

const HelpBinanceAccount = () => {
	const user = JSON.parse(localStorage.getItem("user"));

	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	const [form] = Form.useForm();

	// State management
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	const handleNewExchange = () => {
		setIsModalOpen(true); // Open modal
	};

	const handleCancel = () => {
		setIsModalOpen(false); // Close modal
		form.resetFields(); // Optionally reset form fields on close
	};

	//Responsive Button Rendering
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<Layout>
			<Navbar />
			<div className="container">
				<div>
					{/* Fixed back icon */}
					<ArrowLeftOutlined
						onClick={handleBack} // Navigate back to the previous page
						style={{
							top: 10, // Distance from the top
							fontSize: "24px", // Icon size
							cursor: "pointer", // Change the cursor to pointer on hover
							marginBottom: "10px",
							marginTop: "10px",
						}}
					/>
					<section>
						<div
							style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}
						>
							<h1 style={{ textAlign: "center" }}>
								How to Connect Your Binance Account to BitAdvisor
							</h1>
							<p>
								In this article, you can find detailed steps on how to get your
								Binance account API keys and add them to the BitAdvisor app for
								auto-sync. If you are using the Binance exchange, this article
								will provide step-by-step guidance to automatically connect your
								Binance account to BitAdvisor and track your holdings here. You
								can connect to Binance both on the web and mobile platforms.
							</p>
							<p>
								<strong>Here is how it works 👇🏻</strong>
							</p>

							<p>
								First, log in to your Binance account, then select{" "}
								<strong>Account</strong> from the left side menu{" "}
								<strong>API Management</strong>.
							</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/1184131333/ca36290ff58be6b4397b69fc/Screenshot+2024-09-18+at+15_25_49.png?expires=1733158800&signature=d3ad39001d8148e187062639888b991e35e6bdc35c0f747aa80941516f53c730&req=dSEvEsh9nIJcWvMW1HO4zRVwccFkvjJFtowKj8c6T%2BGMFogDakiTiRLjKP%2FE%0AeQu0ZgwNgYiSRiBNHgU%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>Click Create API</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/710334800/d157dbbec4315a82523ba927/image.png?expires=1733158800&signature=93ba66379146b2630360b09bad4bcd91df3e8b15b1ded01c1522ce19d891acdd&req=cyEnFcp6lYFfFb4f3HP0gOMooOYbksmhUnz7QC%2F4eUtzOjOOdns3kXzHCTdT%0A2DN8k%2F2%2FBY%2B9gHppyA%3D%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>Select System generated API type.</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/710335141/31e74ae0b4fdf1e4a1e5d3e8/image.png?expires=1733158800&signature=fc9834dd1367408cf98a1b914eb6d7c9d6ff11dfa222acb8e7b7e88fcb3a71d1&req=cyEnFcp7nIVeFb4f3HP0gMyF5QAytcsojabQCUw3ymO9d4F2zJaqqjlSi9Ct%0ANySzIrDmf%2FR6t2Kx4g%3D%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>
								Now, label your API key with any label you'd like to (we
								recommend using a separate API key for each service you use and
								label them accordingly).
							</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/710335313/d58951bce76dc3f21b3f28ca/image.png?expires=1733158800&signature=b9c115b1f78b63fc5ab9ef72f4912093f0c8ba8ae0e9165d62873f5d13176936&req=cyEnFcp7noBcFb4f3HP0gP3MSa9PvT7fRLw8G9%2Bhm7szo7sv3Wn7pQfQcDZu%0AO%2Fta8sblPpT%2Bc%2Bkk%2FA%3D%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>
								After clicking Next, it will ask for your phone verification
								code, e-mail verification code, and Google verification code.
							</p>

							<p>
								Note: If you did not set one of those verification types, you
								might not need to verify them.
							</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/710335754/5b6d71356c02c4fa1eec6fb3/image.png?expires=1733158800&signature=b1f3b1f8cf4953615770b43e474fcc2067a76d977e493aaebd9360eff383d176&req=cyEnFcp7moRbFb4f3HP0gBrVWhlH8YnClPAicYAAqr%2FUyk3lQ%2FpEdifiKZiD%0AoEDqGiN0%2B57x174s1w%3D%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>
								After a successful security verification, you will see a screen
								like the one below with a QR code, your API Key, and your Secret
								key.
							</p>

							{/* Image below the card */}
							<div style={{ display: "flex", justifyContent: "left" }}>
								<Image
									src="https://downloads.intercomcdn.com/i/o/710521656/54bbad7d819ee014ea649b07/image.png?expires=1733158800&signature=a38daf4b9ada7e17bd00b461660131e7e9e1c8dbcbb865bf71b70d85a0eec54d&req=cyEnE8t%2Fm4RZFb4f3HP0gMzb2at5HR13%2BTFLPegFek4ICPxtgAfTbB5lNW7L%0A6IJaBjn7wW6AI7mUhg%3D%3D%0A"
									alt="Binance Guide Image"
									width={"100%"}
									preview={false} // Disable preview on click
									style={{ borderRadius: "8px" }}
								/>
							</div>
							<p>
								Note: Please make sure to copy/paste your Secret Key to a safe
								place, as it will only be displayed when it is created. If you
								lose this key, you will need to delete your API and set up a new
								one.
							</p>
							<p>
								Finally click on the button <strong>Edit Restrictions</strong>{" "}
								and check box <strong>"Enable Spot & Margin Trading"</strong>{" "}
								and check radio box <strong>Unrestricted (Less Secure)</strong>
								.Finally click on the button Save
							</p>

							<p>Now, go to the BitAdvisor app.</p>
						</div>
					</section>
				</div>

				<section>
					<ResponsiveMenuDrawer
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</Layout>
	);
};

export default HelpBinanceAccount;
