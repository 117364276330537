import React,{useState,useEffect} from "react";
import {Table, Button,message,Modal,Form,Input,Select,Space} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const PortfolioProducts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const portfolioProductIdUpdate = data.portfolioProductId;

  const [loading,setLoading] = useState(false);

  const [allPortfolioProduct, setAllPortfolioProduct] = useState([]);

  //DropList Category Asset
  const [allAsset, setAllAsset] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false);

  //Edit Portfolio Product
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [weightUpdate, setWeightUpdate] = useState(null);
  const [productNameUpdate, setProductNameUpdate] = useState(null);
  const [cryptoAssetNameUpdate, setCryptoAssetNameUpdate] = useState(null);
  const [portfolioProductUpdate, setPortfolioProductUpdate] = useState(null);

  //table accounts
  const columns =[
    {
      title: '#',
      dataIndex: 'index',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      key: "cryptoAssetName",
      title: "Nome Ativo",
      dataIndex: ["cryptoAssetName","cryptoAssetName"],
      align: "center",
      sorter: (a, b) => a.cryptoAssetName.cryptoAssetName.localeCompare(b.cryptoAssetName.cryptoAssetName),
    },
    {
      key: "brokerName",
      title: "Broker",
      dataIndex: ["cryptoAssetName","brokerName"],
      align: "center",
      sorter: (a, b) => a.cryptoAssetName.brokerName.localeCompare(b.cryptoAssetName.brokerName),
    },
    {
      key: "binanceTicker",
      title: "Binance Ticker",
      dataIndex: ["cryptoAssetName","binanceTicker"],
      align: "center",
      sorter: (a, b) => a.cryptoAssetName.binanceTicker.localeCompare(b.cryptoAssetName.binanceTicker),
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: ["cryptoAssetName","yahooTicker"],
      align: "center",
      sorter: (a, b) => a.cryptoAssetName.yahooTicker.localeCompare(b.cryptoAssetName.yahooTicker),
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["productName","currency"],
      align: "center",
    },
    {
      key:"weights_pct",
      title: "Peso %",
      dataIndex: "weights_pct",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.weights_pct - b.weights_pct,
      render: (weights_pct, record) => {
        if(record.weights_pct >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(weights_pct).toFixed(2)} %</span>
          );
      },
  },
    {
      title: "Acções",
      dataIndex: "type",
      width: "5%",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
             <Space>
            <EditOutlined
              onClick={() => {
                setEditable(record);
                setWeightUpdate(record.weights_pct);
                setProductNameUpdate(record.productName.productName);
                setCryptoAssetNameUpdate(record.cryptoAssetName.cryptoAssetName);
                setPortfolioProductUpdate(record._id);
                setShowModal(true);
              }}
            />
            <DeleteOutlined
                className="mx-2"
                onClick={() => {
                handleDelete(record);
            }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  //useEffect Hook
  useEffect(() => {
    const getAllPortfolioProduct = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/portfolioproducts/get-portfolioProduct', {
          userid:user._id,
          productName: portfolioProductIdUpdate
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllPortfolioProduct(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllPortfolioProduct();
  },[portfolioProductIdUpdate])

  useEffect(() => {
    const getAllAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cryptoAssets/get-cryptoAsset', {
          userid:user._id,
          status: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllAsset(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAsset();
  },[])

  // //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/portfolioproducts/edit-portfolioProduct', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Produto atualizado com sucesso no Portfolio!');

    }else{
      setLoading(true);
      const result = axios.post('/portfolioproducts/add-portfolioProduct', {
        ...values,
        productName: portfolioProductIdUpdate,
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Produto criado com sucesso no Portfolio!');
    }
    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
    }
  }

  //delete handler
  const handleDelete = async (record) => {

    const user = JSON.parse(localStorage.getItem('user'))

    try {
        setLoading(true);
        await axios.post("/portfolioproducts/delete-portfolioProduct", {
        _id: record._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        message.success("Produto apagado com sucesso do Portfolio!");
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        // console.log(error);
        message.error("unable to delete");
    }
    };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setWeightUpdate(null);
    setProductNameUpdate(null);
    setCryptoAssetNameUpdate(null);
    setPortfolioProductUpdate(null);
  };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
          <div>
          </div>
          {/* <div><h1> Portfolio Acções- {investmentNameUpdate} - {currencyUpdate}</h1></div> */}
          <div><h1> Portfolio Produto - {data.productName} </h1></div>
          <div></div>
        </div>
        <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Ativo
        </Button>
        </div>

        <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <br></br>
        <Table
            // size="small"
            columns={columns}
            dataSource={allPortfolioProduct}
            pagination={false}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
      </div>

      {/* <div><pre>{JSON.stringify(allPortfolioProduct, null, 2) }</pre></div> */}

      <Modal
        destroyOnClose={true} 
        title={editable ? "Editar Portfolio" : "Alocar Novo Ativo" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form layout="vertical" onFinish={handleSubmitAccount} style={{padding:"32px 16px"}}>

          <Form.Item label="Ativo" name="cryptoAssetName">
            <Select
              defaultValue={cryptoAssetNameUpdate}
            >
                {
                allAsset.map((opts,i)=>
                  <Select.Option key={i} value={opts._id}>
                    {opts.cryptoAssetName}
                  </Select.Option>)
                }
            </Select>
          </Form.Item>

          <Form.Item label="Alocação % " name="weights_pct">
            <Input style={{ width: '220px' }}
              defaultValue={weightUpdate}
              placeholder='0.00'
              suffix="%" 
            />
          </Form.Item>

          <button type="submit" style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}} className="btn btn-success" 
          onClick={handleClose}
          >
                  {" "}
                  GRAVAR
            </button>
          
          </Form>
      </Modal>

    </Layout>
  );
};

export default PortfolioProducts;