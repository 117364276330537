import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Select, Table, Switch, Button, Space,Tooltip,Tag,DatePicker,Card,Row,Col,Typography} from 'antd';

import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { EditOutlined, SearchOutlined, CheckCircleFilled,ExclamationCircleFilled,DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import moment from 'moment';

const SavingAllTransactions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allTransactions, setAllTransactions] = useState([]);
  const [allBalanceStats, setAllBalanceStats] = useState([]);

  const {RangePicker} = DatePicker;

  //Filters
  const [isReforceOrWithdrawFilter, setIsReforceOrWithdrawFilter] = useState('all');
  const [isPendingFilter, setPendingFilter] = useState('all');
  const [frequency, setFrequency] = useState('yeartodate');
  const [selectedDate, setSelectedDate] = useState([]);

  //table accounts
  const columns =[
    //column date
    {
      key: "createdAt",
      title: "Transaction Date",
      dataIndex: "createdAt",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD  HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "investmentType",
      title: "Investment Type",
      dataIndex: "investmentType",
      align:"center",
      render: (value, record) => {
        if(record.investmentType === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Passive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Passive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.investmentType === value;
      },
    },
    {
      key: "savingProcessId",
      title: "Process Id",
      dataIndex: ["savingProcessId"],
      align: "center",
      sorter: (a, b) => a.savingProcessId - b.savingProcessId,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type number here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              type="number" // Ensure input is numeric
            />
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.savingProcessId === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "productName",
      title: "Product",
      dataIndex: ["productName","productName"],
      sorter: (a, b) => a.productName.productName.localeCompare(b.productName.productName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.productName.productName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "savingName",
      title: "Crypto Basket Name",
      dataIndex: "savingName",
      sorter: (a, b) => a.savingName.localeCompare(b.savingName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.savingName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "reforceOrWithdrawAction",
      title: "",
      dataIndex: "reforceOrWithdrawAction",
      align: 'center',
      render: (text, record) => {
        if (record.reforcePortfolio === true) {
          return (
            <span style={{color: "#39A939"}}>Reforce</span>
          );
        }
        if (record.withdrawPortfolio === true) {
          return (
            <span style={{color: "#FF3234"}}>Withdraw</span>
          );
        }
        return (
          <span style={{color: "#39A939"}}>Deposit</span>
        );
      },
      filters: [
        { text: "Reforce", value: "Reforce" },
        { text: "Withdraw", value: "Withdraw" },
        { text: "Deposit", value: "Deposit" },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (value === "Reforce") {
          return record.reforcePortfolio === true;
        }
        if (value === "Withdraw") {
          return record.withdrawPortfolio === true;
        }
        if (value === "Deposit") {
          return !record.reforcePortfolio && !record.withdrawPortfolio;
        }
        return false;
      },
    },
    //column amount
    {
        key:"amount",
        title: "Transaction Amount",
        dataIndex: "amount",
        align: 'right',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount, record) => {
          if(record.isReforceOrWithdraw === true )
            return (
            <span style={{color: "#39A939"}}>{parseFloat(amount).toLocaleString('de-DE', { style: 'currency', 'currency': 'USD',maximumFractionDigits: 2})}</span>

            );
            return (
              <span style={{color: "#FF3234"}}>{parseFloat(amount).toLocaleString('de-DE', { style: 'currency', 'currency': 'USD',maximumFractionDigits: 2})}</span>
            );
        },
    },
  ];

  useEffect(() => {
    const getAllTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingtransactions/get-savingtransaction',
        {
          userid:user._id,
          // frequency,
          // selectedDate,
          isReforceOrWithdraw: isReforceOrWithdrawFilter,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllTransactions(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllTransactions();

    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();
  },[isReforceOrWithdrawFilter,isPendingFilter,frequency,selectedDate])

    //Analytics
    const totalAllSavingPortfolio = allTransactions
    .reduce((acc, transaction) => acc + transaction.amount, 0);
  
    const numberAllSavingPortfolio = allTransactions.length

  useEffect(() => {
    const getAllTransactions = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingbalances/get-allsavingbalance',
        {
          userid:user._id,
          filterOption:"open"
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllBalanceStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllTransactions();

    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();
  },[isReforceOrWithdrawFilter,isPendingFilter,frequency,selectedDate])
  
  //Analytics
  const totalAllSavingBalance = allBalanceStats
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  const pctSavingBalance = ((totalAllSavingBalance/totalAllSavingPortfolio)-1)*100

  const isPositive = pctSavingBalance >= 0;
  const formatted_isPositive = pctSavingBalance.toLocaleString('de-DE', {maximumFractionDigits: 2});

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Ensure full viewport height
      textAlign: 'center', // Center text within the container
    },
    p: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '10px',
      color: isPositive ? '#39A939' : '#FF3234',
    },
  };

  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>All Crypto Baskets - Transactions</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
            >BACK
          </Button>
        </div>

      <div className="accountpage">
        <div className="vertical-select">
          <h6>Deposits/Withdrawals</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={isReforceOrWithdrawFilter} onChange={(values) => setIsReforceOrWithdrawFilter(values)}>
            <Select.Option value='all'>All</Select.Option>
            <Select.Option value='true'>Deposits</Select.Option>
            <Select.Option value='false'>Withdrawals</Select.Option>
          </Select>
        </div>
        {/* <div>
        <h6 className="vertical-select">Choose the Frequency</h6>
          <Select 
          style={{
            width: '150px',
          }} 
          value={frequency} onChange={(values) => setFrequency(values)}>
            <Select.Option value='7'>Last week</Select.Option>
            <Select.Option value='month'>Current Month</Select.Option>
            <Select.Option value='lastmonth'>Last Month</Select.Option>
            <Select.Option value='yeartodate'>YTD</Select.Option>
            <Select.Option value='thisyear'>This Year</Select.Option>
            <Select.Option value='custom'>Custom Dates</Select.Option>
          </Select>
          {frequency === 'custom' && (
          <RangePicker 
            value={selectedDate} 
            onChange={(values) => { setSelectedDate(values)}}
          />
          )}
        </div> */}
      </div>
      <div className="accountpage">
          <div><h6>Number of Transactions</h6>
          <p className="asset-info">{numberAllSavingPortfolio}</p>
          </div>
          <div><h6>Crypto Baskets Value</h6>
          <p className="asset-info">{(totalAllSavingBalance).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</p>
          </div>
          <div><h6>Total Investing</h6>
          <p className="asset-info">{(totalAllSavingPortfolio).toLocaleString('de-DE', { style: 'currency', currency: 'USD',maximumFractionDigits: 2})}</p>
          </div>
          <div><h6>P/L %</h6>
          <p 
          className="asset-info" 
          style={{ 
            ...styles.p, 
            color: isPositive ? '#39A939' : '#FF3234',
          }}>
          {isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          {formatted_isPositive} %
          </p>
          </div>
        </div>
      <hr></hr>
      <div className="content">
          <Table
            size="small"
            columns={columns}
            dataSource={allTransactions}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
          />
      </div>

      {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default SavingAllTransactions;