import React, { useState, useEffect } from "react";
import { Form, Input, message, Modal, Switch, Button, Table } from "antd";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import { useNavigate } from "react-router-dom";

import { SearchOutlined, EditOutlined } from "@ant-design/icons";

const CurrencyAssets = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TextArea } = Input;

	const [showModal, setShowModal] = useState(false);

	const [loading, setLoading] = useState(false);

	//Handle Close button Form
	const [show, setShow] = useState(false);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	const [allCurrencyAsset, setAllCurrencyAsset] = useState(null);

	//Fields from table to View
	const [currencyUpdate, setCurrencyUpdate] = useState(null);
	const [currencyCtrUpdate, setCurrencyCtrUpdate] = useState(null);
	const [descriptionUpdate, setDescriptionUpdate] = useState(null);
	const [symbolUpdate, setSymbolUpdate] = useState(null);
	const [yahooTickerUpdate, setYahooTickerUpdate] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState(true);

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "currency",
			title: "Moeda Base",
			dataIndex: "currency",
			align: "center",
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.currency.localeCompare(b.currency),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.currency.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "currency_ctr",
			title: "Moeda Contra-Parte",
			dataIndex: "currency_ctr",
			align: "center",
			sorter: (a, b) => a.currency_ctr.localeCompare(b.currency_ctr),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.currency_ctr.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "symbol",
			title: "Símbolo",
			dataIndex: "symbol",
			align: "center",
			sorter: (a, b) => a.symbol.localeCompare(b.symbol),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.symbol.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "yahooTicker",
			title: "Yahoo Ticker",
			dataIndex: "yahooTicker",
			align: "center",
			sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "description",
			title: "Descrição",
			dataIndex: "description",
			sorter: (a, b) => a.description.localeCompare(b.description),
		},
		{
			key: "status",
			title: "Estado",
			dataIndex: "status",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Active</span>;

				return <span style={{ color: "#FF3234" }}>Inactive</span>;
			},
			filters: [
				{ text: "Active", value: true },
				{ text: "Inactive", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.status === value;
			},
		},
		{
			title: "Ações",
			dataIndex: "type",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-2">
						<EditOutlined
							onClick={() => {
								setEditable(record);
								setCurrencyUpdate(record.currency);
								setCurrencyCtrUpdate(record.currency_ctr);
								setDescriptionUpdate(record.description);
								setSymbolUpdate(record.symbol);
								setYahooTickerUpdate(record.yahooTicker);
								setStatusUpdate(record.status);
								setShowModal(true);
							}}
						/>
					</div>
				);
			},
		},
	];

	const user = JSON.parse(localStorage.getItem("user"));

	useEffect(() => {
		const getAllCurrencyAsset = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/currencyAssets/get-currencyAsset",
					{},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllCurrencyAsset(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With Industry');
				// message.error('You are not Admin User');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllCurrencyAsset();
	}, []);

	//Handlers User  **************************************************************************************************************************
	const handleSubmitCurrencyAsset = async (values) => {
		try {
			const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			if (editable) {
				setLoading(true);
				const result = axios.post(
					"/currencyAssets/edit-currencyAsset",
					{
						payload: {
							...values,
							userid: user._id,
						},
						_id: editable._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Currency Asset Updated Successfull");
			} else {
				setLoading(true);
				const result = axios.post(
					"/currencyAssets/add-currencyAsset",
					{
						...values,
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Currency Asset Added Successfull");
			}

			setShowModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
			// message.error('Failed to sector');
			// message.error('You are not Admin User');
		}
	};

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	//Handler Reset Values to modal
	const handleCancelModal = () => {
		setShowModal(false);
		setEditable(null);
		setCurrencyUpdate(null);
		setCurrencyCtrUpdate(null);
		setDescriptionUpdate(null);
		setSymbolUpdate(null);
		setYahooTickerUpdate(null);
		setStatusUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
	};

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spinner />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Moedas Cambiais</h1>
				</div>
				<div></div>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					align="right"
					shape="round"
					className="btn btn-primary"
					onClick={() => setShowModal(true)}
				>
					+ Novo Símbolo de Câmbio
				</Button>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div className="content">
				<Table
					columns={columns}
					dataSource={allCurrencyAsset}
					pagination={false}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>
			{/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}

			<Modal
				destroyOnClose={true}
				title={editable ? "Editar Moeda Cambial" : "Nova Moeda Cambial"}
				open={showModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitCurrencyAsset}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Moeda Base" name="currency">
						<Input type="text" defaultValue={currencyUpdate} />
					</Form.Item>
					<Form.Item label="Moeda Contra-Parte" name="currency_ctr">
						<Input type="text" defaultValue={currencyCtrUpdate} />
					</Form.Item>
					<Form.Item label="Símbolo" name="symbol">
						<Input type="text" defaultValue={symbolUpdate} />
					</Form.Item>
					<Form.Item label="Yahoo Ticker" name="yahooTicker">
						<Input type="text" defaultValue={yahooTickerUpdate} />
					</Form.Item>
					<Form.Item label="Descrição" name="description">
						<TextArea
							showCount
							maxLength={2000}
							style={{
								height: 400,
								marginBottom: 24,
							}}
							type="text"
							defaultValue={descriptionUpdate}
						/>
					</Form.Item>
					<Form.Item label="Estado" name="status" valuePropName="checked">
						<Switch defaultChecked={statusUpdate} />
					</Form.Item>

					<div className="d-flex justify-content-end">
						<button
							type="submit"
							className="btn btn-primary"
							onClick={handleClose}
						>
							{" "}
							GRAVAR
						</button>
					</div>
				</Form>
			</Modal>
		</Layout>
	);
};

export default CurrencyAssets;
