import React, { useState, useEffect } from "react";
import {
	message,
	Table,
	Spin,
	Card,
	Typography,
	Row,
	Input,
	Switch,
	Button,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import moment from "moment";

import Chart from "react-apexcharts";
import {
	ArrowLeftOutlined,
	CloseOutlined,
	SearchOutlined,
} from "@ant-design/icons";

const PortfolioExchanges = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const { Title, Text } = Typography;

	// const exchangeDataUpdate = data;
	const exchangeIdUpdate = data.exchangeID;
	const exchangeNameUpdate = data.exchangeName;
	const currencyUpdate = data.currency;

	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user.profileType;
	const currencyRate = user.currency;

	const [loading, setLoading] = useState(false);

	const [showAll, setShowAll] = useState(true);

	const [allSubscription, setAllSubscription] = useState([]);
	// const [allAccount, setAllAccount] = useState([]);

	// const [allAccountStats, setAllAccountStats] = useState([]);

	// const [show, setShow] = useState(false);

	// const { TextArea } = Input;

	//Edit Transactions
	// const [editable, setEditable] = useState(null);

	//table accounts
	const columns = [
		// {
		// 	key: "date_refreshed",
		// 	title: "Update Date",
		// 	dataIndex: "date_refreshed",
		// 	render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
		// 	sorter: (a, b) => new Date(a.date_refreshed) - new Date(b.date_refreshed),
		// },
		{
			key: "symbol",
			title: "Symbol",
			dataIndex: ["symbol"],
			sorter: (a, b) => a.symbol.localeCompare(b.symbol),
		},
		{
			key: "cryptoAssetName",
			title: "Crypto Name",
			dataIndex: ["cryptoAssetName", "cryptoAssetName"],
			sorter: (a, b) =>
				a.cryptoAssetName.cryptoAssetName.localeCompare(
					b.cryptoAssetName.cryptoAssetName
				),
		},
		{
			key: "qty",
			title: "qty",
			dataIndex: "qty",
			align: "center",
			sorter: (a, b) => a.qty - b.qty,
			render: (qty, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>{qty.toLocaleString("de-DE", {})}</span>
				);
			},
		},
		{
			key: "locked",
			title: "qty Locked",
			dataIndex: "locked",
			align: "center",
			sorter: (a, b) => a.locked - b.locked,
			render: (locked, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>{locked.toLocaleString("de-DE", {})}</span>
				);
			},
		},
		{
			key: "today_price",
			title: "Average Price USDT",
			dataIndex: "today_price",
			align: "right",
			sorter: (a, b) => a.today_price - b.today_price,
			render: (today_price, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>
						{today_price.toLocaleString("de-DE", {
							style: "currency",
							currency: record.currency,
							maximumFractionDigits: record.ticketSize,
						})}
					</span>
				);
			},
		},
		// {
		// 	key: "today_price_ctr",
		// 	title: "Average Price",
		// 	dataIndex: "today_price_ctr",
		// 	align: "right",
		// 	sorter: (a, b) => a.today_price_ctr - b.today_price_ctr,
		// 	render: (today_price_ctr, record) => {
		// 		return (
		// 			// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
		// 			<span>
		// 				{today_price_ctr.toLocaleString("de-DE", {
		// 					style: "currency",
		// 					currency: record.currency_ctr,
		// 					maximumFractionDigits: record.ticketSize,
		// 				})}
		// 			</span>
		// 		);
		// 	},
		// },
		{
			key: "today_value",
			title: "Value USDT",
			dataIndex: "today_value",
			align: "right",
			sorter: (a, b) => a.today_value - b.today_value,
			render: (today_value, record) => {
				return (
					<span>
						{today_value.toLocaleString("de-DE", {
							style: "currency",
							currency: record.currency,
							maximumFractionDigits: 2,
						})}
					</span>
				);
			},
		},
		// {
		// 	key: "today_value_ctr",
		// 	title: "Value",
		// 	dataIndex: "today_value_ctr",
		// 	align: "right",
		// 	sorter: (a, b) => a.today_value_ctr - b.today_value_ctr,
		// 	render: (today_value_ctr, record) => {
		// 		return (
		// 			<span>
		// 				{today_value_ctr.toLocaleString("de-DE", {
		// 					style: "currency",
		// 					currency: record.currency_ctr,
		// 					maximumFractionDigits: 2,
		// 				})}
		// 			</span>
		// 		);
		// 	},
		// },
		{
			key: "weights_pct",
			title: "Weights %",
			dataIndex: "weights_pct",
			defaultSortOrder: "descend",
			align: "right",
			sorter: (a, b) => a.weights_pct - b.weights_pct,
			render: (weights_pct, record) => {
				if (record.weights_pct >= 0)
					return (
						// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
						<span> {parseFloat(weights_pct).toFixed(2)} %</span>
					);
			},
		},
		// {
		//   title: "Acções",
		//   dataIndex: "type",
		//   width: "5%",
		//   render: (type, record) => {
		//     // if(type === "Income" )
		//     return (
		//       <div className="mx-4">
		//         <Space size="middle">
		//         <EditOutlined
		//           onClick={() => {
		//             setEditable(record);
		//             setExchangeNameUpdate(record.exchangeName.exchangeName);
		//             setBrokerNameUpdate(record.exchangeName.brokerName);
		//             setFrequencyUpdate(record.exchangeName.frequency);
		//             setStatusUpdate(record.status);
		//             setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
		//             setExchangeIdUpdate(record.exchangeName._id);
		//             setCashExchangeIdUpdate(record._id);
		//             setShowModal(true);
		//           }}
		//         />
		//         <DeleteOutlined
		//           onClick={() => {
		//             // console.log(record);
		//             // navigate('/Transactions',
		//             // {
		//             //   state:{
		//             //     accountId: record.id,
		//             //     accountDescription: record.accountName
		//             //   }
		//             // });
		//           }}
		//         />
		//         </Space>
		//       </div>
		//     );
		//   },
		// }
	];

	//useEffect Hook
	useEffect(() => {
		const getAllSubscription = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/portfolio-exchanges/get-portfolio-exchange",
					{
						userid: user._id,
						exchangeName: exchangeIdUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSubscription(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllSubscription();
	}, [exchangeIdUpdate]);

	const [weightSubject, setWeightSubject] = useState([]);
	const [weightMarks, setWeightMarks] = useState([]);

	useEffect(() => {
		const sSubject = [];
		const sMarks = [];
		const getAllLoanNameWeights = async (values) => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));
				const res = await axios.post(
					"/portfolio-exchanges/get-balanceexchangeaccount",
					{
						userid: user._id,
						exchangeName: exchangeIdUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				// setAllBalanceExchangeAccountWeights(res.data);
				// console.log(res.data);

				for (let i = 0; i < res.data.length; i++) {
					sSubject.push(res.data[i].cryptoAssetName);
					sMarks.push(convertToCurrency(res.data[i].amount, "USD"));
				}
				setWeightSubject(sSubject);
				setWeightMarks(sMarks);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				message.error("You are not Admin User");
			}
		};
		getAllLoanNameWeights();
	}, [exchangeIdUpdate]);

	// //Handlers User  **************************************************************************************************************************
	// const handleSubmitAccount = async (values) => {

	// try{

	//   const user = JSON.parse(localStorage.getItem('user'))

	//   setLoading(true);
	//   const res = await axios.post('/exchanges/edit-exchange', {
	//     payload:{
	//       archive: false,
	//       status: true,
	//       userid: user._id,
	//     },
	//     _id: exchangeIdUpdate,
	//   },
	//   {
	//     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//   }
	//   );
	//   const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
	//     payload:{
	//       archive: false,
	//       userid: user._id,
	//     },
	//     _id: cashExchangeIdUpdate,
	//   },
	//   {
	//     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//   }
	//   );
	//   setLoading(false);
	//   message.success('Conta Wallet Activada novamente com sucesso!');
	//   setShowModal(false);
	//   setTimeout(function () {
	//     window.location.reload(true);
	//   }, 1000);
	//   console.log(values)
	//   }catch (error){
	//     setLoading(false);
	//     // message.error('Failed to industry');
	//     //message.error('You are not Admin User');
	//   }
	// }

	const handleBack = () => {
		navigate(-1);
	};

	const [searchTerm, setSearchTerm] = useState("");
	const clearSearch = () => setSearchTerm("");

	const [isVisible, setIsVisible] = useState(false);
	const [showDailyReturns, setShowDailyReturns] = useState(false);
	const [isUSD, setIsUSD] = useState(true);
	const [exchangeRate, setExchangeRate] = useState({ usd: 1, eur: 1 });

	const [selectedPeriod, setSelectedPeriod] = useState("1mo");

	const convertReturnsToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;

		// Determine the number of decimal places based on thresholds
		let decimalPlaces;

		decimalPlaces = 2; // Limit to 2 decimals for large amounts

		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(convertedAmount);
	};

	const convertToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;

		// Determine the number of decimal places based on thresholds
		let decimalPlaces;

		// For large amounts like Bitcoin, set a maximum number of decimal places
		if (convertedAmount >= 1) {
			decimalPlaces = 2; // Limit to 2 decimals for large amounts
		} else if (convertedAmount >= 0.1) {
			decimalPlaces = 2; // 3 decimals for values >= 0.01
		} else if (convertedAmount >= 0.01) {
			decimalPlaces = 3; // 3 decimals for values >= 0.001
		} else if (convertedAmount >= 0.001) {
			decimalPlaces = 4; // 3 decimals for values >= 0.001
		} else {
			decimalPlaces = 6; // 6 decimals for very small values (less than 0.00001)
		}

		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(convertedAmount);
	};

	// Toggle visibility handler
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	// Toggle return type handler
	const toggleReturnType = (checked) => {
		setShowDailyReturns(checked);
	};

	// Toggle currency type handler
	const toggleCurrency = (checked) => {
		setIsUSD(checked);
	};

	// Helper function to generate image URLs dynamically
	const generateImageURL = (symbol) => {
		if (symbol.toLowerCase() === "matic") {
			symbol = "matic"; // Adjust to "maticusdt" if it's "polusdt"
		}
		return `https://static.monnos.com/coins/${symbol.toUpperCase()}.svg`;
	};
	// Function to extract the coin symbol (e.g., "btc" from "btcusdt")
	const extractCoinSymbol = (symbol) => {
		if (symbol.toLowerCase() === "polusdt") {
			return "matic"; // Replace "pol" with "matic"
		}
		return symbol.slice(0, -4).toLowerCase(); // Remove "usdt" from the end
	};

	// Fetch exchange rates from CoinGecko API
	const fetchExchangeRate = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=" +
					user.currency.toLowerCase() +
					",usd&vs_currencies=" +
					user.currency.toLowerCase() +
					",usd"
			);
			const data = await response.json();
			setExchangeRate({
				usd: data.usd[user.currency.toLowerCase()], // USD to EUR rate
				eur: 1, // Base currency EUR
			});
			console.log(data);
		} catch (error) {
			console.error("Error fetching exchange rate:", error);
		}
	};

	// Call the fetch function when the component mounts
	useEffect(() => {
		fetchExchangeRate();
	}, []);

	const columnsCoin = [
		{
			title: "",
			dataIndex: "image",
			render: (text, record) => {
				const imageURL = generateImageURL(
					extractCoinSymbol(record.cryptoAssetName.binanceTicker)
				);
				const isMobile = window.innerWidth <= 768;
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column", // Stack items vertically
							alignItems: "center", // Center-align items
							justifyContent: "center", // Center content vertically
						}}
					>
						<img
							src={imageURL} // Dynamically generate the image URL
							alt={record.cryptoAssetName.cryptoAssetName}
							style={{
								width: "30px",
								height: "30px",
								display: "inline-block",
							}} // Optional styling
							onError={(e) => {
								e.target.onerror = null; // Prevent infinite onError loops
								e.target.style.display = "none"; // Hide the image if it fails to load
							}}
						/>
						<br />
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "12px" : "14px", // Font size for text
								fontWeight: "bold",
								marginBottom: 5, // Add space below text
							}}
						>
							{record.weights_pct} %
						</p>
					</div>
				);
			},
		},
		// {
		// 	title: "",
		// 	dataIndex: "info",
		// 	align: "center", // Ensure alignment to the center
		// 	render: (text, record) => {
		// 		const isMobile = window.innerWidth <= 768;
		// 		return (
		// 			<div
		// 				style={{
		// 					display: "flex",
		// 					flexDirection: "column", // Stack items vertically
		// 					alignItems: "center", // Center-align items
		// 					justifyContent: "center", // Center content vertically
		// 				}}
		// 			>
		// 				<img
		// 					src={record.image}
		// 					alt={record.name}
		// 					style={{
		// 						width: isMobile ? 30 : 60, // Adjust size for mobile
		// 						height: isMobile ? 30 : 60,
		// 					}}
		// 				/>
		// 				<p
		// 					style={{
		// 						margin: 0,
		// 						fontSize: isMobile ? "12px" : "14px", // Font size for text
		// 						fontWeight: "bold",
		// 						marginBottom: 5, // Add space below text
		// 					}}
		// 				>
		// 					{record.weights_pct} %
		// 				</p>
		// 			</div>
		// 		);
		// 	},
		// },
		{
			title: "Coin Info",
			dataIndex: "info",
			align: "left", // Ensure alignment to the right
			render: (text, record) => {
				const isMobile = window.innerWidth <= 768;
				return (
					<div
						style={{
							textAlign: "left", // Ensure text aligns to the right
						}}
					>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "12px" : "14px",
								fontWeight: "bold",
							}}
						>
							{record.symbol.toUpperCase()}
						</p>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							{record.cryptoAssetName.cryptoAssetName}
						</p>
						<br />
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							Available
						</p>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							Freeze
						</p>
					</div>
				);
			},
		},
		{
			title: "",
			dataIndex: "info",
			align: "right", // Ensure alignment to the right
			render: (text, record) => {
				const isMobile = window.innerWidth <= 768;
				return (
					<div
						style={{
							textAlign: "right", // Ensure text aligns to the right
						}}
					>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "12px" : "14px",
								fontWeight: "bold",
							}}
						>
							{convertToCurrency(record.today_value, "USD")}
						</p>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							{convertToCurrency(record.today_price, "USD")}
						</p>
						<br />
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							{record.qty}
						</p>
						<p
							style={{
								margin: 0,
								fontSize: isMobile ? "10px" : "12px",
							}}
						>
							{record.locked}
						</p>
					</div>
				);
			},
		},
	];

	const cryptocurrencyData = [
		{
			id: "bitcoin",
			symbol: "btc",
			cryptoAssetName: "Bitcoin",
			image:
				"https://coin-images.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
			price_change_percentage_24h: 0.83,
			today_price: convertToCurrency(94930.86),
			total_volume: 35221896102,
			market_cap: 1344796648744,
			qty: 0.06,
			locked: 0,
			value: convertToCurrency(5712.39),
			weights_pct: 50.0,
		},
		{
			id: "ethereum",
			symbol: "eth",
			cryptoAssetName: "Ethereum",
			image:
				"https://coin-images.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
			price_change_percentage_24h: -1.25,
			today_price: convertToCurrency(3299.88),
			total_volume: 18955432875,
			market_cap: 569469167174,
			qty: 1.298,
			locked: 0,
			today_value: convertToCurrency(4283.71),
			weights_pct: 50.0,
		},
	];

	// Filter data based on the search term and the "showAll" state
	const filteredData = allSubscription.filter((coin) => {
		const matchesSearch =
			coin.cryptoAssetName.cryptoAssetName
				.toLowerCase()
				.includes(searchTerm.toLowerCase()) ||
			coin.symbol.toLowerCase().includes(searchTerm.toLowerCase());
		const matchesValueFilter = showAll || coin.today_value >= 1;
		return matchesSearch && matchesValueFilter;
	});

	// const filteredData = allSubscription.filter(
	// 	(coin) =>
	// 		coin.cryptoAssetName.cryptoAssetName
	// 			.toLowerCase()
	// 			.includes(searchTerm.toLowerCase()) ||
	// 		coin.symbol.toLowerCase().includes(searchTerm.toLowerCase())
	// );

	// Toggle showAll state
	const toggleValueFilter = () => {
		setShowAll((prevShowAll) => !prevShowAll);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				{/* Section 2: Cryptocurrency Table */}
				<section>
					<Card style={{ marginTop: "10px", marginBottom: "10px" }}>
						<Title level={4} className="section-title">
							{exchangeNameUpdate}
						</Title>
						<Row
							justify="space-between"
							className="search-row"
							style={{ marginTop: "10px", marginBottom: "10px" }}
						>
							<Input
								placeholder="Search Coins"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="search-input"
								prefix={<SearchOutlined />}
								style={{ width: "100%" }}
							/>
							{/* <Switch
								checked={isUSD}
								onChange={toggleCurrency}
								checkedChildren="USD"
								unCheckedChildren={currencyRate}
							/> */}
							{/* <Button
									onClick={clearSearch}
									icon={<CloseOutlined />}
									className="clear-button"
								></Button> */}
						</Row>
						<Row
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Button onClick={toggleValueFilter} type="primary">
								{showAll ? "Hide Value < 1" : "Show All"}
							</Button>
							<Switch
								checked={isUSD}
								onChange={toggleCurrency}
								checkedChildren="USD"
								unCheckedChildren={currencyRate}
							/>
						</Row>

						<Table
							columns={columnsCoin}
							dataSource={filteredData}
							pagination={{ pageSize: 10 }}
							scroll={{ x: "max-content" }} // Ensures horizontal scrolling onscreens
							responsive={true} // Makes the table responsive
							className="custom-table" // Apply the custom class here
							showHeader={false} // Disables the table header
						/>
					</Card>
				</section>

				{/* <hr></hr>
					<div className="content">
						<Table
							size="small"
							columns={columns}
							dataSource={allSubscription}
							pagination={{
								position: ["bottomCenter"],
								defaultPageSize: 25,
								showSizeChanger: true,
								pageSizeOptions: ["10", "25", "50"],
							}}
							scroll={{ x: "max-content" }}
							className="custom-table" // Apply custom CSS class for styling
						/>
					</div> */}
			</div>
		</div>
	);
};

export default PortfolioExchanges;
