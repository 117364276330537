import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	Select,
	Modal,
	Table,
	Switch,
	Space,
	Tooltip,
	Button,
	message,
} from "antd";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import {
	SearchOutlined,
	CheckCircleFilled,
	StopOutlined,
	EditOutlined,
} from "@ant-design/icons";

const Users = () => {
	const navigate = useNavigate();

	const { TextArea } = Input;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	const [showModal, setShowModal] = useState(false);
	// const [showModalAddUser, setShowModalAddUser] = useState(false);

	const [loading, setLoading] = useState(false);

	const [allUser, setAllUser] = useState([]);
	const [allAgent, setAllAgent] = useState([]);

	// //Handle Close button Form
	const [show, setShow] = useState(false);

	//Fields from table to View
	const [firstNameUpdate, setFirstNameUpdate] = useState(null);
	const [lastNameUpdate, setLastNameUpdate] = useState(null);
	const [emailUpdate, setEmailUpdate] = useState(null);
	const [passwordUpdate, setPasswordUpdate] = useState(null);
	const [nifUpdate, setNifUpdate] = useState(null);
	const [currencyUpdate, setCurrencyUpdate] = useState(null);
	const [birthdayOpenDateUpdate, setBirthdayOpenDateUpdate] = useState(null);
	const [countryCitizenshipUpdate, setCountryCitizenshipUpdate] =
		useState(null);
	const [phoneNumberUpdate, setPhoneNumberUpdate] = useState(null);
	const [addressLivingUpdate, setAddressLivingUpdate] = useState(null);
	const [postalCodeLivingUpdate, setPostalCodeLivingUpdate] = useState(null);
	const [ocuppationworkUpdate, setOcuppationWorkUpdate] = useState(null);
	const [isPEPUpdate, setIsPEPUpdate] = useState(null);
	const [isCorporateControlUpdate, setIsCorporateControlUpdate] =
		useState(null);
	const [isBlockedUpdate, setIsBlockedUpdate] = useState(null);
	const [isAdminUpdate, setIsAdminUpdate] = useState(null);
	const [isAgentUpdate, setIsAgentUpdate] = useState(null);
	const [acceptPrivacyUpdate, setAcceptPrivacyUpdate] = useState(null);
	const [acceptMarketingUpdate, setAcceptMarketingUpdate] = useState(null);
	const [profileTypeUpdate, setProfileTypeUpdate] = useState(null);
	const [agentNameUpdate, setAgentNameUpdate] = useState(null);
	const [investorProfileUpdate, setInvestorProfileUpdate] = useState(null);
	const [IBANUpdate, setIBANUpdate] = useState(null);

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "createdAt",
			title: "Data Criação",
			dataIndex: "createdAt",
			defaultSortOrder: "descend",
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		},
		{
			key: "updatedAt",
			title: "Última Atualização",
			dataIndex: "updatedAt",
			// defaultSortOrder: 'descend',
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
		},
		{
			key: "agentName",
			title: "Nome do Agente",
			dataIndex: ["agentName", "agentName"],
			sorter: (a, b) => a.agentName.agentName.localeCompare(b.nif),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.agentName.agentName
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "nif",
			title: "NIF",
			dataIndex: "nif",
			sorter: (a, b) => a.nif.localeCompare(b.nif),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.nif.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "firstName",
			title: "Primeiro Nome",
			dataIndex: "firstName",
			sorter: (a, b) => a.firstName.localeCompare(b.firstName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.firstName.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "lastName",
			title: "Ultimo Nome",
			dataIndex: "lastName",
			sorter: (a, b) => a.lastName.localeCompare(b.lastName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.lastName.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "email",
			title: "Email",
			dataIndex: "email",
			sorter: (a, b) => a.email.localeCompare(b.email),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.email.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "isAdmin",
			title: "Administrador",
			dataIndex: "isAdmin",
			align: "center",
			render: (value, record) => {
				if (record.isAdmin === true)
					return (
						<Tooltip title="Yes">
							<CheckCircleFilled
								className="mx-2"
								style={{ color: "#39A939" }}
							/>
						</Tooltip>
					);

				return (
					<Tooltip title="No">
						<StopOutlined className="mx-2" style={{ color: "#FF3234" }} />
					</Tooltip>
				);
			},
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.isAdmin === value;
			},
		},
		{
			key: "profileType",
			title: "Tipo Perfil",
			dataIndex: "profileType",
			align: "center",
			sorter: (a, b) => a.profileType - b.profileType,
			render: (value, record) => {
				if (record.profileType === 0) return <span>Cliente</span>;
				if (record.profileType === 1) return <span>Agente</span>;
				return <span>Super Administrador</span>;
			},
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.name.toLowerCase().includes(value.toLowerCase());
			},
		},
		// {
		//   key: "isAgent",
		//   title: "Agente",
		//   dataIndex: "isAgent",
		//   align: "center",
		//   render: (value, record) => {
		//     if(record.isAgent === true )
		//       return (
		//         <span style={{color: "#39A939"}}>Sim</span>
		//       );

		//       return (
		//         <span style={{color: "#FF3234"}}>Não</span>
		//       );
		//   },
		//   filters: [
		//     { text: "Yes", value: true },
		//     { text: "No", value: false },
		//   ],
		//   filterIcon: () => {
		//     return <SearchOutlined />;
		//   },
		//   onFilter: (value, record) => {
		//     console.log(typeof value)
		//     return record.isAgent === value;
		//   },
		// },
		{
			key: "isBlocked",
			title: "Estado",
			dataIndex: "isBlocked",
			align: "center",
			render: (value, record) => {
				if (record.isBlocked === false)
					return <span style={{ color: "#39A939" }}>Ativo</span>;

				return <span style={{ color: "#FF3234" }}>Bloqueado</span>;
			},
			filters: [
				{ text: "Active", value: false },
				{ text: "Inactive", value: true },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.isBlocked === value;
			},
		},
		{
			title: "Ações",
			dataIndex: "type",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-2">
						<Space direction="horizontal">
							<EditOutlined
								onClick={() => {
									setEditable(record);
									setShowModal(true);
									setFirstNameUpdate(record.firstName);
									setLastNameUpdate(record.lastName);
									setEmailUpdate(record.email);
									setPasswordUpdate(record.password);
									setNifUpdate(record.nif);
									setCurrencyUpdate(record.currency);
									setBirthdayOpenDateUpdate(record.birthdayDate);
									setCountryCitizenshipUpdate(record.countryCitizenship);
									setPhoneNumberUpdate(record.phoneNumber);
									setAddressLivingUpdate(record.addressLiving);
									setPostalCodeLivingUpdate(record.postalCodeLiving);
									setOcuppationWorkUpdate(record.ocuppationwork);
									setIsPEPUpdate(record.isPEP);
									setIsCorporateControlUpdate(record.isCorporateControl);
									setIsBlockedUpdate(record.isBlocked);
									setIsAdminUpdate(record.isAdmin);
									setIsAgentUpdate(record.isAgent);
									setProfileTypeUpdate(record.profileType);
									setInvestorProfileUpdate(record.investorProfile);
									setAgentNameUpdate(record.agentName.agentName);
									setIBANUpdate(record.IBAN);
								}}
							/>
						</Space>
					</div>
				);
			},
		},
	];

	// const user = JSON.parse(localStorage.getItem('user'))

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/users/all-users",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllUser(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Users');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	//Handlers Submit  **************************************************************************************************************************
	const handleSubmitUpdateUser = async (values) => {
		try {
			// const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/users/edit-user",
				{
					payload: {
						...values,
						userid: user._id,
					},
					_id: editable._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Utilizador atualizado com sucesso");

			setShowModal(false);
			setEditable(null);

			navigate("/ProcessingUpdateAgent", {
				state: {
					user: editable.id,
					firstName: editable.firstName,
					lastName: editable.lastName,
					processing: "Actualizar Agente",
				},
			});
		} catch (error) {
			setLoading(false);
			// message.error('Failed to edit User');
		}
	};

	//Handler Asset Class DropList ****************************************************************************************
	useEffect(() => {
		const getAllAgent = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/myagents/get-agent",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllAgent(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllAgent();
	}, []);

	//Handler Reset Values to modal

	const handleCancelModal = () => {
		setShowModal(false);
		setEditable(null);
		setFirstNameUpdate(null);
		setLastNameUpdate(null);
		setEmailUpdate(null);
		setPasswordUpdate(null);
		setNifUpdate(null);
		setCurrencyUpdate(null);
		setBirthdayOpenDateUpdate(null);
		setCountryCitizenshipUpdate(null);
		setPhoneNumberUpdate(null);
		setAddressLivingUpdate(null);
		setPostalCodeLivingUpdate(null);
		setOcuppationWorkUpdate(null);
		setIsPEPUpdate(null);
		setIsCorporateControlUpdate(null);
		setIsBlockedUpdate(null);
		setIsAdminUpdate(null);
		setIsAgentUpdate(null);
		setProfileTypeUpdate(null);
		setInvestorProfileUpdate(null);
		setAgentNameUpdate(null);
		setIBANUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
		// setShowModalAddUser(false);
	};

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	const allCountries = [
		"Afghanistan",
		"Åland Islands",
		"Albania",
		"Algeria",
		"American Samoa",
		"Andorra",
		"Angola",
		"Anguilla",
		"Antarctica",
		"Antigua and Barbuda",
		"Argentina",
		"Armenia",
		"Aruba",
		"Australia",
		"Austria",
		"Azerbaijan",
		"Bahamas",
		"Bahrain",
		"Bangladesh",
		"Barbados",
		"Belarus",
		"Belgium",
		"Belize",
		"Benin",
		"Bermuda",
		"Bhutan",
		"Bolivia (Plurinational State of)",
		"Bonaire, Sint Eustatius and Saba",
		"Bosnia and Herzegovina",
		"Botswana",
		"Bouvet Island",
		"Brazil",
		"British Indian Ocean Territory",
		"Brunei Darussalam",
		"Bulgaria",
		"Burkina Faso",
		"Burundi",
		"Cabo Verde",
		"Cambodia",
		"Cameroon",
		"Canada",
		"Cayman Islands",
		"Central African Republic",
		"Chad",
		"Chile",
		"China",
		"Christmas Island",
		"Cocos (Keeling) Islands",
		"Colombia",
		"Comoros",
		"Congo (Republic of the)",
		"Congo (Democratic Republic of the)",
		"Cook Islands",
		"Costa Rica",
		"Côte d'Ivoire",
		"Croatia",
		"Cuba",
		"Curaçao",
		"Cyprus",
		"Czech Republic",
		"Denmark",
		"Djibouti",
		"Dominica",
		"Dominican Republic",
		"Ecuador",
		"Egypt",
		"El Salvador",
		"Equatorial Guinea",
		"Eritrea",
		"Estonia",
		"Ethiopia",
		"Falkland Islands (Malvinas)",
		"Faroe Islands",
		"Fiji",
		"Finland",
		"France",
		"French Guiana",
		"French Polynesia",
		"French Southern Territories",
		"Gabon",
		"Gambia",
		"Georgia",
		"Germany",
		"Ghana",
		"Gibraltar",
		"Greece",
		"Greenland",
		"Grenada",
		"Guadeloupe",
		"Guam",
		"Guatemala",
		"Guernsey",
		"Guinea",
		"Guinea-Bissau",
		"Guyana",
		"Haiti",
		"Heard Island and McDonald Islands",
		"Vatican City State",
		"Honduras",
		"Hong Kong",
		"Hungary",
		"Iceland",
		"India",
		"Indonesia",
		"Iran",
		"Iraq",
		"Ireland",
		"Isle of Man",
		"Israel",
		"Italy",
		"Jamaica",
		"Japan",
		"Jersey",
		"Jordan",
		"Kazakhstan",
		"Kenya",
		"Kiribati",
		"Korea (Democratic People's Republic of)",
		"Korea (Republic of)",
		"Kuwait",
		"Kyrgyzstan",
		"Lao People's Democratic Republic",
		"Latvia",
		"Lebanon",
		"Lesotho",
		"Liberia",
		"Libya",
		"Liechtenstein",
		"Lithuania",
		"Luxembourg",
		"Macao",
		"Macedonia (the former Yugoslav Republic of)",
		"Madagascar",
		"Malawi",
		"Malaysia",
		"Maldives",
		"Mali",
		"Malta",
		"Marshall Islands",
		"Martinique",
		"Mauritania",
		"Mauritius",
		"Mayotte",
		"Mexico",
		"Micronesia (Federated States of)",
		"Moldova (Republic of)",
		"Monaco",
		"Mongolia",
		"Montenegro",
		"Montserrat",
		"Morocco",
		"Mozambique",
		"Myanmar",
		"Namibia",
		"Nauru",
		"Nepal",
		"Netherlands",
		"New Caledonia",
		"New Zealand",
		"Nicaragua",
		"Niger",
		"Nigeria",
		"Niue",
		"Norfolk Island",
		"Northern Mariana Islands",
		"Norway",
		"Oman",
		"Pakistan",
		"Palau",
		"Palestine, State of",
		"Panama",
		"Papua New Guinea",
		"Paraguay",
		"Peru",
		"Philippines",
		"Pitcairn",
		"Poland",
		"Portugal",
		"Puerto Rico",
		"Qatar",
		"Réunion",
		"Romania",
		"Russian Federation",
		"Rwanda",
		"Saint Barthélemy",
		"Saint Helena, Ascension and Tristan da Cunha",
		"Saint Kitts and Nevis",
		"Saint Lucia",
		"Saint Martin (French part)",
		"Saint Pierre and Miquelon",
		"Saint Vincent and the Grenadines",
		"Samoa",
		"San Marino",
		"Sao Tome and Principe",
		"Saudi Arabia",
		"Senegal",
		"Serbia",
		"Seychelles",
		"Sierra Leone",
		"Singapore",
		"Sint Maarten (Dutch part)",
		"Slovakia",
		"Slovenia",
		"Solomon Islands",
		"Somalia",
		"South Africa",
		"South Georgia and the South Sandwich Islands",
		"South Sudan",
		"Spain",
		"Sri Lanka",
		"Sudan",
		"Suriname",
		"Svalbard and Jan Mayen",
		"Swaziland",
		"Sweden",
		"Switzerland",
		"Syrian Arab Republic",
		"Taiwan, Province of China",
		"Tajikistan",
		"Tanzania, United Republic of",
		"Thailand",
		"Timor-Leste",
		"Togo",
		"Tokelau",
		"Tonga",
		"Trinidad and Tobago",
		"Tunisia",
		"Turkey",
		"Turkmenistan",
		"Turks and Caicos Islands",
		"Tuvalu",
		"Uganda",
		"Ukraine",
		"United Arab Emirates",
		"United Kingdom of Great Britain and Northern Ireland",
		"United States Minor Outlying Islands",
		"United States of America",
		"Uruguay",
		"Uzbekistan",
		"Vanuatu",
		"Venezuela (Bolivarian Republic of)",
		"Vietnam",
		"Virgin Islands (British)",
		"Virgin Islands (U.S.)",
		"Wallis and Futuna",
		"Western Sahara",
		"Yemen",
		"Zambia",
		"Zimbabwe",
	];

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spinner />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Utilizadores</h1>
				</div>
				<div></div>
			</div>

			{/* <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModalAddUser(true)}
          >+ Novo Agente
        </Button>
        </div> */}

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div className="content">
				<Table
					columns={columns}
					dataSource={allUser}
					pagination={{
						defaultPageSize: 25,
						showSizeChanger: true,
						pageSizeOptions: ["10", "25", "50", "100"],
					}}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>

			{/* <div><pre>{JSON.stringify(allUser, null, 2) }</pre></div> */}

			<Modal
				destroyOnClose={true}
				title={"Editar Utilizador"}
				open={showModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitUpdateUser}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Nome do Agente" name="agentName">
						<Select defaultValue={agentNameUpdate}>
							{allAgent.map((opts, i) => (
								<Select.Option key={i} value={opts._id}>
									{opts.agentName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item style={{ width: "100%" }} label="Email" name={"email"}>
						<Input type="text" defaultValue={emailUpdate} />
					</Form.Item>
					<Form.Item label="Primeiro Nome" name="firstName">
						<Input type="text" defaultValue={firstNameUpdate} />
					</Form.Item>
					<Form.Item label="Ultimo Nome" name="lastName">
						<Input type="text" defaultValue={lastNameUpdate} />
					</Form.Item>
					<Form.Item label="NIF" name={"nif"}>
						<Input defaultValue={nifUpdate} />
					</Form.Item>
					<Form.Item label="Data Nascimento" name="birthdayDate">
						<Input
							type="text"
							defaultValue={moment(birthdayOpenDateUpdate).format("YYYY-MM-DD")}
							disabled="true"
						/>
					</Form.Item>

					<Form.Item label="Nacionalidade" name={"countryCitizenship"}>
						<Select defaultValue={countryCitizenshipUpdate}>
							{allCountries.map((country) => (
								<Select.Option key={country} value={country}>
									{country}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item label="Número de Telefone" name={"phoneNumber"}>
						<Input defaultValue={phoneNumberUpdate} />
					</Form.Item>

					<hr></hr>
					<h6>Associações</h6>
					<br />
					<Form.Item>
						<h6>
							O agente, ou um membro da família imediata que reside na mesma
							casa, é empregado ou está associado a algum dos seguintes: um
							corretor, bolsa de valores, empresa membro da bolsa, corretora de
							valores mobiliários ou outra instituição financeira?
						</h6>
					</Form.Item>
					<Form.Item label="" name="isPEP" valuePropName="checked">
						<Switch
							checkedChildren="SIM"
							unCheckedChildren="NÃO"
							defaultChecked={isPEPUpdate}
						/>
					</Form.Item>
					<Form.Item>
						<h6>
							O cônjuge ou algum parente que more em casa, do agente (no mesmo
							endereço), membro do conselho de administração, acionista de 10%,
							um responsável pela elaboração de políticas ou alguém que possa
							dirigir a gestão e as políticas de uma empresa de capital aberto?
						</h6>
					</Form.Item>
					<Form.Item label="" name="isCorporateControl" valuePropName="checked">
						<Switch
							checkedChildren="SIM"
							unCheckedChildren="NÃO"
							defaultChecked={isCorporateControlUpdate}
						/>
					</Form.Item>
					<hr />
					<Form.Item
						label="Administrador?"
						name="isAdmin"
						valuePropName="checked"
					>
						<Switch
							checkedChildren="SIM"
							unCheckedChildren="NÃO"
							defaultChecked={isAdminUpdate}
						/>
					</Form.Item>
					<Form.Item label="Agente?" name="isAgent" valuePropName="checked">
						<Switch
							checkedChildren="SIM"
							unCheckedChildren="NÃO"
							defaultChecked={isAgentUpdate}
						/>
					</Form.Item>
					<Form.Item label="Tipo de Perfil" name="profileType">
						<Select defaultValue={profileTypeUpdate}>
							<Select.Option value="0">Cliente</Select.Option>
							<Select.Option value="1">Agente</Select.Option>
							<Select.Option value="9">Super Administrador</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item label="Estado" name="isBlocked" valuePropName="checked">
						<Switch
							checkedChildren="BLOQUEADO"
							unCheckedChildren="ATIVO"
							defaultChecked={isBlockedUpdate}
						/>
					</Form.Item>
					<button
						type="submit"
						style={{
							width: "100%",
							background: "green",
							color: "white",
							marginTop: 30,
							marginBottom: 30,
						}}
						className="btn btn-success"
						onClick={handleClose}
					>
						{" "}
						EDITAR
					</button>
				</Form>
			</Modal>
		</Layout>
	);
};

export default Users;
