import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Spinner";

import Layout from "../components/Layout/Layout";

import MyProcessing from "./../components/Assets/engineering.png";

const ProcessingSaving = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const savingNameUpdate = data.savingName;
	const exchangeNameUpdate = data.exchangeName;
	const processingUpdate = data.processing;
	const investmentTypeUpdate = data.investmentType;
	const savingProcessIdUpdate = data.savingProcessId;

	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getSavingProcessing = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);

				if (investmentTypeUpdate === false) {
					const res = axios.post(
						"/savings/create-saving-account/" +
							user._id +
							"/" +
							exchangeNameUpdate +
							"/" +
							savingProcessIdUpdate,
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${user.token}`,
							},
						}
					);
					// console.log(res.data);
				} else {
					const res2 = axios.post(
						"/savings/create-saving-active-account/" +
							user._id +
							"/" +
							exchangeNameUpdate +
							"/" +
							savingProcessIdUpdate,
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${user.token}`,
							},
						}
					);
					// console.log(res2.data);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};

		// Clean up the interval when the component unmounts
		return () => getSavingProcessing();
	}, []);

	useEffect(() => {
		const duration = 90 * 1000; // 15 seconds in milliseconds
		const interval = 100; // Update the progress every 100 milliseconds

		const steps = duration / interval;
		let currentStep = 0;

		const updateProgress = () => {
			currentStep += 1;
			const newProgress = (currentStep / steps) * 100;
			setProgress(newProgress);

			if (currentStep < steps) {
				// Continue updating the progress
				setTimeout(updateProgress, interval);
			} else {
				// Navigate to another page after the progress is complete
				navigate("/");
			}
		};

		// Start updating the progress
		updateProgress();

		// Clean up the interval when the component unmounts
		return () => clearTimeout(updateProgress);
	}, [navigate]);

	return (
		<Layout>
			{loading && <Spinner />}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: "50px",
				}}
			>
				<img alt="example" src={MyProcessing} />
				<br />
				<h1 align="center">
					{processingUpdate} <br /> {savingNameUpdate}
				</h1>
				<br />
				<Progress
					type="circle"
					percent={parseFloat(progress).toFixed(0)}
					status="active"
					style={{ fontSize: "40px" }}
				/>
				<br />
				<p style={{ textAlign: "center" }}>
					Please wait Window will close when processing is complete.
				</p>
			</div>
		</Layout>
	);
};

export default ProcessingSaving;
