import React, { useState, useEffect, useMemo } from "react";
import {
	Layout,
	Input,
	Select,
	Modal,
	Table,
	Switch,
	Spin,
	Button,
	Typography,
	Card,
	Row,
	Col,
} from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import {
	SearchOutlined,
	CheckCircleFilled,
	StopOutlined,
	EditOutlined,
	EyeOutlined,
} from "@ant-design/icons";

const Invoices = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { Title } = Typography;
	const { Option } = Select;

	const [loading, setLoading] = useState(false);

	const [feeData, setAllInvoices] = useState([]);

	const formatDate = (monthYearNumber) => {
		const year = monthYearNumber.toString().slice(0, 4); // Extract the year
		const month = monthYearNumber.toString().slice(4, 6); // Extract the month
		return `${year}-${month}`;
	};

	// Format currency
	const formatCurrency = (value) => `$${value.toFixed(2)}`;

	const formatEuroCurrency = (value) => `€${value.toFixed(2)}`;

	const feeColumns = [
		{
			title: "First Name",
			dataIndex: ["userid", "firstName"],
			key: "firstName",
		},
		{ title: "Last Name", dataIndex: ["userid", "lastName"], key: "lastName" },
		{
			title: "Agent Name",
			dataIndex: ["agentName", "agentName"],
			key: "agentName",
		},
		{
			title: "Date Invoice",
			dataIndex: "monthYearNumber",
			key: "monthYearNumber",
			render: (value) => formatDate(value),
		},
		{ title: "Description", dataIndex: "description", key: "description" },
		{
			title: "Today Value ($)",
			dataIndex: "today_value",
			key: "todayValue",
			render: (value) => formatCurrency(value),
		},
		{
			title: "Today Value (€)",
			dataIndex: "today_value_ctr",
			key: "today_value_ctr",
			render: (value) => formatEuroCurrency(value),
		},
		{ title: "Account Level", dataIndex: "feeCategory", key: "feeCategory" },
		{
			title: "Fee (€)",
			dataIndex: "feeMonthly",
			key: "feeMonthly",
			render: (value) => formatEuroCurrency(value),
		},
		{
			key: "isPaid",
			title: "isPaid",
			dataIndex: ["userid", "isPaid"],
			render: (value, record) => {
				if (record.isPaid === true)
					return <span style={{ color: "#39A939" }}>Yes</span>;

				return <span style={{ color: "#FF3234" }}>No</span>;
			},
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.isPaid === value;
			},
		},
	];

	// Calculate statistics
	const totalInvoices = feeData.length;
	const totalAmount = feeData.reduce((sum, item) => sum + item.feeMonthly, 0);

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/invoices/get-allinvoice",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllInvoices(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Users');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spin />}
			<div style={{ padding: "20px" }}>
				<Title level={3}>My Invoices</Title>

				{/* Statistic Cards - Responsive Design */}
				<Row
					gutter={[16, 16]}
					justify="center"
					style={{ marginBottom: "20px" }}
				>
					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Total Invoices
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{totalInvoices}
							</p>
						</Card>
					</Col>
					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Total Fee Subscription
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{formatEuroCurrency(totalAmount)}
							</p>
						</Card>
					</Col>
				</Row>
				<div className="accountpagebuttonsAll">
					<Button
						shape="round"
						className="btn btn-primary btn-block"
						style={{ background: "green", borderColor: "green" }}
						onClick={() => navigate("/")}
					>
						Voltar
					</Button>
				</div>
				<hr></hr>

				{/* Table */}
				<Card>
					<div className="content">
						<Table
							columns={feeColumns}
							dataSource={feeData}
							pagination={{ pageSize: 10 }}
							bordered
							rowKey="key"
							scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
							responsive={true} // Makes the table responsive
							className="custom-table" // Apply the custom class here
						/>
					</div>
				</Card>
			</div>
		</Layout>
	);
};

export default Invoices;
