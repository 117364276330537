import React, { useState, useEffect } from "react";
import {
	Steps,
	Form,
	Input,
	Button,
	Tooltip,
	Select,
	Card,
	Row,
	Radio,
	Alert,
	Switch,
	message,
	Table,
	InputNumber,
	Col,
	Progress,
	Spin,
} from "antd";
import {
	ArrowLeftOutlined,
	CheckCircleTwoTone,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Layout from "../components/Layout/Layout";
import MySaving from "./../components/Assets/robot.png";
import MyWallet from "./../components/Assets/binance.png";
import MyCongratulation from "./../components/Assets/congratulation.png";

import Spinner from "../components/Spinner";

import { FaCheckCircle } from "react-icons/fa";

const NewSavings = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	//ObjectID Sem Agente
	var semAgenteID = "6600a9bf979b670428483dc6";

	let range = { min: 1, max: 999999999 };
	let delta = range.max - range.min;

	const codeSquare = Math.round(range.min + Math.random() * delta);

	// useEffect(() => {
	//   window.scrollTo(0, 0);
	// }, []);

	//loading Apis
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [loginUser, setLoginUser] = useState("");

	//DropList Market
	const [allAgent, setAllAgent] = useState([]);
	const [allCategory, setAllCategory] = useState([]);
	const [allProduct, setAllProduct] = useState([]);
	const [allCashExchanges, setCashExchanges] = useState([]);

	// const { TextArea } = Input;

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const [current, setCurrent] = useState(0);
	const [targetDetails, setTargetDetails] = useState(null);
	const [savingDetails, setSavingDetails] = useState(null);
	const [productOptionDetails, setProductOptionDetails] = useState(null);
	const [confirmProductOptionDetails, setConfirmProductOptionDetails] =
		useState(null);
	const [agentDetails, setAgentDetails] = useState(null);
	const [investorInfoDetails, setInvestorInfoDetails] = useState(null);
	const [paymentDetails, setPaymentDetails] = useState(null);
	const [investorDeclarationDetails, setInvestorDeclarationDetails] =
		useState(null);
	const [contratualDocumentsDetails, setContratualDocumentsDetails] =
		useState(null);
	const [resumeDetails, setResumeDetails] = useState(null);

	const [producOptionPassDetails, setProducOptionPassDetails] = useState(null);
	// const [finishDetails, setFinishDetails] = useState(null);

	const columns = [
		{
			key: "cash",
			title: "Cash Available",
			dataIndex: "cash",
			align: "center",
			defaultSortOrder: "descend",
			sorter: (a, b) => a.cash - b.cash,
			render: (text) => `${text} USDC`,
		},
		{
			key: "investmentName",
			title: "Exchange Name",
			dataIndex: ["exchangeName", "investmentName"],
			align: "center",
			sorter: (a, b) =>
				a.exchangeName.investmentName.localeCompare(
					b.exchangeName.investmentName
				),
		},
		{
			key: "brokerName",
			title: "Broker",
			dataIndex: ["exchangeName", "brokerName"],
			align: "center",
			sorter: (a, b) =>
				a.exchangeName.brokerName.localeCompare(b.exchangeName.brokerName),
		},
		{
			key: "status",
			title: "Status API",
			dataIndex: "status",
			align: "center",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Active</span>;

				return <span style={{ color: "#FF3234" }}>Disable</span>;
			},
		},
		// {
		// 	key: "status",
		// 	title: "Status",
		// 	dataIndex: ["exchangeName", "status"],
		// 	align: "center",
		// 	render: (value, record) => {
		// 		if (record.exchangeName.status === true)
		// 			return <span style={{ color: "#39A939" }}>Active</span>;

		// 		return <span style={{ color: "#FF3234" }}>Disable</span>;
		// 	},
		// },
	];

	//user profile
	const investorProfile = loginUser.investorProfile;

	/*1*/
	const onFinishTargetForm = (values) => {
		setTargetDetails(values);
		setCurrent(1);
	};

	/*2*/
	const onFinishProductOptionForm = (values) => {
		setProductOptionDetails(values);
		setCurrent(2);
	};

	/*3*/
	const onFinishSavingForm = (values) => {
		setSavingDetails(values);
		setCurrent(3);
	};

	/*4*/
	const onFinishConfirmProductOptionForm = (values) => {
		setConfirmProductOptionDetails(values);
		setCurrent(4);
	};

	/*5*/
	// const onFinishAgentForm=(values) => {
	//   setAgentDetails(values);
	//   setCurrent(5);
	// }

	/*6*/
	const onFinishInvestorInfoForm = (values) => {
		setInvestorInfoDetails(values);
		setCurrent(5);
	};

	/*7*/
	const onFinishPaymentForm = (values) => {
		setPaymentDetails(values);
		setCurrent(6);
	};

	/*8*/
	const onFinishInvestorDeclarationForm = (values) => {
		setInvestorDeclarationDetails(values);
		setCurrent(7);
	};

	/*9*/
	// const onFinishContratualDocumentsForm=(values) => {
	//   setContratualDocumentsDetails(values);
	//   setCurrent(8);
	// }

	/*10*/
	const onFinishResumeForm = (values) => {
		setResumeDetails(values);
		setCurrent(8);
	};

	const forms = [
		<TargetForm onFinish={onFinishTargetForm} initialValues={targetDetails} />,
		<ProductOptionForm
			onFinish={onFinishProductOptionForm}
			initialValues={productOptionDetails}
		/>,
		<SavingForm onFinish={onFinishSavingForm} initialValues={savingDetails} />,
		<ConfirmProductOptionForm
			onFinish={onFinishConfirmProductOptionForm}
			initialValues={confirmProductOptionDetails}
		/>,
		// <AgentForm
		//   onFinish={onFinishAgentForm}
		//   initialValues={agentDetails}/>,
		<InvestorInfoForm
			onFinish={onFinishInvestorInfoForm}
			initialValues={investorInfoDetails}
		/>,
		<PaymentForm
			onFinish={onFinishPaymentForm}
			initialValues={paymentDetails}
		/>,
		<InvestorDeclarationForm
			onFinish={onFinishInvestorDeclarationForm}
			initialValues={investorDeclarationDetails}
		/>,
		// <ContratualDocumentsForm
		//   onFinish={onFinishContratualDocumentsForm}
		//   initialValues={contratualDocumentsDetails}/>,
		<ResumeForm onFinish={onFinishResumeForm} initialValues={resumeDetails} />,
		<FinishForm />,
	];

	const isStepDisabled = (stepnumber) => {
		if (stepnumber === 0) {
			return false;
		}
		if (stepnumber === 1) {
			return targetDetails === null;
		}
		if (stepnumber === 2) {
			return targetDetails === null || productOptionDetails === null;
		}
		if (stepnumber === 3) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null
			);
		}
		if (stepnumber === 4) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null ||
				confirmProductOptionDetails === null
			);
		}
		if (stepnumber === 5) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null ||
				confirmProductOptionDetails === null ||
				investorInfoDetails === null
			);
		}
		if (stepnumber === 6) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null ||
				confirmProductOptionDetails === null ||
				investorInfoDetails === null ||
				paymentDetails === null
			);
		}
		if (stepnumber === 7) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null ||
				confirmProductOptionDetails === null ||
				investorInfoDetails === null ||
				paymentDetails === null ||
				investorDeclarationDetails === null
			);
		}
		if (stepnumber === 8) {
			return (
				targetDetails === null ||
				productOptionDetails === null ||
				savingDetails === null ||
				confirmProductOptionDetails === null ||
				investorInfoDetails === null ||
				paymentDetails === null ||
				investorDeclarationDetails === null ||
				resumeDetails === null
			);
		}
	};

	const handleGoToPreviousStep = () => {
		setCurrent(current - 2); // Update the current step to the previous step
	};

	// Assuming contains the URL
	const openPopup = (url) => {
		const popup = window.open(url, "popup", "width=600,height=600");
		if (popup) {
			popup.focus();
			// console.log(url);
		}
	};

	//Handler Asset Class DropList ****************************************************************************************
	useEffect(() => {
		const getAllCategory = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/categories/get-category",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllCategory(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllCategory();
	}, []);

	useEffect(() => {
		const getAllCashExchanges = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/cash-exchanges/get-cash-exchange",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setCashExchanges(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllCashExchanges();
	}, []);

	useEffect(() => {
		const getAllAsset = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/myproducts/get-product",
					{
						status: true,
						isOwnPortfolio: false,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllProduct(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllAsset();
	}, []);

	useEffect(() => {
		const getAllAgent = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/users/get-agent-user",
					{
						_id: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllAgent(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllAgent();
	}, []);

	const submitHandler = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/savings/add-saving",
				{
					userid: loginUser._id,
					savingProcessId: codeSquare,
					categoryName: targetDetails.categoryName,
					savingName: targetDetails.savingName,
					savingDate: producOptionPassDetails.savingDate,
					initialValue: parseFloat(savingDetails.initialValue),
					target: parseFloat(savingDetails.target),
					maxInvestCrypto: isNaN(parseFloat(savingDetails.maxInvestCrypto))
						? 0
						: parseFloat(savingDetails.maxInvestCrypto),
					productName: producOptionPassDetails.productId,
					agentName: loginUser.agentName,
					investorProfileName: loginUser.investorProfile,
					exchangeName: paymentDetails.exchangeName.split("|")[0],
					dayPayment: producOptionPassDetails.dayPayment,
					investmentType: producOptionPassDetails.investmentType,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/savingtransactions/add-savingtransaction",
				{
					userid: loginUser._id,
					savingProcessId: codeSquare,
					savingName: targetDetails.savingName,
					exchangeName: paymentDetails.exchangeName.split("|")[0],
					productName: producOptionPassDetails.productId,
					agentName: loginUser.agentName,
					amount: parseFloat(savingDetails.initialValue),
					savingDate: producOptionPassDetails.savingDate,
					dayPayment: producOptionPassDetails.dayPayment,
					monthYear: producOptionPassDetails.monthYear,
					monthYearNumber: producOptionPassDetails.monthYearNumber,
					dayMonthYearNumber: producOptionPassDetails.dayMonthYearNumber,
					investmentType: producOptionPassDetails.investmentType,
					isFirstTransaction: true,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res3 = axios.post(
				"/savingUserContratualDocuments/add-SavingUserContratualDocument",
				{
					userid: loginUser._id,
					savingProcessId: codeSquare,
					exchangeName: paymentDetails.exchangeName.split("|")[0],
					productName: producOptionPassDetails.productId,
					savingName: targetDetails.savingName,
					isPaymentAuthorization: paymentDetails.isPaymentAuthorization,
					acceptInvestorProfile:
						investorDeclarationDetails.acceptInvestorProfile,
					acceptContractConditions: resumeDetails.acceptGeneralConditions,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			message.success("Successful creation crypto basket");
			setLoading(false);

			navigate("/ProcessingSaving", {
				state: {
					savingName: targetDetails.savingName,
					exchangeName: paymentDetails.exchangeName.split("|")[0],
					processing: "Processing new Crypto Basket",
					investmentType: producOptionPassDetails.investmentType,
					savingProcessId: codeSquare,
				},
			});
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	/*1*/
	function TargetForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
		return (
			<Form
				style={{ width: "100%" }}
				onFinish={onFinish}
				initialValues={initialValues}
				layout="vertical"
			>
				<h4 align="center">New Crypto Basket</h4>
				<hr />
				<br />
				<Form.Item
					label={<span>What are you investing for?&nbsp;</span>}
					name="categoryName"
					rules={[
						{ required: true, message: "Please select your investing for!" },
					]}
				>
					<Select>
						{allCategory &&
							allCategory.map((opts, i) => (
								<Select.Option
									key={i}
									value={opts._id}
									style={{ width: "100%", textAlign: "center" }}
								>
									{opts.categoryName}
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item
					label={<span>Crypto Basket Name&nbsp;</span>}
					name="savingName"
					rules={[
						{
							required: true,
							message: "Please input the name of the crypto basket!",
						},
						{
							max: 24,
							message: "The name must be a maximum of 24 characters!",
						},
					]}
				>
					<Input
						showCount
						maxLength={24}
						type="text"
						size="large"
						style={{ fontSize: "24px", width: "100%", textAlign: "center" }}
					/>
				</Form.Item>

				<Button
					style={{
						width: "100%",
						backgroundColor: "#7600ec",
						borderColor: "#7600ec",
						color: "#fff",
						borderRadius: "5px",
					}}
					type="primary"
					htmlType="submit"
				>
					NEXT
				</Button>
				<Link to="/">
					<Button
						style={{
							width: "100%",
							background: "white",
							color: "Blue",
							marginTop: 30,
							marginBottom: 30,
						}}
						type="primary"
					>
						CANCEL NEW SAVING
					</Button>
				</Link>
			</Form>
		);
	}

	/*2*/
	function ProductOptionForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		const handleContinue = (productId, productName) => {
			onFinish(productId, productName); // Pass the selected product ID and product name to onFinish
		};

		// Assuming targetDetails.targetDate is the date you want to calculate the difference from
		const targetDate = moment(targetDetails.targetDate);
		const currentDate = moment();

		// Extract the day
		const dayPayment = currentDate.date();

		// Calculate the difference in years and months
		const duration = moment.duration(currentDate.diff(targetDate));

		const years = -1 * duration.years();
		const months = -1 * duration.months();

		return (
			<Form
				style={{ width: "100%" }}
				layout="vertical"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h4 align="center">Choose your Crypto Basket</h4>
				<hr />
				<br />
				<h6 align="center">
					Your investor profile is:
					<br />
					<Button
						style={{
							marginTop: 10,
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
						}}
						type="primary"
					>
						{investorProfile} &gt;
					</Button>
				</h6>

				<section>
					<Row gutter={[16, 24]} style={{ justifyContent: "center" }}>
						{allProduct.map((opts, i) => (
							<Card
								key={i}
								size="small"
								hoverable
								style={{
									width: 350, // Slightly narrower card
									marginTop: 10,
									marginRight: "16px",
									marginBottom: "16px",
									borderRadius: "12px", // Rounded corners for soft look
									boxShadow: "0px 6px 12px rgba(0,0,0,0.1)", // Subtle shadow for depth
									backgroundColor:
										productOptionDetails === opts._id ? "#F4F7FE" : "inherit",
								}}
								// cover={
								//   <div align="center" style={{ padding: '10px' }}>
								//     <img
								//       alt={opts.productName}
								//       // src={opts.productImage}
								//       style={{
								//         width: '100%',
								//         height: 'auto',
								//         borderRadius: '8px',
								//         objectFit: 'cover',
								//         maxHeight: '180px',
								//       }}
								//     />
								//   </div>
								// }
							>
								<div style={{ padding: "16px" }}>
									{/* Product Name and Tooltip */}
									<h4
										className="price"
										style={{
											textAlign: "center",
											fontSize: "16px",
											marginBottom: "20px",
										}}
									>
										{opts.productName}{" "}
										<Tooltip title={opts.shortDescription}>
											<InfoCircleOutlined
												style={{ color: "rgba(0,0,0,.30)", fontSize: "14px" }}
											/>
										</Tooltip>
									</h4>
									{/* Row for Product Type, Assets, Expected Annual Income, Minimum Investment */}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											marginBottom: "10px",
										}}
									>
										{/* Investment Type */}
										<div style={{ textAlign: "center", fontSize: "12px" }}>
											<p
												style={{
													fontWeight: "bold",
													color: opts.investmentType ? "#28a745" : "#dc3545",
												}}
											>
												{opts.investmentType ? "Active" : "Passive"}
											</p>
											<p style={{ color: "#adb5bd", fontSize: "10px" }}>
												Investment Type
											</p>
										</div>

										{/* Number of Assets with Tooltip */}
										<div style={{ textAlign: "center", fontSize: "12px" }}>
											<p>
												<strong>{opts.numberAssets}</strong>
												<Tooltip title={opts.marketSelection}>
													<InfoCircleOutlined
														style={{
															color: "rgba(0,0,0,.20)",
															marginLeft: "5px",
														}}
													/>
												</Tooltip>
											</p>
											<p style={{ color: "#adb5bd", fontSize: "10px" }}>
												Number of Assets
											</p>
										</div>

										{/* Expected Annual Income with Tooltip */}
										<div style={{ textAlign: "center", fontSize: "12px" }}>
											<p>
												<strong>{opts.expectedAnnualIncome}%</strong>
												<Tooltip title={opts.tooltipExpectAnnualIncome}>
													<InfoCircleOutlined
														style={{
															color: "rgba(0,0,0,.20)",
															marginLeft: "5px",
														}}
													/>
												</Tooltip>
											</p>
											<p style={{ color: "#adb5bd", fontSize: "10px" }}>
												Expected Annual Income
											</p>
										</div>

										{/* Minimum Investment */}
										<div style={{ textAlign: "center", fontSize: "12px" }}>
											<p>
												<strong>{opts.minimumInvestment} USDC</strong>
											</p>
											<p style={{ color: "#adb5bd", fontSize: "10px" }}>
												Minimum Investment
											</p>
										</div>
									</div>
									<Button
										type="primary"
										style={{
											textAlign: "center",
											width: "100%",
											backgroundColor: "#7600ec",
											borderColor: "#7600ec",
											color: "#fff",
										}}
										onClick={() => {
											setProductOptionDetails({ productId: opts._id });
											handleContinue(opts._id);
											setProducOptionPassDetails({
												productId: opts._id,
												productName: opts.productName,
												investmentType: opts.investmentType,
												marketSelection: opts.marketSelection,
												allocations: opts.allocations,
												expectedAnnualIncome: opts.expectedAnnualIncome,
												tooltipExpectAnnualIncome:
													opts.tooltipExpectAnnualIncome,
												rebalancing: opts.rebalancing,
												expenseRatio: opts.expenseRatio,
												assets: opts.assets.asset,
												isr: opts.isr,
												dif: opts.ifiURL,
												savingDate: moment(currentDate).format("YYYY-MM-DD"),
												monthYear: moment(currentDate).format("MMM YYYY"),
												monthYearNumber: moment(currentDate).format("YYYYMM"),
												dayMonthYearNumber:
													moment(currentDate).format("YYYYMMDD"),
												dayPayment: dayPayment,
												savingProcessId: codeSquare,
												minimumInvestment: opts.minimumInvestment,
											});
										}}
									>
										Choose {opts.productName}
									</Button>
									,
								</div>
							</Card>
						))}
					</Row>
				</section>
			</Form>
		);
	}

	// Custom validation rule to ensure target value > initial value
	const validateTargetGreaterThanInitial = ({ getFieldValue }) => ({
		validator(_, value) {
			const initialValue = getFieldValue("initialValue");
			if (value > initialValue) {
				return Promise.resolve();
			}
			return Promise.reject(
				new Error("Target portfolio must be greater than the initial value.")
			);
		},
	});

	/*3*/
	function SavingForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		return (
			<Form
				style={{ width: "100%" }}
				onFinish={onFinish}
				initialValues={initialValues}
				layout="vertical"
			>
				<h4 align="center">Deposit</h4>
				<hr />
				<br />
				<Form.Item
					label={
						<span>
							Initial Deposit&nbsp;
							<Tooltip title="Enter the amount you want to deposit initially.">
								<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
							</Tooltip>
						</span>
					}
					name="initialValue"
					rules={[
						{
							required: true,
							message: "Please enter an initial deposit amount!",
						},
						{
							validator: (_, value) => {
								if (value === undefined || value === null || value === "") {
									return Promise.reject(
										"Please enter an initial deposit amount!"
									);
								}
								if (value < producOptionPassDetails.minimumInvestment) {
									return Promise.reject(
										`The minimum investment amount is ${producOptionPassDetails.minimumInvestment} USDC!`
									);
								}
								return Promise.resolve();
							},
						},
					]}
				>
					<InputNumber
						placeholder="0.00"
						size="large"
						addonAfter="USDC"
						style={{ fontSize: "24px", width: "100%", textAlign: "right" }}
					/>
				</Form.Item>

				<Form.Item label="Target Portfolio" name="target">
					<InputNumber
						rules={[
							{ required: true, message: "Please enter the target portfolio!" },
							validateTargetGreaterThanInitial,
						]}
						placeholder="0.00"
						size="large"
						addonAfter="USDC"
						style={{
							fontSize: "24px",
							width: "100%",
							textAlign: "right",
						}}
					/>
				</Form.Item>
				<Button
					style={{
						width: "100%",
						backgroundColor: "#7600ec",
						borderColor: "#7600ec",
						color: "#fff",
						borderRadius: "5px",
					}}
					type="primary"
					htmlType="submit"
				>
					NEXT
				</Button>
				<Link to="/">
					<Button
						style={{
							width: "100%",
							background: "white",
							color: "Blue",
							marginTop: 30,
							marginBottom: 30,
						}}
						type="primary"
					>
						CANCEL NEW SAVING
					</Button>
				</Link>
			</Form>
		);
	}

	/*4*/
	function ConfirmProductOptionForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
		return (
			<Form
				style={{ width: "100%" }}
				onFinish={onFinish}
				initialValues={initialValues}
				layout="vertical"
			>
				<h4 align="center">Crypto Basket Option Confirmation</h4>

				<section>
					<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
						<Card
							key={1}
							size="small" // Reduced size
							style={{
								width: 300, // Smaller width
								marginTop: 8, // Reduced margin
								backgroundColor: "#495057",
								color: "white",
								padding: "10px", // Added padding for better alignment
							}}
						>
							<div align="center" style={{ padding: "5px" }}>
								{/* Reduced padding */}
								<p
									style={{
										fontSize: "14px",
										fontWeight: "bold",
										margin: "5px 0",
									}}
								>
									{targetDetails.savingName}
								</p>{" "}
								{/* Smaller text */}
								{/* <div style={{ textAlign: 'center', margin: '5px 0' }}>
							<img
							alt="example"
							src={''}
							style={{ width: '50px', height: '50px' }} // Smaller image size
							/>
						</div> */}
								<hr />
								<div
									style={{
										display: "flex",
										justifyContent: "center", // Centering the content horizontally
										alignItems: "center",
										flexDirection: "column", // Ensures everything is stacked vertically
									}}
								>
									{/* Initial Deposit */}
									<div style={{ textAlign: "center", fontSize: "12px" }}>
										<p style={{ margin: "5px 0" }}>
											{Number(savingDetails.initialValue).toLocaleString(
												"de-DE",
												{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
											)}{" "}
											USDC
											<br />
											<span style={{ color: "#adb5bd", fontSize: "12px" }}>
												Initial Deposit
											</span>
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Row>
				</section>

				<h6 align="center" style={{ marginTop: 30 }}>
					Investor Profile:
					<br />
					<Button
						style={{
							marginTop: 10,
							marginBottom: 30,
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
						}}
						type="primary"
					>
						{investorProfile} &gt;
					</Button>
				</h6>

				<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
					<Card
						key={1}
						size="small"
						style={{ width: "100%" }} // Set the width to 270
						cover={[
							<div align="center">
								<br />
								<div align="center">
									<h6>Crypto Basket Option</h6>
								</div>
								<div>
									<br />
									<h4 className="price">
										{producOptionPassDetails.productName}
									</h4>
								</div>
								<div align="center">
									<hr></hr>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Investment Type</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>
												{producOptionPassDetails.investmentType
													? "Active"
													: "Passive"}
											</b>
										</p>
									</div>

									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Allocations</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.allocations}</b>
										</p>
									</div>

									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Rebalancing</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.rebalancing}</b>
										</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Expected Annual Income</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.expectedAnnualIncome} % </b>
										</p>
									</div>
									<div className="additional" align="center">
										{/* <h4 className="price">Montante: <span className="quantity"></span></h4> */}
										<hr />
										<h6 className="price">
											Market Selection
											<br />
										</h6>
										<div
											style={{
												textAlign: "center",
												marginLeft: "10px",
												marginRight: "10px",
											}}
										>
											<p>
												<b>{producOptionPassDetails.marketSelection} </b>
											</p>
										</div>
									</div>
								</div>
							</div>,
						]}
					></Card>
				</Row>

				<h6 align="center" style={{ marginTop: 30 }}>
					The option you selected is appropriate to your investor profile.
				</h6>

				<div style={{ textAlign: "center" }}>
					<Button
						style={{
							width: "100%",
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
							borderRadius: "5px",
						}}
						type="primary"
						htmlType="submit"
					>
						NEXT
					</Button>
					<br />
					<Button
						style={{
							width: "100%",
							marginTop: "30px",
							background: "transparent",
							color: "#000000",
							border: "1px solid #000000",
						}}
						type="primary"
						htmlType="submit"
						onClick={handleGoToPreviousStep}
					>
						CHANGE OPTION
					</Button>
					<Link to="/">
						<Button
							style={{
								width: "100%",
								background: "white",
								color: "Blue",
								marginTop: 30,
								marginBottom: 30,
							}}
							type="primary"
						>
							CANCEL NEW SAVING
						</Button>
					</Link>
				</div>
			</Form>
		);
	}

	/*5*/
	// function AgentForm({onFinish, initialValues}){

	//   const handleContinue = (agentId) => {
	//     onFinish(agentId); // Pass the selected agent ID and agent name to onFinish
	//   };

	//   return (
	//     <Form style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues} layout="vertical">
	//       <h4 align="center">Confirmação do seu agente</h4>
	//       <hr/>
	//       <br/>

	//       <h5 align="center">Agora que já definiu o seu objectibo, pode associar um agente para acompanhar em caso de dúvidas.</h5>
	//       <br/>
	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       {allAgent.map((opts, i) => (
	//         <Card
	//           key={1}
	//           size="small"
	//           // hoverable
	//           style={{backgroundColor:"#f1f2f6"}}
	//           cover={[
	//             <div align="center" style={{ padding: "50px" }}>
	//                 <div style={{ textAlign: "center"}}>
	//                   <p>O seu agente</p>
	//                   <p><b>{opts.agentName.agentName}</b></p>
	//                 </div>
	//                 <div style={{ textAlign: "center", marginBottom: "20px"}}>
	//                   <img alt="example" src={MyCustomerCare}/>
	//                 </div>
	//                 <div style={{ textAlign: "center"}}>
	//                   <p><b>Morada:</b> {opts.agentName.addressAgent}</p>
	//                 </div>
	//                 <div style={{ textAlign: "center"}}>
	//                   <p>{opts.agentName.postalCodeAgent} - {opts.agentName.stateAgent}</p>
	//                 </div>
	//               </div>
	//           ]}
	//           >
	//         </Card>
	//       ))}
	//     </Row>

	//     <div style={{ textAlign: 'center' }}>
	//     {allAgent.map((opts, i) => (
	//       semAgenteID !== opts.agentName._id
	//                   ?
	//                   <Button
	//                   style={{ width: "100%", marginTop:"30px"}}
	//                   type="primary"
	//                   htmlType='submit'
	//                   onClick={() => {
	//                     setAgentDetails({ agentId: opts.agentName._id});
	//                     handleContinue(opts.agentName._id);
	//                     // setAgentPassDetails({
	//                     //   agentID: opts.agentName._id,
	//                     // });
	//                   }}
	//                   >
	//                   Avançar com o seu agente
	//                 </Button>
	//                   :
	//                 <p></p>
	//     ))}
	//    <br/>
	//    {allAgent.map((opts, i) => (
	//       semAgenteID !== opts.agentName._id
	//                   ?
	//                   <Button
	//                   style={{ width: "100%", marginTop:"30px", background: "transparent", color: "#000000" , border: "1px solid #000000"}}
	//                   type="primary"
	//                   htmlType='submit'
	//                   onClick={() => {
	//                     setAgentDetails({ semAgenteID});
	//                     handleContinue(semAgenteID);
	//                     // setAgentPassDetails({
	//                     //   agentID: semAgenteID
	//                     // });
	//                   }}
	//                   >
	//                   Avançar sem agente
	//                  </Button>
	//                   :
	//                 <p></p>
	//     ))}
	//    <br/>
	//    {allAgent.map((opts, i) => (
	//       semAgenteID === opts.agentName._id
	//                   ?
	//                   <Button
	//                   style={{ width: "100%", marginTop:"30px"}}
	//                   type="primary"
	//                   htmlType='submit'
	//                   onClick={() => {
	//                     setAgentDetails({ agentId: opts.agentName._id});
	//                     handleContinue(opts.agentName._id);
	//                     // setAgentPassDetails({
	//                     //   agentID: opts.agentName._id,
	//                     // });
	//                   }}
	//                   >
	//                   Avançar sem agente
	//                  </Button>
	//                   :
	//                 <p></p>
	//     ))}
	//     </div>
	//   </Form>)
	// }

	/*6*/
	function InvestorInfoForm({ onFinish, initialValues }) {
		const dif = producOptionPassDetails.dif;

		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		return (
			<Form
				style={{ width: "100%" }}
				onFinish={onFinish}
				initialValues={initialValues}
				layout="vertical"
			>
				<h4 align="center">Investor Information</h4>
				<hr />
				<br />
				<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
					<Card
						key={1}
						size="small"
						style={{ width: "100%" }} // Set the width to 270
						cover={[
							<div align="center">
								<br />
								<div align="center">
									<h6>Crypto Basket Option</h6>
								</div>
								<div>
									<br />
									<h4 className="price">
										{producOptionPassDetails.productName}
									</h4>
								</div>
								<div align="center">
									<hr></hr>
									<div>
										<p>Summary Risk Indicator</p>
										<p>Risk of losing part of the invested capital</p>
										<Radio.Group
											style={{ display: "flex", justifyContent: "center" }}
											value={producOptionPassDetails.isr}
											buttonStyle="solid"
										>
											<Row gutter={[2, 8]}>
												<Radio.Button value={1} className="custom-radio">
													1
												</Radio.Button>
												<Radio.Button value={2} className="custom-radio">
													2
												</Radio.Button>
												<Radio.Button value={3} className="custom-radio">
													3
												</Radio.Button>
												<Radio.Button value={4} className="custom-radio">
													4
												</Radio.Button>
												<Radio.Button value={5} className="custom-radio">
													5
												</Radio.Button>
												<Radio.Button value={6} className="custom-radio">
													6
												</Radio.Button>
												<Radio.Button value={7} className="custom-radio">
													7
												</Radio.Button>
											</Row>
										</Radio.Group>
										{/* <div style={{ display: "flex", justifyContent: "space-between", marginTop:"30px"}}>
                      <div style={{ textAlign: "left", marginLeft:"20px" }}>
                        <p>Risco mais baixo</p>
                      </div>
                      <div style={{ textAlign: "right" , marginRight:"20px" }}>
                        <p>Risco mais elevado</p>
                      </div>
                    </div> */}
										<p style={{ marginTop: "20px" }}>
											The risk indicator assumes that the product is held for{" "}
											<b>5 years and 1 day.</b>
										</p>
									</div>
								</div>
							</div>,
						]}
					></Card>
				</Row>
				{/* <p align="center" style={{marginTop:"20px"}} >Para continuar,<b>ABRA e LEIA</b> o seguinte documento</p>
      <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
        <Card
        style={{ width: "100%"}} // Set the width to 270
        >
          <Row justify="space-between" align="middle">
            <Col>
              <h6>Informações pré-contratuais + DIF</h6>
            </Col>
            <Col>
              <Space>
                <FilePdfOutlined 
                style={{ fontSize: '24px', color: '#1890ff' }}
                onClick={() => openPopup(dif)} 
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </Row> */}

				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "30px",
					}}
				>
					<div style={{ textAlign: "center", width: "100%" }}>
						<div>
							<p>
								Responsible investing requires that you know its implications
								and that you are willing to accept them
							</p>
						</div>
						<Button
							style={{
								width: "100%",
								backgroundColor: "#7600ec",
								borderColor: "#7600ec",
								color: "#fff",
								borderRadius: "5px",
							}}
							type="primary"
							htmlType="submit"
						>
							NEXT
						</Button>
						<Link to="/">
							<Button
								style={{
									width: "100%",
									background: "white",
									color: "Blue",
									marginTop: 30,
									marginBottom: 30,
								}}
								type="primary"
							>
								CANCEL NEW SAVING
							</Button>
						</Link>
					</div>
				</div>
			</Form>
		);
	}

	/*7*/
	function PaymentForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Form
					style={{ width: "100%", marginBottom: "20px" }}
					onFinish={onFinish}
					initialValues={initialValues}
					layout="vertical"
				>
					<h4 align="center">Deposit Details</h4>
					<hr />
					<br />
					<Form.Item label="Owner of the account" name={"name"}>
						<Input
							defaultValue={`${loginUser.firstName} ${loginUser.lastName}`}
							disabled
							suffix={<CheckCircleTwoTone twoToneColor="#52c41a" />}
						/>
					</Form.Item>

					<Table
						size="small"
						columns={columns}
						dataSource={allCashExchanges}
						pagination={false}
						scroll={{ x: "max-content" }}
						className="custom-table" // Apply custom CSS class for styling
					/>

					<Form.Item
						style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
						label={<span>Choose your Exchange?&nbsp;</span>}
						name="exchangeName"
						rules={[
							{ required: true, message: "Please select your exchange!" },
						]}
					>
						<Select>
							{allCashExchanges &&
								allCashExchanges.map((opts, i) => (
									<Select.Option
										key={i}
										value={`${opts.exchangeName._id}|${opts.exchangeName.investmentName}|${opts.cash}`}
										style={{
											width: "100%",
											textAlign: "center",
											marginTop: "20px",
										}}
									>
										{opts.exchangeName.investmentName} ({opts.cash} USDC)
									</Select.Option>
								))}
						</Select>
					</Form.Item>

					<div style={{ textAlign: "left" }}>
						<p>I declare that I authorize:</p>
						<ul>
							<li>
								BIT Advisor sending instructions directly to Binance to
								automatically transact the account indicated above;
							</li>
						</ul>
					</div>

					<Form.Item
						style={{ textAlign: "left" }}
						label=""
						name="isPaymentAuthorization"
						valuePropName="checked"
						className="daily-returns-toggle"
						rules={[
							{
								required: true,
								message: "Please accept the instructions.",
							},
						]}
					>
						<Switch
							checkedChildren="I authorize"
							unCheckedChildren="I do not authorize"
						/>
					</Form.Item>

					<Button
						style={{
							width: "100%",
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
							borderRadius: "5px",
						}}
						type="primary"
						htmlType="submit"
					>
						NEXT
					</Button>
					<Link to="/">
						<Button
							style={{
								width: "100%",
								background: "white",
								color: "Blue",
								marginTop: 30,
								marginBottom: 30,
							}}
							type="primary"
						>
							CANCEL NEW SAVING
						</Button>
					</Link>
				</Form>
			</div>
		);
	}

	/*8*/
	function InvestorDeclarationForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Form
					style={{ width: "100%" }}
					onFinish={onFinish}
					initialValues={initialValues}
					layout="vertical"
				>
					<h4 align="center">Declaration</h4>
					<hr />
					<br />
					<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
						<Card
							key={1}
							size="small"
							style={{ width: "100%" }} // Set the width to 270
							cover={[
								<div align="center">
									<br />
									<div align="center">
										<h6>Crypto Basket Option</h6>
									</div>
									<div>
										<br />
										<h4 className="price">
											{producOptionPassDetails.productName}
										</h4>
									</div>
									<div align="center">
										<hr></hr>
										<div
											style={{
												textAlign: "center",
												marginLeft: "20px",
												marginRight: "20px",
											}}
										>
											<p>Risk Level:</p>
										</div>
										<div
											style={{
												textAlign: "center",
												marginLeft: "20px",
												marginRight: "20px",
											}}
										>
											<p>
												<b>
													Investing in cryptocurrencies presents significant
													risks. Extreme fluctuations in the market can result
													in the total loss of your capital.
												</b>
											</p>
										</div>
									</div>
								</div>,
							]}
						></Card>
					</Row>
					<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
						<Card
							key={1}
							size="small"
							style={{ width: "100%", marginTop: "20px" }} // Set the width to 270
							cover={[
								<div align="center">
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "30px",
										}}
									>
										<div style={{ textAlign: "left", marginLeft: "20px" }}>
											<p>Investor name:</p>
										</div>
										<div style={{ textAlign: "right", marginRight: "20px" }}>
											<p>
												<b>
													{loginUser.firstName} {loginUser.lastName}
												</b>
											</p>
										</div>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "30px",
										}}
									>
										<div style={{ textAlign: "left", marginLeft: "20px" }}>
											<p>Investor profile:</p>
										</div>
										<div style={{ textAlign: "right", marginRight: "20px" }}>
											<p>
												<b>{loginUser.investorProfile}</b>
											</p>
										</div>
									</div>
								</div>,
							]}
						></Card>
					</Row>

					<div style={{ textAlign: "left", marginTop: "20px" }}>
						<p>
							According to the Investor Profile existing on this date, it is
							considered that the option is appropriate for you?
						</p>
					</div>
					<hr></hr>
					<div style={{ textAlign: "left" }}>
						<p>
							I declare that I have been requested to provide information about
							my knowledge and experience in investment matters.
						</p>
					</div>

					<Form.Item
						label=""
						name="acceptInvestorProfile"
						valuePropName="checked"
						className="daily-returns-toggle"
						rules={[
							{
								required: true,
								message: "Please accept the instructions.",
							},
						]}
					>
						<Switch
							style={{ marginLeft: "10px" }}
							checkedChildren="Yes"
							unCheckedChildren="No"
						/>
					</Form.Item>

					<Button
						style={{
							width: "100%",
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
							borderRadius: "5px",
						}}
						type="primary"
						htmlType="submit"
					>
						NEXT
					</Button>
					<Link to="/">
						<Button
							style={{
								width: "100%",
								background: "white",
								color: "Blue",
								marginTop: 30,
								marginBottom: 30,
							}}
							type="primary"
						>
							CANCEL NEW SAVING
						</Button>
					</Link>
				</Form>
			</div>
		);
	}

	/*9*/
	// function ContratualDocumentsForm({onFinish, initialValues}){
	//   return (
	//     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
	//     <Form style={{ width: '100%' }} onFinish={onFinish} initialValues={initialValues} layout="vertical">
	//       <h4 align="center">Documentos Contratuais</h4>
	//       <hr/>
	//       <br/>
	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       <Card
	//       size="small"
	//       style={{ width: "100%"}} // Set the width to 270
	//       >
	//       <Row justify="space-between" align="middle">
	//             <Col>
	//               <h6>Perfil de Investidor</h6>
	//             </Col>
	//             <Col>
	//               <Space>
	//                 <FilePdfOutlined
	//                 style={{ fontSize: '24px', color: '#1890ff' }}
	//                 onClick={() => openPopup()}
	//                 />
	//               </Space>
	//             </Col>
	//           </Row>
	//         </Card>
	//       </Row>

	//       <Form.Item
	//             label=""
	//             name="acceptInvestorProfile"
	//             valuePropName="checked"
	//             rules={[{
	//               required:true,
	//               message:"Por favor, aceite as instruções."
	//             }]}>
	//             <Switch style={{ marginLeft: "10px" }} checkedChildren="Aceito" unCheckedChildren="Não Aceito"/>
	//       </Form.Item>

	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       <Card
	//       size="small"
	//       style={{ width: "100%"}} // Set the width to 270
	//       >
	//       <Row justify="space-between" align="middle">
	//             <Col>
	//               <h6>Declaração de Carácter Apropriado</h6>
	//             </Col>
	//             <Col>
	//               <Space>
	//                 <FilePdfOutlined
	//                 style={{ fontSize: '24px', color: '#1890ff' }}
	//                 onClick={() => openPopup()}
	//                 />
	//               </Space>
	//             </Col>
	//           </Row>
	//         </Card>
	//       </Row>

	//       <Form.Item
	//             label=""
	//             name="acceptAppropriateCharacterDeclaration"
	//             valuePropName="checked"
	//             rules={[{
	//               required:true,
	//               message:"Por favor, aceite as instruções."
	//             }]}>
	//             <Switch style={{ marginLeft: "10px" }} checkedChildren="Aceito" unCheckedChildren="Não Aceito"/>
	//       </Form.Item>

	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       <Card
	//       size="small"
	//       style={{ width: "100%"}} // Set the width to 270
	//       >
	//       <Row justify="space-between" align="middle">
	//             <Col>
	//               <h6>Informações Pré-Contratuais e DIF</h6>
	//             </Col>
	//             <Col>
	//               <Space>
	//                 <FilePdfOutlined
	//                 style={{ fontSize: '24px', color: '#1890ff' }}
	//                 onClick={() => openPopup()}
	//                 />
	//               </Space>
	//             </Col>
	//           </Row>
	//         </Card>
	//       </Row>

	//       <Form.Item
	//             label=""
	//             name="acceptPreContratualinformation"
	//             valuePropName="checked"
	//             rules={[{
	//               required:true,
	//               message:"Por favor, aceite as instruções."
	//             }]}>
	//             <Switch style={{ marginLeft: "10px" }} checkedChildren="Aceito" unCheckedChildren="Não Aceito"/>
	//       </Form.Item>

	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       <Card
	//       size="small"
	//       style={{ width: "100%"}} // Set the width to 270
	//       >
	//       <Row justify="space-between" align="middle">
	//             <Col>
	//               <h6>Proposta</h6>
	//             </Col>
	//             <Col>
	//               <Space>
	//                 <FilePdfOutlined
	//                 style={{ fontSize: '24px', color: '#1890ff' }}
	//                 onClick={() => openPopup()}
	//                 />
	//               </Space>
	//             </Col>
	//           </Row>
	//         </Card>
	//       </Row>

	//       <Form.Item
	//             label=""
	//             name="acceptProposal"
	//             valuePropName="checked"
	//             rules={[{
	//               required:true,
	//               message:"Por favor, aceite as instruções."
	//             }]}>
	//             <Switch style={{ marginLeft: "10px" }} checkedChildren="Aceito" unCheckedChildren="Não Aceito"/>
	//       </Form.Item>

	//       <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
	//       <Card
	//       size="small"
	//       style={{ width: "100%"}} // Set the width to 270
	//       >
	//       <Row justify="space-between" align="middle">
	//             <Col>
	//               <h6>Condições Gerais e Especiais</h6>
	//             </Col>
	//             <Col>
	//               <Space>
	//                 <FilePdfOutlined
	//                 style={{ fontSize: '24px', color: '#1890ff' }}
	//                 onClick={() => openPopup()}
	//                 />
	//               </Space>
	//             </Col>
	//           </Row>
	//         </Card>
	//       </Row>

	//       <Form.Item
	//             label=""
	//             name="acceptGeneralConditions"
	//             valuePropName="checked"
	//             rules={[{
	//               required:true,
	//               message:"Por favor, aceite as instruções."
	//             }]}>
	//             <Switch style={{ marginLeft: "10px" }} checkedChildren="Aceito" unCheckedChildren="Não Aceito"/>
	//       </Form.Item>

	//       <div style={{ textAlign: "left", marginTop:"20px" }}>
	//           <p>Estes documentos ficam disponíveis para consulta no menu em <b>Contratos e Documentos.</b></p>
	//       </div>

	//       <Button style={{ width: "100%" }} type="primary" htmlType="submit">
	//         Continuar
	//       </Button>
	//     </Form>
	//   </div>)
	// }

	/*10*/
	function ResumeForm({ onFinish, initialValues }) {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);
		return (
			<Form
				style={{ width: "100%" }}
				onFinish={onFinish}
				initialValues={initialValues}
				layout="vertical"
			>
				<h4 align="center">Summary</h4>

				<section>
					<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
						<Card
							key={1}
							size="small" // Reduced size
							style={{
								width: 300, // Smaller width
								marginTop: 8, // Reduced margin
								backgroundColor: "#495057",
								color: "white",
								padding: "10px", // Added padding for better alignment
							}}
						>
							<div align="center" style={{ padding: "5px" }}>
								{/* Reduced padding */}
								<p
									style={{
										fontSize: "14px",
										fontWeight: "bold",
										margin: "5px 0",
									}}
								>
									{targetDetails.savingName}
								</p>{" "}
								{/* Smaller text */}
								{/* <div style={{ textAlign: 'center', margin: '5px 0' }}>
							<img
							alt="example"
							src={''}
							style={{ width: '50px', height: '50px' }} // Smaller image size
							/>
						</div> */}
								<hr />
								<div
									style={{
										display: "flex",
										justifyContent: "center", // Centering the content horizontally
										alignItems: "center",
										flexDirection: "column", // Ensures everything is stacked vertically
									}}
								>
									{/* Initial Deposit */}
									<div style={{ textAlign: "center", fontSize: "14px" }}>
										<p style={{ margin: "5px 0" }}>
											{Number(savingDetails.initialValue).toLocaleString(
												"de-DE",
												{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
											)}{" "}
											USDC
											<br />
											<span style={{ color: "#adb5bd", fontSize: "14px" }}>
												Initial Deposit
											</span>
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Row>
				</section>

				<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
					<Card
						key={1}
						size="small"
						style={{ width: "100%", marginTop: "20px" }} // Set the width to 270
						cover={[
							<div align="center">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "30px",
									}}
								>
									<div style={{ textAlign: "left", marginLeft: "20px" }}>
										<p>Investor profile:</p>
									</div>
									<div style={{ textAlign: "right", marginRight: "20px" }}>
										<p>
											<b>{loginUser.investorProfile}</b>
										</p>
									</div>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "30px",
									}}
								>
									<div style={{ textAlign: "left", marginLeft: "20px" }}>
										<p>Exchange</p>
									</div>
									<div style={{ textAlign: "right", marginRight: "20px" }}>
										<p>
											<b>{paymentDetails.exchangeName.split("|")[1]}</b>
										</p>
									</div>
								</div>
							</div>,
						]}
					></Card>
				</Row>

				<Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
					<Card
						key={1}
						size="small"
						style={{ width: "100%" }} // Set the width to 270
						cover={[
							<div align="center">
								<br />
								<div align="center">
									<h6>Crypto Basket Option</h6>
								</div>
								<div>
									<br />
									<h4 className="price">
										{producOptionPassDetails.productName}
									</h4>
								</div>
								<div align="center">
									<hr></hr>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Investment Type</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>
												{producOptionPassDetails.investmentType
													? "Active"
													: "Passive"}
											</b>
										</p>
									</div>

									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Allocations</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.allocations}</b>
										</p>
									</div>

									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Rebalancing</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.rebalancing}</b>
										</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>Expected Annual Income</p>
									</div>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.expectedAnnualIncome} % </b>
										</p>
									</div>
								</div>
								<div className="additional" align="center">
									{/* <h4 className="price">Montante: <span className="quantity"></span></h4> */}
									<hr />
									<h6 className="price">
										Market Selection
										<br />
									</h6>
									<div
										style={{
											textAlign: "center",
											marginLeft: "10px",
											marginRight: "10px",
										}}
									>
										<p>
											<b>{producOptionPassDetails.marketSelection} </b>
										</p>
									</div>
								</div>
							</div>,
						]}
					></Card>
				</Row>

				<h6 align="center" style={{ marginTop: 30 }}>
					I declare that I have read the information provided and that I accept
					the conditions of this contract.
				</h6>

				<Form.Item
					style={{ textAlign: "center" }}
					label=""
					name="acceptGeneralConditions"
					valuePropName="checked"
					className="daily-returns-toggle"
					rules={[
						{
							required: true,
							message: "Please accept the instructions.",
						},
					]}
				>
					<Switch
						style={{ marginLeft: "10px" }}
						checkedChildren="Accepted"
						unCheckedChildren="I do not accept"
					/>
				</Form.Item>

				<div style={{ textAlign: "center" }}>
					<Button
						style={{
							width: "100%",
							backgroundColor: "#7600ec",
							borderColor: "#7600ec",
							color: "#fff",
							borderRadius: "5px",
						}}
						type="primary"
						htmlType="submit"
					>
						CONFIRM
					</Button>
					<Link to="/">
						<Button
							style={{
								width: "100%",
								background: "white",
								color: "Blue",
								marginTop: 30,
								marginBottom: 30,
							}}
							type="primary"
						>
							CANCEL NEW SAVING
						</Button>
					</Link>
				</div>
			</Form>
		);
	}

	function FinishForm() {
		const [isButtonEnabled, setIsButtonEnabled] = useState(false);

		useEffect(() => {
			// Check if initialValue is greater than or equal to minimumInvestment
			if (
				parseFloat(paymentDetails.exchangeName.split("|")[2]) >=
				parseFloat(savingDetails.initialValue)
			) {
				setIsButtonEnabled(true);
			} else {
				setIsButtonEnabled(false);
			}
		}, []);

		return (
			<div align="center">
				<div style={{ textAlign: "center" }}>
					<img alt="example" src={MyCongratulation} />
				</div>
				<br />
				<h4>Thank you {loginUser.firstName}!</h4>
				<br />
				<h4>Your roboadvisor is being created!</h4>
				<br></br>
				<p>
					We are withdrawing from your exchange balance (USDC) to buy cryptos to
					your portfolio:{" "}
				</p>
				<br></br>
				<h2>
					<i>{targetDetails.savingName}</i>
				</h2>

				<Button
					style={{
						width: "100%",
						textAlign: "center",
						marginTop: "20px",
						backgroundColor: "#7600ec",
						borderColor: "#7600ec",
						color: "#fff",
						borderRadius: "5px",
					}}
					type="primary"
					htmlType="submit"
					disabled={!isButtonEnabled}
					onClick={submitHandler}
				>
					STARTING NOW
				</Button>
				{!isButtonEnabled && (
					<Alert
						message="Button Disabled"
						description={`Please make sure the initial value ${
							savingDetails.initialValue
						} USDC is greater than or equal to the your cash available ${
							paymentDetails.exchangeName.split("|")[2]
						} USDC.`}
						type="info"
						showIcon
					/>
				)}
			</div>
		);
	}

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//     console.log({
	//       //SAVING
	//       userid:loginUser._id,
	//       categoryName:targetDetails.categoryName,
	//       savingName:targetDetails.savingName,
	//       productName:producOptionPassDetails.productId,
	//       agentName:agentDetails,
	//       initialValue:savingDetails.initialValue,
	//       targetValue:targetDetails.targetValue,
	//       targetDate:targetDetails.targetDate,
	//       monthlyValue:savingDetails.monthlyValue,
	//       savingDate:producOptionPassDetails.savingDate,
	//       InvestorProfileName:loginUser.investorProfile,
	//       IBAN:paymentDetails.IBAN,
	//       isPaymentAuthorization:paymentDetails.isPaymentAuthorization,
	//       savingProcessId:codeSquare,
	//       dayPayment:producOptionPassDetails.dayPayment,
	//       //TRANSACTION
	//       amount:savingDetails.initialValue+savingDetails.monthlyValue,
	//       monthYear:producOptionPassDetails.monthYear,
	//       monthYearNumber:producOptionPassDetails.monthYearNumber,
	//       dayMonthYearNumber:producOptionPassDetails.dayMonthYearNumber,
	//       //SavingUserContratualDocument
	//       acceptInvestorProfile:contratualDocumentsDetails.acceptInvestorProfile,
	//       acceptAppropriateCharacterDeclaration:contratualDocumentsDetails.acceptAppropriateCharacterDeclaration,
	//       acceptPreContratualinformation:contratualDocumentsDetails.acceptPreContratualinformation,
	//       acceptProposal:contratualDocumentsDetails.acceptProposal,
	//       acceptGeneralConditions:contratualDocumentsDetails.acceptGeneralConditions,
	//       acceptContractConditions:resumeDetails.acceptGeneralConditions
	//     })};

	//***************************RENDER**************************************************************************/
	// Calculate progress based on the current step
	const totalSteps = 9; // Assuming you have 9 steps
	const progressPercent = ((current + 1) / totalSteps) * 100;

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<h1
						style={{
							fontSize: "2rem",
							fontWeight: "bold",
							marginBottom: "20px",
						}}
					>
						New Crypto Basket
					</h1>

					{/* Progress bar with 50px margin below */}
					<Row gutter={[0, 8]} justify="center">
						<Col span={24}>
							<Progress
								percent={progressPercent}
								showInfo={false}
								strokeColor="#7600ec"
								style={{ marginBottom: "10px" }}
							/>
						</Col>
					</Row>

					{/* Step indicator */}
					<Row gutter={[0, 8]} justify="center">
						<Col
							span={24}
							style={{ textAlign: "center", marginBottom: "50px" }}
						>
							<span style={{ fontSize: "16px", fontWeight: "500" }}>
								Step {current + 1}/{totalSteps}
							</span>
						</Col>
					</Row>

					<div className="steps-action">{forms[current]}</div>
				</Card>
			</div>
		</div>
	);
};

export default NewSavings;
