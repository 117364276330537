import React,{useState,useEffect} from "react";
import {Input, message,Space,Button} from 'antd';

import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import moment from 'moment';
import {useNavigate,useLocation} from "react-router-dom";

import {
  SearchOutlined,
} from "@ant-design/icons";

const InvestorProfilesDetail = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const data = location.state;
  // console.log(data);

  const navigate = useNavigate();

  const result = data.resultSelects.selectPortfolio;

  const [loading,setLoading] = useState(false);

  const [allInvestorProfile, setAllInvestorProfile] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  const handlerHome = () => {
    navigate('/');
  }

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "userid",
      title: "Client Name",
      dataIndex: ["userid", "name"],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.userid.name.localeCompare(b.userid.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userid.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "createdAt",
      title: "Investor Quiz Date",
      dataIndex: "createdAt",
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:MM")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "selectPortfolio",
      title: "Risk Profile",
      dataIndex: "selectPortfolio",
      sorter: (a, b) => a.selectPortfolio.localeCompare(b.selectPortfolio),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.selectPortfolio.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
  ];

  const user = JSON.parse(localStorage.getItem('user'))


  //useEffects Hook **************************************************************************************************************************
  //List of Users
  // useEffect(() => {
  //   const getAllInvestorProfiles = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/investorProfiles/get-investorProfile',
  //       {
  //         // userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllInvestorProfile(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       // message.error('Fetch Issue With Sector');
  //       //message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllInvestorProfiles();
  // },[])

  // const submitHandler = async (values) => {
  //   try{
  //     navigate('/ ')
  //   }catch (error){
  //     message.error('Something went wrong');
  //   }
  // }

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Your Investor Profile</h1></div>
        <div></div>
      </div>
      <hr></hr>
      <div className="accountpage">
        <div></div>
        <div>
            {result === undefined && (
              <c></c>
            )}
            {result === "Defensive" && (
              <c><b>The most suitable Investor Profile for you is the <i>"Defensive"</i></b>
              <br/><br/>
              Portfolio composed of 80% Bonds and 20% Shares/Cryptos
              <br/><br/>
              <b>Result</b>
              <br/>
              For investors whose main need is the preservation of income and capital.</c>
            )}
            {result === "Conservative" && (
              <c><b>The most suitable Investor Profile for you is the <i>"Conservative"</i></b>
              <br/><br/>
              Portfolio composed of 60% Bonds and 40% Shares/Cryptos
              <br/><br/>
              <b>Result</b>
              <br/> 
              For investors looking for long-term capital growth and are aware of volatility.</c>
            )}
            {result === "Moderate" &&(
              <c><b>The most suitable Investor Profile for you is the <i>"Moderate"</i></b>
              <br/><br/>
              Portfolio composed of 40% Bonds and 60% Shares/Cryptos
              <br/><br/>
              <b>Result</b>
              <br/> 
              For investors looking for long-term capital growth and are aware of volatility.</c>
            )}
            {result === "Aggressive" &&(
              <c><b>The most suitable Investor Profile for you is the <i>"Aggressive"</i></b>
              <br/><br/>
              Portfolio composed of 20% Bonds and 80% Shares/Cryptos
              <br/><br/>
              <b>Result</b> 
              <br/>
              For aggressive investors looking to maximize returns and can tolerate slightly higher volatility. </c>
            )}
            {result === "Very Aggressive" && (
              <c><b>The most suitable Investor Profile for you is the <i>"Very Aggressive"</i></b>
              <br/><br/>
              Portfolio composed of 100% Shares/Cryptos
              <br/><br/>
              <b>Result</b> 
              <br/>
              For aggressive investors who seek to maximize returns and can tolerate greater volatility.</c>
            )}
            <br/>
            <Button
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '10px',
              marginRight: '10px',
              width: '100%',
              whiteSpace: 'normal', // Ensure text wraps
              padding: '0px', // Adjust padding for better appearance
            }}
            type="primary"
            onClick={handlerHome}
          >
            NEXT!
          </Button>
        </div>
        <div></div>
        </div>
        <div className="accountpage">
        <div></div>
        <div>
          Thank you, we will update your profile! You will need to log in again
        </div>
        <div></div>
        </div>
      {/* <div><pre>{JSON.stringify(data, null, 2) }</pre></div> */}

    </Layout>
  );

};

export default InvestorProfilesDetail;