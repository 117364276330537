import React,{useState,useEffect} from "react";
import {Input, Tabs,Form,Button, Radio,message ,Tooltip,Row,Card,Table} from 'antd';
import MyWallet from "./../components/Assets/binance.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleFilled,ExclamationCircleFilled, HddOutlined, MinusOutlined, PieChartOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, StockOutlined, TableOutlined, UnorderedListOutlined, UploadOutlined } from "@ant-design/icons";

import moment from 'moment';
import  Chart  from "react-apexcharts";

const NewExchanges = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency

  const currencySymbol = user_currency === 'USD' ? '$' : '€';


  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  // console.log(investor)

  const { Meta } = Card;
  const { TabPane } = Tabs;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allExchangePortfolio, setAllExchangePortfolio] = useState([]);

  const [activeTab, setActiveTab] = useState('Aggregate');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };


  const [brokerDetails, setBrokerDetails] = useState(null);

  const onChangeBroker = ({ target: { value } }) => {
    // console.log('Broker choose: ', value);
    setBrokerDetails(value);
  };

  const [allInvestmentStats, setAllInvestmentStats] = useState([]);
  const [allStats, setAllStats] = useState([]);
  const [allBalanceStats, setAllBalanceStats] = useState([]);
  const [cashExchanges, setCashExchanges] = useState([]);

  //Fields from Graphs to View
  const [allInvestmentTypeWeights, setAllInvestmentTypeWeights] = useState(null);
  const [allAllInvestmentIsProtectedWeights, setAllInvestmentIsProtectedWeights] = useState({});

  const [distinctExchanges, setDistinctExchanges] = useState([]);

  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

  /*************************************************************************** */

  // console.log("user.investorProfile: ",user.investorProfile);
  // console.log("investor: ",investor);

  let range = {min: 1, max: 999999999}
  let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

//NEW EXCHANGE
  const handleSubmitNewExchange = (values) => {
    // console.log('Submitted Email:', values);
    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);

      if (brokerDetails === "Binance"){
      const result = axios.post('/exchanges/add-exchange', {
        ...values,
        brokerName:brokerDetails,
        codeSquare: codeSquare,
        assetType: "Cryptocurrencies",
        quantity: 1,
        userid:user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      )
      
      const res2 =  axios.post('/users/edit-user', {
        payload:{
          haveInvestorProfile: true,
          newUser: false
        },
        _id: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );

      // Ensure the user object exists
      if (user) {
        // Update the newUser property
        user.newUser = false;
        user.haveInvestorProfile= true;

        // Save the updated user object back to localStorage
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        console.error("No user object found in localStorage.");
      };
      
      }
      setLoading(false);
      navigate('/ProcessingExchanges',
        {
          state:{
            exchangeName:values.investmentName,
            processing:"Portfolio em criação",
            navigateLink:"/MyInvestments",
            codeSquare: codeSquare
          }
        }
      );
    }catch (error){
      setLoading(false);
    }
  };

  return (
    <Layout>
      {loading && <Spinner/>}

      <div style={{height: '100%' }}>
        <div style={{marginTop: "10px"}}>
        <div>

            <Form 
              layout="vertical"
              name="newPortfolioForm"
              onFinish={handleSubmitNewExchange}
              >

            <h5 style={{ marginBottom:30, marginTop:30, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>Broker Name</h5>

            <div style={{ marginBottom:30, marginTop:30}}>
              <Radio.Group
                onChange={onChangeBroker}
                style={{ display: "flex", flexDirection: "column" }}
                required
              >
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                  <Radio.Button value="Binance" style={{ width: "100%", textAlign: "center", margin: "10px" }}>Binance</Radio.Button>
                  {/* <Radio.Button disabled value="XTB" style={{ width: "100%", textAlign: "center", margin: "10px" }}>XTB</Radio.Button>
                  <Radio.Button disabled value="DeGiro" style={{ width: "100%", textAlign: "center", margin: "10px" }}>DeGiro</Radio.Button>
                  <Radio.Button disabled value="KuCoin" style={{ width: "100%", textAlign: "center", margin: "10px" }}>KuCoin</Radio.Button>
                  <Radio.Button disabled value="CoinBase" style={{ width: "100%", textAlign: "center", margin: "10px" }}>CoinBase</Radio.Button> */}
                </div>
              </Radio.Group>
              {!setBrokerDetails && <span style={{ color: 'red' }}>* Required field</span>}
            </div>

            <Form.Item label="Connection name" name="investmentName">
              <Input
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>

            <Form.Item label="Api Key" name="apiKey">
              <Input
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>

            <Form.Item label="Key Secret" name="keySecret">
              <Input 
              type="text"
              style={{
                width: '100%'
              }} 
              />
            </Form.Item>

            <Form.Item>
              <Button 
              style={{ width: "100%",marginTop: "20px"}} 
              type="primary" 
              htmlType="submit"
              >
                CREATE
              </Button>
              <Button
                style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}
                type="primary" 
                htmlType="submit"
                onClick={() => navigate('/')}
              >
                CANCEL
              </Button>
            </Form.Item>

            </Form>
      </div>
          {/* Content for the left border (80% of screen) */}
        </div>
      </div>

    </Layout>
  );
};

export default NewExchanges;