import React, { useState, useEffect } from "react";
import { message, Table, Spin, Card } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import moment from "moment";

import Chart from "react-apexcharts";
import { ArrowLeftOutlined } from "@ant-design/icons";

const PortfolioExchanges = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	// const exchangeDataUpdate = data;
	const exchangeIdUpdate = data.exchangeID;
	const exchangeNameUpdate = data.exchangeName;
	const currencyUpdate = data.currency;

	const [loading, setLoading] = useState(false);

	const [allSubscription, setAllSubscription] = useState([]);
	// const [allAccount, setAllAccount] = useState([]);

	// const [allAccountStats, setAllAccountStats] = useState([]);

	// const [show, setShow] = useState(false);

	// const { TextArea } = Input;

	//Edit Transactions
	// const [editable, setEditable] = useState(null);

	//table accounts
	const columns = [
		// {
		// 	key: "date_refreshed",
		// 	title: "Update Date",
		// 	dataIndex: "date_refreshed",
		// 	render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
		// 	sorter: (a, b) => new Date(a.date_refreshed) - new Date(b.date_refreshed),
		// },
		{
			key: "symbol",
			title: "Symbol",
			dataIndex: ["symbol"],
			sorter: (a, b) => a.symbol.localeCompare(b.symbol),
		},
		{
			key: "cryptoAssetName",
			title: "Crypto Name",
			dataIndex: ["cryptoAssetName", "cryptoAssetName"],
			sorter: (a, b) =>
				a.cryptoAssetName.cryptoAssetName.localeCompare(
					b.cryptoAssetName.cryptoAssetName
				),
		},
		{
			key: "qty",
			title: "Quantity",
			dataIndex: "qty",
			align: "center",
			sorter: (a, b) => a.qty - b.qty,
			render: (qty, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>{qty.toLocaleString("de-DE", {})}</span>
				);
			},
		},
		{
			key: "locked",
			title: "Quantity Locked",
			dataIndex: "locked",
			align: "center",
			sorter: (a, b) => a.locked - b.locked,
			render: (locked, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>{locked.toLocaleString("de-DE", {})}</span>
				);
			},
		},
		{
			key: "today_price",
			title: "Average Price USDT",
			dataIndex: "today_price",
			align: "right",
			sorter: (a, b) => a.today_price - b.today_price,
			render: (today_price, record) => {
				return (
					// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
					<span>
						{today_price.toLocaleString("de-DE", {
							style: "currency",
							currency: record.currency,
							maximumFractionDigits: record.ticketSize,
						})}
					</span>
				);
			},
		},
		// {
		// 	key: "today_price_ctr",
		// 	title: "Average Price",
		// 	dataIndex: "today_price_ctr",
		// 	align: "right",
		// 	sorter: (a, b) => a.today_price_ctr - b.today_price_ctr,
		// 	render: (today_price_ctr, record) => {
		// 		return (
		// 			// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
		// 			<span>
		// 				{today_price_ctr.toLocaleString("de-DE", {
		// 					style: "currency",
		// 					currency: record.currency_ctr,
		// 					maximumFractionDigits: record.ticketSize,
		// 				})}
		// 			</span>
		// 		);
		// 	},
		// },
		{
			key: "today_value",
			title: "Value USDT",
			dataIndex: "today_value",
			align: "right",
			sorter: (a, b) => a.today_value - b.today_value,
			render: (today_value, record) => {
				return (
					<span>
						{today_value.toLocaleString("de-DE", {
							style: "currency",
							currency: record.currency,
							maximumFractionDigits: 2,
						})}
					</span>
				);
			},
		},
		// {
		// 	key: "today_value_ctr",
		// 	title: "Value",
		// 	dataIndex: "today_value_ctr",
		// 	align: "right",
		// 	sorter: (a, b) => a.today_value_ctr - b.today_value_ctr,
		// 	render: (today_value_ctr, record) => {
		// 		return (
		// 			<span>
		// 				{today_value_ctr.toLocaleString("de-DE", {
		// 					style: "currency",
		// 					currency: record.currency_ctr,
		// 					maximumFractionDigits: 2,
		// 				})}
		// 			</span>
		// 		);
		// 	},
		// },
		{
			key: "weights_pct",
			title: "Weights %",
			dataIndex: "weights_pct",
			defaultSortOrder: "descend",
			align: "right",
			sorter: (a, b) => a.weights_pct - b.weights_pct,
			render: (weights_pct, record) => {
				if (record.weights_pct >= 0)
					return (
						// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
						<span> {parseFloat(weights_pct).toFixed(2)} %</span>
					);
			},
		},
		// {
		//   title: "Acções",
		//   dataIndex: "type",
		//   width: "5%",
		//   render: (type, record) => {
		//     // if(type === "Income" )
		//     return (
		//       <div className="mx-4">
		//         <Space size="middle">
		//         <EditOutlined
		//           onClick={() => {
		//             setEditable(record);
		//             setExchangeNameUpdate(record.exchangeName.exchangeName);
		//             setBrokerNameUpdate(record.exchangeName.brokerName);
		//             setFrequencyUpdate(record.exchangeName.frequency);
		//             setStatusUpdate(record.status);
		//             setExchangeDescriptionUpdate(record.exchangeName.exchangeDescription);
		//             setExchangeIdUpdate(record.exchangeName._id);
		//             setCashExchangeIdUpdate(record._id);
		//             setShowModal(true);
		//           }}
		//         />
		//         <DeleteOutlined
		//           onClick={() => {
		//             // console.log(record);
		//             // navigate('/Transactions',
		//             // {
		//             //   state:{
		//             //     accountId: record.id,
		//             //     accountDescription: record.accountName
		//             //   }
		//             // });
		//           }}
		//         />
		//         </Space>
		//       </div>
		//     );
		//   },
		// }
	];

	//useEffect Hook
	useEffect(() => {
		const getAllSubscription = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/portfolio-exchanges/get-portfolio-exchange",
					{
						userid: user._id,
						exchangeName: exchangeIdUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSubscription(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllSubscription();
	}, [exchangeIdUpdate]);

	const [weightSubject, setWeightSubject] = useState([]);
	const [weightMarks, setWeightMarks] = useState([]);

	useEffect(() => {
		const sSubject = [];
		const sMarks = [];
		const getAllLoanNameWeights = async (values) => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));
				const res = await axios.post(
					"/portfolio-exchanges/get-balanceexchangeaccount",
					{
						userid: user._id,
						exchangeName: exchangeIdUpdate,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				// setAllBalanceExchangeAccountWeights(res.data);
				// console.log(res.data);

				for (let i = 0; i < res.data.length; i++) {
					sSubject.push(res.data[i].cryptoAssetName);
					sMarks.push(res.data[i].amount);
				}
				setWeightSubject(sSubject);
				setWeightMarks(sMarks);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				message.error("You are not Admin User");
			}
		};
		getAllLoanNameWeights();
	}, [exchangeIdUpdate]);

	// //Handlers User  **************************************************************************************************************************
	// const handleSubmitAccount = async (values) => {

	// try{

	//   const user = JSON.parse(localStorage.getItem('user'))

	//   setLoading(true);
	//   const res = await axios.post('/exchanges/edit-exchange', {
	//     payload:{
	//       archive: false,
	//       status: true,
	//       userid: user._id,
	//     },
	//     _id: exchangeIdUpdate,
	//   },
	//   {
	//     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//   }
	//   );
	//   const res2 = await axios.post('/cash-exchanges/edit-cash-exchange', {
	//     payload:{
	//       archive: false,
	//       userid: user._id,
	//     },
	//     _id: cashExchangeIdUpdate,
	//   },
	//   {
	//     headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
	//   }
	//   );
	//   setLoading(false);
	//   message.success('Conta Wallet Activada novamente com sucesso!');
	//   setShowModal(false);
	//   setTimeout(function () {
	//     window.location.reload(true);
	//   }, 1000);
	//   console.log(values)
	//   }catch (error){
	//     setLoading(false);
	//     // message.error('Failed to industry');
	//     //message.error('You are not Admin User');
	//   }
	// }

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<div className="accountpage">
						<div></div>
						<div>
							<h1>
								{" "}
								Portfolio <br /> {exchangeNameUpdate}{" "}
							</h1>
						</div>
						<div></div>
					</div>
					<br></br>
					{/* Start Graphs */}
					<div
						className="row"
						style={{
							marginTop: "20px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<div className="col-xs-12 col-md-6">
							{" "}
							{/* Each chart takes half of the width on medium screens and full width on extra small screens */}
							<div className="chart-container">
								<Chart
									align="center"
									type="donut"
									width="100%"
									series={weightMarks}
									options={{
										responsive: [
											{
												breakpoint: 281, // iPhone SE width
												options: {
													chart: {
														width: "100%",
														height: 150, // Adjust height for iPhone SE
													},
												},
											},
											{
												breakpoint: 361, // iPhone SE width
												options: {
													chart: {
														width: "100%",
														height: 230, // Adjust height for iPhone SE
													},
												},
											},
											{
												breakpoint: 481, // iPhone SE width
												options: {
													chart: {
														width: "100%",
														height: 250, // Adjust height for iPhone SE
													},
												},
											},
											{
												breakpoint: 769, // iPhone 12 Mini width
												options: {
													chart: {
														width: "100%",
														height: 275, // Adjust height for iPhone 12 Mini
													},
												},
											},
											{
												breakpoint: 768, // iPhone 12, iPhone 14, and website width
												options: {
													chart: {
														width: "100%",
														height: 300, // Adjust height for iPhone 12, iPhone 14, and website
													},
												},
											},
											{
												breakpoint: 1024, // iPhone 12, iPhone 14, and website width
												options: {
													chart: {
														width: "100%",
														height: 300, // Adjust height for iPhone 12, iPhone 14, and website
													},
												},
											},
											{
												breakpoint: 1200, // iPhone 12, iPhone 14, and website width
												options: {
													chart: {
														width: "100%",
														height: 300, // Adjust height for iPhone 12, iPhone 14, and website
													},
												},
											},
											{
												breakpoint: 2000, // iPhone 12, iPhone 14, and website width
												options: {
													chart: {
														width: "100%",
														height: 325, // Adjust height for iPhone 12, iPhone 14, and website
													},
												},
											},
										],
										labels: weightSubject,
										noData: { text: "No Data" },
										plotOptions: {
											pie: {
												donut: {
													labels: {
														show: true,
														name: {
															formatter: function (val) {
																return val;
															},
														},
													},
												},
											},
										},
										dataLabels: { enabled: true },
										legend: { show: false }, // Hide legends
									}}
								/>
							</div>
							{/* <h4 align="center">Exchanges Total</h4> */}
						</div>
					</div>
					{/* Final Graphs */}
					<hr></hr>
					<div className="content">
						<Table
							size="small"
							columns={columns}
							dataSource={allSubscription}
							pagination={{
								position: ["bottomCenter"],
								defaultPageSize: 25,
								showSizeChanger: true,
								pageSizeOptions: ["10", "25", "50"],
							}}
							scroll={{ x: "max-content" }}
							className="custom-table" // Apply custom CSS class for styling
						/>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default PortfolioExchanges;
