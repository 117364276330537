import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch,Button,Select} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {useNavigate} from "react-router-dom";

import moment from 'moment';

import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

const FinanceLiteracySetup = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allVideos, setAllVideos] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [idVideoUpdate , setIdvideoUpdate]  = useState(null);
  const [titleVideoUpdate , setTitlevideoUpdate]  = useState(null);
  const [uploadDateVideoUpdate , setUploaddatevideoUpdate]  = useState(null);
  const [channelVideoUpdate , setChannelvideoUpdate]  = useState(null);
  const [categoryVideoUpdate , setCategoryvideoUpdate]  = useState(null);
  const [statusUpdate , setStatusUpdate]  = useState(null);
  
  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "uploadDateVideo",
      title: "Data do Upload",
      dataIndex: "uploadDateVideo",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.uploadDateVideo) - new Date(b.uploadDateVideo)
    },
    {
      key: "channelVideo",
      title: "Canal",
      dataIndex: "channelVideo",
      sorter: (a, b) => a.channelVideo.localeCompare(b.channelVideo),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.channelVideo.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "titleVideo",
      title: "Título do Vídeo",
      dataIndex: "titleVideo",
      sorter: (a, b) => a.titleVideo.localeCompare(b.titleVideo),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.titleVideo.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "categoryVideo",
      title: "Categoria do Video",
      dataIndex: "categoryVideo",
      sorter: (a, b) => a.categoryVideo.localeCompare(b.categoryVideo),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryVideo.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      align: 'center',
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Live</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Desativo</span>
          );
      },
      filters: [
        { text: "Live", value: true },
        { text: "Desativo", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Ações",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setIdvideoUpdate(record.idVideo);
                    setTitlevideoUpdate(record.titleVideo);
                    setUploaddatevideoUpdate(record.uploadDateVideo);
                    setChannelvideoUpdate(record.channelVideo);
                    setCategoryvideoUpdate(record.categoryVideo);
                    setStatusUpdate(record.status);
                    setShowModal(true);
              }}
            />
            <DeleteOutlined
                className="mx-2"
                onClick={() => {
                handleDelete(record);
            }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Categories
  useEffect(() => {
    const getAllVideos = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/financeLiteracys/get-allfinanceLiteracy',
        {
          status:"all"
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllVideos(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllVideos();
  },[])

  //Handlers Submit  **************************************************************************************************************************
  const handleSubmitVideo = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/financeLiteracys/edit-financeLiteracy', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Video atualizado com sucesso!');

    }else{
      setLoading(true);
      const result = axios.post('/financeLiteracys/add-financeLiteracy', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Video criado com Sucesso!');
    }
    setShowModal(false);
    setEditable(null);
    setTimeout(function () {
      window.location.reload(true);
    }, 1000);

    }catch (error){
      setLoading(false);
    }
  }

  //delete handler
  const handleDelete = async (record) => {
    try {
        setLoading(true);
        await axios.post("/financeLiteracys/delete-financeLiteracy", {
        _id: record._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        message.success("Video apagado com sucesso!");
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        // console.log(error);
        message.error("unable to delete");
    }
    };

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setIdvideoUpdate(null);
    setTitlevideoUpdate(null);
    setUploaddatevideoUpdate(null);
    setChannelvideoUpdate(null);
    setCategoryvideoUpdate(null);
    setStatusUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  
    /************************************DATE DISABLE***************************/
    let dateDisable;
    if (!uploadDateVideoUpdate) {
        dateDisable = 
        <Form.Item label="Data do Upload do vídeo" name="uploadDateVideo"
            rules={[
                { required: true, whitespace:true, message: 'Inserir data da cotação do ativo!' },
                ]}>
            <Input type="date" defaultValue={uploadDateVideoUpdate}/>
        </Form.Item>
        ;
    } else {
        dateDisable =
        <Form.Item label="Data do Upload do vídeo" name="uploadDateVideo">
        <Input 
            type="text"
            defaultValue={moment(uploadDateVideoUpdate).format("YYYY-MM-DD")}
            disabled="true"
        />
    </Form.Item>;
    }

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Upload Videos Literacia Financeira</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Video
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>
      <hr></hr>

      {/* <div><pre>{JSON.stringify(allVideos, null, 2) }</pre></div> */}

      <div className="content">
        <Table 
        columns={columns}
        dataSource={allVideos}
        pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>

      <Modal
        destroyOnClose={true} 
        title={editable ? "Editar Upload Video" : "Novo Upload Video" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitVideo} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="idVideo" name="idVideo">
              <Input 
                type="text"
                defaultValue={idVideoUpdate}
                />
            </Form.Item>
            <Form.Item label="Título do Video" name="titleVideo">
              <Input 
                type="text"
                defaultValue={titleVideoUpdate}
                />
            </Form.Item>

            {dateDisable}

            <Form.Item label="Canal" name="channelVideo">
              <Input 
                type="text"
                defaultValue={channelVideoUpdate}
                />
            </Form.Item>

            <Form.Item label="Categoria do Video" name="categoryVideo">
                  <Select 
                  defaultValue={categoryVideoUpdate}
                  style={{
                    width: '100%',
                  }}  
                  >
                    <Select.Option value="Mercados Financeiros">Mercados Financeiros</Select.Option>
                    {/* <Select.Option value="USD">USD</Select.Option>
                    <Select.Option value="CHF">CHF</Select.Option>
                    <Select.Option value="GBP">GBP</Select.Option>
                    <Select.Option value="Other">Other</Select.Option> */}
                  </Select>
                </Form.Item>

            <Form.Item label="Estado" name="status" valuePropName="checked">
            <Switch 
              checkedChildren="LIVE" unCheckedChildren="DESACTIVO" defaultChecked={statusUpdate}
            />
          </Form.Item>

              <button type="submit" style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}} className="btn btn-success" onClick={handleClose}>
                  {" "}
                  GRAVAR
              </button>
            
          </Form>
      </Modal>

    </Layout>
  );

};

export default FinanceLiteracySetup;