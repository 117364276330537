import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	FaHome,
	FaEnvelope,
	FaBook,
	FaAddressBook,
	FaCog,
	FaSignOutAlt,
	FaUserShield,
	FaTachometerAlt,
} from "react-icons/fa";
import MyLogo from "../Assets/bi-capital.jpg";

const Navbar = () => {
	const [loginUser, setLoginUser] = useState("");
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user ? user.profileType : null;

	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	};

	const logoutHandler = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<nav className="navbar">
			<div className="navbar-brand">
				<a href="/" className="logo">
					<img
						style={{ width: "24px", height: "24px", marginRight: "6px" }}
						alt="Logo"
						src={MyLogo}
					/>
					BIT ADVISOR
				</a>
				{/* <button className="navbar-toggle" onClick={toggleNavbar}>
					☰
				</button> */}
			</div>
			{/* <div className={`navbar-menu ${isOpen ? "is-open" : ""}`}>
				<a href="/" className="navbar-item">
					<FaHome className="navbar-icon" /> Home
				</a>
				{profileType === 0 && (
					<a href="/MyInvestments" className="navbar-item">
						<FaAddressBook className="navbar-icon" /> Exchanges
					</a>
				)}
				{profileType === 9 && (
					<a href="/ExplorerSettings" className="navbar-item">
						<FaUserShield className="navbar-icon" /> Admin
					</a>
				)}
				{profileType === 9 && (
					<a href="/" className="navbar-item">
						<FaTachometerAlt className="navbar-icon" /> Dashboard
					</a>
				)}
				<a href="/FinanceLiteracy" className="navbar-item">
					<FaBook className="navbar-icon" /> Academy
				</a>
				{profileType === 9 && (
					<a href="/MyContactsAdmin" className="navbar-item">
						<FaAddressBook className="navbar-icon" /> Contacts
					</a>
				)}
				{profileType === 1 && (
					<a href="/MyContactsAgent" className="navbar-item">
						<FaAddressBook className="navbar-icon" /> Contacts
					</a>
				)}
				<a href="/MySettings" className="navbar-item">
					<FaCog className="navbar-icon" /> Account
				</a>
				<a className="navbar-item" onClick={logoutHandler}>
					<FaSignOutAlt className="navbar-icon" /> Sign Off
				</a>
			</div> */}
		</nav>
	);
};

export default Navbar;
