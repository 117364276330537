import React,{useState,useEffect} from "react";
import {Select,DatePicker,Row,Col} from 'antd';

import YouTube from 'react-youtube';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';

const FinanceLiteracy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading,setLoading] = useState(false);

  const [allVideos, setAllVideos] = useState([]);

  const {RangePicker} = DatePicker;

  //Filters
  const [frequency, setFrequency] = useState('month');
  const [selectedDate, setSelectedDate] = useState([]);

  useEffect(() => {
    const getAllVideos = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/financeLiteracys/get-allfinanceLiteracy',
        {
          frequency,
          selectedDate,
          status: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllVideos(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        //message.error('You are not Admin User');
      }
    };  
    
    getAllVideos();

    // // Clean up the interval when the component unmounts
    // return () => getAllTransactions();
  },[frequency,selectedDate])


  // useEffect(() => {
  //   // Fetch or define the list of videos
  //   const fetchedVideos = [
  //     { id: 'tqHRIgmuk3g' , title:"IMGA PODCASTS - Mensal [Fevereiro 2024]", upload:"2024-03-01", channel:"IM Gestão de Ativos"},
  //     { id: 'Vj6_JrBBGVE' , title:"IMGA PODCASTS - Mensal [Abril 2024]", upload:"2024-05-01", channel:"IM Gestão de Ativos"},
  //     { id: '7y6a0K4wAvA' , title:"IMGA PODCASTS - Mensal [Março 2024]", upload:"2024-04-01", channel:"IM Gestão de Ativos"},
  //     // Add more videos as needed
  //   ];
  //   setAllVideos(fetchedVideos);
  // }, []);

    //Analytics
    const countVideos= allVideos.length;

  const opts = {
    height: '315',
    width: '100%', // Adjust width for mobile
    playerVars: {
      autoplay: 0,
    },
  };

  const videosInRows = [];
  let row = [];
  allVideos.forEach((item, index) => {
    row.push(
      <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8} key={item.id}>
        <YouTube videoId={item.idVideo} opts={opts} />
        <h5>{item.titleVideo}</h5>
        <h7><b>{item.channelVideo}</b></h7>
        <p>{moment(item.uploadDateVideo).format("YYYY-MM-DD")}</p>
      </Col>
    );
    if ((index + 1) % 3 === 0 || index === allVideos.length - 1) {
      videosInRows.push(
        <Row gutter={[16, 16]} key={index}>
          {row.map(video => video)}
        </Row>
      );
      row = [];
    }
  });

  return (
    <Layout>
      {loading && <Spinner/>}

      {/* <div className="accountpagebuttonsAll"></div>

      <div className="accountpage">
        <div>
        </div>
        <div className="vertical-select"><h6>Número de Vídeos</h6>
          <p className="asset-info">{countVideos}</p>
          </div>
        <div></div>
      </div> */}

      {/* <hr></hr> */}

      {/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

      {videosInRows.map(row => row)}

    </Layout>
  );
};

export default FinanceLiteracy;