import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import moment from 'moment';

import {useNavigate } from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";

const MyAgents = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  //All Agents
  const [allAgent, setAllAgent] = useState([]);

  //DropList Category Asset
  const [allAsset, setAllAsset] = useState([]);

  //DropList Category Asset
  const [allCategoryAsset, setAllCategoryAsset] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [agentNameUpdate , setAgentNameUpdate]  = useState(null);
  const [agentCodeUpdate , setAgentCodeUpdate]  = useState(null);
  const [mobileAgentUpdate , setMobileAgentUpdate]  = useState(null);
  const [emailAgentUpdate , setEmailAgentUpdate]  = useState(null);
  const [nifAgentUpdate , setNifAgentUpdate]  = useState(null);
  const [logoPhotoURLUpdate , setLogoPhotoURLUpdate]  = useState(null);
  const [ibanAgentUpdate , setIbanAgentUpdate]  = useState(null);
  const [countryAgenttUpdate , setCountryAgentUpdate]  = useState(null);
  const [statusUpdate , setStatusUpdate]  = useState(null);

  const [addressAgentUpdate , setAddressAgentUpdate]  = useState(null);
  const [postalCodeAgentUpdate , setPostalCodeAgentUpdate]  = useState(null);
  const [phoneAgentUpdate , setPhoneAgentUpdate]  = useState(null);
  const [cityAgentUpdate , setCityAgentUpdate]  = useState(null);
  const [stateAgentUpdate , setStateAgentUpdate]  = useState(null);
  
  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "createdAt",
      title: "Data Criação",
      dataIndex: "createdAt",
      defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      key: "updatedAt",
      title: "Ultima Atualização",
      dataIndex: "updatedAt",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    },
    {
      key: "agentName",
      title: "Agente",
      dataIndex: "agentName",
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.agentName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "agentCode",
      title: "Agente CODE",
      dataIndex: "agentCode",
      sorter: (a, b) => a.agentCode.localeCompare(b.agentCode),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.agentCode.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "nifAgent",
      title: "NIF",
      dataIndex: "nifAgent",
      sorter: (a, b) => a.nifAgent-b.nifAgent,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.nifAgent.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "emailAgent",
      title: "Email",
      dataIndex: "emailAgent",
      sorter: (a, b) => a.agentName.localeCompare(b.agentName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.emailAgent.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "countryAgent",
      title: "País",
      dataIndex: "countryAgent",
      sorter: (a, b) => a.countryAgent.localeCompare(b.countryAgent),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.countryAgent.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      align:"center",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Ativo</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Desactivo</span>
          );
      },
      filters: [
        { text: "Ativo", value: true },
        { text: "Desativo", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Ações",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setAgentNameUpdate(record.agentName);
                    setAgentCodeUpdate(record.agentCode);
                    setMobileAgentUpdate(record.mobileAgent);
                    setEmailAgentUpdate(record.emailAgent);
                    setNifAgentUpdate(record.nifAgent);
                    setCountryAgentUpdate(record.countryAgent);
                    setLogoPhotoURLUpdate(record.logoPhotoURL);
                    setIbanAgentUpdate(record.IBANAgent);
                    setStatusUpdate(record.status);
                    setShowModal(true);
              }}
            />
            <DeleteOutlined
                className="mx-2"
                onClick={() => {
                handleDelete(record);
            }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

    //useEffects Hook **************************************************************************************************************************
  //List of Assets
  useEffect(() => {
    const getAllAgent = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/myagents/get-agent',
        {
          // userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllAgent(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllAgent();
  },[])

  //Handlers Submit  **************************************************************************************************************************
  const handleSubmitAgent = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/myagents/edit-agent', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Agente atualizado com sucesso!');

    }else{
      setLoading(true);
      const result = axios.post('/myagents/add-agent', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Agente criado com sucesso!');
    }

    // setShowModal(false);
    // setEditable(null);
    // setTimeout(function () {
    //   window.location.reload(true);
    // }, 1000);

    }catch (error){
      setLoading(false);
    }
  }

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //delete handler
  const handleDelete = async (record) => {
    try {
        setLoading(true);
        await axios.post("/myagents/delete-agent", {
        _id: record._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        message.success("Agente apagado com sucesso!");
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        // console.log(error);
        message.error("unable to delete");
    }
    };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setAgentNameUpdate(null);
    setAgentCodeUpdate(null);
    setMobileAgentUpdate(null);
    setEmailAgentUpdate(null);
    setCountryAgentUpdate(null)
    setNifAgentUpdate(null);
    setLogoPhotoURLUpdate(null);
    setIbanAgentUpdate(null);
    setStatusUpdate(null);
     
  };

  /************************************LOGO DISABLE BUTTON***************************/

  let logoURLUDisable;

  if (logoPhotoURLUpdate) {
    logoURLUDisable = 
    <div>
      <img src={logoPhotoURLUpdate} alt={logoPhotoURLUpdate} style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px',display: 'block'}}/>
    </div>
  } 
  

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Agentes</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Agente
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

      <hr></hr>
      <div className="content">
        <Table
        size="small" 
        columns={columns}
        dataSource={allAgent}
        pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allEtfAsset, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
      
      <Modal
        destroyOnClose={true} 
        title={editable ? "Editar Agente" : "Criar Agente" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitAgent}  
            style={{padding:"32px 16px"}}
          >

          {/* See the button view */}
          {logoURLUDisable}

            <Form.Item label="Nome do Agente" name="agentName">
              <Input 
                type="text"
                defaultValue={agentNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Agente Code" name="agentCode">
              <Input 
                type="text"
                defaultValue={agentCodeUpdate}
                />
            </Form.Item>
            <Form.Item 
            label="NIF do Agente" 
            name="nifAgent"
            rules={[
              { required: true, whitespace:true, message: 'Inserir o nif!' },
              ]}
            >
              <Input
                type="text"
                defaultValue={nifAgentUpdate} />
            </Form.Item>
            
            {/* <Form.Item label="Morada do Agente" name="addressAgent">
              <TextArea
                showCount
                maxLength={200}
                style={{
                  height: 100,
                  marginBottom: 12,
                }}
                type="text" 
                defaultValue={addressAgentUpdate}
                />
            </Form.Item>

            <Form.Item label="Código-Postal" name="postalCodeAgent">
              <Input 
                type="text"
                defaultValue={postalCodeAgentUpdate}
                />
            </Form.Item>

            <Form.Item label="Número Telefone" name="phoneAgent">
              <Input 
                type="text"
                defaultValue={phoneAgentUpdate}
                />
            </Form.Item> */}

            <Form.Item label="Número de Telemóvel" name="mobileAgent">
              <Input 
                type="text"
                defaultValue={mobileAgentUpdate}
                />
            </Form.Item>

            <Form.Item label="Email do Agente" name="emailAgent">
              <Input 
                type="text"
                defaultValue={emailAgentUpdate}
                />
            </Form.Item>

            <Form.Item label="País" name={"countryAgent"} 
            rules={[{
              required:true, 
              message:"Por favor, insira o seu país"
            }]}
            >
              <Select
              defaultValue={countryAgenttUpdate}
              >
              <Select.Option value="Afghanistan">Afghanistan</Select.Option>
              <Select.Option value="Åland Islands">Åland Islands</Select.Option>
              <Select.Option value="Albania">Albania</Select.Option>
              <Select.Option value="Algeria">Algeria</Select.Option>
              <Select.Option value="American Samoa">American Samoa</Select.Option>
              <Select.Option value="Andorra">Andorra</Select.Option>
              <Select.Option value="Angola">Angola</Select.Option>
              <Select.Option value="Anguilla">Anguilla</Select.Option>
              <Select.Option value="Antarctica">Antarctica</Select.Option>
              <Select.Option value="Antigua and Barbuda">Antigua and Barbuda</Select.Option>
              <Select.Option value="Argentina">Argentina</Select.Option>
              <Select.Option value="Armenia">Armenia</Select.Option>
              <Select.Option value="Aruba">Aruba</Select.Option>
              <Select.Option value="Australia">Australia</Select.Option>
              <Select.Option value="Austria">Austria</Select.Option>
              <Select.Option value="Azerbaijan">Azerbaijan</Select.Option>
              <Select.Option value="Bahamas">Bahamas</Select.Option>
              <Select.Option value="Bahrain">Bahrain</Select.Option>
              <Select.Option value="Bangladesh">Bangladesh</Select.Option>
              <Select.Option value="Barbados">Barbados</Select.Option>
              <Select.Option value="Belarus">Belarus</Select.Option>
              <Select.Option value="Belgium">Belgium</Select.Option>
              <Select.Option value="Belize">Belize</Select.Option>
              <Select.Option value="Benin">Benin</Select.Option>
              <Select.Option value="Bermuda">Bermuda</Select.Option>
              <Select.Option value="Bhutan">Bhutan</Select.Option>
              <Select.Option value="Bolivia (Plurinational State of)">Bolivia (Plurinational State of)</Select.Option>
              <Select.Option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</Select.Option>
              <Select.Option value="Bosnia and Herzegovina">Bosnia and Herzegovina</Select.Option>
              <Select.Option value="Botswana">Botswana</Select.Option>
              <Select.Option value="Bouvet Island">Bouvet Island</Select.Option>
              <Select.Option value="Brazil">Brazil</Select.Option>
              <Select.Option value="British Indian Ocean Territory">British Indian Ocean Territory</Select.Option>
              <Select.Option value="Brunei Darussalam">Brunei Darussalam</Select.Option>
              <Select.Option value="Bulgaria">Bulgaria</Select.Option>
              <Select.Option value="Burkina Faso">Burkina Faso</Select.Option>
              <Select.Option value="Burundi">Burundi</Select.Option>
              <Select.Option value="Cabo Verde">Cabo Verde</Select.Option>
              <Select.Option value="Cambodia">Cambodia</Select.Option>
              <Select.Option value="Cameroon">Cameroon</Select.Option>
              <Select.Option value="Canada">Canada</Select.Option>
              <Select.Option value="Cayman Islands">Cayman Islands</Select.Option>
              <Select.Option value="Central African Republic">Central African Republic</Select.Option>
              <Select.Option value="Chad">Chad</Select.Option>
              <Select.Option value="Chile">Chile</Select.Option>
              <Select.Option value="China">China</Select.Option>
              <Select.Option value="Christmas Island">Christmas Island</Select.Option>
              <Select.Option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</Select.Option>
              <Select.Option value="Colombia">Colombia</Select.Option>
              <Select.Option value="Comoros">Comoros</Select.Option>
              <Select.Option value="Congo (Republic of the)">Congo (Republic of the)</Select.Option>
              <Select.Option value="Congo (Democratic Republic of the)">Congo (Democratic Republic of the)</Select.Option>
              <Select.Option value="Cook Islands">Cook Islands</Select.Option>
              <Select.Option value="Costa Rica">Costa Rica</Select.Option>
              <Select.Option value="Côte d'Ivoire">Côte d'Ivoire</Select.Option>
              <Select.Option value="Croatia">Croatia</Select.Option>
              <Select.Option value="Cuba">Cuba</Select.Option>
              <Select.Option value="Curaçao">Curaçao</Select.Option>
              <Select.Option value="Cyprus">Cyprus</Select.Option>
              <Select.Option value="Czech Republic">Czech Republic</Select.Option>
              <Select.Option value="Denmark">Denmark</Select.Option>
              <Select.Option value="Djibouti">Djibouti</Select.Option>
              <Select.Option value="Dominica">Dominica</Select.Option>
              <Select.Option value="Dominican Republic">Dominican Republic</Select.Option>
              <Select.Option value="Ecuador">Ecuador</Select.Option>
              <Select.Option value="Egypt">Egypt</Select.Option>
              <Select.Option value="El Salvador">El Salvador</Select.Option>
              <Select.Option value="Equatorial Guinea">Equatorial Guinea</Select.Option>
              <Select.Option value="Eritrea">Eritrea</Select.Option>
              <Select.Option value="Estonia">Estonia</Select.Option>
              <Select.Option value="Ethiopia">Ethiopia</Select.Option>
              <Select.Option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</Select.Option>
              <Select.Option value="Faroe Islands">Faroe Islands</Select.Option>
              <Select.Option value="Fiji">Fiji</Select.Option>
              <Select.Option value="Finland">Finland</Select.Option>
              <Select.Option value="France">France</Select.Option>
              <Select.Option value="French Guiana">French Guiana</Select.Option>
              <Select.Option value="French Polynesia">French Polynesia</Select.Option>
              <Select.Option value="French Southern Territories">French Southern Territories</Select.Option>
              <Select.Option value="Gabon">Gabon</Select.Option>
              <Select.Option value="Gambia">Gambia</Select.Option>
              <Select.Option value="Georgia">Georgia</Select.Option>
              <Select.Option value="Germany">Germany</Select.Option>
              <Select.Option value="Ghana">Ghana</Select.Option>
              <Select.Option value="Gibraltar">Gibraltar</Select.Option>
              <Select.Option value="Greece">Greece</Select.Option>
              <Select.Option value="Greenland">Greenland</Select.Option>
              <Select.Option value="Grenada">Grenada</Select.Option>
              <Select.Option value="Guadeloupe">Guadeloupe</Select.Option>
              <Select.Option value="Guam">Guam</Select.Option>
              <Select.Option value="Guatemala">Guatemala</Select.Option>
              <Select.Option value="Guernsey">Guernsey</Select.Option>
              <Select.Option value="Guinea">Guinea</Select.Option>
              <Select.Option value="Guinea-Bissau">Guinea-Bissau</Select.Option>
              <Select.Option value="Guyana">Guyana</Select.Option>
              <Select.Option value="Haiti">Haiti</Select.Option>
              <Select.Option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</Select.Option>
              <Select.Option value="Vatican City State">Vatican City State</Select.Option>
              <Select.Option value="Honduras">Honduras</Select.Option>
              <Select.Option value="Hong Kong">Hong Kong</Select.Option>
              <Select.Option value="Hungary">Hungary</Select.Option>
              <Select.Option value="Iceland">Iceland</Select.Option>
              <Select.Option value="India">India</Select.Option>
              <Select.Option value="Indonesia">Indonesia</Select.Option>
              <Select.Option value="Iran">Iran</Select.Option>
              <Select.Option value="Iraq">Iraq</Select.Option>
              <Select.Option value="Ireland">Ireland</Select.Option>
              <Select.Option value="Isle of Man">Isle of Man</Select.Option>
              <Select.Option value="Israel">Israel</Select.Option>
              <Select.Option value="Italy">Italy</Select.Option>
              <Select.Option value="Jamaica">Jamaica</Select.Option>
              <Select.Option value="Japan">Japan</Select.Option>
              <Select.Option value="Jersey">Jersey</Select.Option>
              <Select.Option value="Jordan">Jordan</Select.Option>
              <Select.Option value="Kazakhstan">Kazakhstan</Select.Option>
              <Select.Option value="Kenya">Kenya</Select.Option>
              <Select.Option value="Kiribati">Kiribati</Select.Option>
              <Select.Option value="Korea (Democratic People's Republic of)">Korea (Democratic People's Republic of)</Select.Option>
              <Select.Option value="Korea (Republic of)">Korea (Republic of)</Select.Option>
              <Select.Option value="Kuwait">Kuwait</Select.Option>
              <Select.Option value="Kyrgyzstan">Kyrgyzstan</Select.Option>
              <Select.Option value="Lao People's Democratic Republic">Lao People's Democratic Republic</Select.Option>
              <Select.Option value="Latvia">Latvia</Select.Option>
              <Select.Option value="Lebanon">Lebanon</Select.Option>
              <Select.Option value="Lesotho">Lesotho</Select.Option>
              <Select.Option value="Liberia">Liberia</Select.Option>
              <Select.Option value="Libya">Libya</Select.Option>
              <Select.Option value="Liechtenstein">Liechtenstein</Select.Option>
              <Select.Option value="Lithuania">Lithuania</Select.Option>
              <Select.Option value="Luxembourg">Luxembourg</Select.Option>
              <Select.Option value="Macao">Macao</Select.Option>
              <Select.Option value="Macedonia (the former Yugoslav Republic of)">Macedonia (the former Yugoslav Republic of)</Select.Option>
              <Select.Option value="Madagascar">Madagascar</Select.Option>
              <Select.Option value="Malawi">Malawi</Select.Option>
              <Select.Option value="Malaysia">Malaysia</Select.Option>
              <Select.Option value="Maldives">Maldives</Select.Option>
              <Select.Option value="Mali">Mali</Select.Option>
              <Select.Option value="Malta">Malta</Select.Option>
              <Select.Option value="Marshall Islands">Marshall Islands</Select.Option>
              <Select.Option value="Martinique">Martinique</Select.Option>
              <Select.Option value="Mauritania">Mauritania</Select.Option>
              <Select.Option value="Mauritius">Mauritius</Select.Option>
              <Select.Option value="Mayotte">Mayotte</Select.Option>
              <Select.Option value="Mexico">Mexico</Select.Option>
              <Select.Option value="Micronesia (Federated States of)">Micronesia (Federated States of)</Select.Option>
              <Select.Option value="Moldova (Republic of)">Moldova (Republic of)</Select.Option>
              <Select.Option value="Monaco">Monaco</Select.Option>
              <Select.Option value="Mongolia">Mongolia</Select.Option>
              <Select.Option value="Montenegro">Montenegro</Select.Option>
              <Select.Option value="Montserrat">Montserrat</Select.Option>
              <Select.Option value="Morocco">Morocco</Select.Option>
              <Select.Option value="Mozambique">Mozambique</Select.Option>
              <Select.Option value="Myanmar">Myanmar</Select.Option>
              <Select.Option value="Namibia">Namibia</Select.Option>
              <Select.Option value="Nauru">Nauru</Select.Option>
              <Select.Option value="Nepal">Nepal</Select.Option>
              <Select.Option value="Netherlands">Netherlands</Select.Option>
              <Select.Option value="New Caledonia">New Caledonia</Select.Option>
              <Select.Option value="New Zealand">New Zealand</Select.Option>
              <Select.Option value="Nicaragua">Nicaragua</Select.Option>
              <Select.Option value="Niger">Niger</Select.Option>
              <Select.Option value="Nigeria">Nigeria</Select.Option>
              <Select.Option value="Niue">Niue</Select.Option>
              <Select.Option value="Norfolk Island">Norfolk Island</Select.Option>
              <Select.Option value="Northern Mariana Islands">Northern Mariana Islands</Select.Option>
              <Select.Option value="Norway">Norway</Select.Option>
              <Select.Option value="Oman">Oman</Select.Option>
              <Select.Option value="Pakistan">Pakistan</Select.Option>
              <Select.Option value="Palau">Palau</Select.Option>
              <Select.Option value="Palestine, State of">Palestine, State of</Select.Option>
              <Select.Option value="Panama">Panama</Select.Option>
              <Select.Option value="Papua New Guinea">Papua New Guinea</Select.Option>
              <Select.Option value="Paraguay">Paraguay</Select.Option>
              <Select.Option value="Peru">Peru</Select.Option>
              <Select.Option value="Philippines">Philippines</Select.Option>
              <Select.Option value="Pitcairn">Pitcairn</Select.Option>
              <Select.Option value="Poland">Poland</Select.Option>
              <Select.Option value="Portugal">Portugal</Select.Option>
              <Select.Option value="Puerto Rico">Puerto Rico</Select.Option>
              <Select.Option value="Qatar">Qatar</Select.Option>
              <Select.Option value="Réunion">Réunion</Select.Option>
              <Select.Option value="Romania">Romania</Select.Option>
              <Select.Option value="Russian Federation">Russian Federation</Select.Option>
              <Select.Option value="Rwanda">Rwanda</Select.Option>
              <Select.Option value="Saint Barthélemy">Saint Barthélemy</Select.Option>
              <Select.Option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena, Ascension and Tristan da Cunha</Select.Option>
              <Select.Option value="Saint Kitts and Nevis">Saint Kitts and Nevis</Select.Option>
              <Select.Option value="Saint Lucia">Saint Lucia</Select.Option>
              <Select.Option value="Saint Martin (French part)">Saint Martin (French part)</Select.Option>
              <Select.Option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</Select.Option>
              <Select.Option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</Select.Option>
              <Select.Option value="Samoa">Samoa</Select.Option>
              <Select.Option value="San Marino">San Marino</Select.Option>
              <Select.Option value="Sao Tome and Principe">Sao Tome and Principe</Select.Option>
              <Select.Option value="Saudi Arabia">Saudi Arabia</Select.Option>
              <Select.Option value="Senegal">Senegal</Select.Option>
              <Select.Option value="Serbia">Serbia</Select.Option>
              <Select.Option value="Seychelles">Seychelles</Select.Option>
              <Select.Option value="Sierra Leone">Sierra Leone</Select.Option>
              <Select.Option value="Singapore">Singapore</Select.Option>
              <Select.Option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</Select.Option>
              <Select.Option value="Slovakia">Slovakia</Select.Option>
              <Select.Option value="Slovenia">Slovenia</Select.Option>
              <Select.Option value="Solomon Islands">Solomon Islands</Select.Option>
              <Select.Option value="Somalia">Somalia</Select.Option>
              <Select.Option value="South Africa">South Africa</Select.Option>
              <Select.Option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</Select.Option>
              <Select.Option value="South Sudan">South Sudan</Select.Option>
              <Select.Option value="Spain">Spain</Select.Option>
              <Select.Option value="Sri Lanka">Sri Lanka</Select.Option>
              <Select.Option value="Sudan">Sudan</Select.Option>
              <Select.Option value="Suriname">Suriname</Select.Option>
              <Select.Option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</Select.Option>
              <Select.Option value="Swaziland">Swaziland</Select.Option>
              <Select.Option value="Sweden">Sweden</Select.Option>
              <Select.Option value="Switzerland">Switzerland</Select.Option>
              <Select.Option value="Syrian Arab Republic">Syrian Arab Republic</Select.Option>
              <Select.Option value="Taiwan, Province of China">Taiwan, Province of China</Select.Option>
              <Select.Option value="Tajikistan">Tajikistan</Select.Option>
              <Select.Option value="Tanzania, United Republic of">Tanzania, United Republic of</Select.Option>
              <Select.Option value="Thailand">Thailand</Select.Option>
              <Select.Option value="Timor-Leste">Timor-Leste</Select.Option>
              <Select.Option value="Togo">Togo</Select.Option>
              <Select.Option value="Tokelau">Tokelau</Select.Option>
              <Select.Option value="Tonga">Tonga</Select.Option>
              <Select.Option value="Trinidad and Tobago">Trinidad and Tobago</Select.Option>
              <Select.Option value="Tunisia">Tunisia</Select.Option>
              <Select.Option value="Turkey">Turkey</Select.Option>
              <Select.Option value="Turkmenistan">Turkmenistan</Select.Option>
              <Select.Option value="Turks and Caicos Islands">Turks and Caicos Islands</Select.Option>
              <Select.Option value="Tuvalu">Tuvalu</Select.Option>
              <Select.Option value="Uganda">Uganda</Select.Option>
              <Select.Option value="Ukraine">Ukraine</Select.Option>
              <Select.Option value="United Arab Emirates">United Arab Emirates</Select.Option>
              <Select.Option value="United Kingdom of Great Britain and Northern Ireland">United Kingdom of Great Britain and Northern Ireland</Select.Option>
              <Select.Option value="United States Minor Outlying Islands">United States Minor Outlying Islands</Select.Option>
              <Select.Option value="United States of America">United States of America</Select.Option>
              <Select.Option value="Uruguay">Uruguay</Select.Option>
              <Select.Option value="Uzbekistan">Uzbekistan</Select.Option>
              <Select.Option value="Vanuatu">Vanuatu</Select.Option>
              <Select.Option value="Venezuela (Bolivarian Republic of)">Venezuela (Bolivarian Republic of)</Select.Option>
              <Select.Option value="Vietnam">Vietnam</Select.Option>
              <Select.Option value="Virgin Islands (British)">Virgin Islands (British)</Select.Option>
              <Select.Option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</Select.Option>
              <Select.Option value="Wallis and Futuna">Wallis and Futuna</Select.Option>
              <Select.Option value="Western Sahara">Western Sahara</Select.Option>
              <Select.Option value="Yemen">Yemen</Select.Option>
              <Select.Option value="Zambia">Zambia</Select.Option>
              <Select.Option value="Zimbabwe">Zimbabwe</Select.Option>
              </Select>
          </Form.Item>
 
            <Form.Item label="Foto do Agente" name="logoPhotoURL">
              <Input 
                type="text"
                defaultValue={logoPhotoURLUpdate}
                />
            </Form.Item>

            <Form.Item label="IBAN do Agente" name="IBANAgent">
              <Input 
                type="text"
                defaultValue={ibanAgentUpdate}
                placeholder="PT50 "
                />
            </Form.Item>

            <Form.Item label="Estado" name="status" valuePropName="checked">
              <Switch 
                checkedChildren="ACTIVO" unCheckedChildren="DESACTIVO" defaultChecked={statusUpdate}
              />
            </Form.Item>

            <button type="submit" style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}} className="btn btn-success"
            //  onClick={handleClose} 
             >
                  {" "}
                  GRAVAR
            </button>

            {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default MyAgents;