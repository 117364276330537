import React, { useState, useEffect } from "react";
import { Form, Input, message, Modal, Table, Switch, Button } from "antd";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import { useNavigate } from "react-router-dom";

import {
	SearchOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";

const InvestorRiskProfiles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TextArea } = Input;

	const [showModal, setShowModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [allInvestorRiskProfile, setAllInvestorRiskProfile] = useState([]);

	//Handle Close button Form
	const [show, setShow] = useState(false);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	//Fields from table to View
	const [orderUpdate, setOrderUpdate] = useState(null);
	const [investorRiskProfileNameUpdate, setInvestorRiskProfileNameUpdate] =
		useState(null);
	const [investorRiskProfileNamePTUpdate, setInvestorRiskProfileNamePTUpdate] =
		useState(null);
	const [percentageBondsUpdate, setPercentageBondsUpdate] = useState(null);
	const [percentageEquitiesUpdate, setPercentageEquitiessUpdate] =
		useState(null);
	const [startPointQuizUpdate, setStartPointQuizUpdate] = useState(null);
	const [endPointQuizUpdate, setEndPointQuizUpdate] = useState(null);
	const [descriptionUpdate, setDescriptionUpdate] = useState(null);
	const [descriptionPTUpdate, setDescriptionPTUpdate] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState(null);

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "order",
			title: "Ordenação",
			dataIndex: "order",
			align: "center",
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.order - b.order,
		},
		{
			key: "investorRiskProfileName",
			title: "Perfil de Risco EN",
			dataIndex: "investorRiskProfileName",
			sorter: (a, b) =>
				a.investorRiskProfileName.localeCompare(b.investorRiskProfileName),
		},
		{
			key: "investorRiskProfileNamePT",
			title: "Perfil de Risco PT",
			dataIndex: "investorRiskProfileNamePT",
			sorter: (a, b) =>
				a.investorRiskProfileNamePT.localeCompare(b.investorRiskProfileNamePT),
		},
		{
			key: "percentageBonds",
			title: "Obrigações",
			dataIndex: "percentageBonds",
			sorter: (a, b) => a.percentageBonds - b.percentageBonds,
			render: (percentageBonds, record) => {
				return <span>{percentageBonds.toFixed(2)} %</span>;
			},
		},
		{
			key: "percentageEquities",
			title: "Ativos",
			dataIndex: ["percentageEquities"],
			sorter: (a, b) => a.percentageEquities - b.percentageEquities,
			render: (percentageEquities, record) => {
				return <span>{percentageEquities.toFixed(2)} %</span>;
			},
		},
		{
			key: "startPointQuiz",
			title: "Pontos Iniciais",
			dataIndex: "startPointQuiz",
			align: "center",
			sorter: (a, b) => a.startPointQuiz - b.startPointQuiz,
		},
		{
			key: "endPointQuiz",
			title: "Pontos Finais",
			dataIndex: ["endPointQuiz"],
			align: "center",
			sorter: (a, b) => a.endPointQuiz - b.endPointQuiz,
		},
		{
			key: "status",
			title: "Status",
			dataIndex: "status",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Ativo</span>;

				return <span style={{ color: "#FF3234" }}>Desactivo</span>;
			},
			filters: [
				{ text: "Ativo", value: true },
				{ text: "Desactivo", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.status === value;
			},
		},
		{
			title: "Ações",
			dataIndex: "type",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-2">
						<EditOutlined
							onClick={() => {
								setEditable(record);
								setOrderUpdate(record.order);
								setInvestorRiskProfileNameUpdate(
									record.investorRiskProfileName
								);
								setInvestorRiskProfileNamePTUpdate(
									record.investorRiskProfileNamePT
								);
								setPercentageBondsUpdate(record.percentageBonds);
								setPercentageEquitiessUpdate(record.percentageEquities);
								setStartPointQuizUpdate(record.startPointQuiz);
								setEndPointQuizUpdate(record.endPointQuiz);
								setDescriptionUpdate(record.description);
								setDescriptionPTUpdate(record.descriptionPT);
								setStatusUpdate(record.status);
								setShowModal(true);
							}}
						/>
						<DeleteOutlined
							className="mx-2"
							onClick={() => {
								handleDelete(record);
							}}
						/>
					</div>
				);
			},
		},
	];

	const user = JSON.parse(localStorage.getItem("user"));

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllRiskTypeInvestor = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/investorriskprofiles/get-investorriskprofile",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllInvestorRiskProfile(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllRiskTypeInvestor();
	}, []);

	//Handlers Submit  **************************************************************************************************************************
	const handleSubmitInvestor = async (values) => {
		try {
			const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			if (editable) {
				setLoading(true);
				const result = axios.post(
					"/investorriskprofiles/edit-investorriskprofile",
					{
						payload: {
							...values,
							userid: user._id,
						},
						_id: editable._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Perfil de Investidor atualizado com sucesso!");
			} else {
				setLoading(true);
				const result = axios.post(
					"/investorriskprofiles/add-investorriskprofile",
					{
						...values,
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Perfil de Investidor criado com sucesso!");
			}

			setShowModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
		}
	};

	//delete handler
	const handleDelete = async (record) => {
		try {
			setLoading(true);
			await axios.post(
				"/investorriskprofiles/delete-investorriskprofile",
				{
					_id: record._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Perfil de Investidor apagado com sucesso!");
			setTimeout(function () {
				window.location.reload(true);
				message.success("reload 1 segundo");
			}, 1000);
		} catch (error) {
			setLoading(false);
			// console.log(error);
			message.error("unable to delete");
		}
	};

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	//Handler Reset Values to modal
	const handleCancelModal = () => {
		setShowModal(false);
		setEditable(null);
		setOrderUpdate(null);
		setInvestorRiskProfileNameUpdate(null);
		setInvestorRiskProfileNamePTUpdate(null);
		setPercentageBondsUpdate(null);
		setPercentageEquitiessUpdate(null);
		setStartPointQuizUpdate(null);
		setEndPointQuizUpdate(null);
		setDescriptionUpdate(null);
		setDescriptionPTUpdate(null);
		setStatusUpdate(null);
		setStatusUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
	};

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spinner />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Tipos de Perfis de Investidor</h1>
				</div>
				<div></div>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					align="right"
					shape="round"
					className="btn btn-primary"
					onClick={() => setShowModal(true)}
				>
					+ Novo Tipo de Perfil de Investidor
				</Button>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div className="content">
				<Table
					columns={columns}
					dataSource={allInvestorRiskProfile}
					pagination={false}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>
			{/* <div><pre>{JSON.stringify(allInvestor, null, 2) }</pre></div> */}

			<Modal
				destroyOnClose={true}
				title={
					editable
						? "Editar Tipo Perfil de Investidor"
						: "Novo Tipo Perfil de Investidor"
				}
				open={showModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitInvestor}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Ordenação" name="order">
						<Input type="number" defaultValue={orderUpdate} />
					</Form.Item>
					<Form.Item
						label="Perfil de Investidor EN"
						name="investorRiskProfileName"
					>
						<Input type="text" defaultValue={investorRiskProfileNameUpdate} />
					</Form.Item>
					<Form.Item
						label="Perfil de Investidor PT"
						name="investorRiskProfileNamePT"
					>
						<Input type="text" defaultValue={investorRiskProfileNamePTUpdate} />
					</Form.Item>
					<Form.Item label="Obrigações %" name="percentageBonds">
						<Input type="number" defaultValue={percentageBondsUpdate} />
					</Form.Item>
					<Form.Item label="Ativos %" name="percentageEquities">
						<Input type="number" defaultValue={percentageEquitiesUpdate} />
					</Form.Item>
					<Form.Item label="Pontos Iniciais" name="startPointQuiz">
						<Input type="text" defaultValue={startPointQuizUpdate} />
					</Form.Item>
					<Form.Item label="Pontos Finais" name="endPointQuiz">
						<Input type="text" defaultValue={endPointQuizUpdate} />
					</Form.Item>
					<Form.Item label="Descrição" name="description">
						<TextArea
							showCount
							maxLength={250}
							style={{
								height: 100,
								marginBottom: 24,
							}}
							type="text"
							defaultValue={descriptionUpdate}
						/>
					</Form.Item>
					<Form.Item label="Descrição PT" name="descriptionPT">
						<TextArea
							showCount
							maxLength={250}
							style={{
								height: 100,
								marginBottom: 24,
							}}
							type="text"
							defaultValue={descriptionPTUpdate}
						/>
					</Form.Item>
					<Form.Item label="Estado" name="status" valuePropName="checked">
						<Switch defaultChecked={statusUpdate} />
					</Form.Item>

					<button
						type="submit"
						style={{
							width: "100%",
							background: "green",
							color: "white",
							marginTop: 30,
							marginBottom: 30,
						}}
						className="btn btn-success"
						onClick={handleClose}
					>
						{" "}
						GRAVAR
					</button>
				</Form>
			</Modal>
		</Layout>
	);
};

export default InvestorRiskProfiles;
