import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Spin,
	Card,
	Typography,
	Row,
	Col,
	Tooltip,
	Button,
	Divider,
} from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Layout/Navbar";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path

import {
	User,
	DollarSign,
	Clock,
	Settings,
	HelpCircle,
	Info,
	Bell,
	ChevronRight,
	LifeBuoy,
	FileText,
	Lock,
	UserCheck,
	Activity,
	Mail,
	Phone,
	ShieldAlert,
	Shield,
	AlertCircle,
} from "lucide-react"; // Importing icons from Lucide
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const MySettings = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user.profileType;
	const user_currency = user.currency;

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const [allSavingStats, setAllSavingStats] = useState([]);

	const [loading, setLoading] = useState(false);

	const [activeTab, setActiveTab] = useState("profile");

	const [form] = Form.useForm();

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	const logoutHandler = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	const handleBack = () => {
		navigate("/");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	// Grouped admin settings items
	const categories = [
		{
			title: "Personal Profile",
			items: [
				{
					tooltip: "Account Details",
					icon: <User size={24} color="white" />,
					action: () => navigate("/MyAccountDetails"),
				},
				{
					tooltip: "Documentation",
					icon: <FileText size={24} color="white" />,
					// action: () => navigate("/Documentation"),
					disabled: true, // Mark the button as disabled
				},
			],
		},
		{
			title: "Security",
			items: [
				{
					tooltip: "Password",
					icon: <Lock size={24} color="white" />,
					action: () => navigate("/ResetPassword"),
				},
				{
					tooltip: "Account Level",
					icon: <UserCheck size={24} color="white" />,
					action: () => navigate("/MyAccountLevel"),
				},
				{
					tooltip: "Account Activity",
					icon: <Activity size={24} color="white" />,
					// action: () => navigate('/Documentation'),
					disabled: true, // Mark the button as disabled
				},
			],
		},
		{
			title: "Support",
			items: [
				{
					tooltip: "Email",
					icon: <Mail size={24} color="white" />,
					action: () => navigate("/MyContactEmail"),
				},
				{
					tooltip: "Phone Scheduler",
					icon: <Phone size={24} color="white" />,
					action: () => navigate("/MyContacts"),
				},
			],
		},
		{
			title: "Legal",
			items: [
				{
					tooltip: "Terms of Use",
					icon: <FileText size={24} color="white" />,
					action: () => navigate("/TermsService"),
				},
				{
					tooltip: "Privacy Policy",
					icon: <Shield size={24} color="white" />,
					action: () => navigate("/PrivacyPolicies"),
				},
				{
					tooltip: "Disclaimer",
					icon: <AlertCircle size={24} color="white" />,
					action: () => navigate("/Disclaimer"),
				},
			],
		},
		{
			title: "Others",
			items: [
				{
					tooltip: "History",
					icon: <Clock size={24} color="white" />,
					action: () => navigate("/MyHistory"),
					// disabled: true, // Mark the button as disabled
				},
				// {
				// 	tooltip: "Fees & Limits",
				// 	icon: <DollarSign size={24} color="white" />,
				// 	// action: () => navigate('/'),
				// 	disabled: true, // Mark the button as disabled
				// },
				{
					tooltip: "Help Center",
					icon: <HelpCircle size={24} color="white" />,
					// action: () => navigate('/ResetPassword'),
					disabled: true, // Mark the button as disabled
				},
				{
					tooltip: "Notifications",
					icon: <Bell size={24} color="white" />,
					// action: () => navigate('/ResetPassword'),
					disabled: true, // Mark the button as disabled
				},
				{
					tooltip: "Version",
					icon: <Info size={24} color="white" />,
					action: () => navigate("/MyAppInfo"),
				},
			],
		},
	];

	// MENU
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		if (typeof action === "function") {
			action(); // Execute the action callback
		} else {
			console.error("Invalid action: action is not a function.");
		}
	};

	return (
		<div className="container" style={{ height: "75vh" }}>
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>

				<section>
					<Card style={{ marginBottom: "10px" }}>
						{categories.map((category) => (
							<div key={category.title}>
								{/* Title for the category */}
								<Divider>
									<Title level={4} style={{ margin: 0 }}>
										{category.title}
									</Title>
								</Divider>
								<Row
									gutter={[16, 16]}
									justify="start"
									style={{ textAlign: "center" }}
								>
									{category.items.map((item, index) => (
										<Col xs={6} sm={6} md={6} key={index}>
											<Tooltip title={item.tooltip}>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<Button
														icon={item.icon}
														shape="square"
														style={{
															width: "60px",
															height: "60px",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															padding: 0,
															opacity: item.disabled ? 0.5 : 1, // Reduce opacity for disabled state
															cursor: item.disabled ? "not-allowed" : "pointer", // Change cursor to not-allowed
														}}
														className="TemplateButtonSquare"
														onClick={
															item.disabled
																? null
																: () => handleClick(item.action)
														} // Disable click action
													/>
													{/* Label below the button */}
													<span
														style={{
															marginTop: "8px",
															fontSize: "12px",
															color: "#555",
														}}
													>
														{item.tooltip}
													</span>
												</div>
											</Tooltip>
										</Col>
									))}
								</Row>
							</div>
						))}
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleSettingsClick={handleSettingsClick}
						handleAdmin={handleAdmin}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default MySettings;
