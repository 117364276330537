import React, { useState, useEffect, useMemo } from "react";
import {
	Layout,
	Input,
	Select,
	Modal,
	Table,
	Switch,
	Spin,
	Button,
	Typography,
	Card,
	Row,
	Col,
} from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import {
	SearchOutlined,
	CheckCircleFilled,
	StopOutlined,
	EditOutlined,
	EyeOutlined,
} from "@ant-design/icons";

const Contractuals = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { Title } = Typography;
	const { Option } = Select;

	const [loading, setLoading] = useState(false);

	const [allContratuals, setAllContratuals] = useState([]);

	const formatDate = (monthYearNumber) => {
		const year = monthYearNumber.toString().slice(0, 4); // Extract the year
		const month = monthYearNumber.toString().slice(4, 6); // Extract the month
		return `${year}-${month}`;
	};

	// Format currency
	const formatCurrency = (value) => `$${value.toFixed(2)}`;

	const formatEuroCurrency = (value) => `€${value.toFixed(2)}`;

	// Define Columns
	const feeColumns = [
		{
			key: "rowNumber",
			title: "#",
			dataIndex: "rowNumber",
			align: "center",
			render: (_, __, index) => <span>{index + 1}</span>,
		},
		{
			key: "createdAt",
			title: "Data Criação",
			dataIndex: "createdAt",
			defaultSortOrder: "descend",
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		},
		{
			key: "_id",
			title: "ID Contracto",
			dataIndex: "_id",
		},
		// {
		// 	key: "updatedAt",
		// 	title: "Última Atualização",
		// 	dataIndex: "updatedAt",
		// 	// defaultSortOrder: 'descend',
		// 	align: "center",
		// 	render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
		// 	sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
		// },
		{
			title: "Nome Completo",
			key: "fullName",
			render: (_, record) =>
				`${record.userid.firstName} ${record.userid.lastName}`,
		},

		{
			title: "Saving ID",
			dataIndex: "savingProcessId",
			key: "savingProcessId",
		},
		{
			title: "Saving",
			dataIndex: "savingName",
			key: "savingName",
		},
		{
			title: "Nome do Produto",
			dataIndex: ["productName", "productName"],
			key: "productName",
		},
		{
			title: "Broker",
			dataIndex: ["exchangeName", "brokerName"],
			key: "exchanbrokerNamegeName",
		},
		{
			title: "Nome da Exchange",
			dataIndex: ["exchangeName", "investmentName"],
			key: "investmentName",
		},
		{
			title: "Payment Authorization",
			dataIndex: "isPaymentAuthorization",
			key: "isPaymentAuthorization",
			align: "center",
			render: (text) => (
				<span style={{ color: text ? "#39A939" : "#FF3234" }}>
					{text ? "Yes" : "No"}
				</span>
			),
		},
		{
			title: "Investor Profile Accepted",
			dataIndex: "acceptInvestorProfile",
			key: "acceptInvestorProfile",
			align: "center",
			render: (text) => (
				<span style={{ color: text ? "#39A939" : "#FF3234" }}>
					{text ? "Yes" : "No"}
				</span>
			),
		},
		{
			title: "Contract Conditions Accepted",
			dataIndex: "acceptContractConditions",
			key: "acceptContractConditions",
			align: "center",
			render: (text) => (
				<span style={{ color: text ? "#39A939" : "#FF3234" }}>
					{text ? "Yes" : "No"}
				</span>
			),
		},
		// {
		// 	title: "Status Portfolio",
		// 	dataIndex: "statusPortfolio",
		// 	key: "statusPortfolio",
		// 	render: (text) => (
		// 		<span style={{ color: text ? "#39A939" : "#FF3234" }}>
		// 			{text ? "Yes" : "No"}
		// 		</span>
		// 	),
		// },
		{
			key: "closePortfolio",
			title: "Close Portfolio",
			dataIndex: "closePortfolio",
			align: "center",
			render: (value, record) => {
				if (record.closePortfolio === true)
					return <span style={{ color: "#FF3234" }}>Yes</span>;

				return <span style={{ color: "#39A939" }}>No</span>;
			},
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.closePortfolio === value;
			},
		},
	];

	// Calculate statistics
	const totalContratuals = allContratuals.length;

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingUserContratualDocuments/get-allSavingUserContratualDocument",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllContratuals(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Users');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spin />}
			<div style={{ padding: "20px" }}>
				<Title level={3}>My Contractuals</Title>

				{/* Statistic Cards - Responsive Design */}
				<Row
					gutter={[16, 16]}
					justify="center"
					style={{ marginBottom: "20px" }}
				>
					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Total Contractuals
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{totalContratuals}
							</p>
						</Card>
					</Col>
				</Row>
				<div className="accountpagebuttonsAll">
					<Button
						shape="round"
						className="btn btn-primary btn-block"
						style={{ background: "green", borderColor: "green" }}
						onClick={() => navigate("/")}
					>
						Voltar
					</Button>
				</div>
				<hr></hr>

				{/* Table */}
				<Card>
					<div className="content">
						<Table
							columns={feeColumns}
							dataSource={allContratuals}
							pagination={{ pageSize: 15 }}
							bordered
							rowKey="key"
							scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
							responsive={true} // Makes the table responsive
							className="custom-table" // Apply the custom class here
						/>
					</div>
				</Card>
			</div>
		</Layout>
	);
};

export default Contractuals;
