import React,{useState,useEffect} from 'react';
import {Card, Typography, Row, Col, Switch,Tooltip,Progress,Layout,Tabs} from 'antd';
import Navbar from "./../components/Layout/Navbar";
import { format, subDays } from 'date-fns';

// import Layout from "./../components/Layout/Layout";
import MyPlus from "./../components/Assets/plus-sign.png";

import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";


import  Chart  from "react-apexcharts";

const today = new Date()

const HomePage = () => {

  const performanceData = {
    '7d': Array.from({ length: 7 }, (_, i) => ({
      date: format(subDays(today, i), 'yyyy-MM-dd'),
      value: Math.random() * 1000 + 90000, // Simulated data
    })),
    '1mo': Array.from({ length: 30 }, (_, i) => ({
      date: format(subDays(today, i), 'yyyy-MM-dd'),
      value: Math.random() * 1000 + 90000,
    })),
    '3mo': Array.from({ length: 90 }, (_, i) => ({
      date: format(subDays(today, i), 'yyyy-MM-dd'),
      value: Math.random() * 1000 + 70000,
    })),
    '1yr': Array.from({ length: 365 }, (_, i) => ({
      date: format(subDays(today, i), 'yyyy-MM-dd'),
      value: Math.random() * 1000 + 60000,
    })),
    'allTime': Array.from({ length: 730 }, (_, i) => ({
      date: format(subDays(today, i), 'yyyy-MM-dd'),
      value: Math.random() * 1000 + 60000,
    })),
  };

  const [selectedPeriod, setSelectedPeriod] = useState('1mo');

  const chartDataGraph = performanceData[selectedPeriod].map((data) => ({
    x: data.date,
    y: data.value,
  }));

  // Calculate cumulative return
  const initialValue = chartDataGraph[0].y;
  const finalValue = chartDataGraph[chartDataGraph.length - 1].y;
  const cumulativeReturn = ((finalValue - initialValue) / initialValue) * 100;

  // Calculate max and min values
  const maxValue = Math.max(...chartDataGraph.map(data => data.y));
  const minValue = Math.min(...chartDataGraph.map(data => data.y));

  const options = {
    chart: {
      id: 'crypto-performance',
      toolbar: { show: false },
      type: 'area',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value.toLocaleString()}`,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.4,
    },
    tooltip: {
      x: {
        formatter: (value) => new Date(value).toLocaleDateString(),
      },
    },
    annotations: {
      yaxis: [
        {
          y: finalValue,
          borderColor: '#00E396',
          label: {
            text: `Cumulative Return: ${cumulativeReturn.toFixed(2)}%`,
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
        {
          y: maxValue,
          borderColor: '#FF4560',
          label: {
            text: `Max: $${maxValue.toLocaleString()}`,
            style: {
              color: '#fff',
              background: '#FF4560',
            },
          },
        },
        {
          y: minValue,
          borderColor: '#775DD0',
          label: {
            text: `Min: $${minValue.toLocaleString()}`,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;
  const { Title, Text } = Typography;

  //loading Apis
  const [loading,setLoading] = useState(false);

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user_agentName = loginUser.agentName
  // console.log("user_agent:",user_agent)
  const profileType = loginUser.profileType
  const user_currency = loginUser.currency

  const handleNewSaving = () => {
    navigate('/NewSavings');
  };

  //***********************************************************************************************************/
  const [currentBalance, setCurrentBalance] = useState(1500); // in USD
  const [invested, setInvested] = useState(1000); // in USD
  const [returns, setReturns] = useState(-500); // Change this value to see different colors
  const [cash, setCash] = useState(200); // in USD
  const [dailyReturn, setDailyReturn] = useState(100); // in USD

  // State to store the exchange rate with default USD and EUR values
  const [exchangeRate, setExchangeRate] = useState({ usd: 1, eur: 1 });
  const [isVisible, setIsVisible] = useState(false); 
  const [showDailyReturns, setShowDailyReturns] = useState(false);
  const [isUSD, setIsUSD] = useState(true);

  // Fetch exchange rates from CoinGecko API
  const fetchExchangeRate = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=usd,eur&vs_currencies=eur');
      const data = await response.json();

      console.log("Fetched data:", data); // Debugging to check API response structure

      // Check if data structure contains expected properties
      if (data.usd && data.usd.eur) {
        setExchangeRate({
          usd: data.usd.eur, // USD to EUR conversion rate
          eur: 1,            // Base currency EUR
        });
      } else {
        console.error("Unexpected data structure:", data);
      }
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  // Call the fetch function when the component mounts
  useEffect(() => {
    fetchExchangeRate();
  }, []);

  // Toggle visibility handler
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Toggle return type handler
  const toggleReturnType = (checked) => {
    setShowDailyReturns(checked);
  };

  // Toggle currency type handler
  const toggleCurrency = (checked) => {
    setIsUSD(checked);
  };

  // // Function to convert values based on the currency type
  // const convertToCurrency = (amount) => {
  //   return isUSD ? amount.toFixed(2) : (amount * exchangeRate.usd).toFixed(2);
  // };

  const convertToCurrency = (amount) => {
    const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: isUSD ? 'USD' : 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(convertedAmount);
  };

  // Calculate the return percentage
  const returnPercentage = ((returns / invested) * 100).toFixed(2);
  const dailyReturnPercentage = ((dailyReturn / invested) * 100).toFixed(2);

  //***********************************************************************************************************/
  const portfolio = [
    {
      id: 'bitcoin',
      symbol: 'Carteira Garantia 2024',
      name: 'Hedge Inflation',
      image: 'https://e7.pngegg.com/pngimages/429/120/png-clipart-retirement-others-child-food-thumbnail.png',
      price_change_percentage_24h: 4.818,
      current_price: 1051.62,
      market_cap: 200.37,
      target: 5000, // Target money for Bitcoin
      page: '/MySavings' // URL for Bitcoin
    },
    {
      id: 'ethereum',
      symbol: 'Carteira Garantia 2024',
      name: 'Hedge Inflation',
      image: 'https://e7.pngegg.com/pngimages/429/120/png-clipart-retirement-others-child-food-thumbnail.png',
      price_change_percentage_24h: 4.818,
      current_price: 2200.37,
      market_cap: 300.12,
      target: 8000, // Target money for Ethereum
      page: '/MySavings' // URL for Ethereum
    },
    {
      id: 'ripple',
      symbol: 'Carteira Garantia 2024',
      name: 'Hedge Inflation',
      image: 'https://e7.pngegg.com/pngimages/429/120/png-clipart-retirement-others-child-food-thumbnail.png',
      price_change_percentage_24h: 3.45,
      current_price: 1.22,
      market_cap: 50.25,
      target: 100, // Target money for Ripple
      page: '/MySavings' // URL for Ripple
    }
  ];
  
  //***************************RENDER**************************************************************************/

  const returnColor = returns > 0 ? 'green' : 'red';
  const dailyReturnColor = dailyReturn > 0 ? 'green' : 'red';

  //***************************RENDER**************************************************************************/
  return (
    
    <Layout>

      <Navbar />

      {loading && <Spinner/>}


    {/* Cliente */}
    {profileType === 0 && (
    // <div style={{ padding: '10px'}}>
    <div>
      {/* Portfolio Card */}
      <div style={{ 
        backgroundColor: '#f0f2f5', // Outer background color
        padding: '10px',            // Padding around the Card for outer color visibility
        borderRadius: '12px'        // Rounded corners for the outer border
      }}>
        <Card 
          style={{ 
            width: '100%', 
            borderRadius: 8, 
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)', 
            backgroundColor: 'white', // Card inside background color
            padding: '10px', 
          }}
        >
          <Row justify="space-between" align="middle" style={{ paddingBottom: '10px' }}>
            <Title level={4} style={{ color: '#333' }}>Total Market Value</Title>
            <Switch 
              checked={isUSD} 
              onChange={toggleCurrency} 
              checkedChildren="USD" 
              unCheckedChildren="EUR" 
              style={{ marginLeft: 'auto' }}
            />
          </Row>

          <Row justify="space-between" align="middle" style={{ marginTop: 5, paddingBottom: '10px' }}>
            <Col style={{ textAlign: 'center', cursor: 'pointer' }} onClick={toggleVisibility}>
              <h1 style={{ color: '#333', margin: 0 }}>
                {isVisible ? `${convertToCurrency(currentBalance)}` : <span style={{ fontSize: '16px', color: '#999' }}>Tap to see balance</span>}
              </h1>
            </Col>
          </Row>

          <Row justify="space-between" style={{ margin: '10px 0', marginTop: '20px', paddingBottom: '10px' }}>
            <Col style={{ textAlign: 'center' }}>
              <Text strong style={{ color: '#333' }}>Net contributions</Text>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <Text strong style={{ color: '#333' }}>Return</Text>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <Text strong style={{ color: '#333' }}>Cash Available</Text>
            </Col>
          </Row>

          <Row justify="space-between" style={{ paddingBottom: '10px' }}>
            <Col style={{ textAlign: 'left' }}>
              <Text style={{ color: '#333', fontWeight: 'bold' }}>{isVisible ? `${convertToCurrency(invested)}` : <span style={{ color: '#999' }}>****</span>}</Text>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Text style={{ color: isVisible ? (showDailyReturns ? dailyReturnColor : returnColor) : '#999', fontWeight: 'bold' }}>
                {isVisible ? 
                  showDailyReturns ? 
                    `${convertToCurrency(dailyReturn)} (${dailyReturnPercentage}%)` : 
                    `${convertToCurrency(returns)} (${returnPercentage}%)` 
                : 
                '****'
                }
              </Text>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Text style={{ color: '#333', fontWeight: 'bold' }}>
                {isVisible ? `${convertToCurrency(cash)}` : <span style={{ color: '#999' }}>****</span>}
              </Text>
            </Col>
          </Row>

          <Row justify="center" style={{ margin: '10px 0', marginTop: '20px', paddingTop: '10px' }}>
            <Text style={{ color: '#333', marginRight: '8px' }}>Show Daily Returns</Text>
            <Switch 
              checked={showDailyReturns} 
              onChange={toggleReturnType} 
              checkedChildren="Yes" 
              unCheckedChildren="No" 
            />
          </Row>
        </Card>
      </div>

    {/* Chart Section */}
    <div style={{ padding: '10px'}}>
    <div style={{
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Box shadow for chart container
    backgroundColor: '#ffffff',                 // White background for chart container
    padding: '10px',                            // Padding for inner spacing
  }}>
    <Chart
        options={{
          ...options,
          chart: {
            background: '#ffffff', // Set background color to white
          },
          fill: {
            type: 'solid',
            colors: ['#ffffff'], // Optional: make sure the fill color of the area is not transparent
          },
          // Optionally, you may also want to set the background of the grid to white
          grid: {
            show: true,
            background: '#ffffff',
          },
        }}
        series={[{ name: 'Portfolio Value', data: chartDataGraph }]}
        type="area"
        height={350}
      />
    </div>
    
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ffffff', padding: '10px', borderRadius: '8px', marginTop: '20px' }}>
        <Tabs
          defaultActiveKey="7d"
          onChange={(key) => setSelectedPeriod(key)}
          items={[
            { label: <span style={{ fontSize: '10px' }}>7 Days</span>, key: '7d' },
            { label: <span style={{ fontSize: '10px' }}>1 Month</span>, key: '1mo' },
            { label: <span style={{ fontSize: '10px' }}>3 Months</span>, key: '3mo' },
            { label: <span style={{ fontSize: '10px' }}>1 Year</span>, key: '1yr' },
            { label: <span style={{ fontSize: '10px' }}>Max</span>, key: 'allTime' },
          ]}
        />
      </div>

    </div>

    {/* Mapping through portfolioData to create cards */}
    {portfolio.map(portfolio => {
      const progressPercentage = Math.min((portfolio.current_price / portfolio.target) * 100, 100).toFixed(2);

      return (
        <Card 
            key={portfolio.id}
            hoverable
            bordered={false} 
            className="coin-card-content"
            onClick={() => navigate(`/MySavings`)} // Navigate to the target route
            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
          >
            <Meta
              title={
                <div className="coin-info" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt={portfolio.name} src={portfolio.image} className="coin-logo" />
                    <div className="coin-details" style={{ marginLeft: 10 }}>
                      <span className="coin-symbol">{portfolio.symbol.toUpperCase()}</span>
                      <span className="coin-name">{portfolio.name}</span>
                    </div>
                  </div>

                  <div style={{ textAlign: 'right' }}>
                    <p className="current-price" style={{ margin: 0 }}>
                      ${portfolio.current_price.toLocaleString()}
                    </p>
                    <p className={portfolio.price_change_percentage_24h >= 0 ? "market-cap-positive" : "market-cap-negative"} style={{ margin: 0, fontSize: '10px' }}>
                      ${portfolio.market_cap.toLocaleString()} ({portfolio.price_change_percentage_24h.toFixed(2)}%)
                    </p>
                  </div>
                </div>
              }
            />
            <Progress
              percent={parseFloat(progressPercentage)}
              style={{ marginTop: '10px' }}
              showInfo={true}
              strokeColor={parseFloat(progressPercentage) >= 100 ? '#52c41a' : '#7600EC'}
              status={parseFloat(progressPercentage) >= 100 ? 'success' : 'active'}
              format={() => <span style={{ fontSize: '10px' }}>{progressPercentage}%</span>}
            />
          </Card>
      );
    })}
      <Card 
            key={portfolio.id}
            hoverable
            bordered={false} 
            className="coin-card-content"
            onClick={handleNewSaving}
            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer', marginBottom: '20px' }}
          >
            <Meta
              title={
                <div className="coin-info" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={MyPlus} className="coin-logo" />
                    <div className="coin-details" style={{ marginLeft: 10 }}>
                      <span className="coin-symbol">NEW CRYPTO BASKET</span>
                    </div>
                  </div>
                </div>
              }
            />
          </Card>
      {/* </Tooltip> */}

      </div>
    )}
    
    </Layout>
    
  );
};

export default HomePage;