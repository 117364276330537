import React from 'react';
import Layout from "../components/Layout/Layout";
import {
  User,
  Users,
  UserCheck,
  Tag,
  Shield,
  BarChart,
  Bitcoin,
  DollarSign,
  Box,
  LineChart,
  AlertTriangle,
  Cpu,
  TrendingUp,
  ChevronRight,
} from 'lucide-react'; // Importing icons from Lucide
import { Menu } from 'antd';

// Define menu items with key, icon, and label
const items = [
  {
    key: '1',
    icon: <User />,
    label: 'Users',
  },
  {
    key: '2',
    icon: <Users />,
    label: 'Agents',
  },
  {
    key: '3',
    icon: <UserCheck />,
    label: 'User Agents',
  },
  {
    key: '4',
    icon: <Tag />,
    label: 'Categories',
  },
  {
    key: '5',
    icon: <Shield />,
    label: 'Risk Profile',
  },
  {
    key: '6',
    icon: <TrendingUp />,
    label: 'Asset Class',
  },
  {
    key: '7',
    icon: <Bitcoin />,
    label: 'Cryptocurrencies',
  },
  {
    key: '8',
    icon: <DollarSign />,
    label: 'Exchange Currencies',
  },
  {
    key: '9',
    icon: <Box />,
    label: 'Products',
  },
  {
    key: '10',
    icon: <LineChart />,
    label: 'Exchanges Quote',
  },
  {
    key: '11',
    icon: <LineChart />,
    label: 'Cryptocurrencies Quote',
  },
  {
    key: '12',
    icon: <AlertTriangle />,
    label: 'Cryptocurrency Risk',
  },
  {
    key: '13',
    icon: <Cpu />,
    label: 'AI Smart-Advisor Cryptocurrencies',
  },
];

const App = () => {
  // Function to handle menu item clicks
  const handleMenuClick = (e) => {
    const urlMapping = {
      '1': '/Users',
      '2': '/MyAgents',
      '3': '/UsersAgents',
      '4': '/Categories',
      '5': '/InvestorRiskProfiles',
      '6': '/CategoryAssets',
      '7': '/CryptoAssets',
      '8': '/CurrencyAssets',
      '9': '/MyProducts',
      '10': '/CurrencyRates',
      '11': '/CryptoPrices',
      '12': '/CryptoRisks',
      '13': '/CryptoSmarts', // Assuming a URL for the last item
    };

    const selectedUrl = urlMapping[e.key];
    if (selectedUrl) {
      window.location.href = selectedUrl; // Redirecting to the mapped URL
    }
  };

  return (
    <Layout>
    <Menu
      onClick={handleMenuClick}
      mode="vertical"
      items={items.map(item => ({
        key: item.key,
        icon: item.icon,
        label: item.label
      }))}
    />
    </Layout>
  );
};

export default App;
