import React, { useState } from 'react';
import Navbar from "./Navbar";
import Header from "./Header";
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";

// import {
//   LogoutOutlined,
//   SettingOutlined,
//   WalletOutlined,
//   UserOutlined,
//   HomeOutlined,
//   StockOutlined,
//   MailOutlined,
//   UnderlineOutlined,
//   UnorderedListOutlined,
//   PhoneOutlined,
//   VideoCameraOutlined,
//   EuroCircleOutlined,
//   EllipsisOutlined,
// } from '@ant-design/icons';

import { Layout, Menu, theme, message } from 'antd';

const { Sider, Content } = Layout;

const LayoutSide = ({ children}) => {

  let now=('v.2024.10.1');

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const user = JSON.parse(localStorage.getItem('user'))
  const profileType = user.profileType
  console.log("profileType:",profileType)

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const DashboardHandler = () => {
    navigate('/');
  }

  const ExplorerSettingsHandler = () => {
    navigate('/ExplorerSettings');
  }

  const MySavingsHandler = () => {
    navigate('/MySavings');
  }

  const MySavingsAdminHandler = () => {
    navigate('/MySavingsAdmin');
  }

  const MySavingsAgentHandler = () => {
    navigate('/MySavingsAgent');
  }

  const MyInvestingHandler = () => {
    navigate('/MyInvestings');
  }

  const MySettingsHandler = () => {
    navigate('/MySettings');
  }
  
  const MyClientHandler = () => {
    navigate('/MyClients');
  }

  const MyClientsAgentHandler = () => {
    navigate('/MyClientsAgent');
  }

  const OrderTransactionsHandler = () => {
    navigate('/OrderTransactions');
  }

  const FinanceLiteracyHandler = () => {
    navigate('/FinanceLiteracy');
  }
  
  const MyContactsHandler = () => {
    navigate('/MyContacts');
  }

  const MyEmailsHandler = () => {
    navigate('/MyEmails');
  }
  
  const MyContactsAgentHandler = () => {
    navigate('/MyContactsAgent');
  }

  const MyContactsAdminHandler = () => {
    navigate('/MyContactsAdmin');
  }

  const MyInvestmentsAdminHandler = () => {
    navigate('/MyInvestments');
  }
  
  const logoutHandler = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('investorProfile');
    message.success("Logout Successfully");
    navigate('/login');
  }

  return (
    <Layout
    style={{
      minHeight: '100%',
    }}
    >
      {/* <Sider
        theme="light"
        width={80} // Adjust the width here
        collapsible 
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
      <Menu
            theme="light"
            // defaultSelectedKeys={['1']}
            // mode="inline"
          >
          <Menu.Item key="1" icon={<HomeOutlined/>} onClick={DashboardHandler} style={{ marginTop:'20px'}}>
                Home
          </Menu.Item>
          {profileType === 0 && (
            <hr></hr>
          )}
          {profileType === 0 && (
          <Menu.Item key="10" icon={<MailOutlined/>} onClick={MyEmailsHandler}>
                Mailbox
          </Menu.Item>
          )}
          <hr></hr>
          {profileType === 9 && (
          <Menu.Item key="29" icon={<UserOutlined/>} onClick={MyClientHandler}>
                Clients
          </Menu.Item>
          )}
          {profileType === 1 && (
          <Menu.Item key="21" icon={<UserOutlined/>} onClick={MyClientsAgentHandler}>
                Clients
          </Menu.Item>
          )}
          {profileType === 9 && (
          <Menu.Item key="39" icon={<WalletOutlined/>} onClick={MySavingsAdminHandler}>
                RoboAdvisor
          </Menu.Item>
          )}
          {profileType === 1 && (
          <Menu.Item key="31" icon={<WalletOutlined/>} onClick={MySavingsAgentHandler}>
                RoboAdvisor
          </Menu.Item>
          )}
          {profileType === 9 && (
          <Menu.Item key="5" icon={<UnorderedListOutlined/>} onClick={OrderTransactionsHandler} >
                Orders
          </Menu.Item>
          )}
          <Menu.Item key="6" icon={<EuroCircleOutlined/>} onClick={FinanceLiteracyHandler}>
          Financial Literacy
          </Menu.Item>
          {profileType === 0 && (
          <Menu.Item key="70" icon={<PhoneOutlined/>} onClick={MyContactsHandler}>
            Contacts
          </Menu.Item>
          )}
          {profileType === 1 && (
          <Menu.Item key="71" icon={<PhoneOutlined/>} onClick={MyContactsAgentHandler}>
            Contacts
          </Menu.Item>
          )}
          {profileType === 9 && (
          <Menu.Item key="79" icon={<PhoneOutlined/>} onClick={MyContactsAdminHandler}>
            Contacts
          </Menu.Item>
          )}
          <Menu.Item key="8" icon={<EllipsisOutlined/>} onClick={MySettingsHandler}>
            More
          </Menu.Item>
          {profileType === 9 && (
            <hr></hr>
          )}
          {profileType === 9 && (
          <Menu.Item key="1300" icon={<SettingOutlined/>} onClick={ExplorerSettingsHandler}>
                Settings
          </Menu.Item>
          )}
          <hr></hr>
          <Menu.Item key="99999" icon={<LogoutOutlined/>} onClick={logoutHandler}>
                Logout
          </Menu.Item>
      </Menu>
      </Sider> */}
      <Layout>
        {/* <Header style={{ padding: 0, background: colorBgContainer }}>
          <Header />
        </Header> */}
        <Navbar style={{ padding: 0, background: colorBgContainer }}>
          <Navbar />
        </Navbar>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 3000,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
        {/* <Footer>
          <Footer />
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default LayoutSide;