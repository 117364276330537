import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Tabs,
	Layout,
	Input,
	Modal,
	Select,
	Table,
	Switch,
	Button,
	Space,
	Tooltip,
	Tag,
	DatePicker,
	Card,
	Spin,
	Col,
	Typography,
} from "antd";

import moment from "moment";

import axios from "axios";
import {
	EditOutlined,
	SearchOutlined,
	CheckCircleFilled,
	ExclamationCircleFilled,
	DeleteOutlined,
	ArrowUpOutlined,
	ArrowLeftOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { TabPane } = Tabs;

const MyHistory = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [allTransactions, setAllTransactions] = useState([]);
	const [allWithdrawnTransactions, setAllWithdrawnTransactions] = useState([]);
	const [allFees, setAllFees] = useState([]);

	// Columns for each table, including the new "Basket" column for deposits and withdrawals
	const depositColumns = [
		{
			key: "createdAt",
			title: "Transaction Date",
			dataIndex: "createdAt",
			defaultSortOrder: "descend",
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD  HH:mm")}</span>,
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		},
		{
			key: "savingName",
			title: "Saving Name",
			dataIndex: "savingName",
			sorter: (a, b) => a.savingName.localeCompare(b.savingName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.savingName.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "productName",
			title: "Product",
			dataIndex: ["productName", "productName"],
			sorter: (a, b) =>
				a.productName.productName.localeCompare(b.productName.productName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.productName.productName
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "reforceOrWithdrawAction",
			title: "",
			dataIndex: "reforceOrWithdrawAction",
			align: "center",
			render: (text, record) => {
				if (record.reforcePortfolio === true) {
					return <span style={{ color: "Green" }}>Reforce</span>;
				}
				if (record.withdrawPortfolio === true) {
					return <span style={{ color: "#FF3234" }}>Withdraw</span>;
				}
				return <span style={{ color: "#39A939" }}>Deposit</span>;
			},
			filters: [
				{ text: "Reforce", value: "Reforce" },
				{ text: "Withdraw", value: "Withdraw" },
				{ text: "Deposit", value: "Deposit" },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				if (value === "Reforce") {
					return record.reforcePortfolio === true;
				}
				if (value === "Withdraw") {
					return record.withdrawPortfolio === true;
				}
				if (value === "Deposit") {
					return !record.reforcePortfolio && !record.withdrawPortfolio;
				}
				return false;
			},
		},
		//column amount
		{
			key: "amount",
			title: "Transaction Amount",
			dataIndex: "amount",
			align: "right",
			sorter: (a, b) => a.amount - b.amount,
			render: (amount, record) => {
				if (record.isReforceOrWithdraw === true)
					return (
						<span style={{ color: "#39A939" }}>
							{parseFloat(amount).toLocaleString("de-DE", {
								style: "currency",
								currency: "USD",
								maximumFractionDigits: 2,
							})}
						</span>
					);
				return (
					<span style={{ color: "#FF3234" }}>
						{parseFloat(amount).toLocaleString("de-DE", {
							style: "currency",
							currency: "USD",
							maximumFractionDigits: 2,
						})}
					</span>
				);
			},
		},
		{
			key: "closePortfolio",
			title: "Close Portfolio",
			dataIndex: "closePortfolio",
			align: "center",
			render: (text, record) => {
				if (record.closePortfolio === true) {
					return <span style={{ color: "#39A939" }}>Yes</span>;
				}
				if (record.closePortfolio === false) {
					return <span style={{ color: "#FF3234" }}>No</span>;
				}
			},
			filters: [
				{ text: "Close", value: "Close" },
				{ text: "Open", value: "Open" },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				if (value === "Close") {
					return record.closePortfolio === true;
				}
				if (value === "Open") {
					return record.closePortfolio === false;
				}
				return false;
			},
		},
	];

	const withdrawalColumns = [
		{
			key: "createdAt",
			title: "Transaction Date",
			dataIndex: "createdAt",
			defaultSortOrder: "descend",
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD  HH:mm")}</span>,
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
		},
		{
			key: "savingName",
			title: "Saving Name",
			dataIndex: "savingName",
			sorter: (a, b) => a.savingName.localeCompare(b.savingName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.savingName.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "productName",
			title: "Product",
			dataIndex: ["productName", "productName"],
			sorter: (a, b) =>
				a.productName.productName.localeCompare(b.productName.productName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.productName.productName
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		//column amount
		{
			key: "amount",
			title: "Transaction Amount",
			dataIndex: "amount",
			align: "right",
			sorter: (a, b) => a.amount - b.amount,
			render: (amount, record) => {
				if (record.isReforceOrWithdraw === true)
					return (
						<span style={{ color: "#39A939" }}>
							{parseFloat(amount).toLocaleString("de-DE", {
								style: "currency",
								currency: "USD",
								maximumFractionDigits: 2,
							})}
						</span>
					);
				return (
					<span style={{ color: "#FF3234" }}>
						{parseFloat(amount).toLocaleString("de-DE", {
							style: "currency",
							currency: "USD",
							maximumFractionDigits: 2,
						})}
					</span>
				);
			},
		},
		{
			key: "closePortfolio",
			title: "Close Portfolio",
			dataIndex: "closePortfolio",
			align: "center",
			render: (text, record) => {
				if (record.closePortfolio === true) {
					return <span style={{ color: "#39A939" }}>Yes</span>;
				}
				if (record.closePortfolio === false) {
					return <span style={{ color: "#FF3234" }}>No</span>;
				}
			},
			filters: [
				{ text: "Close", value: "Close" },
				{ text: "Open", value: "Open" },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				if (value === "Close") {
					return record.closePortfolio === true;
				}
				if (value === "Open") {
					return record.closePortfolio === false;
				}
				return false;
			},
		},
	];

	const formatDate = (monthYearNumber) => {
		const year = monthYearNumber.toString().slice(0, 4); // Extract the year
		const month = monthYearNumber.toString().slice(4, 6); // Extract the month
		return `${year}-${month}`;
	};

	// Format currency
	const formatCurrency = (value) => `$${value.toFixed(2)}`;

	const formatEuroCurrency = (value) => `€${value.toFixed(2)}`;

	const feeColumns = [
		{
			title: "Date Invoice",
			dataIndex: "monthYearNumber",
			key: "monthYearNumber",
			render: (value) => formatDate(value),
		},
		{ title: "Description", dataIndex: "description", key: "description" },
		{
			title: "Today Value ($)",
			dataIndex: "today_value",
			key: "todayValue",
			render: (value) => formatCurrency(value),
		},
		{
			title: "Today Value (€)",
			dataIndex: "today_value_ctr",
			key: "today_value_ctr",
			render: (value) => formatEuroCurrency(value),
		},
		{ title: "Account Level", dataIndex: "feeCategory", key: "feeCategory" },
		{
			title: "Fee (€)",
			dataIndex: "feeMonthly",
			key: "feeMonthly",
			render: (value) => formatEuroCurrency(value),
		},
		{
			key: "isPaid",
			title: "isPaid",
			dataIndex: ["userid", "isPaid"],
			render: (value, record) => {
				if (record.isPaid === true)
					return <span style={{ color: "#39A939" }}>Yes</span>;

				return <span style={{ color: "#FF3234" }}>No</span>;
			},
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.isPaid === value;
			},
		},
	];

	useEffect(() => {
		const getAllTransactions = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingtransactions/get-savingtransaction",
					{
						userid: user._id,
						// frequency,
						// selectedDate,
						isReforceOrWithdraw: true, //BUY
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllTransactions(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllTransactions();

		// // Clean up the interval when the component unmounts
		// return () => getAllTransactions();
	}, []);

	const handleBack = () => {
		navigate("/MySettings");
	};

	useEffect(() => {
		const getAllWithdrawnTransactions = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingtransactions/get-savingtransaction",
					{
						userid: user._id,
						// frequency,
						// selectedDate,
						isReforceOrWithdraw: false, //BUY
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllWithdrawnTransactions(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllWithdrawnTransactions();

		// // Clean up the interval when the component unmounts
		// return () => getAllTransactions();
	}, []);

	useEffect(() => {
		const getAllFees = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/invoices/get-invoice",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllFees(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllFees();

		// // Clean up the interval when the component unmounts
		// return () => getAllTransactions();
	}, []);

	return (
		<div className="container" style={{ height: "75vh" }}>
			{loading && <Spin />}

			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card style={{ marginTop: "16px", width: "100%" }}>
					<Title level={3}>Transaction Details</Title>
					<br></br>
					<Tabs defaultActiveKey="1">
						<TabPane tab="Deposits" key="1">
							<Table
								columns={depositColumns}
								dataSource={allTransactions}
								pagination={{
									position: ["bottomCenter"],
									defaultPageSize: 15,
									showSizeChanger: true,
									pageSizeOptions: ["10", "15", "25"],
								}}
								scroll={{ x: "max-content" }}
								className="custom-table" // Apply custom CSS class for styling
							/>
						</TabPane>

						<TabPane tab="Withdrawals" key="2">
							<Table
								columns={withdrawalColumns}
								dataSource={allWithdrawnTransactions}
								pagination={{
									position: ["bottomCenter"],
									defaultPageSize: 15,
									showSizeChanger: true,
									pageSizeOptions: ["10", "15", "25"],
								}}
								scroll={{ x: "max-content" }}
								className="custom-table" // Apply custom CSS class for styling
							/>
						</TabPane>

						<TabPane tab="Fees" key="3">
							<Table
								columns={feeColumns}
								dataSource={allFees}
								pagination={{
									position: ["bottomCenter"],
									defaultPageSize: 12,
									showSizeChanger: true,
									pageSizeOptions: ["12", "15", "25"],
								}}
								scroll={{ x: "max-content" }}
								className="custom-table" // Apply custom CSS class for styling
							/>
						</TabPane>
					</Tabs>
				</Card>
			</div>
		</div>
	);
};

export default MyHistory;
