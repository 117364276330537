import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography, Layout } from "antd";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";

const PrivacyPolicies = () => {
	const { Title, Paragraph } = Typography;
	const { Content } = Layout;

	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<section>
					<Card
						style={{
							maxWidth: "100%",
							width: "100%",
							boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
							borderRadius: 8,
							backgroundColor: "#ffffff",
						}}
					>
						<Typography>
							<Title
								level={2}
								style={{
									textAlign: "left",
									marginBottom: "24px",
									color: "#333",
								}}
							>
								Privacy Policy
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								Company, Lda built the Bit-Advisor app as a Freemium app. This
								SERVICE is provided by Company, Lda at no cost and is intended
								for use as is.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								This page is used to inform visitors regarding our policies with
								the collection, use, and disclosure of Personal Information if
								anyone decided to use our Service. If you choose to use our
								Service, then you agree to the collection and use of information
								in relation to this policy. The Personal Information that we
								collect is used for providing and improving the Service. We will
								not use or share your information with anyone except as
								described in this Privacy Policy.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Information Collection and Use
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								For a better experience, while using our Service, we may require
								you to provide us with certain personally identifiable
								information. The information that we request will be retained by
								us and used as described in this privacy policy.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								The app does use third-party services that may collect
								information used to identify you. Link to the privacy policies
								of third-party service providers used by the app:
							</Paragraph>
							<ul style={{ paddingLeft: "20px" }}>
								<li>
									<a
										href="https://policies.google.com/privacy"
										target="_blank"
										rel="noopener noreferrer"
									>
										Google Analytics
									</a>
								</li>
							</ul>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Log Data
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								We want to inform you that whenever you use our Service, in a
								case of an error in the app we collect data and information
								(through third-party products) on your phone called Log Data.
								This Log Data may include information such as your device
								Internet Protocol (“IP”) address, device name, operating system
								version, the configuration of the app when utilizing our
								Service, the time and date of your use of the Service, and other
								statistics.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Cookies
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								Cookies are files with a small amount of data that are commonly
								used as anonymous unique identifiers. These are sent to your
								browser from the websites that you visit and are stored on your
								device's internal memory. This Service does not use these
								“cookies” explicitly. However, the app may use third-party code
								and libraries that use “cookies” to collect information and
								improve their services.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Service Providers
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								We may employ third-party companies and individuals to
								facilitate our Service, provide the Service on our behalf,
								perform Service-related services, or assist us in analyzing how
								our Service is used. These third parties have access to your
								Personal Information to perform the tasks assigned to them but
								are obligated not to disclose or use the information for any
								other purpose.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Security
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								We value your trust in providing us your Personal Information,
								thus we are striving to use commercially acceptable means of
								protecting it. But remember that no method of transmission over
								the internet, or method of electronic storage is 100% secure and
								reliable, and we cannot guarantee its absolute security.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Changes to This Privacy Policy
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								We may update our Privacy Policy from time to time. Thus, you
								are advised to review this page periodically for any changes. We
								will notify you of any changes by posting the new Privacy Policy
								on this page.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Contact Us
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								If you have any questions or suggestions about our Privacy
								Policy, do not hesitate to contact us at{" "}
								<a href="mailto:support@company-lda.pt">
									support@company-lda.pt
								</a>
							</Paragraph>
						</Typography>
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default PrivacyPolicies;
