import React, { useState, useEffect } from "react";
import {
	Card,
	Spin,
	Form,
	Button,
	Space,
	message,
	Radio,
	Row,
	Col,
	Progress,
} from "antd";
import Layout from "../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ArrowLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";

const InvestorProfiles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	//loading Apis
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const [current, setCurrent] = useState(0);
	const [oneDetails, setOneDetails] = useState(null);
	const [twoDetails, setTwoDetails] = useState(null);
	const [threeDetails, setThreeDetails] = useState(null);
	const [fourDetails, setFourDetails] = useState(null);
	const [fiveDetails, setFiveDetails] = useState(null);
	const [sixDetails, setSixDetails] = useState(null);
	const [sevenDetails, setSevenDetails] = useState(null);
	const [eightDetails, setEightDetails] = useState(null);
	const [nineDetails, setNineDetails] = useState(null);
	const [tenDetails, setTenDetails] = useState(null);

	const [resultDetails, setResultDetails] = useState(null);

	const [resultSelects, setResultSelects] = useState({
		selectPortfolio: "0-0",
	});

	const onFinishOneForm = (values) => {
		// setOneDetails(values);
		setCurrent(1);
	};

	const onFinishTwoForm = (values) => {
		// setTwoDetails(values);
		setCurrent(2);
	};

	const onFinishThreeForm = (values) => {
		// setTwoDetails(values);
		setCurrent(3);
	};

	const onFinishFourForm = (values) => {
		// setOneDetails(values);
		setCurrent(4);
	};

	const onFinishFiveForm = (values) => {
		// setTwoDetails(values);
		setCurrent(5);
	};

	const onFinishSixForm = (values) => {
		// setTwoDetails(values);
		setCurrent(6);
	};

	const onFinishSevenForm = (values) => {
		// setOneDetails(values);
		setCurrent(7);
	};

	const onFinishEightForm = (values) => {
		// setTwoDetails(values);
		setCurrent(8);
	};

	const onFinishNineForm = (values) => {
		// setTwoDetails(values);
		setCurrent(9);
	};

	const onFinishTenForm = (values) => {
		// setTwoDetails(values);
		setCurrent(10);
	};

	const onFinishSucessForm = (values) => {
		// setLoginDetails(values);
		setCurrent(11);
	};

	const forms = [
		<OneForm onFinish={onFinishOneForm} initialValues={oneDetails} />,
		<TwoForm onFinish={onFinishTwoForm} initialValues={twoDetails} />,
		<ThreeForm onFinish={onFinishThreeForm} initialValues={threeDetails} />,
		<FourForm onFinish={onFinishFourForm} initialValues={fourDetails} />,
		<FiveForm onFinish={onFinishFiveForm} initialValues={fiveDetails} />,
		<SixForm onFinish={onFinishSixForm} initialValues={sixDetails} />,
		<SevenForm onFinish={onFinishSevenForm} initialValues={sevenDetails} />,
		<EightForm onFinish={onFinishEightForm} initialValues={eightDetails} />,
		<NineForm onFinish={onFinishNineForm} initialValues={nineDetails} />,
		<TenForm onFinish={onFinishTenForm} initialValues={tenDetails} />,
		<FinishForm onFinish={onFinishSucessForm} />,
	];

	const isStepDisabled = (stepnumber) => {
		if (stepnumber === 0) {
			return false;
		}
		if (stepnumber === 1) {
			return oneDetails === null;
		}
		if (stepnumber === 2) {
			return oneDetails === null || twoDetails === null;
		}
		if (stepnumber === 3) {
			return (
				oneDetails === null || twoDetails === null || threeDetails === null
			);
		}
		if (stepnumber === 4) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null
			);
		}
		if (stepnumber === 5) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null
			);
		}
		if (stepnumber === 6) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null ||
				sixDetails === null
			);
		}
		if (stepnumber === 7) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null ||
				sixDetails === null ||
				sevenDetails === null ||
				eightDetails === null
			);
		}
		if (stepnumber === 8) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null ||
				sixDetails === null ||
				sevenDetails === null ||
				eightDetails === null ||
				nineDetails === null
			);
		}
		if (stepnumber === 9) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null ||
				sixDetails === null ||
				sevenDetails === null ||
				eightDetails === null ||
				nineDetails === null ||
				tenDetails === null
			);
		}
		if (stepnumber === 10) {
			return (
				oneDetails === null ||
				twoDetails === null ||
				threeDetails === null ||
				fourDetails === null ||
				fiveDetails === null ||
				sixDetails === null ||
				sevenDetails === null ||
				eightDetails === null ||
				nineDetails === null ||
				tenDetails === null
			);
		}
		// if(stepnumber===11){
		//   return oneDetails === null || twoDetails === null
		// }
	};

	const user = JSON.parse(localStorage.getItem("user"));

	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const submitHandler = async (values) => {
		let questionPoints1 = parseFloat(oneDetails.split("|")[0]);
		let questionPoints2 = parseFloat(twoDetails.split("|")[0]);
		let questionPoints3 = parseFloat(threeDetails.split("|")[0]);
		let questionPoints4 = parseFloat(fourDetails.split("|")[0]);
		let questionPoints5 = parseFloat(fiveDetails.split("|")[0]);
		let questionPoints6 = parseFloat(sixDetails.split("|")[0]);
		let questionPoints7 = parseFloat(sevenDetails.split("|")[0]);
		let questionPoints8 = parseFloat(eightDetails.split("|")[0]);
		let questionPoints9 = parseFloat(nineDetails.split("|")[0]);
		let questionPoints10 = parseFloat(tenDetails.split("|")[0]);

		let results =
			questionPoints1 +
			questionPoints2 +
			questionPoints3 +
			questionPoints4 +
			questionPoints5 +
			questionPoints6 +
			questionPoints7 +
			questionPoints8 +
			questionPoints9 +
			questionPoints10;

		// console.log(resultSelects)

		if (results <= 10) {
			setResultSelects(
				(resultSelects) => (resultSelects.selectPortfolio = "Defensive")
			);
		}
		if (results >= 11 && results <= 30) {
			setResultSelects(
				(resultSelects) => (resultSelects.selectPortfolio = "Conservative")
			);
		}
		if (results >= 31 && results <= 114) {
			setResultSelects(
				(resultSelects) => (resultSelects.selectPortfolio = "Moderate")
			);
		}
		if (results >= 115 && results <= 135) {
			setResultSelects(
				(resultSelects) => (resultSelects.selectPortfolio = "Aggressive")
			);
		}
		if (results >= 136 && results <= 150) {
			setResultSelects(
				(resultSelects) => (resultSelects.selectPortfolio = "Very Aggressive")
			);
		}

		try {
			setLoading(true);
			await axios.post(
				"/investorProfiles/add-investorProfile",
				{
					userid: user._id,
					question1: oneDetails.split("|")[1],
					question2: twoDetails.split("|")[1],
					question3: threeDetails.split("|")[1],
					question4: fourDetails.split("|")[1],
					question5: fiveDetails.split("|")[1],
					question6: sixDetails.split("|")[1],
					question7: sevenDetails.split("|")[1],
					question8: eightDetails.split("|")[1],
					question9: nineDetails.split("|")[1],
					question10: tenDetails.split("|")[1],
					results: results,
					selectPortfolio: resultSelects.selectPortfolio,
					status: true,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = await axios.post(
				"/users/edit-user",
				{
					payload: {
						investorProfile: resultSelects.selectPortfolio,
						newUser: false,
						haveInvestorProfile: true,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			// Ensure the user object exists
			if (user) {
				// Update the newUser property
				user.newUser = false;
				user.investorProfile = resultSelects.selectPortfolio;
				user.haveInvestorProfile = true;

				// Save the updated user object back to localStorage
				localStorage.setItem("user", JSON.stringify(user));
			} else {
				console.error("No user object found in localStorage.");
			}

			message.success("Investor Profile Successfull");
			setLoading(false);
			navigate("/InvestorProfilesDetail", {
				state: {
					resultSelects: resultSelects,
				},
			});
		} catch (error) {
			setLoading(false);
			message.error("Something went wrong");
		}
	};

	//Handler Test Console Log Values
	// const onFinish = () => {
	//   console.log({
	//   });
	// };

	const onChange = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setOneDetails(value);
		setCurrent(1);
	};

	const onChange1 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setTwoDetails(value);
		setCurrent(2);
	};

	const onChange2 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setThreeDetails(value);
		setCurrent(3);
	};

	const onChange3 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setFourDetails(value);
		setCurrent(4);
	};

	const onChange4 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setFiveDetails(value);
		setCurrent(5);
	};

	const onChange5 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setSixDetails(value);
		setCurrent(6);
	};

	const onChange6 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setSevenDetails(value);
		setCurrent(7);
	};

	const onChange7 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setEightDetails(value);
		setCurrent(8);
	};

	const onChange8 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setNineDetails(value);
		setCurrent(9);
	};

	const onChange9 = ({ target: { value } }) => {
		// console.log('radio1 checked', value);
		setTenDetails(value);
		setCurrent(10);
	};

	// //Handler Test Console Log Values
	// const onFinishForm = (values) => {
	// 	let questionPoints1 = parseFloat(oneDetails.split("|")[0]);
	// 	let questionPoints2 = parseFloat(twoDetails.split("|")[0]);
	// 	let questionPoints3 = parseFloat(threeDetails.split("|")[0]);
	// 	let questionPoints4 = parseFloat(fourDetails.split("|")[0]);
	// 	let questionPoints5 = parseFloat(fiveDetails.split("|")[0]);
	// 	let questionPoints6 = parseFloat(sixDetails.split("|")[0]);
	// 	let questionPoints7 = parseFloat(sevenDetails.split("|")[0]);
	// 	let questionPoints8 = parseFloat(eightDetails.split("|")[0]);
	// 	let questionPoints9 = parseFloat(nineDetails.split("|")[0]);
	// 	let questionPoints10 = parseFloat(tenDetails.split("|")[0]);

	// 	let results =
	// 		questionPoints1 +
	// 		questionPoints2 +
	// 		questionPoints3 +
	// 		questionPoints4 +
	// 		questionPoints5 +
	// 		questionPoints6 +
	// 		questionPoints7 +
	// 		questionPoints8 +
	// 		questionPoints9 +
	// 		questionPoints10;

	// 	setResultDetails(results);

	// 	// console.log({
	// 	//   "userid": user._id,
	// 	//   "questionPoints1":oneDetails.split('|')[0],
	// 	//   "question1":oneDetails.split('|')[1],
	// 	//   "questionPoints2":twoDetails.split('|')[0],
	// 	//   "question2":twoDetails.split('|')[1],
	// 	//   "questionPoints3":threeDetails.split('|')[0],
	// 	//   "question3":threeDetails.split('|')[1],
	// 	//   "questionPoints4":fourDetails.split('|')[0],
	// 	//   "question4":fourDetails.split('|')[1],
	// 	//   "questionPoints5":fiveDetails.split('|')[0],
	// 	//   "question5":fiveDetails.split('|')[1],
	// 	//   "questionPoints6":sixDetails.split('|')[0],
	// 	//   "question6":sixDetails.split('|')[1],
	// 	//   "questionPoints7":sevenDetails.split('|')[0],
	// 	//   "question7":sevenDetails.split('|')[1],
	// 	//   "questionPoints8":eightDetails.split('|')[0],
	// 	//   "question8":eightDetails.split('|')[1],
	// 	//   "questionPoints9":nineDetails.split('|')[0],
	// 	//   "question9":nineDetails.split('|')[1],
	// 	//   "questionPoints10":tenDetails.split('|')[0],
	// 	//   "question10":tenDetails.split('|')[1],
	// 	//   "results": results,
	// 	//   "status": true
	// 	// });
	// };

	function OneForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Personal and Financial Situation</h2>
				<hr />
				<br />
				<h4 align="center">1. How old are you?</h4>
				<br />
				<h6 align="center">
					In general, there is less need for older investors to take on
					significant risky investments than there is for younger investors.
					Although other circumstances, such as wealth, may contradict this
					assumption, advancing age generally reduces risk tolerance.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={oneDetails}
						onChange={onChange}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="8|30 years or less">30 years or less</Radio>
							<Radio value="6|31 to 40">31 to 40</Radio>
							<Radio value="4|41 to 50">41 to 50</Radio>
							<Radio value="2|51 to 65">51 to 65</Radio>
							<Radio value="0|Over 65 years old">Over 65 years old</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function TwoForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Personal and Financial Situation</h2>
				<hr />
				<br />
				<h4 align="center">
					2. What is your annual household income (before taxes)?
				</h4>
				<br />
				<h6 align="center">
					Your financial advisor must understand your financial situation to
					provide you with a personalized investment strategy that reflects your
					current situation and allows you to achieve your financial goals.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={twoDetails}
						onChange={onChange1}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Less than 40.000">Less than 40.000</Radio>
							<Radio value="4|40.001 to 75.000">40.001 to 75.000</Radio>
							<Radio value="9|75.001 to 125.000">75.001 to 125.000</Radio>
							<Radio value="14|125.001 to 200.000">125.001 to 200.000</Radio>
							<Radio value="17|More than 200.000">More than 200.000</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function ThreeForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Personal and Financial Situation</h2>
				<hr />
				<br />
				<h4 align="center">
					3. How much does your household have in invested assets (total assets
					minus your home, business and personal property)?
				</h4>
				<br />
				<h6 align="center">
					Greater wealth and investable assets typically imply greater
					“financial” risk tolerance. However, some wealthy investors may have a
					low tolerance for “psychological” risk.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={threeDetails}
						onChange={onChange2}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Less than 35.000">Less than 35.000</Radio>
							<Radio value="4|35.001 to 75.000">35.001 to 75.000</Radio>
							<Radio value="9|75.001 to 150.000">75.001 to 150.000</Radio>
							<Radio value="14|150.001 to 300.000">150.001 to 300.000</Radio>
							<Radio value="17|More than 300.000">More than 300.000</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function FourForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment objectives and risk tolerance</h2>
				<hr />
				<br />
				<h4 align="center">4. What is your main investment objective?</h4>
				<br />
				<h6 align="center">
					Determining your investment objective allows your financial advisor to
					select an appropriate asset allocation to maximize your portfolio's
					return and minimize your risk.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={fourDetails}
						onChange={onChange3}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Preserve capital">Preserve capital</Radio>
							<Radio value="4|Generate maximum returns with modest asset growth">
								Generate maximum returns with modest asset growth
							</Radio>
							<Radio value="9|Alcançar crescimento e rendimentos moderados">
								Achieve moderate growth and income
							</Radio>
							<Radio value="14|Achieve strong asset growth with modest returns">
								Achieve strong asset growth with modest returns
							</Radio>
							<Radio value="17|Alcance o máximo crescimento de activos">
								Achieve maximum asset growth
							</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function FiveForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment objectives and risk tolerance</h2>
				<hr />
				<br />
				<h4 align="center">
					5. How long do you plan to invest in funds before you start
					withdrawing a substantial portion of your portfolio (e.g. a withdrawal
					of more than half)?
				</h4>
				<br />
				<h6 align="center">
					If you plan to invest for less than three years, you may experience a
					declining part of the market cycle without the opportunity to take
					advantage of long-term market trends.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={fiveDetails}
						onChange={onChange4}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|1 to 3 years">1 to 3 years</Radio>
							<Radio value="5|4 to 5 years">4 to 5 years</Radio>
							<Radio value="11|6 to 10 years">6 to 10 years</Radio>
							<Radio value="16|11 to 15 years">11 to 15 years</Radio>
							<Radio value="25|More than 15 years">More than 15 years</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function SixForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment objectives and risk tolerance</h2>
				<hr />
				<br />
				<h4 align="center">
					6. Given your financial objectives, how much volatility (risk) are you
					willing to assume to achieve your portfolio objectives? expected
					return?
				</h4>
				<br />
				<h6 align="center">
					Any well-diversified portfolio is subject to some volatility, where
					the portfolio will likely have negative returns in certain years.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={sixDetails}
						onChange={onChange5}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Low volatility as it requires positive returns every year">
								Low volatility as it requires positive returns every year
							</Radio>
							<Radio value="5|Low to medium volatility, such as negative returns every 8 years">
								Low to medium volatility, such as negative returns every 8 years
							</Radio>
							<Radio value="11|Average volatility, such as negative returns every 6 years">
								Average volatility, such as negative returns every 6 years
							</Radio>
							<Radio value="16|Medium to high volatility, such as negative returns every 5 years">
								Medium to high volatility, such as negative returns every 5
								years
							</Radio>
							<Radio value="25|High volatility, such as negative returns every 4 years">
								High volatility, such as negative returns every 4 years
							</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function SevenForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment objectives and risk tolerance</h2>
				<hr />
				<br />
				<h4 align="center">
					7. How much temporary negative return on your investment portfolio
					could you tolerate over a period of one year?
				</h4>
				<br />
				<h6 align="center">
					Your portfolio will likely have a negative return in certain years. A
					portfolio with higher volatility will likely have higher negative
					returns in any given year. You will need to be “psychological” and
					“financial” risk tolerant to withstand these negative returns.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={sevenDetails}
						onChange={onChange6}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|0%">0%</Radio>
							<Radio value="4|-5%">-5%</Radio>
							<Radio value="9|-10%">-10%</Radio>
							<Radio value="14|-15%">-15%</Radio>
							<Radio value="17|More than -15%">More than -15%</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function EightForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment knowledge and experience</h2>
				<hr />
				<br />
				<h4 align="center">
					8. What do you expect the overall average annual return on your
					investment portfolio to be over the long term (10+ years), before
					taxes but after inflation?
				</h4>
				<br />
				<h6 align="center">
					Your financial advisor can help you align your return expectations
					with your risk tolerance. Ideally, you should focus on the long-term
					returns of your investment portfolio after inflation (i.e. the real
					rate of return).
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={eightDetails}
						onChange={onChange7}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|0% to 2%">0% to 2%</Radio>
							<Radio value="2|1% to 3%">1% to 3%</Radio>
							<Radio value="4|4% to 7%">4% to 7%</Radio>
							<Radio value="6|5% to 9%">5% to 9%</Radio>
							<Radio value="8|More than 9%">More than 9%</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function NineForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment knowledge and experience</h2>
				<hr />
				<br />
				<h4 align="center">
					9. Which statement best describes your level of knowledge about
					investing in financial markets and products?
				</h4>
				<br />
				<h6 align="center">
					Knowing your level of investment knowledge helps your financial
					advisor communicate with you at an appropriate level. Additionally,
					your level of knowledge helps your advisor assess how much background
					information is needed when discussing investment strategies and
					products.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={nineDetails}
						onChange={onChange8}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Very limited knowledge">
								Very limited knowledge
							</Radio>
							<Radio value="2|Basic knowledge and minimum experience">
								Basic knowledge and minimum experience
							</Radio>
							<Radio value="4|Good knowledge and some investment experience">
								Good knowledge and some investment experience
							</Radio>
							<Radio value="6|Strong knowledge and experience">
								Strong knowledge and experience
							</Radio>
							<Radio value="8|Advanced knowledge and extensive experience">
								Advanced knowledge and extensive experience
							</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function TenForm({ onFinish, initialValues }) {
		return (
			<Form
				style={{ width: "60%" }}
				name="radio-group"
				label="Radio.Group"
				onFinish={onFinish}
				initialValues={initialValues}
			>
				<h2 align="center">Investment knowledge and experience</h2>
				<hr />
				<br />
				<h4 align="center">
					Which of the following statements best describes your current
					investment portfolio situation?
				</h4>
				<br />
				<h6 align="center">
					Information about your past investment experience helps your financial
					advisor evaluate your investment knowledge and attitude toward
					investment risk.
				</h6>
				<br />
				<Form.Item>
					<Radio.Group
						value={tenDetails}
						onChange={onChange9}
						onFinish={onFinish}
						initialValues={initialValues}
					>
						<Space direction="vertical">
							<Radio value="0|Mainly money market securities (e.g. cash)">
								Mainly money market securities (e.g. cash)
							</Radio>
							<Radio value="2|Mainly fixed income securities (e.g. government and/or corporate bonds, savings certificates, term deposits and treasury bills)">
								Mainly fixed income securities (e.g. government and/or corporate
								bonds, savings certificates, term deposits and treasury bills)
							</Radio>
							<Radio value="4|Equal amounts of fixed income securities and shares">
								Equal amounts of fixed income securities and shares
							</Radio>
							<Radio value="6|Mainly Portuguese and international blue-chip shares">
								Mainly Portuguese and international blue-chip shares
							</Radio>
							<Radio value="8|Mainly aggressive national and international titles">
								Mainly aggressive national and international titles
							</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Button style={{ width: "100%" }} type="primary" htmlType="submit">
					Continue
				</Button>
			</Form>
		);
	}

	function FinishForm() {
		return (
			<div style={{ padding: "50px", textAlign: "center" }}>
				<Space direction="vertical" size="large" style={{ width: "100%" }}>
					<h2>Congratulations!</h2>
					<Button
						style={{
							width: "100%",
							whiteSpace: "normal",
							padding: "0px",
							fontSize: "10px",
						}}
						type="primary"
						onClick={submitHandler}
					>
						Next you will see your Investor Profile!
					</Button>
				</Space>
			</div>
		);
	}

	//***************************RENDER**************************************************************************/
	// Calculate progress based on the current step
	const totalSteps = 11; // Assuming you have 9 steps
	const progressPercent = ((current + 1) / totalSteps) * 100;

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<div className="App-Investor">
						<Row gutter={[0, 8]} justify="center">
							<Col span={24}>
								<Progress
									percent={progressPercent}
									showInfo={false}
									style={{ marginBottom: "10px" }}
								/>
							</Col>
						</Row>

						{/* Step indicator */}
						<Row gutter={[0, 8]} justify="center">
							<Col
								span={24}
								style={{ textAlign: "center", marginBottom: "50px" }}
							>
								<span style={{ fontSize: "16px", fontWeight: "500" }}>
									Step {current + 1}/{totalSteps}
								</span>
							</Col>
						</Row>
						{/* <Steps 
        style={{ padding: "32px 16px"}}
        onChange={setCurrent}
        current={current}
        >
        <Steps.Step disabled={isStepDisabled(0)} title="" icon={1} />
        <Steps.Step disabled={isStepDisabled(1)} title="" icon={2} />
        <Steps.Step disabled={isStepDisabled(2)} title="" icon={3} />
        <Steps.Step disabled={isStepDisabled(3)} title="" icon={4} />
        <Steps.Step disabled={isStepDisabled(4)} title="" icon={5} />
        <Steps.Step disabled={isStepDisabled(5)} title="" icon={6} />
        <Steps.Step disabled={isStepDisabled(6)} title="" icon={7} />
        <Steps.Step disabled={isStepDisabled(7)} title="" icon={8} />
        <Steps.Step disabled={isStepDisabled(8)} title="" icon={9} />
        <Steps.Step disabled={isStepDisabled(9)} title="" icon={10} />
        <Steps.Step disabled={isStepDisabled(10)} title="End of Investor Questionnaire" icon={<CheckCircleOutlined />} />
      </Steps> */}
					</div>
					<div className="registerInvestorProfile-page">{forms[current]}</div>
				</Card>
			</div>
		</div>
	);
};

export default InvestorProfiles;
