import React,{useState,useEffect} from "react";
import {Tabs, Form, Input, Col, Space ,Card,Row, message, Button, Select} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import moment from 'moment';
import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";

const Version = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [allSavingStats, setAllSavingStats] = useState([]);

  const [loading,setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('profile');

  const [form] = Form.useForm();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // Assuming contains the URL
  const openPopup = (url) => {
    const popup = window.open(url, 'popup', 'width=600,height=600');
    if (popup) {
      popup.focus();
      // console.log(url);
    }
  };

  return (
    <Layout>
      {loading && <Spinner/>}

      <div>

        <Form 
            layout="vertical"
            name="aboutForm" 
            // onFinish={handleSubmitEmail}
            >
            <Form.Item
              label={
                <div>
                  <h4>VERSION</h4>
                </div>
              }
              name="version"
            >
            </Form.Item>

            <Row gutter={[8, 24]} style={{ justifyContent: "left" }}>
            <Card
              // key={}
              size="small"
              hoverable
              style={{ width: 450, marginRight: "10px" }}
              cover={[
                <div align="center">
                  <br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ textAlign: "left", marginLeft:"20px" }}>
                        <h5>Version<br></br><b>2024.10.1</b></h5>
                      </div>
                    </div>
                    <hr></hr>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ textAlign: "left", marginLeft:"20px" }}>
                        <h5>Last update<br></br><b>2024-10-26</b></h5>
                      </div>
                    </div>
                </div>
              ]}
              >
              <br />
            </Card>
            </Row>

            <Row gutter={[8, 24]} style={{ justifyContent: "left", marginTop: "20px" }}>
              <Card
              style={{ width: 450, marginRight: "10px" }}
              >
                <Row justify="space-between" align="middle">
                  <Space align="horizontal">
                  <Col>
                    <h6>General Conditions of Use</h6>
                  </Col>
                  <Col> 
                      <FilePdfOutlined 
                      style={{ fontSize: '24px', color: '#1890ff'}}
                      // onClick={() => openPopup('www.imga.pt')} 
                      />
                  </Col>
                  </Space>
                </Row>
              </Card>
            </Row>

            <Row gutter={[8, 24]} style={{ justifyContent: "left" }}>
              <Card
              style={{ width: 450, marginRight: "10px" }}
              >
                <Row justify="space-between" align="middle">
                  <Space align="horizontal">
                  <Col>
                    <h6>Privacy and Personal Data Protection Policy</h6>
                  </Col>
                  <Col>
                      <FilePdfOutlined 
                      style={{ fontSize: '24px', color: '#1890ff' }}
                      // onClick={() => openPopup('www.imga.pt')} 
                      />
                  </Col>
                  </Space>
                </Row>
              </Card>
            </Row>

            <Row gutter={[8, 24]} style={{ justifyContent: "left", marginTop: "20px"  }}>
            {/* <p style={{ width: 450, marginRight: "10px"}}>
              <b>BI CAPITAL</b>
              - NIPC e Matrícula 500 000 000, na CRC Lisboa - Sede: Av. do Atlântico Nº 16, 14º Piso, Escritório 8, 1990-019 Lisboa - Portugal - Capital Social € 600 - www.bicapital.pt
            </p> */}
            </Row>
              
          </Form>
    </div>

    </Layout>
  );
};

export default Version;