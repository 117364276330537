import React,{useState,useEffect} from "react";
import {Progress} from 'antd';
import {useNavigate,useLocation} from "react-router-dom";
import axios from 'axios';
import Spinner from "../components/Spinner";

import Layout from "../components/Layout/Layout";

import MyProcessing from "./../components/Assets/engineering.png";

const ProcessingRefreshAllSavings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const getSavingRefreshProcessing = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);

        const res = axios.post('/savings/refresh-all-saving/',{
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        )
        // console.log(res.data);
        ;

        setLoading(false);
        
      }catch (error){
        setLoading(false);

      }
    };  
  
    // Clean up the interval when the component unmounts
    return () => getSavingRefreshProcessing();

  },[])

  useEffect(() => {
    const duration = 120 * 1000; // 15 seconds in milliseconds
    const interval = 100; // Update the progress every 100 milliseconds

    const steps = duration / interval;
    let currentStep = 0;

    const updateProgress = () => {
      currentStep += 1;
      const newProgress = (currentStep / steps) * 100;
      setProgress(newProgress);

      if (currentStep < steps) {
        // Continue updating the progress
        setTimeout(updateProgress, interval);
      }else {
        // Navigate to another page after the progress is complete
        navigate('/MySavingsAdmin');
      }
    };

    // Start updating the progress
    updateProgress();

    // Clean up the interval when the component unmounts
    return () => clearTimeout(updateProgress);
  }, [navigate]);

  return (
    <Layout>
      {loading && <Spinner/>}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
        <img alt="example" src={MyProcessing} />
        <br />
        <h1 align="center">Processing All Portfolios</h1>
        <br />
        <Progress
          type="circle"
          percent={parseFloat(progress).toFixed(0)} 
          status="active"
          style={{ fontSize: '40px' }}
          />
        <br />
        <p style={{ textAlign: 'center' }}>Please wait Window will close when processing is complete.</p>
      </div>

    </Layout>
  );
};

export default ProcessingRefreshAllSavings;