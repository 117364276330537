import React,{useState,useEffect} from "react";
import {Input,Button,Tooltip,Card, Table} from 'antd';
import MyInvestment from "./../components/Assets/investment.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { CheckCircleFilled, MinusOutlined, PlusOutlined, ReloadOutlined, SafetyCertificateOutlined, SearchOutlined, SettingOutlined, TableOutlined, UnorderedListOutlined } from "@ant-design/icons";

import moment from 'moment';

import  Chart  from "react-apexcharts";

const MyClients = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  // console.log(investor)

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allClients, setAllClients] = useState([]);

  //Fields from Graphs to View
  const [allInvestmentTypeWeights, setAllInvestmentTypeWeights] = useState(null);
  const [allAllInvestmentIsProtectedWeights, setAllInvestmentIsProtectedWeights] = useState({});

  const [allInvestmentStats, setAllInvestmentStats] = useState([]);
  const [allNoInvestmentStats, setAllNoInvestmentStats] = useState([]);
  const [allInvestmentStocksStats, setAllInvestmentStocksStats] = useState([]);
  const [allInvestmentEtfStats, setAllInvestmentEtfStats] = useState([]);
  const [allInvestmentFundFamilyStats, setAllInvestmentFundFamilyStats] = useState([]);

  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

  //Edit Transactions
  const [editable, setEditable] = useState(null);

    //Fields from table to View
    const [accountNameUpdate, setAccountNameUpdate] = useState(null);
    const [accountDescriptionUpdate, setAccountDescriptionUpdate] = useState(null);
    const [accountIBANUpdate, setAccountIBANUpdate] = useState(null);
    const [accountTypeUpdate, setAccountTypeUpdate] = useState(null);
    const [statusUpdate, setStatusUpdate] = useState(null);


      //table users **************************************************************************************************************************
  const columns =[
    {
      key: "confirmed",
      title: "",
      align: 'center',
      render: (text, record) => {
        const today = moment();
        const date = moment(record._id.birthdayDate);
        const isSameMonthAndDay = today.month() === date.month() && today.date() === date.date();
        return isSameMonthAndDay ? (
          <Tooltip title="Dia de Aniversário">
            <CheckCircleFilled className="mx-2" style={{color: "#39A939"}} />
          </Tooltip>
        ) : null;
      },
      filters: [
        { text: 'Aniversário', value: 'aniversario' },
        { text: 'Dia Sem Aniversário', value: 'semAniversario' },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        const today = moment();
        const date = moment(record._id.birthdayDate);
        const isSameMonthAndDay = today.month() === date.month() && today.date() === date.date();
        return value === 'aniversario' ? isSameMonthAndDay : !isSameMonthAndDay;
      },
    },
    {
      key: "createdAt",
      title: "Data Criação Cliente",
      dataIndex: ["_id","createdAt"],
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a._id.createdAt) - new Date(b._id.createdAt)
    },
    {
      key: "fullName",
      title: "Nome Completo",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.fullName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: ["_id","email"],
      align:"center",
      sorter: (a, b) => a._id.email.localeCompare(b._id.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record._id.email.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "birthdayDate",
      title: "Data Aniversário",
      dataIndex: ["_id", "birthdayDate"],
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a._id.birthdayDate) - new Date(b._id.birthdayDate),
    },
    {
      key: "nif",
      title: "NIF",
      dataIndex: ["_id","nif"],
      sorter: (a, b) => a._id.nif-b._id.nif,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record._id.nif.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "investorProfile",
      title: "Perfil de Investidor",
      dataIndex: ["_id","investorProfile"],
      align:"center",
      sorter: (a, b) => a._id.investorProfile.localeCompare(b._id.investorProfile),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record._id.investorProfile.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "distinctSavingNamesCount",
      title: "Número de Cestos",
      dataIndex: "distinctSavingNamesCount",
      align:"center",
      sorter: (a, b) => a.distinctSavingNamesCount - b.distinctSavingNamesCount,
      render: (distinctSavingNamesCount, record) => {
        if(record.distinctSavingNamesCount >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span> {parseFloat(distinctSavingNamesCount).toFixed(0)}</span>
          );
      },
    },
    {
      key: "totalAmount",
      title: "Valor dos Cestos",
      dataIndex: "totalAmount",
      align: "right",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (totalAmount, record) => {
        if(record.totalAmount >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(totalAmount).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
          
          );
      },
    },
    {
      key: "today_value_investment",
      title: "Total Investido",
      dataIndex: "today_value_investment",
      align: "right",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.today_value_investment - b.today_value_investment,
      render: (today_value_investment, record) => {
        if(record.today_value_investment >= 0 )
          return (
          // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
          <span>{(today_value_investment).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
          
          );
      },
    },
    {
      key: "percentagePL",
      title: "Retorno %",
      dataIndex: "percentagePL",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.percentagePL - b.percentagePL,
      render: (percentagePL, record) => {
        const formattedPL = parseFloat(percentagePL * 100).toFixed(2);
        const color = record.percentagePL >= 0 ? 'green' : 'red';
        return (
          <span style={{ color: color }}>{formattedPL} %</span>
        );
      },
    },
    // {
    //   title: "Acções",
    //   dataIndex: "type",
    //   align: "center",
    //   render: (type, record) => {
    //     // if(type === "Income" )
    //     return (
    //       <div className="mx-4">
    //         <Space size="middle">
    //         <Tooltip title="Transacções">
    //         <UnorderedListOutlined
    //           onClick={() => {
    //             console.log(record);
    //             navigate('/SavingUserOrderTransactionsAdmin',
    //             {
    //               state:{
    //                 userID: record.userid._id,
    //                 savingName: record.savingName,
    //                 savingProcessID:  record.savingProcessId            
    //               }
    //             });
    //           }}
    //         />
    //         </Tooltip>
    //         </Space>
    //       </div>
    //     );
    //   },
    // }
  ];

  // const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllUser = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingportfolios/get-allsavingportfolioadmin',
        {
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllClients(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        // message.error('Fetch Issue With Users');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllUser();
  },[])

  //Analytics
  const totalAllClients = allClients
  .reduce((acc, transaction) => acc + transaction.totalAmount, 0);

  const totalAllInvestmentClients = allClients
  .reduce((acc, transaction) => acc + transaction.today_value_investment, 0);

  const PL = (totalAllClients/totalAllInvestmentClients)-1

  const Unrealized = (totalAllClients-totalAllInvestmentClients)

  const totalAllSavings = allClients
  .reduce((acc, transaction) => acc + transaction.distinctSavingNamesCount, 0);

  const numberAllClients = allClients.length;

  // Add a combined name field
  allClients.forEach(item => {
    item.fullName = `${item._id.firstName} ${item._id.lastName}`;
  });

  // const [weightSubject, setWeightSubject]= useState([]);
  // const [weightMarks, setWeightMarks]= useState([]);

  // useEffect(() => {

  //   const sSubject=[];
  //   const sMarks=[];
  //   const getAllinvestmentTypeWeights = async (values) => {
  //     try{
  //     setLoading(true);
  //     const user = JSON.parse(localStorage.getItem('user'))
  //     const res = await axios.post('/investment-transactions/get-userinvestmentTypebalance',
  //     {
  //       userid:user._id,
  //     },
  //     {
  //       headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //     }
  //     );
  //     setLoading(false);
  //     setAllInvestmentTypeWeights(res.data);
  //     console.log(res.data);
  
  //     for(let i=0; i< res.data.length; i++)
  //     {
  //     sSubject.push(res.data[i].investmentType);
  //     sMarks.push(res.data[i].today_value_ctr);
  //     }
  //     setWeightSubject(sSubject);
  //     setWeightMarks(sMarks);
  //     }catch (error){
  //     setLoading(false);
  //     // message.error('Fetch Issue With Sector');
  //     message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllinvestmentTypeWeights();
  //   },[])
  
    // useEffect(() => {
  
    //   const getAllinvestmentIsProtectedWeights = async (values) => {
    //     try{
    //     setLoading(true);
    //     const user = JSON.parse(localStorage.getItem('user'))
    //     const res = await axios.post('/investment-transactions/get-userinvestmentTypebalance',
    //     {
    //       userid:user._id,
    //     },
    //     {
    //       headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //     }
    //     );
    //     setLoading(false);

    //     const groupedData = res.data.reduce((acc, item) => {
    //       const key = item.isProtectedCapital ? true : false;
    //       if (!acc[key]) {
    //           acc[key] = {
    //               today_value_ctr: 0,
    //           };
    //       }
    //       acc[key].today_value_ctr += item.today_value_ctr;
    //       return acc;
    //       }, {});

    //       setAllInvestmentIsProtectedWeights(groupedData);
    //       console.log(groupedData);

    //     }catch (error){
    //     setLoading(false);
    //     // message.error('Fetch Issue With Sector');
    //     message.error('You are not Admin User');
    //     }
    //   };  
    //   getAllinvestmentIsProtectedWeights();
    //   },[])

    // const { true: protectedData, false: notProtectedData } = allAllInvestmentIsProtectedWeights;

    // const todayValueCtrProtected = protectedData?.today_value_ctr != null ? Number(protectedData.today_value_ctr.toFixed(2)) : 0;
    // const todayValueCtrNotProtected = notProtectedData?.today_value_ctr != null ? Number(notProtectedData.today_value_ctr.toFixed(2)) : 0;

    // const seriesProtected = [todayValueCtrProtected, todayValueCtrNotProtected];
  

  return (
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>Meus Clientes</h1></div>
        <div></div>
      </div>

      <div className="accountpage">
          <div><h6>Número de Clientes</h6>
          <p className="asset-info">{numberAllClients}</p>
          </div>
          <div><h6>Número de Cestos</h6>
          <p className="asset-info">{totalAllSavings}</p>
          </div>
        </div>

        <div className="accountpage">
          <div><h6>Valor Investido</h6>
          <p className="asset-info">{(totalAllInvestmentClients).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</p>
          </div>
          <div><h6>Valor do Cesto</h6>
          <p className="asset-info">{(totalAllClients).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</p>
          </div>
          <div><h6>Retorno %</h6>
          <p className="asset-info" style={{ color: PL < 0 ? 'red' : 'green' }}>
            {(PL * 100).toLocaleString('de-DE')}%
          </p>
          </div>
          <div><h6>Retorno {user_currency}</h6>
          <p className="asset-info" style={{ color: PL < 0 ? 'red' : 'green' }}>
            {(Unrealized).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}
          </p>
          </div>
        </div>

      {/* Start Graphs */}
      {/* {weightMarks.length > 0 && seriesProtected.length > 0 && (
      <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
      <div className="col-xs-12 col-md-6">
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={weightMarks}            
          options={{
            responsive: [
              {
                breakpoint: 281,
                options: {
                  chart: {
                    width: '100%',
                    height: 150
                  }
                }
              },
              {
                breakpoint: 361,
                options: {
                  chart: {
                    width: '100%',
                    height: 230
                  }
                }
              },
              {
                breakpoint: 481,
                options: {
                  chart: {
                    width: '100%',
                    height: 250
                  }
                }
              },
              {
                breakpoint: 769,
                options: {
                  chart: {
                    width: '100%',
                    height: 275
                  }
                }
              },
              {
                breakpoint: 768,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 1024,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 2000,
                options: {
                  chart: {
                    width: '100%',
                    height: 325
                  }
                }
              }
            ],
          labels: weightSubject,
          noData: { text: "No Data" }, 
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
  
                  return val;
                }
              }
              }
            }
            }
          },
          dataLabels: { enabled: true },
          legend: { show: false } 
          }}
        />
        </div>

      <h4 align="center">Clientes</h4>
      </div>
      <div className="col-xs-12 col-md-6">
        <div className="chart-container">
        <Chart
          align="center" 
          type="donut"
          width="100%"
          series={seriesProtected}                
          options={{
            responsive: [
              {
                breakpoint: 281,
                options: {
                  chart: {
                    width: '100%',
                    height: 150
                  }
                }
              },
              {
                breakpoint: 361,
                options: {
                  chart: {
                    width: '100%',
                    height: 230
                  }
                }
              },
              {
                breakpoint: 481,
                options: {
                  chart: {
                    width: '100%',
                    height: 250
                  }
                }
              },
              {
                breakpoint: 769,
                options: {
                  chart: {
                    width: '100%',
                    height: 275
                  }
                }
              },
              {
                breakpoint: 768,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 1024,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    width: '100%',
                    height: 300
                  }
                }
              },
              {
                breakpoint: 2000,
                options: {
                  chart: {
                    width: '100%',
                    height: 325
                  }
                }
              }
            ],
          labels: ['Capital Garantido', 'Capital Não Garantido'],
          noData: { text: "No Data" },
          plotOptions: {
            pie: {
            donut: {
              labels: {
              show: true,
              name: {
                formatter: function(val) {
                return val
                }
              }
              }
            }
            }
          },
          dataLabels: { 
            enabled: true,
          },
          legend: { show: false }
          }}
        />
        </div>
        <h4 align="center">Capital Garantido?</h4>
      </div>
      </div>
      )} */}

        {/* <div><pre>{JSON.stringify(allClients, null, 2) }</pre></div> */}

        <hr></hr>
        <div className="content">
          <Table 
          columns={columns}
          dataSource={allClients}
          pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100']}}
          scroll={{ x: 'max-content'}}
          className="custom-table"
          />
        
      </div>

    </Layout>
  );
};

export default MyClients;