import React, { useState, useEffect } from "react";
import { Form, Input, message, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Spinner";
import biCapital from "./../components/Assets/bi-capital-circle.png";

const Login = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const [capsLockOn, setCapsLockOn] = useState(false);
	const [form] = Form.useForm();

	const handleKeyPress = (event) => {
		const capsLock = event.getModifierState("CapsLock");
		setCapsLockOn(capsLock);
	};

	//from submit
	const submitHandler = async (values) => {
		try {
			setLoading(true);
			const { data } = await axios.post("/users/login", values);
			setLoading(false);
			message.success("Login sucess");
			localStorage.setItem("user", JSON.stringify({ ...data, password: "" }));
			navigate("/");
		} catch (error) {
			setLoading(false);
			message.error("Email or Password are wrong or User Blocked!");
		}
	};

	useEffect(() => {
		if (localStorage.getItem("user")) {
			navigate("/");
		}
	}, [navigate]);

	return (
		<>
			<div className="login-container">
				<div class="circular--landscape" style={{ marginTop: "220px" }}>
					<img src={biCapital} alt="Login" />
				</div>
				<div
					className="login-form"
					align="center"
					style={{ marginTop: "20px" }}
				>
					<h2>BIT ADVISOR</h2>
					{loading && <Spinner />}
					<Form
						layout="vertical"
						onFinish={submitHandler}
						form={form}
						style={{ marginTop: "20px" }}
					>
						<Form.Item label="Email" name="email">
							<Input type="email" />
						</Form.Item>
						<Form.Item label="Password" name="password">
							<Input.Password onKeyPress={handleKeyPress} type="password" />
						</Form.Item>
						{capsLockOn && (
							<Alert message="Caps Lock is on" type="warning" showIcon />
						)}
						<div className="d-grid">
							<button
								style={{
									width: "100%",
									textAlign: "center",
									marginTop: "20px",
									backgroundColor: "#7600ec",
									borderColor: "#7600ec",
									color: "#fff",
									borderRadius: "5px",
								}}
								className="btn btn-primary"
							>
								Login
							</button>
						</div>
						{/* <div className="signup-link" style={{ marginBottom: "20px" }}>
							Don't have an account? <Link to="/register">Signup</Link>
						</div> */}
					</Form>
				</div>
			</div>
		</>
	);
};

export default Login;
