import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Col,
	Spin,
	Card,
	Row,
	message,
	Button,
	Select,
	Typography,
	Divider,
	Modal,
} from "antd";
import { useNavigate } from "react-router-dom";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import axios from "axios";

import moment from "moment";
import {
	ArrowLeftOutlined,
	EditOutlined,
	FilePdfOutlined,
} from "@ant-design/icons";

const Settings = () => {
	const { Title, Text } = Typography;

	const [loginUser, setLoginUser] = useState("");
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const [allUser, setAllUser] = useState([]);

	const [loading, setLoading] = useState(false);

	// State to control modal visibility
	const [isModalVisible, setIsModalVisible] = useState(false);

	// Handlers for opening and closing the modal
	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleDelete = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/accounts/delete-account",
				{
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/accounts/delete-all-account/" + user._id,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);

			localStorage.removeItem("user");
			localStorage.removeItem("investorProfile");

			navigate("/login");
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	//useEffect Hook
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/users/get-user",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllUser(userData);
				}

				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	const handleBack = () => {
		navigate("/");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				{/* Account Details Section */}
				<Card style={{ marginTop: "16px", width: "100%" }}>
					<Title level={4} style={{ marginBottom: "20px", marginTop: "20px" }}>
						My Identity
					</Title>
					<Card style={{ marginTop: "16px", width: "100%" }}>
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>First name:</strong>
							<span>{allUser.firstName}</span>
						</Text>
						<Divider />
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Last name:</strong>
							<span>{allUser.lastName}</span>
						</Text>

						<Divider />
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Date of birth:</strong>
							<span>{moment(allUser.birthdayDate).format("YYYY/MM/DD")}</span>
						</Text>
					</Card>

					<Title level={4} style={{ marginBottom: "20px", marginTop: "20px" }}>
						Contacts
					</Title>
					<Card style={{ marginTop: "16px", width: "100%" }}>
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Email:</strong>
							<span>{allUser.email}</span>
						</Text>
						<Divider />
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Phone number:</strong>
							<span>{allUser.phoneNumber}</span>
						</Text>
					</Card>

					<Title level={4} style={{ marginBottom: "20px", marginTop: "20px" }}>
						Account Information
					</Title>
					<Card style={{ marginTop: "16px", width: "100%" }}>
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Currency:</strong>
							<span>{allUser.currency}</span>
						</Text>
						<Divider />
						<Text style={{ display: "flex", justifyContent: "space-between" }}>
							<strong>Account creation:</strong>
							<span>
								{moment(allUser.createdAt).format("YYYY/MM/DD HH:mm")}
							</span>
						</Text>
					</Card>

					{/* Button to Open Modal */}
					<Button
						type="primary"
						danger
						onClick={showModal}
						style={{ marginTop: "16px", width: "100%" }}
					>
						Delete Account
					</Button>
				</Card>

				{/* Modal Section */}
				<Modal
					visible={isModalVisible}
					onCancel={handleCancel}
					footer={null} // No default footer
					centered
					width={600}
				>
					<Card>
						<Row justify="center">
							<Col xs={24}>
								<div
									style={{
										backgroundColor: "white",
										padding: "40px",
										textAlign: "center",
									}}
								>
									<Title level={2}>Delete Your Account</Title>
									<Text
										type="danger"
										style={{ display: "block", marginBottom: "20px" }}
									>
										Warning: Deleting your account is permanent and cannot be
										undone.
									</Text>
									<Text style={{ display: "block", marginBottom: "30px" }}>
										Once you delete your account, all of your data will be
										permanently removed from our system.
									</Text>

									{/* Buttons for confirmation */}
									<Button
										type="primary"
										danger
										size="large"
										onClick={handleDelete}
										style={{ marginRight: "10px" }} // Adds a gap between buttons
									>
										Yes, Delete My Account
									</Button>
									<Button type="default" size="large" onClick={handleCancel}>
										Cancel
									</Button>
								</div>
							</Col>
						</Row>
					</Card>
				</Modal>
				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default Settings;
