import React,{useState,useEffect} from "react";
import {Form, Input, message, Select, Switch, Button} from 'antd';
import {useNavigate,useLocation,Link} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import { InfoCircleOutlined } from "@ant-design/icons";

const EditSavings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  // console.log("data:",data);

  const savingNameIDUpdate = data.savingNameID;
  const savingNameUpdate = data.savingName;
  const savingProcessIDUpdate = data.savingProcessID;
  const categoryNameUpdate = data.categoryNameUpdate;
  const productNameUpdate = data.productNameUpdate;
  const agentNameUpdate = data.agentNameUpdate;
  const IBANUpdate = data.IBANUpdate;
  const monthlyAmountUpdate = data.monthlyAmountUpdate;
  const dayPaymentUpdate = data.dayPaymentUpdate;
  const isDepositActiveUpdate = data.isDepositActiveUpdate;
  const takeProfitUpdate =  data.takeProfitUpdate;
  const percentageTPUpdate = data.percentageTPUpdate ;
  const stopLossUpdate = data.stopLossUpdate;
  const percentageSLUpdate = data.percentageSLUpdate;
  const investmentTypeUpdate = data.investmentTypeUpdate;
  const maxInvestCryptoUpdate = data.maxInvestCryptoUpdate;
  const initialValueUpdate = data.initialValueUpdate;
  const closePortfolioUpdate = data.closePortfolioUpdate;
  const archivePortfolioUpdate = data.archivePortfolioUpdate;

  const [loading,setLoading] = useState(false);

  //DropList Market
  const [allAgent, setAllAgent] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  // const [show, setShow] = useState(false);

  const { TextArea } = Input;

  const [switchValue, setSwitchValue] = useState(takeProfitUpdate);

  const handleSwitchChange = (checked) => {
    setSwitchValue(checked);
  };

  const [switchSLValue, setSwitchSLValue] = useState(stopLossUpdate);

  const handleSwitchSLChange = (checked) => {
    setSwitchSLValue(checked);
  };
    //Handler Asset Class DropList ****************************************************************************************
    useEffect(() => {
      const getAllCategory = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/categories/get-category',
          {
            // userid:user._id,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllCategory(res.data);
          // console.log(res.data);
        }catch (error){
          setLoading(false);
        }
      };  
      getAllCategory();
    },[])

    useEffect(() => {
      const getAllAgent = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/myagents/get-allAgent', {
            // _id:user._id
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }
          );
          setLoading(false);
          setAllAgent(res.data);
          // console.log(res.data);
        }catch (error){
          setLoading(false);
        }
      };  
      getAllAgent();
    },[])

    
  //Handlers User  **************************************************************************************************************************
  const handleSubmitAccount = async (values) => {

  try{
  
    const user = JSON.parse(localStorage.getItem('user'))

    setLoading(true);
    const res = await axios.post('/savings/edit-saving', {
      payload:{
        ...values,
        userid: user._id,
      },
      _id: data.savingNameID,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res3 = await axios.post('/savingtransactions/edit-closeportfolio-savingtransaction', {
      payload:{
        savingName: values.savingName,
        userid: user._id,
      },
      savingProcessId: parseInt(data.savingProcessID),
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    const res2 = await axios.post('/savingbalances/edit-savingbalance', {
      payload:{
        monthlyAmount: values.monthlyAmount,
        agentName: values.agentName,
        userid: user._id,
      },
      savingName: data.savingNameID,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    message.success('Savings updated successfully!');
    navigate('/MySavings');
    // console.log(values)

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  return (
    <Layout>
      <div className="accountpage">
        <div></div>
        <div>
          <h1>Edit - {savingNameUpdate}</h1>
        </div>
        
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            onClick={() => navigate(-1)}
          >
            BACK
          </Button>
        </div>
      <br></br>
      <div>
        <div></div>
        <div aling="center" className='registerAccount'>
        <Form 
            layout="vertical"
            onFinish={handleSubmitAccount} 
          >
            <Form.Item 
            label="Portfolio Name" 
            name="savingName"
            rules={[
              {
                max: 24,
                message: 'The name must be a maximum of 24 characters!',
              },
            ]}
            >
              <Input
              type="text"
              showCount
              maxLength={24}
              defaultValue={savingNameUpdate}
              />
            </Form.Item>

            <Form.Item 
              label={<span>What are you investing for?&nbsp;</span>}
              name="categoryName"
            >
              <Select defaultValue={categoryNameUpdate}>
                {allCategory && allCategory.map((opts, i) => (
                  <Select.Option key={i} value={opts._id} style={{ textAlign: 'center' }}>
                    {opts.categoryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {/* {closePortfolioUpdate && (
            <Form.Item label="Archive Portfolio" name="archivePortfolio" valuePropName="checked">
              <Switch 
                checkedChildren="YES" unCheckedChildren="NO" defaultChecked={archivePortfolioUpdate}
              />
            </Form.Item>
            )} */}
            
            {/* <hr/>
            <hr/>
            <Form.Item label="Lucro Máximo" name="takeProfit" valuePropName="checked">
              <Switch
                checkedChildren="SIM"
                unCheckedChildren="NÃO"
                onChange={handleSwitchChange}
                defaultChecked={takeProfitUpdate}
              />
            </Form.Item>

            <Form.Item
              label="Lucro Máximo %"
              name="percentageTP"
              dependencies={['takeProfit']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('takeProfit')) {
                      if (!value || value.trim() === '') {
                        return Promise.reject(new Error('Please enter a value'));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type="number"
                suffix="%"
                disabled={!switchValue}
                defaultValue={percentageTPUpdate}
              />
            </Form.Item>

            <Form.Item label="Perda Máxima" name="stopLoss" valuePropName="checked">
              <Switch
                checkedChildren="SIM"
                unCheckedChildren="NÃO"
                onChange={handleSwitchSLChange}
                defaultChecked={stopLossUpdate}
              />
            </Form.Item>

            <Form.Item
              label="Perda Máxima %"
              name="percentageSL"
              dependencies={['stopLoss']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('stopLoss')) {
                      if (!value || value.trim() === '') {
                        return Promise.reject(new Error('Please enter a value'));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                type="number"
                suffix="%"
                disabled={!switchSLValue}
                defaultValue={percentageSLUpdate}
              />
            </Form.Item>

            <hr/>
            <hr/> */}
            {/* Só aparece ser for investimentActive = true */}
            {/* <Form.Item label="Entrega ativa" name="isDepositActive" valuePropName="checked">
              <Switch 
                checkedChildren="SIM" unCheckedChildren="NÃO" defaultChecked={isDepositActiveUpdate}
              />
            </Form.Item> */}

            {/* <Form.Item label="Valor da entrega" name="monthlyAmount">
              <Input 
              type="number"
              suffix="€"
              defaultValue={monthlyAmountUpdate} 
              />
            </Form.Item>

            <Form.Item label="Data do pagamento" name="dayPayment">
              <Input 
              type="number"
              defaultValue={dayPaymentUpdate} 
              />
            </Form.Item>

            <Form.Item label="IBAN" name="IBAN">
              <Input 
              type="number"
              defaultValue={IBANUpdate}
              />
            </Form.Item>

            <Form.Item 
              label="Agente"
              name="agentName"
            >
              <Select defaultValue={agentNameUpdate}>
                {allAgent && allAgent.map((opts, i) => (
                  <Select.Option key={i} value={opts._id} style={{ textAlign: 'center' }}>
                    {opts.agentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
                EDIT
              </Button>
              <Link to="/MySavings">
                <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
                  CANCEL
                </Button>
              </Link>
              </Form>
        </div>
        <div></div>

            </div>
            {/* <div><pre>{JSON.stringify(accountDataUpdate, null, 2) }</pre></div> */}
    </Layout>
  );
};

export default EditSavings;