import React, { useState, useEffect } from "react";
import { Form, Input, message, Modal, Table, Switch, Button } from "antd";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import { useNavigate } from "react-router-dom";

import {
	SearchOutlined,
	EditOutlined,
	DeleteOutlined,
} from "@ant-design/icons";

const CategoryAssets = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(false);

	const [loading, setLoading] = useState(false);

	const [allCategory, setAllSector] = useState([]);

	//Handle Close button Form
	const [show, setShow] = useState(false);

	//Edit Transactions
	const [editable, setEditable] = useState(null);

	//Fields from table to View
	const [categoryAssetNameUpdate, setCategoryAssetNameUpdate] = useState(null);
	const [categoryAssetNamePTUpdate, setCategoryAssetNamePTUpdate] =
		useState(null);
	const [statusUpdate, setStatusUpdate] = useState(null);

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "categoryAssetName",
			title: "Classe de Ativos EN",
			dataIndex: "categoryAssetName",
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.categoryAssetName.localeCompare(b.categoryAssetName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.categoryAssetName
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "categoryAssetNamePT",
			title: "Classe de Ativos PT",
			dataIndex: "categoryAssetNamePT",
			sorter: (a, b) =>
				a.categoryAssetNamePT.localeCompare(b.categoryAssetNamePT),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.categoryAssetNamePT
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "status",
			title: "Estado",
			dataIndex: "status",
			render: (value, record) => {
				if (record.status === true)
					return <span style={{ color: "#39A939" }}>Active</span>;

				return <span style={{ color: "#FF3234" }}>Inactive</span>;
			},
			filters: [
				{ text: "Active", value: true },
				{ text: "Inactive", value: false },
			],
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				// console.log(typeof value)
				return record.status === value;
			},
		},
		{
			title: "Ações",
			dataIndex: "type",
			render: (type, record) => {
				// if(type === "Income" )
				return (
					<div className="mx-2">
						<EditOutlined
							onClick={() => {
								setEditable(record);
								setCategoryAssetNameUpdate(record.categoryAssetName);
								setCategoryAssetNamePTUpdate(record.categoryAssetNamePT);
								setStatusUpdate(record.status);
								setShowModal(true);
							}}
						/>
						<DeleteOutlined
							className="mx-2"
							onClick={() => {
								handleDelete(record);
							}}
						/>
					</div>
				);
			},
		},
	];

	const user = JSON.parse(localStorage.getItem("user"));

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllCategory = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/categoryAssets/get-categoryAsset",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSector(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				// message.error('You are not Admin User');
			}
		};

		getAllCategory();

		// Clean up the interval when the component unmounts
		//return () => ;
	}, []);

	//Handlers Submit  **************************************************************************************************************************
	const handleSubmitCategory = async (values) => {
		try {
			const promises = [];

			const user = JSON.parse(localStorage.getItem("user"));

			if (editable) {
				setLoading(true);
				const result = axios.post(
					"/categoryAssets/edit-categoryAsset",
					{
						payload: {
							...values,
							userid: user._id,
						},
						_id: editable._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Classe de Ativos atualizada com sucesso!");
			} else {
				setLoading(true);
				const result = axios.post(
					"/categoryAssets/add-categoryAsset",
					{
						...values,
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				message.success("Classe de Ativos criada com sucesso!");
			}

			setShowModal(false);
			setEditable(null);
			setTimeout(function () {
				window.location.reload(true);
			}, 1000);
		} catch (error) {
			setLoading(false);
		}
	};

	//delete handler
	const handleDelete = async (record) => {
		try {
			setLoading(true);
			await axios.post(
				"/categories/delete-category",
				{
					_id: record._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Classe de Ativos apagada com sucesso!");
			setTimeout(function () {
				window.location.reload(true);
				message.success("reload 1 segundo");
			}, 1000);
		} catch (error) {
			setLoading(false);
			// console.log(error);
			message.error("unable to delete");
		}
	};

	// //Handler Test Console Log Values
	// const onFinish = (values) => {
	//   console.log({values});
	// };

	//Handler Reset Values to modal
	const handleCancelModal = () => {
		setShowModal(false);
		setEditable(null);
		setCategoryAssetNameUpdate(null);
		setCategoryAssetNamePTUpdate(null);
		setStatusUpdate(null);
	};

	//Handler Close Modal
	const handleClose = () => {
		setShow(false);
	};

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spinner />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Classes de Activos</h1>
				</div>
				<div></div>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					align="right"
					shape="round"
					className="btn btn-primary"
					onClick={() => setShowModal(true)}
				>
					+ Nova Classe de Activos
				</Button>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>

			<hr></hr>
			<div className="content">
				<Table
					columns={columns}
					dataSource={allCategory}
					pagination={false}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>
			{/* <div><pre>{JSON.stringify(allCategory, null, 2) }</pre></div> */}

			<Modal
				destroyOnClose={true}
				title={editable ? "Editar Classe de Ativos" : "Nova Classe de Ativos"}
				open={showModal}
				onCancel={handleCancelModal}
				footer={false}
				style={{ padding: "32px 16px" }}
			>
				<Form
					layout="vertical"
					onFinish={handleSubmitCategory}
					style={{ padding: "32px 16px" }}
				>
					<Form.Item label="Classe de Ativos EN" name="categoryAssetName">
						<Input type="text" defaultValue={categoryAssetNameUpdate} />
					</Form.Item>
					<Form.Item label="Classe de Ativos PT" name="categoryAssetNamePT">
						<Input type="text" defaultValue={categoryAssetNamePTUpdate} />
					</Form.Item>
					<Form.Item label="Estado" name="status" valuePropName="checked">
						<Switch defaultChecked={statusUpdate} />
					</Form.Item>

					<button
						type="submit"
						style={{
							width: "100%",
							background: "green",
							color: "white",
							marginTop: 30,
							marginBottom: 30,
						}}
						className="btn btn-success"
						onClick={handleClose}
					>
						{" "}
						GRAVAR
					</button>
				</Form>
			</Modal>
		</Layout>
	);
};

export default CategoryAssets;
