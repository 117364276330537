import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Typography,
	Col,
	Space,
	Card,
	Row,
	message,
	Button,
	Spin,
	Divider,
} from "antd";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout.js";
import axios from "axios";
import Spinner from "../components/Spinner.js";

import moment from "moment";
import {
	ArrowLeftOutlined,
	CheckCircleOutlined,
	EditOutlined,
	FilePdfOutlined,
	LockOutlined,
} from "@ant-design/icons";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { BadgePercent, Crown, Gem, Star } from "lucide-react";

const MyAccountLevel = () => {
	const { Title, Text } = Typography;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const [allSavingStats, setAllSavingStats] = useState([]);

	const [loading, setLoading] = useState(false);

	const [activeTab, setActiveTab] = useState("profile");

	const [form] = Form.useForm();

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	// Assuming contains the URL
	const openPopup = (url) => {
		const popup = window.open(url, "popup", "width=600,height=600");
		if (popup) {
			popup.focus();
			// console.log(url);
		}
	};

	//Handlers User  **************************************************************************************************************************
	// useEffect(() => {
	//   const fetchUserData = async () => {
	//     try {
	//       const user = JSON.parse(localStorage.getItem('user'));
	//       setLoading(true);
	//       const res = await axios.post('/users/get-user',
	//       { userid: user._id },
	//       { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` } });
	//       setAllSavingStats(res.data[0]);
	//       // Format birthdayDate using moment
	//       const formattedDate = moment(res.data[0].birthdayDate).format('YYYY-MM-DD');
	//       // Set form fields value
	//       form.setFieldsValue({
	//         name: res.data[0].name,
	//         birthdayDate: formattedDate, // Set formatted date
	//         nif: res.data[0].nif,
	//         addressLiving: res.data[0].addressLiving,
	//         postalCodeLiving: res.data[0].postalCodeLiving,
	//         cityLiving: res.data[0].cityLiving,
	//         stateLiving: res.data[0].stateLiving,
	//         email: res.data[0].email,
	//         phoneNumber: res.data[0].phoneNumber,
	//         currency: res.data[0].currency,
	//       });
	//       console.log(res.data[0]);
	//       setLoading(false);
	//     } catch (error) {
	//       setLoading(false);
	//       message.error('Fetch Issue With account');
	//     }
	//   };

	//   fetchUserData();
	// }, [form]);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				if (!user) {
					message.error("No user found in localStorage");
					return;
				}

				setLoading(true);

				const res = await axios.post(
					"/users/get-user",
					{ userid: user._id },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllSavingStats(userData);

					// Format birthdayDate using moment
					const formattedDate = moment(userData.birthdayDate).format(
						"YYYY-MM-DD"
					);

					// Set form fields value
					form.setFieldsValue({
						name: userData.firstName + " " + userData.lastName,
						countryCitizenship: userData.countryCitizenship,
						birthdayDate: formattedDate,
						nif: userData.nif,
						email: userData.email,
						phoneNumber: userData.phoneNumber,
						currency: userData.currency,
					});
				} else {
					message.error("No user data found");
				}

				setLoading(false);
			} catch (error) {
				setLoading(false);
				message.error("Fetch Issue With account");
			}
		};

		fetchUserData();
	}, [form]);

	const handleSubmitPassword = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const res = await axios.post(
				"/users/edit-user",
				{
					payload: {
						...values,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Password updated successfully!");
			navigate("/");
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};

	const handleSubmitProfile = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const res = await axios.post(
				"/users/edit-user",
				{
					payload: {
						...values,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Personal Data updated successfully!");
			navigate("/MySettings");
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};
	// const onFinish = (values) => {
	//   console.log({...values.serviceRating
	//   });
	// };

	const currencyOptions = [
		{ value: "USD", label: "USD" },
		{ value: "EUR", label: "EUR" },
		{ value: "GBP", label: "GBP" },
		{ value: "CHF", label: "CHF" },
		{ value: "BRL", label: "BRL" },
	];

	//DELETE ACCOUNT **************************************************************************************************************************

	const handleDelete = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/accounts/delete-account",
				{
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/accounts/delete-all-account/" + user._id,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);

			localStorage.removeItem("user");
			localStorage.removeItem("investorProfile");

			navigate("/login");
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				if (!user) {
					message.error("No user found in localStorage");
					return;
				}

				setLoading(true);

				const res = await axios.post(
					"/users/get-user",
					{ userid: user._id },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllSavingStats(userData);

					// Format birthdayDate using moment
					const formattedDate = moment(userData.birthdayDate).format(
						"YYYY-MM-DD"
					);

					// Set form fields value
					form.setFieldsValue({
						name: userData.firstName + " " + userData.lastName,
						countryCitizenship: userData.countryCitizenship,
						birthdayDate: formattedDate,
						nif: userData.nif,
						email: userData.email,
						phoneNumber: userData.phoneNumber,
						currency: userData.currency,
					});
				} else {
					message.error("No user data found");
				}

				setLoading(false);
			} catch (error) {
				setLoading(false);
				message.error("Fetch Issue With account");
			}
		};

		fetchUserData();
	}, [form]);

	const handleBack = () => {
		navigate("/MySettings");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	const [loginUser, setLoginUser] = useState("");
	const [plan, setPlan] = useState("Newbie"); // Default plan

	const profileType = loginUser.profileType;

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	useEffect(() => {
		if (loginUser && loginUser.subscriptionPlan) {
			setPlan(loginUser.subscriptionPlan);
		}
	}, [loginUser]); // Run effect when `loginUser` updates

	console.log("Subscription:", plan);

	const plans = [
		{
			value: "Newbie",
			title: "Newbie",
			features: ["Investment Range: €0 - €500", "Annual Fee: 2.40%"],
			price: "€ 1.00 /per month",
			icon: <BadgePercent />,
		},
		{
			value: "Enthusiast",
			title: "Enthusiast",
			features: ["Investment Range: €501 - €2,500", "Annual Fee: 2.00%"],
			price: "€ 5.00 /per month",
			icon: <Star />,
		},
		{
			value: "Trader",
			title: "Trader",
			features: ["Investment Range: €2,501 - €25,000", "Annual Fee: 1.50%"],
			price: "€ 30.00 /per month",
			icon: <Gem />,
		},
		{
			value: "Unlimited",
			title: "Unlimited",
			features: ["Investment Range: €25,001+", "Annual Fee: 1%"],
			price: "0.08 % /per month", // Percentage of the investment
			icon: <Crown />,
		},
	];

	// // Function to handle plan selection
	// const handlePlanChange = (value) => {
	// 	setPlan(value);
	// 	console.log("Selected Plan:", value); // Log the selected plan value
	// };

	// // Function to handle button click
	// const handleProceed = () => {
	// 	if (plan) {
	// 		console.log("Proceeding with plan:", plan); // Log the plan when proceeding
	// 	}
	// };

	return (
		<div className="container" style={{ height: "75vh" }}>
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<section>
					<Card>
						{" "}
						<Title
							level={4}
							style={{ marginBottom: "20px", marginTop: "20px" }}
						>
							My Account Level
						</Title>
						<main className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-indigo-500 py-10">
							<Divider
								style={{
									width: "100%",
									margin: "1rem auto",
									borderColor: "#ffffff",
								}}
							/>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // For responsive layout
									gap: "1rem",
									width: "100%",
								}}
							>
								{plans.map((planItem) => (
									<Card
										key={planItem.value}
										style={{
											width: "100%",
											textAlign: "center",
											cursor: "pointer",
											backgroundColor:
												planItem.value === plan ? "#ffffff" : "#f0f0f0",
											boxShadow:
												planItem.value === plan
													? "0 4px 10px rgba(0, 0, 0, 0.2)"
													: "none",
											borderRadius: "8px",
										}}
										// onClick={() => handlePlanChange(planItem.value)} // Handle plan selection
										bordered={false}
										hoverable
									>
										<div
											style={{
												fontSize: "24px",
												marginBottom: "10px",
												color: "#4a4a4a",
											}}
										>
											{planItem.icon}
										</div>
										<Title level={4}>{planItem.title}</Title>

										{/* Features Section */}
										<div
											style={{
												display: "grid",
												gridTemplateColumns:
													"repeat(auto-fill, minmax(150px, 1fr))",
												gap: "10px",
												marginBottom: "10px",
											}}
										>
											{planItem.features.map((feature, index) => (
												<div
													key={index}
													style={{ fontSize: "14px", color: "#777" }}
												>
													{feature}
												</div>
											))}
										</div>

										<div
											style={{
												marginTop: "10px",
												fontWeight: "bold",
												fontSize: "18px",
											}}
										>
											{planItem.price}
										</div>
									</Card>
								))}
							</div>
						</main>
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default MyAccountLevel;
