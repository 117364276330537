import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";

const CryptoAssets = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allCryptoAsset, setAllCryptoAsset] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [cryptoAssetNameUpdate, setCryptoAssetNameUpdate] = useState(null);
  const [yahooTickerUpdate, setYahooTickerUpdate] = useState(null);
  const [binanceTickerUpdate, setBinanceTickerUpdate] = useState(null);
  const [cryptoAssetDescriptionUpdate, setCryptoAssetDescriptionUpdate] = useState(null);
  const [currencyUpdate, setCurrencyUpdate] = useState(null);
  const [precisionUpdate, setPrecisionUpdate] = useState(null);
  const [decimalPrecisionUpdate, setDecimalPrecisionUpdate] = useState(null);
  const [ticketSizeUpdate, setTicketSizeUpdate] = useState(null);
  const [brokerNameUpdate, setBrokerNameUpdate] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "cryptoAssetName",
      title: "Activo",
      dataIndex: "cryptoAssetName",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.cryptoAssetName.localeCompare(b.cryptoAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.cryptoAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: "yahooTicker",
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "binanceTicker",
      title: "Binance Ticker",
      dataIndex: "binanceTicker",
      sorter: (a, b) => a.binanceTicker.localeCompare(b.binanceTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.binanceTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "ticketSize",
      title: "Tamanho Ticket",
      dataIndex: ["ticketSize"],
      align: "center",
      sorter: (a, b) => a.ticketSize-b.ticketSize,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ticketSize === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "decimalPrecision",
      title: "Precisão Decimal",
      dataIndex: ["decimalPrecision"],
      align: "center",
      sorter: (a, b) => a.decimalPrecision-b.decimalPrecision,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.decimalPrecision === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "precision",
      title: "Casas Precisão",
      dataIndex: ["precision"],
      align: "center",
      sorter: (a, b) => a.precision-b.precision,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.precision === Number(value); // Convert to number for comparison
      },
    },
    {
      key: "currency",
      title: "Moeda",
      dataIndex: ["currency"],
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Active</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Inactive</span>
          );
      },
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      title: "Ações",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setCryptoAssetNameUpdate(record.cryptoAssetName);
                    setYahooTickerUpdate(record.yahooTicker);
                    setBinanceTickerUpdate(record.binanceTicker);
                    setCryptoAssetDescriptionUpdate(record.cryptoAssetDescription);
                    setPrecisionUpdate(record.precision);
                    setDecimalPrecisionUpdate(record.decimalPrecision);
                    setTicketSizeUpdate(record.ticketSize);
                    setBrokerNameUpdate(record.brokerName);
                    setCurrencyUpdate(record.currency);
                    setStatusUpdate(record.status);
                    //navigate('/ImportFund', { state: record });
                    setShowModal(true);
              }}
            />
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllCryptoAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cryptoAssets/get-cryptoAsset',
        {
          status: "all"
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCryptoAsset(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCryptoAsset();
  },[])

  //Handlers User  **************************************************************************************************************************
  const handleSubmitStockAsset = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/cryptoAssets/edit-cryptoAsset', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      setShowModal(false);
      setEditable(null);
      setTimeout(function () {
        window.location.reload(true);
      }, 1000);
      message.success('Crypto Asset Updated Successfull');

    }else{
      setLoading(true);
      const result = axios.post('/cryptoAssets/add-cryptoAsset', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      setShowModal(false);
      setEditable(null);
      setTimeout(function () {
        window.location.reload(true);
      }, 1000);
      message.success('Crypto Asset Added Successfull');
    }

    // setShowModal(false);
    // setEditable(null);
    // setTimeout(function () {
    //   window.location.reload(true);
    // }, 1000);

    }catch (error){
      setLoading(false);
      // message.error('Failed to industry');
      // message.error('You are not Admin User');
    }
  }

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setCryptoAssetNameUpdate(null);
    setYahooTickerUpdate(null);
    setBinanceTickerUpdate(null);
    setCryptoAssetDescriptionUpdate(null);
    setPrecisionUpdate(null);
    setDecimalPrecisionUpdate(null);
    setTicketSizeUpdate(null);
    setBrokerNameUpdate(null);
    setCurrencyUpdate(null);
    setStatusUpdate(null);
  };

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };
  
  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Criptomoedas</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Ativo
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "green", borderColor: "green" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

      <hr></hr>

      <div className="content">
        <Table
        size="small" 
        columns={columns}
        dataSource={allCryptoAsset}
        pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50','100']}}
        scroll={{ x: 'max-content'}}
        className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
      
      <Modal
        destroyOnClose={true} 
        title={editable ? "Edit Crypto Asset" : "Add Crypto Asset" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >

          <Form 
            layout="vertical" 
            onFinish={handleSubmitStockAsset} 
            style={{padding:"32px 16px"}}
          >

            <Form.Item label="Asset" name="cryptoAssetName">
              <Input 
                type="text"
                defaultValue={cryptoAssetNameUpdate}
                />
            </Form.Item>
            <Form.Item label="Yahoo Ticker" name="yahooTicker">
              <Input 
                type="text"
                defaultValue={yahooTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Binance Ticker" name="binanceTicker">
              <Input
                defaultValue={binanceTickerUpdate}
                />
            </Form.Item>

            <Form.Item label="Description" name="cryptoAssetDescription">
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 100,
                  marginBottom: 24,
                }}
                type="text" 
                defaultValue={cryptoAssetDescriptionUpdate}
                />
            </Form.Item>

            <Form.Item label="Ticket Size" name="ticketSize">
              <Input
                type="number"
                defaultValue={ticketSizeUpdate}
                />
            </Form.Item>
            <Form.Item label="Decimal Precision" name="decimalPrecision">
              <Input
                type="number"
                defaultValue={decimalPrecisionUpdate}
                />
            </Form.Item>
            <Form.Item label="Precison" name="precision">
              <Input
                type="number"
                defaultValue={precisionUpdate}
                />
            </Form.Item>

            <Form.Item label="Currency" name="currency">
              <Select defaultValue={currencyUpdate}>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="CHF">CHF</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="HKD">HKD</Select.Option>
                <Select.Option value="BRL">BRL</Select.Option>
                <Select.Option value="JPY">JPY</Select.Option>    
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Broker Name" name="brokerName">
              <Select defaultValue={brokerNameUpdate}>
                <Select.Option value="BINANCE">BINANCE</Select.Option>  
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
            
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch 
                defaultChecked={statusUpdate}
              />
            </Form.Item>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={handleClose}>
                {" "}
                SAVE
              </button>
            </div>
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default CryptoAssets;