import React, { useState, useEffect, useMemo } from "react";
import {
	Layout,
	Tag,
	Select,
	Modal,
	Table,
	Switch,
	Spin,
	Button,
	Typography,
	Card,
	Row,
	Col,
} from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import {
	SearchOutlined,
	CheckCircleFilled,
	StopOutlined,
	EditOutlined,
	EyeOutlined,
} from "@ant-design/icons";

const OrdersAdmin = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { Title } = Typography;

	const [loading, setLoading] = useState(false);
	const [allBuyOrdersClients, setAllBuyOrdersClients] = useState([]);
	const [filteredBuyOrdersClients, setFilteredBuyOrdersClients] = useState([]); // New state for filtered data

	const renderCurrency = (value) => {
		if (value === null || value === undefined || isNaN(value)) {
			return "N/A"; // Handle empty values
		}
		return (
			<span>
				{new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(value)}
			</span>
		);
	};

	// Define Columns
	const feeColumns = [
		{
			key: "transactionTime",
			title: "Transaction Time",
			dataIndex: "transactionTime",
			defaultSortOrder: "descend",
			align: "center",
			render: (text) => (
				<span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
			),
			sorter: (a, b) =>
				new Date(a.transactionTime) - new Date(b.transactionTime),
		},
		{
			key: "orderType",
			title: "Buy/Sell",
			dataIndex: "type",
			align: "center",
			render: (type) => (
				<span style={{ color: type === "buy" ? "green" : "red" }}>
					{type === "buy" ? "Buy" : "Sell"}
				</span>
			),
			filters: [
				{ text: "Buy", value: "buy" },
				{ text: "Sell", value: "sell" },
			],
			onFilter: (value, record) => record.type === value,
		},

		{
			title: "User Name",
			dataIndex: ["userid"],
			key: "userid",
			render: (userid) => {
				if (!userid) return "-"; // Handle null values
				return `${userid.firstName} ${userid.lastName}`;
			},
		},
		{
			title: "Saving Name",
			dataIndex: ["savingName", "savingName"],
			key: "savingName",
		},
		{
			title: "Saving ID",
			dataIndex: ["savingProcessId"],
			key: "savingProcessId",
		},
		{
			title: "Product Name",
			dataIndex: ["productName", "productName"],
			key: "productName",
		},
		{
			title: "Symbol",
			dataIndex: ["symbol"],
			key: "symbol",
		},
		{
			title: "Quantity Executed",
			dataIndex: "executedQty",
			key: "executedQty",
			align: "center",
		},
		{
			title: "Price Executed",
			dataIndex: "filledPrice",
			key: "filledPrice",
			render: renderCurrency,
			align: "center",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
			render: renderCurrency,
			align: "center",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => (
				<span style={{ color: status === "Success" ? "green" : "red" }}>
					{status}
				</span>
			),
		},
		{
			title: "Error Message",
			dataIndex: ["error_message"],
			key: "error_message",
		},
	];

	// Helper functions for statistics
	const getDistinctUserIds = (data) =>
		new Set(data.map((item) => item.userid._id)).size;
	const getDistinctSavingNames = (data) =>
		new Set(data.map((item) => item.savingName)).size;
	const getTotalTodayValue = (data) =>
		data.reduce((total, item) => total + item.total, 0);

	// Table filter handler
	const handleTableChange = (_, filters) => {
		// Apply filtering based on table filters
		let filteredData = allBuyOrdersClients;

		// Example: Filter by % Daily Change
		if (filters.pctDailyChange) {
			const threshold = parseFloat(filters.pctDailyChange[0]);
			filteredData = allBuyOrdersClients.filter(
				(record) => parseFloat(record.pctDailyChange) * 100 < threshold
			);
		}

		if (filters.pctTodayValueAccum) {
			const threshold = parseFloat(filters.pctTodayValueAccum[0]);
			filteredData = allBuyOrdersClients.filter(
				(record) => parseFloat(record.pctTodayValueAccum) * 100 < threshold
			);
		}

		setFilteredBuyOrdersClients(filteredData); // Update state with filtered data
	};

	// useEffect to fetch data and apply filtering
	useEffect(() => {
		const getAllOrders = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);

				// Fetch both buy and sell orders
				const [buyOrders, sellOrders] = await Promise.all([
					axios.post(
						"/buyorders/get-all-buy-orders",
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${user.token}`,
							},
						}
					),
					axios.post(
						"/sellorders/get-all-sell-orders",
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${user.token}`,
							},
						}
					),
				]);

				setLoading(false);

				// Add type to each order and adjust the total for sell orders
				const combinedOrders = [
					...buyOrders.data.map((order) => ({
						...order,
						type: "buy",
						total: order.total * 1,
						executedQty: order.executedQty * 1,
					})),
					...sellOrders.data.map((order) => ({
						...order,
						type: "sell",
						total: order.total * -1,
						executedQty: order.executedQty * -1,
					})),
				];

				setAllBuyOrdersClients(combinedOrders);
				setFilteredBuyOrdersClients(combinedOrders);
			} catch (error) {
				setLoading(false);
				console.error("Error fetching orders:", error);
			}
		};

		getAllOrders();
	}, []);

	// Table column definition with filter
	const columns = [
		{
			key: "orderType",
			title: "Buy/Sell",
			dataIndex: "type",
			align: "center",
			render: (type) => (
				<span style={{ color: type === "buy" ? "green" : "red" }}>
					{type === "buy" ? "Buy" : "Sell"}
				</span>
			),
			filters: [
				{ text: "Buy", value: "buy" },
				{ text: "Sell", value: "sell" },
			],
			onFilter: (value, record) => record.type === value,
			filterMultiple: false, // Optional: Single selection
		},
		// Other columns...
	];

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spin />}
			<div style={{ padding: "20px" }}>
				<Title level={3}>Savings Orders</Title>
				{/* Statistic Cards */}
				<Row
					gutter={[16, 16]}
					justify="center"
					style={{ marginBottom: "20px" }}
				>
					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Distinct User
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{getDistinctUserIds(filteredBuyOrdersClients)}
							</p>
						</Card>
					</Col>

					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Distinct Saving Names
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{getDistinctSavingNames(filteredBuyOrdersClients)}
							</p>
						</Card>
					</Col>

					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Total Transactions
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{renderCurrency(getTotalTodayValue(filteredBuyOrdersClients))}
							</p>
						</Card>
					</Col>
				</Row>

				<div className="accountpagebuttonsAll">
					<Button
						shape="round"
						className="btn btn-primary btn-block"
						style={{ background: "green", borderColor: "green" }}
						onClick={() => navigate("/")}
					>
						Voltar
					</Button>
				</div>
				<p
					style={{
						fontSize: "24px",
						textAlign: "center", // Ensure the text is centered
						margin: 0,
					}}
				></p>

				<hr></hr>

				{/* Table */}
				<Card>
					<div className="content">
						<Table
							columns={feeColumns}
							dataSource={filteredBuyOrdersClients} // Use filtered data here
							pagination={{ pageSize: 15 }}
							bordered
							rowKey="key"
							scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
							responsive={true} // Makes the table responsive
							className="custom-table" // Apply the custom class here
							onChange={handleTableChange} // Handle filters
						/>
					</div>
				</Card>
			</div>
		</Layout>
	);
};

export default OrdersAdmin;
