import React, { useState, useEffect, useMemo } from "react";
import {
	Layout,
	Tag,
	Select,
	Modal,
	Table,
	Switch,
	Spin,
	Button,
	Typography,
	Card,
	Row,
	Col,
} from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import {
	SearchOutlined,
	CheckCircleFilled,
	StopOutlined,
	EditOutlined,
	EyeOutlined,
} from "@ant-design/icons";

const Contractuals = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { Title } = Typography;

	const [loading, setLoading] = useState(false);
	const [allClients, setAllClients] = useState([]);
	const [filteredClients, setFilteredClients] = useState([]); // New state for filtered data

	const renderCurrency = (value) => {
		if (value === null || value === undefined || isNaN(value)) {
			return "N/A"; // Handle empty values
		}
		return (
			<span>
				{new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(value)}
			</span>
		);
	};

	// Define Columns
	const feeColumns = [
		{
			title: "User Name",
			dataIndex: ["userid"],
			key: "userid",
			render: (userid) => {
				if (!userid) return "-"; // Handle null values
				return `${userid.firstName} ${userid.lastName}`;
			},
		},
		{
			title: "Investor Profile",
			dataIndex: ["userid", "investorProfile"],
			key: "investorProfile",
		},
		{
			title: "Saving Name",
			dataIndex: ["savingName", "savingName"],
			key: "savingName",
		},
		{
			title: "Invest Total",
			key: "investTotal",
			align: "center",
			render: (text, record) => {
				// Calculate investTotal as today_value - diffAccum
				const investTotal = record.today_value - record.diffAccum;

				// Format it as a dollar amount with thousands separator and 2 decimal places
				const formattedInvestTotal = new Intl.NumberFormat("en-US").format(
					investTotal.toFixed(2)
				);

				return `$${formattedInvestTotal}`;
			},
		},
		{
			title: "Take Profit",
			dataIndex: ["savingName", "takeProfit"],
			key: "takeProfit",
			render: (value) => (
				<Tag color={value ? "green" : "red"}>{value ? "YES" : "NO"}</Tag>
			),
		},
		{
			title: "Stop Loss",
			dataIndex: ["savingName", "stopLoss"],
			key: "stopLoss",
			render: (value) => (
				<Tag color={value ? "green" : "red"}>{value ? "YES" : "NO"}</Tag>
			),
		},
		{
			title: "% TP",
			dataIndex: ["savingName", "percentageTP"],
			key: "percentageTP",
			align: "center",
			render: (value) => {
				if (value === null) return "-";
				return `${value.toFixed(2)}%`; // Converts to percentage with 2 decimal places
			},
		},
		{
			title: "% SL",
			dataIndex: ["savingName", "percentageSL"],
			key: "percentageSL",
			align: "center",
			render: (value) => {
				if (value === null) return "-";
				return `${value.toFixed(2)}%`;
			},
		},

		{
			title: "Today Value",
			dataIndex: "today_value",
			key: "today_value",
			render: renderCurrency,
			align: "center",
		},
		{
			title: "Diff Daily",
			dataIndex: "diffDaily",
			key: "diffDaily",
			render: renderCurrency,
			align: "center",
		},
		{
			title: "% Daily Change",
			dataIndex: "pctDailyChange",
			key: "pctDailyChange",
			align: "center",
			render: (value) => {
				const numericValue = parseFloat(value) * 100; // Convert to percentage
				const color = numericValue < 0 ? "red" : "green"; // Set color conditionally

				return <span style={{ color }}>{numericValue.toFixed(2)}%</span>; // Apply color
			},
			filters: [{ text: "Less than -1%", value: "-1" }],
			onFilter: (value, record) => parseFloat(record.pctDailyChange) < -0.01,
		},
		{
			title: "Diff Accum",
			dataIndex: "diffAccum",
			key: "diffAccum",
			render: renderCurrency,
			align: "center",
		},
		{
			title: "% Today Value Accum",
			dataIndex: "pctTodayValueAccum",
			key: "pctTodayValueAccum",
			align: "center",
			render: (value) => {
				const numericValue = parseFloat(value) * 100; // Convert to percentage
				const color = numericValue < 0 ? "red" : "green"; // Set color conditionally

				return <span style={{ color }}>{numericValue.toFixed(2)}%</span>; // Apply color
			},
			filters: [{ text: "Less than -10%", value: "-10" }],
			onFilter: (value, record) => parseFloat(record.pctTodayValueAccum) < -0.1,
		},
	];

	// Helper functions for statistics
	const getDistinctUserIds = (data) =>
		new Set(data.map((item) => item.userid._id)).size;
	const getDistinctSavingNames = (data) =>
		new Set(data.map((item) => item.savingName)).size;
	const getTotalTodayValue = (data) =>
		data.reduce((total, item) => total + item.today_value, 0);

	// Table filter handler
	const handleTableChange = (_, filters) => {
		// Apply filtering based on table filters
		let filteredData = allClients;

		// Example: Filter by % Daily Change
		if (filters.pctDailyChange) {
			const threshold = parseFloat(filters.pctDailyChange[0]);
			filteredData = allClients.filter(
				(record) => parseFloat(record.pctDailyChange) * 100 < threshold
			);
		}

		if (filters.pctTodayValueAccum) {
			const threshold = parseFloat(filters.pctTodayValueAccum[0]);
			filteredData = allClients.filter(
				(record) => parseFloat(record.pctTodayValueAccum) * 100 < threshold
			);
		}

		setFilteredClients(filteredData); // Update state with filtered data
	};

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingbalances/get-allsavingbalances",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllClients(res.data);
				setFilteredClients(res.data); // Initialize filtered data with all data
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Users');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	const getMaxDateSavingPerformance = (data) => {
		if (data.length === 0) return "N/A"; // Handle empty array case
		return data.reduce((max, item) =>
			new Date(item.dateSavingPerformance) > new Date(max.dateSavingPerformance)
				? item
				: max
		).dateSavingPerformance;
	};

	// Assuming you already have filteredClients data
	const maxDateSavingPerformance = getMaxDateSavingPerformance(filteredClients); // Get the max date

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spin />}
			<div style={{ padding: "20px" }}>
				<Title level={3}>Savings Summary</Title>
				{/* Statistic Cards */}
				<Row
					gutter={[16, 16]}
					justify="center"
					style={{ marginBottom: "20px" }}
				>
					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Distinct User IDs
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{getDistinctUserIds(filteredClients)}
							</p>
						</Card>
					</Col>

					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Distinct Saving Names
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{getDistinctSavingNames(filteredClients)}
							</p>
						</Card>
					</Col>

					<Col xs={24} sm={12} md={8}>
						<Card
							style={{
								backgroundColor: "#6a00c1",
								color: "white",
								textAlign: "center",
							}}
							bordered={false}
						>
							<Title level={4} style={{ color: "white", marginBottom: "8px" }}>
								Total Today Value
							</Title>
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									color: "white",
									margin: 0,
								}}
							>
								{renderCurrency(getTotalTodayValue(filteredClients))}
							</p>
						</Card>
					</Col>
				</Row>

				<div className="accountpagebuttonsAll">
					<Button
						shape="round"
						className="btn btn-primary btn-block"
						style={{ background: "green", borderColor: "green" }}
						onClick={() => navigate("/")}
					>
						Voltar
					</Button>
				</div>
				<p
					style={{
						fontSize: "24px",
						textAlign: "center", // Ensure the text is centered
						margin: 0,
					}}
				>
					{maxDateSavingPerformance === "N/A"
						? "No data available"
						: new Date(maxDateSavingPerformance)
								.toLocaleDateString("en-GB") // dd/mm/yy format
								.concat(
									" ",
									new Date(maxDateSavingPerformance).toLocaleTimeString(
										"en-GB",
										{ hour12: false }
									)
								)}
				</p>

				<hr></hr>

				{/* Table */}
				<Card>
					<div className="content">
						<Table
							columns={feeColumns}
							dataSource={filteredClients} // Use filtered data here
							pagination={{ pageSize: 15 }}
							bordered
							rowKey="key"
							scroll={{ x: "max-content" }} // Ensures horizontal scrolling on smaller screens
							responsive={true} // Makes the table responsive
							className="custom-table" // Apply the custom class here
							onChange={handleTableChange} // Handle filters
						/>
					</div>
				</Card>
			</div>
		</Layout>
	);
};

export default Contractuals;
