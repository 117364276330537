import React, { useState, useEffect } from "react";
import { Spin, Card, DatePicker, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import YouTube from "react-youtube";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import moment from "moment";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";

const FinanceLiteracy = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [allVideos, setAllVideos] = useState([]);

	const { RangePicker } = DatePicker;

	//Filters
	const [frequency, setFrequency] = useState("month");
	const [selectedDate, setSelectedDate] = useState([]);

	useEffect(() => {
		const getAllVideos = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/financeLiteracys/get-allfinanceLiteracy",
					{
						frequency,
						selectedDate,
						status: true,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllVideos(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With Industry');
				//message.error('You are not Admin User');
			}
		};

		getAllVideos();

		// // Clean up the interval when the component unmounts
		// return () => getAllTransactions();
	}, [frequency, selectedDate]);

	// useEffect(() => {
	//   // Fetch or define the list of videos
	//   const fetchedVideos = [
	//     { id: 'tqHRIgmuk3g' , title:"IMGA PODCASTS - Mensal [Fevereiro 2024]", upload:"2024-03-01", channel:"IM Gestão de Ativos"},
	//     { id: 'Vj6_JrBBGVE' , title:"IMGA PODCASTS - Mensal [Abril 2024]", upload:"2024-05-01", channel:"IM Gestão de Ativos"},
	//     { id: '7y6a0K4wAvA' , title:"IMGA PODCASTS - Mensal [Março 2024]", upload:"2024-04-01", channel:"IM Gestão de Ativos"},
	//     // Add more videos as needed
	//   ];
	//   setAllVideos(fetchedVideos);
	// }, []);

	//Analytics
	const countVideos = allVideos.length;

	const opts = {
		height: "315",
		width: "100%", // Adjust width for mobile
		playerVars: {
			autoplay: 0,
		},
	};

	const videosInRows = [];
	let row = [];
	allVideos.forEach((item, index) => {
		row.push(
			<Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8} key={item.id}>
				<YouTube videoId={item.idVideo} opts={opts} />
				<h5>{item.titleVideo}</h5>
				<h7>
					<b>{item.channelVideo}</b>
				</h7>
				<p>{moment(item.uploadDateVideo).format("YYYY-MM-DD")}</p>
			</Col>
		);
		if ((index + 1) % 3 === 0 || index === allVideos.length - 1) {
			videosInRows.push(
				<Row gutter={[16, 16]} key={index}>
					{row.map((video) => video)}
				</Row>
			);
			row = [];
		}
	});

	//Responsive Button Rendering
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleHomeClick} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					{/* <div className="accountpagebuttonsAll"></div>

      <div className="accountpage">
        <div>
        </div>
        <div className="vertical-select"><h6>Número de Vídeos</h6>
          <p className="asset-info">{countVideos}</p>
          </div>
        <div></div>
      </div> */}

					{/* <hr></hr> */}

					{/* <div><pre>{JSON.stringify(allTransactions, null, 2) }</pre></div> */}

					{videosInRows.map((row) => row)}

					<section>
						<ResponsiveMenuDrawer
							handleMenuClick={handleMenuClick}
							handleHomeClick={handleHomeClick}
							handleStrategiesClick={handleStrategiesClick}
							handleExchangesClick={handleExchangesClick}
							handleAcademyClick={handleAcademyClick}
							handleSignOut={handleSignOut}
						/>
					</section>
				</Card>
			</div>
		</div>
	);
};

export default FinanceLiteracy;
