import React,{useState,useEffect} from "react";
import {Tabs, Form, Input, Menu,Button} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";

import {
  User,
  DollarSign,
  Clock,
  Settings,
  HelpCircle,
  Info,
  Bell,
  ChevronRight,
  LifeBuoy,
  FileText,
} from 'lucide-react'; // Importing icons from Lucide

const MySettings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const [allSavingStats, setAllSavingStats] = useState([]);

  const [loading,setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('profile');

  const [form] = Form.useForm();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // Assuming contains the URL
  const openPopup = (url) => {
    const popup = window.open(url, 'popup', 'width=600,height=600');
    if (popup) {
      popup.focus();
      // console.log(url);
    }
  };

// Define the menu items with keys, icons, and labels
const items = [
  {
    key: '1',
    icon: <User />,
    label: 'Account Details',
  },
  // {
  //   key: '2',
  //   icon: <DollarSign />,
  //   label: 'Fees & Limits',
  // },
  // {
  //   key: '3',
  //   icon: <Clock />,
  //   label: 'History',
  // },
  {
    key: '4',
    icon: <Settings />,
    label: 'Security',
  },
  // {
  //   key: '5',
  //   icon: <HelpCircle />,
  //   label: 'Help Center',
  // },
  {
    key: '6',
    icon: <Info />,
    label: 'Version',
  },
  // {
  //   key: '7',
  //   icon: <Bell />,
  //   label: 'Notifications',
  // },
  {
    key: '8',
    icon: <LifeBuoy />,
    label: 'Support',
  },
  // {
  //   key: '9',
  //   icon: <FileText />,
  //   label: 'Legal',
  // },
];

const logoutHandler = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('investorProfile');
  navigate('/login');
}

const handleMenuClick = (e) => {
  const urlMapping = {
    '1': '/MyAccountDetails',
    '2': '/',
    '3': '/MyHistory',
    '4': '/Settings',
    '5': '/',
    '6': '/Version',
    '7': '/',
    '8': '/MyContacts',
    '9': '/',
  };

  const selectedUrl = urlMapping[e.key];
  if (selectedUrl) {
    window.location.href = selectedUrl; // Redirecting to the mapped URL
  }
};

  return (
    <Layout>
      <Menu
            onClick={handleMenuClick}
            mode="vertical"
            items={items.map((item) => ({
              key: item.key,
              icon: item.icon,
              label: item.label,
            }))}
          />
      <Button 
        style={{ width: "100%", marginTop: "20px" }} 
        type="primary" 
        htmlType="button" 
        onClick={logoutHandler} // Attach logout function to the button
      >
        LOG OUT
      </Button>
    </Layout>
  );
};

export default MySettings;