import React, { useState, useEffect } from "react";
import { Input, Table, Button } from "antd";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import { SearchOutlined } from "@ant-design/icons";

import moment from "moment";

const CryptoPrices = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	// const { TextArea } = Input;

	const [loading, setLoading] = useState(false);

	const [allStockAsset, setAllStockAsset] = useState([]);

	//DropList Market
	const [allMarket, setAllMarket] = useState([]);
	//DropList Sectors
	const [allSector, setAllSector] = useState([]);
	//DropList Industry
	const [allIndustry, setAllIndustry] = useState([]);

	//Filters
	const [marketName, setMarketNameFilter] = useState("all");
	const [fundFamilyName, setFundFamilyNameFilter] = useState("all");
	const [categoryAssetName, setCategoryAssetNameFilter] = useState("all");

	//table users **************************************************************************************************************************
	const columns = [
		{
			key: "dateRefresh",
			title: "Última Data Actualização",
			dataIndex: "dateRefresh",
			// defaultSortOrder: 'descend',
			align: "center",
			render: (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
			sorter: (a, b) => new Date(a.dateRefresh) - new Date(b.dateRefresh),
		},
		{
			key: "cryptoAssetName",
			title: "Activo",
			dataIndex: ["cryptoAssetName", "cryptoAssetName"],
			sorter: (a, b) =>
				a.cryptoAssetName.cryptoAssetName.localeCompare(
					b.cryptoAssetName.cryptoAssetName
				),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.cryptoAssetName.cryptoAssetName
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "binanceTicker",
			title: "Binance Ticker",
			dataIndex: ["cryptoAssetName", "binanceTicker"],
			sorter: (a, b) =>
				a.binanceTicker.binanceTicker.localeCompare(
					b.binanceTicker.binanceTicker
				),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.binanceTicker.binanceTicker
					.toLowerCase()
					.includes(value.toLowerCase());
			},
		},
		{
			key: "brokerName",
			title: "Broker",
			dataIndex: "brokerName",
			sorter: (a, b) => a.brokerName.localeCompare(b.brokerName),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.brokerName.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "price",
			title: "Preço",
			dataIndex: "price",
			align: "right",
			sorter: (a, b) => a.price - b.price,
			render: (price, record) => {
				if (record.price >= 0)
					return (
						// <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
						<span> {parseFloat(price).toFixed(record.ticketSize)}</span>
					);
			},
		},
		{
			key: "currency",
			title: "",
			dataIndex: ["currency"],
			sorter: (a, b) => a.currency.localeCompare(b.currency),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<Input
							autoFocus
							placeholder="Type text here"
							value={selectedKeys[0]}
							onChange={(e) => {
								setSelectedKeys(e.target.value ? [e.target.value] : []);
								confirm({ closeDropdown: false });
							}}
							onPressEnter={() => {
								confirm();
							}}
							onBlur={() => {
								confirm();
							}}
						></Input>
						<Button
							onClick={() => {
								confirm();
							}}
							type="primary"
						>
							Search
						</Button>
						<Button
							onClick={() => {
								clearFilters();
							}}
							type="danger"
						>
							Reset
						</Button>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.currency.toLowerCase().includes(value.toLowerCase());
			},
		},
		{
			key: "returnPercentage",
			title: "Retorno%",
			dataIndex: "returnPercentage",
			defaultSortOrder: "descend",
			align: "right",
			sorter: (a, b) => a.returnPercentage - b.returnPercentage,
			render: (returnPercentage, record) => {
				if (record.returnPercentage >= 0)
					return (
						<span style={{ color: "#39A939" }}>
							{returnPercentage.toLocaleString("pt-BR")} %
						</span>
					);
				return (
					<span style={{ color: "#FF3234" }}>
						{returnPercentage.toLocaleString("pt-BR")} %
					</span>
				);
			},
		},
	];

	const user = JSON.parse(localStorage.getItem("user"));

	//useEffects Hook **************************************************************************************************************************
	//List of Users
	useEffect(() => {
		const getAllStockAsset = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/cryptoPrices/get-cryptoPrice",
					{},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllStockAsset(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With Industry');
				// message.error('You are not Admin User');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllStockAsset();
	}, []);

	//APP  **************************************************************************************************************************
	return (
		<Layout>
			{loading && <Spinner />}
			<div className="accountpage">
				<div></div>
				<div>
					<h1>Definições - Cotações Criptomoedas</h1>
				</div>
				<div></div>
			</div>
			<div className="accountpagebuttonsAll">
				<Button
					shape="round"
					className="btn btn-primary btn-block"
					style={{ background: "green", borderColor: "green" }}
					onClick={() => navigate("/ProcessingRefreshCryptoPricesDaily")}
				>
					Refresh Cotações
				</Button>
			</div>

			<div className="accountpagebuttonsAll">
				<Button
					align="right"
					shape="round"
					className="btn btn-primary"
					style={{ background: "blue", borderColor: "blue" }}
					onClick={() => navigate("/")}
				>
					Voltar
				</Button>
			</div>
			<hr></hr>
			<div className="content">
				<Table
					size="small"
					columns={columns}
					dataSource={allStockAsset}
					pagination={{
						position: ["bottomCenter"],
						defaultPageSize: 25,
						showSizeChanger: true,
						pageSizeOptions: ["10", "25", "50"],
					}}
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
				/>
			</div>

			{/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}
		</Layout>
	);
};

export default CryptoPrices;
