import React, { useState, useEffect, useRef } from "react";
import { Spin, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import MyProcessing from "./../components/Assets/engineering.png";

const ProcessingOwnSaving = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const savingNameUpdate = data.savingName;
	const exchangeNameUpdate = data.exchangeName;
	const processingUpdate = data.processing;
	const investmentTypeUpdate = data.investmentType;
	const savingProcessIdUpdate = data.savingProcessId;
	const productAssetUpdate = data.productAssetDetails;
	const amountInvestmentUpdate = data.amountInvestment;

	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);
	const hasRun = useRef(false); // Track if the effect has run

	const getSavingRefreshProcessing = async () => {
		setLoading(true);
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			await axios.post(
				"/savings/create-own-saving-account/" +
					user._id +
					"/" +
					exchangeNameUpdate +
					"/" +
					savingProcessIdUpdate,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			navigate("/");
		} catch (error) {
			console.error("Error fetching :", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!hasRun.current) {
			hasRun.current = true; // Set the flag to true
			getSavingRefreshProcessing();
		}
	}, []);

	return (
		<Layout>
			<div className="container" style={{ height: "75vh" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "50px",
					}}
				>
					{loading && <Spin />}
					<br />
					<img alt="example" src={MyProcessing} />
					<br />
					<h1 align="center">
						{processingUpdate} <br /> {savingNameUpdate}
					</h1>
					<br />
					<p style={{ textAlign: "center" }}>
						Please wait Window will close when processing is complete.
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default ProcessingOwnSaving;
