import React,{useState,useEffect} from "react";
import {Input, Table, Button,Form,Space} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
} from "@ant-design/icons";

import moment from 'moment';

const CurrencyRates = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  const [allCurrencyRate, setAllCurrencyRate] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Sectors
  const [allSector, setAllSector] = useState([]);
  //DropList Industry
  const [, setAllIndustry] = useState([]);

  //Filters
  const [marketName, setMarketNameFilter] = useState('all');
  const [fundFamilyName, setFundFamilyNameFilter] = useState('all');
  const [categoryAssetName, setCategoryAssetNameFilter] = useState('all');

  //Fields from table to View
  const [descriptionUpdate, setDescriptionUpdate] = useState(null);
  const [websiteUpdate, setWebsiteUpdate] = useState(null);

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "date",
      title: "Última Data Actualização",
      dataIndex: "date",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD")}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date)
    },
    {
      key: "symbol",
      title: "Símbolo",
      dataIndex: ["symbol"],
      align: 'center',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.symbol.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "yahooTicker",
      title: "Yahoo Ticker",
      dataIndex: ["yahooTicker"],
      align: 'center',
      sorter: (a, b) => a.yahooTicker.localeCompare(b.yahooTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.yahooTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "currency",
      title: "Moeda Base",
      dataIndex: ["currency"],
      align: 'center',
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "currency_ctr",
      title: "Moeda Contra-Parte",
      dataIndex: ["currency_ctr"],
      align: 'center',
      sorter: (a, b) => a.currency_ctr.localeCompare(b.currency_ctr),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.currency_ctr.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"value",
      title: "Preço",
      dataIndex: "value",
      align: 'right',
      sorter: (a, b) => a.value - b.value,
    },
    {
      key:"returnPercentage",
      title: "Retorno%",
      dataIndex: "returnPercentage",
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.returnPercentage - b.returnPercentage,
      render: (returnPercentage, record) => {
        if(record.returnPercentage >= 0 )
          return (
          <span style={{color: "#39A939"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span style={{color: "#FF3234"}}>{returnPercentage.toLocaleString('pt-BR')} %</span>
          );
      },
  },
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllCurrencyAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/currencyRates/get-currencyRate',
        {

        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCurrencyRate(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    getAllCurrencyAsset();

    // // Clean up the interval when the component unmounts
    // return () => getAllStockAsset();
  },[marketName,fundFamilyName,categoryAssetName])

  // //Handler Markets DropList ****************************************************************************************
  // useEffect(() => {
  //   const getAllMarkets = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/markets/get-etfmarket', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllMarket(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       message.error('You are not Admin User');
  //     }
  //   };  
  //   getAllMarkets();
  // },[])

  // //Handler Sectors DropList ****************************************************************************************
  // useEffect(() => {
  //   const getAllSectors = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/FundFamilies/get-fundFamily', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllSector(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       // message.error('You are not Admin User');
  //     }
  //   };  
    
  //   // Clean up the interval when the component unmounts
  //   return () => getAllSectors();
  // },[])

  // //Handler Sectors DropList ****************************************************************************************
  // useEffect(() => {
  //   const getAllIndustries = async (values) => {
  //     try{
  //       const user = JSON.parse(localStorage.getItem('user'))
  //       setLoading(true);
  //       const res = await axios.post('/CategoryAssets/get-categoryAsset', {
  //         userid:user._id,
  //       },
  //       {
  //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
  //       }
  //       );
  //       setLoading(false);
  //       setAllIndustry(res.data);
  //       console.log(res.data);
  //     }catch (error){
  //       setLoading(false);
  //       //message.error('Fetch Issue With sector');
  //       // message.error('You are not Admin User');
  //     }
  //   };  
    
  //   // Clean up the interval when the component unmounts
  //   return () => getAllIndustries();
  // },[])

    /************************************Website DISABLE BUTTON***************************/
    let webSiteInputDisable;

    if (!websiteUpdate) {
      webSiteInputDisable = 
      <Form.Item label="Morningstar Website Stats" name="website">
          <Input 
          type="text"
          />
      </Form.Item>
    } else {
      webSiteInputDisable = 
      <Form.Item label="" name="website">
        <Space.Compact style={{ width: '100%' }}>
          <Button style={{width: "100%" }} type="primary" onClick={() => window.open(websiteUpdate, "Popup","width=600, height=600")}>VER MAIS NO WEBSITE</Button>
        </Space.Compact>
      </Form.Item>
    }

      //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setDescriptionUpdate(null);
    setWebsiteUpdate(null);
  };

  // //Handler Close Modal
  // const handleClose = () => {
  //   setShow(false);
  // };

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Definições - Cotações Moedas Cambiais</h1></div>
        <div></div>
      </div>
      <div className="accountpagebuttonsAll">
      <Button
        shape="round"
        className="btn btn-primary btn-block"
        style={{ background: "green", borderColor: "green" }}
        onClick={() => navigate('/ProcessingRefreshCurrecyRatesDaily')}
      >
        Refresh Cambiais
      </Button>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "blue", borderColor: "blue" }}
            onClick={() => navigate("/ExplorerSettings")}
            >Voltar
          </Button>
        </div>
      <hr></hr>
      <div className="content">
        <Table 
            size="small"
            columns={columns}
            dataSource={allCurrencyRate}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>

      {/* <div><pre>{JSON.stringify(allCurrencyRate, null, 2) }</pre></div> */}

    </Layout>
  );
};

export default CurrencyRates;