import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../components/Layout/Navbar";
import { useMediaQuery } from "react-responsive";
import Chart from "react-apexcharts";
import ky from "ky";
import Marquee from "react-fast-marquee";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";

import {
	Card,
	Typography,
	Row,
	Col,
	Switch,
	Table,
	Input,
	Button,
	FloatButton,
	Space,
	DatePicker,
	Tabs,
	Tooltip,
	Statistic,
	Divider,
	Collapse,
	Form,
	message,
	Popconfirm,
	Modal,
	Select,
	Menu,
	Dropdown,
	Slider,
	Progress,
	Layout,
	Spin,
} from "antd";
import { format, subDays } from "date-fns";
import {
	ArrowUpOutlined,
	ArrowDownOutlined,
	SearchOutlined,
	CloseOutlined,
	ReloadOutlined,
	PlusOutlined,
	EditOutlined,
	StopOutlined,
	QuestionCircleOutlined,
	LockOutlined,
	CheckCircleOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
	BadgePercent,
	Star,
	Crown,
	Gem,
	ArrowRight,
	Database,
	MoreHorizontal,
	Edit,
	FileText,
	RefreshCcw,
	BarChart,
	PiggyBank,
	GraduationCap,
	ArrowLeftRight,
	TrendingUp,
	RefreshCw,
	RefreshCwIcon,
} from "lucide-react";
import MySaving from "./../components/Assets/robot.png";
import MyRetirement from "./../components/Assets/beach-chair.png";
import MyTravel from "./../components/Assets/beach.png";
import MyHouse from "./../components/Assets/buy-a-house.png";
import MyTecnology from "./../components/Assets/buy-technolology.png";
import MyCar from "./../components/Assets/buy-a-car.png";
import MyTermGrowth from "./../components/Assets/longterm.png";
import MyChildren from "./../components/Assets/playtime.png";
import MyHealthcare from "./../components/Assets/healthcare.png";
import MyZen from "./../components/Assets/meditation.png";

const { Title, Text } = Typography;
const { Meta } = Card;
const { Option } = Select; // Extract Option from Select

const HomePage = ({ portfolio }) => {
	// Helper function to filter the data by a given range
	const filterDataByPeriod = (data, days) => {
		const endDate = new Date();
		const startDate = subDays(endDate, days); // Calculate the start date

		return data.filter((item) => {
			const itemDate = new Date(item.dateSavingPerformance);
			return itemDate >= startDate && itemDate <= endDate; // Include only the items within the range
		});
	};

	// Calculate the max value
	const getMaxValue = (data) => {
		return Math.max(...data.map((item) => item.today_value)); // Return the highest value
	};

	// Helper function to get min value
	const getMinValue = (data) =>
		Math.min(...data.map((item) => item.today_value));

	// Helper function to calculate cumulative return
	const getCumulativeReturn = (data) => {
		const initialValue = data[0]?.today_value || 0;
		const finalValue = data[data.length - 1]?.today_value || 0;
		return initialValue
			? ((finalValue - initialValue) / initialValue) * 100
			: 0;
	};

	const today = new Date();
	//loading Apis
	const [loading, setLoading] = useState(false);
	const [loginUser, setLoginUser] = useState("");

	//APIS
	const [allSavingStats, setAllSavingStats] = useState([]);
	const [allSavingPerformances, setSavingPerformances] = useState([]);
	const [allCash, setAllCash] = useState([]);

	const navigate = useNavigate();

	const [isVisible, setIsVisible] = useState(false);
	const [showDailyReturns, setShowDailyReturns] = useState(false);
	const [isUSD, setIsUSD] = useState(true);
	const [exchangeRate, setExchangeRate] = useState({ usd: 1, eur: 1 });

	const [selectedPeriod, setSelectedPeriod] = useState("1mo");
	const [customDateRange, setCustomDateRange] = useState([null, null]);

	// Toggle visibility handler
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	// Toggle return type handler
	const toggleReturnType = (checked) => {
		setShowDailyReturns(checked);
	};

	// Toggle currency type handler
	const toggleCurrency = (checked) => {
		setIsUSD(checked);
	};

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const user_agentName = loginUser.agentName;
	// console.log("user_agent:",user_agent)
	const profileType = loginUser.profileType;
	const newUsers = Boolean(loginUser.newUser);
	console.log("newUsers", newUsers);
	const haveInvestorProfiles = Boolean(loginUser.haveInvestorProfile);
	console.log("haveInvestorProfiles", haveInvestorProfiles);
	const currencyRate = loginUser.currency;

	console.log("currencyRate", currencyRate);

	// // Ensure the user object exists
	// if (loginUser) {
	//   // Update the newUser property
	//   loginUser.newUser = false;

	//   // Save the updated user object back to localStorage
	//   localStorage.setItem('user', JSON.stringify(loginUser));
	// } else {
	//   console.error("No user object found in localStorage.");
	// }

	const convertToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;
		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(convertedAmount);
	};

	const getSavingNameImage = (categoryName) => {
		switch (categoryName) {
			case "6652420623d1a2622afcff84": //MyRetirement
				return MyRetirement;
			case "6652423c23d1a2622afcff92": //MyChildren
				return MyChildren;
			case "6652421a23d1a2622afcff8b": //MyHouse
				return MyHouse;
			case "665242f123d1a2622afcffd4": //MyCar
				return MyCar;
			case "6659b35eb19a5a32a59b7e5c": //MyTecnology
				return MyTecnology;
			case "6652425c23d1a2622afcff99": //MyTermGrowth
				return MyTermGrowth;
			case "6652427a23d1a2622afcffa0": //MyZen
				return MyZen;
			case "666983edfeb2426ac60808c2": //MyTravel
				return MyTravel;
			case "66698565feb2426ac6080995": //MyHealthcare
				return MyHealthcare;
			default:
				return MySaving;
		}
	};

	// Fetch exchange rates from CoinGecko API
	const fetchExchangeRate = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=" +
					user.currency.toLowerCase() +
					",usd&vs_currencies=" +
					user.currency.toLowerCase() +
					",usd"
			);
			const data = await response.json();
			setExchangeRate({
				usd: data.usd[user.currency.toLowerCase()], // USD to EUR rate
				eur: 1, // Base currency EUR
			});
			console.log(data);
		} catch (error) {
			console.error("Error fetching exchange rate:", error);
		}
	};

	// Call the fetch function when the component mounts
	useEffect(() => {
		fetchExchangeRate();
	}, []);

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllBalance = async (values) => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));
				const res = await axios.post(
					"/savingbalances/get-allsavingbalance",
					{
						userid: user._id,
						filterOption: "open",
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllSavingStats(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				message.error("You are not Admin User");
			}
		};
		getAllBalance();
	}, []);

	//Analytics
	const currentBalance = allSavingStats.reduce(
		(acc, transaction) => acc + transaction.today_value,
		0
	);

	const returns = allSavingStats.reduce(
		(acc, transaction) => acc + transaction.diffAccum,
		0
	);

	const dailyReturn = allSavingStats.reduce(
		(acc, transaction) => acc + transaction.diffDaily,
		0
	);

	const invested = currentBalance - returns;

	const returnPercentage = ((returns / invested) * 100).toFixed(2);
	const dailyReturnPercentage = ((dailyReturn / invested) * 100).toFixed(2);

	const returnColor = returns > 0 ? "green" : "red";
	const dailyReturnColor = dailyReturn > 0 ? "green" : "red";

	// Fetch Cash
	useEffect(() => {
		const getAllCash = async (values) => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));
				const res = await axios.post(
					"/cash-exchanges/get-cash-exchange",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllCash(res.data);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				message.error("You are not Admin User");
			}
		};
		getAllCash();
	}, []);

	//Analytics
	const cash = allCash.reduce((acc, transaction) => acc + transaction.cash, 0);

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllSavingPerformances = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/savingperformances/get-allsaving-performance",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setSavingPerformances(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingPerformances();
	}, []);

	let range = { min: 1, max: 999999999 };
	let delta = range.max - range.min;

	const codeSquare = Math.round(range.min + Math.random() * delta);

	//NEW EXCHANGE
	const handleSubmitNewExchange = (values) => {
		// console.log('Submitted Email:', values);
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/exchanges/add-exchange",
				{
					...values,
					brokerName: "Binance",
					codeSquare: codeSquare,
					assetType: "Cryptocurrencies",
					quantity: 1,
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/users/edit-user",
				{
					payload: {
						newUser: false,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			// Ensure the user object exists
			if (user) {
				// Update the newUser property
				user.newUser = false;

				// Save the updated user object back to localStorage
				localStorage.setItem("user", JSON.stringify(user));
			} else {
				console.error("No user object found in localStorage.");
			}

			setLoading(false);
			navigate("/ProcessingExchanges", {
				state: {
					exchangeName: values.investmentName,
					processing: "Portfolio em criação",
					navigateLink: "/",
					codeSquare: codeSquare,
				},
			});
		} catch (error) {
			setLoading(false);
		}
	};

	// Updated tickerData with price and return status
	const tickerData = [
		{ name: "Bitcoin (BTC)", price: "$67,794.98", return: 2.5 }, // Positive return
		{ name: "Ethereum (ETH)", price: "$4,700.00", return: -1.0 }, // Negative return
		{ name: "Ripple (XRP)", price: "$1.24", return: 0.5 }, // Positive return
		{ name: "Litecoin (LTC)", price: "$200.00", return: -3.0 }, // Negative return
		{ name: "Cardano (ADA)", price: "$2.50", return: 1.2 }, // Positive return
		// Add more items as needed
	];

	// Log the performanceData to the console
	console.log(allSavingPerformances);

	// Data for different periods
	const periodData = {
		"7d": filterDataByPeriod(allSavingPerformances, 7),
		"1mo": filterDataByPeriod(allSavingPerformances, 30),
		"3mo": filterDataByPeriod(allSavingPerformances, 90),
		"1yr": filterDataByPeriod(allSavingPerformances, 365),
		allTime: allSavingPerformances, // For MAX, use all-time data
	};

	// Get the data for the selected period
	const dataToUse = periodData[selectedPeriod];

	// Get the max value for the selected period
	const maxValue = getMaxValue(dataToUse);

	// Get the min value for the selected period
	const minValue = getMinValue(dataToUse);

	// Get the cumulative return for the selected period
	const cumulativeReturn = getCumulativeReturn(dataToUse);

	// Calculate the midpoint of minValue and maxValue
	const midValue = (maxValue + minValue) / 2;

	// Prepare chart data
	const chartData = dataToUse.map((item) => ({
		x: item.dateSavingPerformance,
		y: item.today_value,
	}));

	const options = {
		chart: {
			id: "portfolio-performance-chart",
			type: "area",
			toolbar: { show: false },
		},
		xaxis: {
			type: "datetime",
		},
		yaxis: {
			labels: {
				formatter: (value) => `$${value.toLocaleString()}`,
			},
		},
		stroke: {
			curve: "smooth",
			width: 2,
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 0.4,
		},
		tooltip: {
			x: {
				formatter: (value) => new Date(value).toLocaleDateString(),
			},
		},
		annotations: {
			yaxis: [
				{
					y: maxValue,
					borderColor: "#FF4560",
					label: {
						text: `Max: $${maxValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#FF4560",
						},
					},
				},
				{
					y: minValue,
					borderColor: "#775DD0",
					label: {
						text: `Min: $${minValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#775DD0",
						},
					},
				},
				{
					y: midValue, // Positioning Cumulative Return at the midpoint
					borderColor: "#00E396",
					label: {
						text: `Cumulative Return: ${cumulativeReturn.toFixed(2)}%`,
						style: {
							color: "#fff",
							background: "#00E396",
						},
					},
				},
			],
		},
	};

	// State management
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	const handleNewExchange = () => {
		setIsModalOpen(true); // Open modal
	};

	const handleCancel = () => {
		setIsModalOpen(false); // Close modal
		form.resetFields(); // Optionally reset form fields on close
	};

	const handleNewProfileQuiz = () => {
		navigate("/InvestorProfiles");
	};

	const handleNewSaving = () => {
		navigate("/NewSavings");
	};

	const handleRefreshPortfolioData = () => {
		navigate("/ProcessingRefreshMySavings");
	};

	const handleStrategies = () => {
		// navigate('/ProcessingRefreshMySavings');
	};

	const handleExchanges = () => {
		navigate("/MyInvestments");
	};

	const handleAcademy = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	// Handler function for navigation
	const handleMySavingPortfolio = (portfolio) => {
		navigate("/SavingPortfolio", {
			state: {
				savingNameID: portfolio.savingName._id,
				savingName: portfolio.savingName.savingName,
				savingProcessID: portfolio.savingProcessId,
				productName: portfolio.productName.productName,
				closePortfolio: portfolio.savingName.closePortfolio,
			},
		});
	};

	// Dynamic Button Rendering
	const renderButton = () => {
		if (newUsers && !haveInvestorProfiles) {
			return (
				<Button
					className="button-app"
					style={{
						backgroundColor: "#9133EF",
						borderColor: "#9133EF",
						color: "#fff",
					}}
					size="large"
					onClick={handleNewExchange}
					icon={
						<RefreshCcw
							size={24}
							style={{ marginRight: "8px", color: "#fff" }}
						/>
					} // Add icon
				>
					New Exchange
				</Button>
			);
		} else if (!haveInvestorProfiles && !newUsers) {
			return (
				<Button
					className="button-app"
					style={{
						backgroundColor: "#2F005E",
						borderColor: "#2F005E",
						color: "#fff",
					}}
					size="large"
					onClick={handleNewProfileQuiz}
					icon={
						<BarChart size={24} style={{ marginRight: "8px", color: "#fff" }} />
					} // Add icon
				>
					New Profile Quiz
				</Button>
			);
		} else {
			return (
				<Button
					className="button-app"
					style={{
						backgroundColor: "#7600ec",
						borderColor: "#7600ec",
						color: "#fff",
					}}
					size="large"
					onClick={handleNewSaving}
					icon={
						<PiggyBank
							size={24}
							style={{ marginRight: "8px", color: "#fff" }}
						/>
					} // Add icon
				>
					New Saving
				</Button>
			);
		}
	};

	const [form] = Form.useForm();

	//Handler Test Console Log Values
	const onFinish = (values) => {
		console.log({ values });
	};

	//Responsive Button Rendering
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	const handleHelpNavigation = () => {
		navigate("/HelpBinanceAccount"); // Navigate to the specified route
	};

	//***************************RENDER**************************************************************************/
	return (
		<Layout>
			<Navbar />

			{loading && <Spin />}

			<div className="container">
				{profileType === 0 && (
					<>
						{/* Section 2: Financial Summary Card */}
						<section>
							<Card className="card-financial-summary">
								<Row
									justify="space-between"
									align="middle"
									className="daily-returns-toggle"
								>
									<Title level={4} className="section-title">
										Total Market Values
									</Title>
									<Switch
										checked={isUSD}
										onChange={toggleCurrency}
										checkedChildren="USD"
										unCheckedChildren={currencyRate}
									/>
								</Row>
								<Row justify="center" className="balance-section">
									<Col onClick={toggleVisibility} className="balance-text">
										<h1>
											{isVisible
												? convertToCurrency(currentBalance)
												: "Tap to see balance"}
										</h1>
									</Col>
								</Row>
								<Row justify="space-between" className="summary-row">
									<Col>Net Contributions</Col>
									<Col>Returns</Col>
									<Col>Cash Available</Col>
								</Row>
								<Row justify="space-between" className="summary-row">
									<Col>{isVisible ? convertToCurrency(invested) : "****"}</Col>
									<Col style={{ textAlign: "left" }}>
										<Text
											style={{
												color: isVisible
													? showDailyReturns
														? dailyReturnColor
														: returnColor
													: "#999",
												fontWeight: "bold",
											}}
										>
											{isVisible
												? showDailyReturns
													? dailyReturn && dailyReturnPercentage
														? `${convertToCurrency(
																dailyReturn
														  )} (${dailyReturnPercentage}%)`
														: "0.00 (0.00%)"
													: returns && returnPercentage
													? `${convertToCurrency(
															returns
													  )} (${returnPercentage}%)`
													: "0.00 (0.00%)"
												: "****"}
										</Text>
									</Col>
									<Col>{isVisible ? convertToCurrency(cash) : "****"}</Col>
								</Row>

								<Row
									justify="center"
									className="daily-returns-toggle"
									style={{ marginTop: "20px" }}
								>
									<Text>Show Daily Returns</Text>
									<Switch
										checked={showDailyReturns}
										onChange={toggleReturnType}
										checkedChildren="Yes"
										unCheckedChildren="No"
									/>
								</Row>
							</Card>
						</section>

						<section>
							<Row
								gutter={[10, 10]}
								justify="center"
								style={{ textAlign: "center" }}
							>
								<Col xs={6} sm={6} md={6}>
									<Tooltip title="Refresh">
										<Button
											icon={<RefreshCwIcon size={24} color="white" />}
											block
											shape="square"
											size="large"
											style={{
												height: "60px",
												width: "60px",
												backgroundColor: "#7600ec", // Set background color
												borderColor: "#7600ec", // Set border color to match
												color: "white", // Set text/icon color to white
											}}
											onClick={handleRefreshPortfolioData}
										/>
									</Tooltip>
								</Col>
								<Col xs={6} sm={6} md={6}>
									<Tooltip title="Strategies">
										<Button
											icon={<TrendingUp size={24} color="white" />}
											block
											shape="square"
											size="large"
											style={{
												height: "60px",
												width: "60px",
												backgroundColor: "#7600ec", // Set background color
												borderColor: "#7600ec", // Set border color to match
												color: "white", // Set text/icon color to white
											}}
											onClick={handleRefreshPortfolioData}
										/>
									</Tooltip>
								</Col>
								<Col xs={6} sm={6} md={6}>
									<Tooltip title="Exchanges">
										<Button
											icon={<ArrowLeftRight size={24} color="white" />}
											block
											shape="square"
											size="large"
											style={{
												height: "60px",
												width: "60px",
												backgroundColor: "#7600ec", // Set background color
												borderColor: "#7600ec", // Set border color to match
												color: "white", // Set text/icon color to white
											}}
											onClick={handleExchanges}
										/>
									</Tooltip>
								</Col>
								<Col xs={6} sm={6} md={6}>
									<Tooltip title="Academy">
										<Button
											icon={<GraduationCap size={24} color="white" />}
											block
											shape="square"
											size="large"
											style={{
												height: "60px",
												width: "60px",
												backgroundColor: "#7600ec", // Set background color
												borderColor: "#7600ec", // Set border color to match
												color: "white", // Set text/icon color to white
											}}
											onClick={handleAcademy}
										/>
									</Tooltip>
								</Col>
							</Row>
						</section>

						{/* Section 4: Graph */}
						<section>
							<Card>
								<Chart
									options={options}
									series={[{ name: "Portfolio Value", data: chartData }]}
									type="area"
									height={350}
								/>

								<div className="tabs-container">
									<Tabs
										defaultActiveKey="1mo"
										onChange={(key) => setSelectedPeriod(key)}
										items={[
											{ label: "7d", key: "7d" },
											{ label: "1M", key: "1mo" },
											{ label: "3M", key: "3mo" },
											{ label: "1Y", key: "1yr" },
											{ label: "MAX", key: "allTime" },
										]}
									/>
								</div>
							</Card>
						</section>

						{/* Section 5: Cards */}
						<section>
							<>
								{allSavingStats.map((portfolio) => {
									const progressPercentage = Math.min(
										(portfolio.today_value / portfolio.savingName.target) * 100,
										100
									).toFixed(2);

									return (
										<a
											key={portfolio.id}
											href={portfolio.page}
											target="_blank"
											rel="noopener noreferrer"
											style={{
												textDecoration: "none",
												color: "inherit",
												marginBottom: "10px",
											}}
										>
											<Card
												hoverable
												bordered={false}
												className="coin-card-content"
												onClick={() => handleMySavingPortfolio(portfolio)} // Pass portfolio to the handler
											>
												<Meta
													title={
														<div
															className="coin-info"
															style={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
															}}
														>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<img
																	alt="Categories bitAdvisor"
																	src={getSavingNameImage(
																		portfolio.savingName.categoryName
																	)}
																	className="coin-logo"
																/>
																<div
																	className="coin-details"
																	style={{ marginLeft: 10 }}
																>
																	<span className="coin-symbol">
																		{portfolio.savingName.savingName.toUpperCase()}
																	</span>
																	<span className="coin-name">
																		{portfolio.productName.productName}
																	</span>
																</div>
															</div>

															<div style={{ textAlign: "right" }}>
																<p
																	className="current-price"
																	style={{ margin: 0 }}
																>
																	${portfolio.today_value.toFixed(2)}
																</p>
																<p
																	className={
																		portfolio.pctTodayValueAccum.toFixed(2) >= 0
																			? "market-cap-positive"
																			: "market-cap-negative"
																	}
																	style={{ margin: 0, fontSize: "12px" }}
																>
																	${portfolio.diffAccum.toFixed(2)}(
																	{(portfolio.pctTodayValueAccum * 100).toFixed(
																		2
																	)}
																	%)
																</p>
															</div>
														</div>
													}
												/>
												<Progress
													percent={parseFloat(progressPercentage)}
													style={{ marginTop: "10px" }}
													showInfo={true}
													strokeColor={
														parseFloat(progressPercentage) >= 100
															? "#52c41a"
															: "#673ab7"
													}
													status={
														parseFloat(progressPercentage) >= 100
															? "success"
															: "active"
													}
													format={() => (
														<span style={{ fontSize: "10px" }}>
															{progressPercentage}%
														</span>
													)}
												/>
											</Card>
										</a>
									);
								})}
							</>
						</section>

						{/* Section 6: NewSaving */}
						<section>
							<Row justify="center" style={{ margin: "20px 0" }}>
								{renderButton()}

								{/* Modal for New Exchange */}
								{/* Modal for New Exchange */}
								<Modal
									title={
										<div style={{ textAlign: "center", width: "100%" }}>
											Connect New Exchange
										</div>
									}
									open={isModalOpen}
									onCancel={handleCancel}
									centered
									footer={null} // Remove default modal footer
								>
									{/* Centered Binance Logo */}
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											marginBottom: "16px",
										}}
									>
										<img
											src="https://cdn.worldvectorlogo.com/logos/binance.svg"
											alt="Binance Logo"
											style={{ width: "120px", height: "120px" }}
										/>
									</div>

									{/* Form */}
									<Form
										form={form}
										layout="vertical"
										onFinish={handleSubmitNewExchange} // Form submission handler
									>
										{/* Select Dropdown */}
										<Form.Item
											label="Exchange"
											name="exchange"
											initialValue="Binance"
											rules={[
												{
													required: true,
													message: "Please select an exchange!",
												},
											]}
										>
											<Select>
												<Option value="Binance">Binance</Option>
											</Select>
										</Form.Item>

										{/* Connection Name */}
										<Form.Item label="Connection name" name="investmentName">
											<Input placeholder="Connection Name" type="text" />
										</Form.Item>

										{/* API Key Input */}
										<Form.Item
											label="API Key"
											name="apiKey"
											rules={[
												{
													required: true,
													message: "Please input the API key!",
												},
											]}
										>
											<Input placeholder="API Key" type="text" />
										</Form.Item>

										{/* Secret Key Input */}
										<Form.Item
											label="Secret Key"
											name="keySecret"
											rules={[
												{
													required: true,
													message: "Please input the secret key!",
												},
											]}
										>
											<Input placeholder="Secret Key" type="text" />
										</Form.Item>

										{/* Help Link */}
										<p style={{ marginBottom: "16px", textAlign: "center" }}>
											Don&apos;t have a Binance account?{" "}
											<a
												href="https://www.binance.com/activity/referral-entry/CPA/together-v4?hl=en&ref=CPA_00FTGI1106"
												target="_blank"
												rel="noopener noreferrer"
											>
												Create it now
											</a>
										</p>

										{/* Connect Button */}
										<Form.Item>
											<Button type="primary" htmlType="submit" block>
												Connect
											</Button>
										</Form.Item>

										{/* Informational Cards */}
										<div style={{ marginTop: "20px" }}>
											<Card
												style={{
													marginBottom: "10px",
													padding: "12px", // Reduce padding for smaller appearance
													fontSize: "14px", // Smaller font size for text
												}}
												bodyStyle={{ padding: "10px" }} // Further control over the internal padding
											>
												<h4 style={{ marginBottom: "8px" }}>
													🔒 Secure Connection
												</h4>
												<p style={{ margin: 0 }}>
													All data is exchanged over a secure, encrypted
													connection to protect your information.
												</p>
											</Card>
											<Card
												style={{
													padding: "12px",
													fontSize: "14px",
												}}
												bodyStyle={{ padding: "10px" }}
											>
												<h4 style={{ marginBottom: "8px" }}>
													🔧 User-Controlled Permissions
												</h4>
												<p style={{ margin: 0 }}>
													You are in control. You can review and revoke access
													at any time from your account settings.
												</p>
											</Card>
										</div>
										<div style={{ marginTop: "20px" }}>
											<Card
												style={{
													marginBottom: "10px",
													padding: "12px",
													fontSize: "14px",
												}}
												bodyStyle={{ padding: "10px" }}
											>
												<h4 style={{ marginBottom: "8px" }}>
													📝 Step-by-Step Instructions
												</h4>
												<ol style={{ paddingLeft: "20px", margin: "0" }}>
													<li style={{ marginBottom: "8px" }}>
														Login to your Binance account on your computer.
													</li>
													<li style={{ marginBottom: "8px" }}>
														Select <strong>Account</strong> from the left side
														menu <strong>API Management</strong>.
													</li>
													<li style={{ marginBottom: "8px" }}>
														Click the <strong>Create API</strong> button and
														choose <strong>System generated API key</strong>{" "}
														type.
													</li>
													<li style={{ marginBottom: "8px" }}>
														In the <strong>Label API Key</strong> field, type
														what you want to call it, e.g.,{" "}
														<em>BitAdvisor Binance</em>, then click{" "}
														<strong>Next</strong>.
													</li>
													<li style={{ marginBottom: "8px" }}>
														Input your{" "}
														<strong>Google Authentication Code (2FA)</strong>{" "}
														for Binance and{" "}
														<strong>Email Verification Code</strong>.
													</li>
													<li style={{ marginBottom: "8px" }}>
														Next, copy the <strong>API Key</strong> and{" "}
														<strong>Secret Key</strong> to BitAdvisor.
													</li>
												</ol>
											</Card>
											{/* Help Link */}
											<p style={{ marginBottom: "16px", textAlign: "center" }}>
												Do you need more help?{" "}
												<span
													onClick={handleHelpNavigation}
													style={{
														color: "#1890ff",
														cursor: "pointer",
														textDecoration: "underline",
													}}
												>
													Click here
												</span>
											</p>
										</div>
									</Form>
								</Modal>
							</Row>
						</section>
					</>
				)}
				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</Layout>
	);
};

export default HomePage;
