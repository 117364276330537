import React, { useState, useEffect } from "react";
import {
	Form,
	Input,
	message,
	Select,
	Spin,
	Button,
	Card,
	InputNumber,
	Switch,
} from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";

const EditSavings = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;
	// console.log("data:",data);

	const savingNameIDUpdate = data.savingNameID;
	const savingNameUpdate = data.savingName;
	const targetUpdate = data.target;
	const savingProcessIDUpdate = data.savingProcessID;
	const categoryNameUpdate = data.categoryNameUpdate;
	const productNameUpdate = data.productNameUpdate;
	const agentNameUpdate = data.agentNameUpdate;
	const IBANUpdate = data.IBANUpdate;
	const monthlyAmountUpdate = data.monthlyAmountUpdate;
	const dayPaymentUpdate = data.dayPaymentUpdate;
	const isDepositActiveUpdate = data.isDepositActiveUpdate;
	const takeProfitUpdate = data.takeProfitUpdate;
	const percentageTPUpdate = data.percentageTPUpdate;
	const stopLossUpdate = data.stopLossUpdate;
	const percentageSLUpdate = data.percentageSLUpdate;
	const investmentTypeUpdate = data.investmentTypeUpdate;
	const maxInvestCryptoUpdate = data.maxInvestCryptoUpdate;
	const initialValueUpdate = data.initialValueUpdate;
	const closePortfolioUpdate = data.closePortfolioUpdate;
	const archivePortfolioUpdate = data.archivePortfolioUpdate;

	const [loading, setLoading] = useState(false);

	//DropList Market
	const [allAgent, setAllAgent] = useState([]);
	const [allCategory, setAllCategory] = useState([]);

	// const [show, setShow] = useState(false);

	const { TextArea } = Input;

	const [switchValue, setSwitchValue] = useState(takeProfitUpdate);

	const handleSwitchChange = (checked) => {
		setSwitchValue(checked);
	};

	const [switchSLValue, setSwitchSLValue] = useState(stopLossUpdate);

	const handleSwitchSLChange = (checked) => {
		setSwitchSLValue(checked);
	};
	//Handler Asset Class DropList ****************************************************************************************
	useEffect(() => {
		const getAllCategory = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/categories/get-category",
					{
						// userid:user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllCategory(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};
		getAllCategory();
	}, []);

	useEffect(() => {
		const getAllAgent = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/myagents/get-allAgent",
					{
						// _id:user._id
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllAgent(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};
		getAllAgent();
	}, []);

	//Handlers User  **************************************************************************************************************************
	const handleSubmitAccount = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const res = await axios.post(
				"/savings/edit-saving",
				{
					payload: {
						...values,
						userid: user._id,
					},
					_id: data.savingNameID,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			const res3 = await axios.post(
				"/savingtransactions/edit-closeportfolio-savingtransaction",
				{
					payload: {
						savingName: values.savingName,
						userid: user._id,
					},
					savingProcessId: parseInt(data.savingProcessID),
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			const res2 = await axios.post(
				"/savingbalances/edit-savingbalance",
				{
					payload: {
						monthlyAmount: values.monthlyAmount,
						agentName: values.agentName,
						userid: user._id,
					},
					savingName: data.savingNameID,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Savings updated successfully!");
			navigate(-1);
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<Card>
					<div className="accountpage">
						<div></div>
						<div>
							<h1>
								Edit <br /> {savingNameUpdate}
							</h1>
						</div>

						<div></div>
					</div>
					<br></br>
					<div>
						<div></div>
						<div aling="center" className="registerAccount">
							<Form layout="vertical" onFinish={handleSubmitAccount}>
								<Form.Item
									label="Portfolio Name"
									name="savingName"
									rules={[
										{
											max: 24,
											message: "The name must be a maximum of 24 characters!",
										},
									]}
								>
									<Input
										type="text"
										showCount
										maxLength={24}
										defaultValue={savingNameUpdate}
									/>
								</Form.Item>

								<Form.Item label="Target Portfolio" name="target">
									<InputNumber
										defaultValue={targetUpdate}
										placeholder="0.00"
										size="large"
										addonAfter="USDT"
										style={{
											fontSize: "24px",
											width: "100%",
											textAlign: "right",
										}}
									/>
								</Form.Item>

								<Form.Item
									label={<span>What are you investing for?&nbsp;</span>}
									name="categoryName"
								>
									<Select defaultValue={categoryNameUpdate}>
										{allCategory &&
											allCategory.map((opts, i) => (
												<Select.Option
													key={i}
													value={opts._id}
													style={{ textAlign: "center" }}
												>
													{opts.categoryName}
												</Select.Option>
											))}
									</Select>
								</Form.Item>

								{/* {closePortfolioUpdate && (
            <Form.Item label="Archive Portfolio" name="archivePortfolio" valuePropName="checked">
              <Switch 
                checkedChildren="YES" unCheckedChildren="NO" defaultChecked={archivePortfolioUpdate}
              />
            </Form.Item>
            )} */}

								<hr />
								<Form.Item
									label="Take Profit"
									name="takeProfit"
									valuePropName="checked"
									className="daily-returns-toggle"
								>
									<Switch
										checkedChildren="YES"
										unCheckedChildren="NO"
										onChange={handleSwitchChange}
										defaultChecked={takeProfitUpdate}
									/>
								</Form.Item>

								<Form.Item
									label="Take Profit %"
									name="percentageTP"
									dependencies={["takeProfit"]}
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												if (getFieldValue("takeProfit")) {
													if (!value || value.trim() === "") {
														return Promise.reject(
															new Error("Please enter only numbers")
														);
													}
													if (isNaN(value) || Number(value) < 1) {
														return Promise.reject(
															new Error(
																"Value must be greater than or equal to 1"
															)
														);
													}
												}
												return Promise.resolve();
											},
										}),
									]}
								>
									{/* <Input
										suffix="%"
										disabled={!switchValue}
										defaultValue={percentageTPUpdate}
									/> */}
									<Input
										suffix="%"
										disabled={!switchValue}
										defaultValue={percentageTPUpdate}
										onChange={(e) => {
											const value = e.target.value;
											// Allow only numbers and "+" or "-" symbols
											const regex = /^[-+]?\d*\.?\d*$/;
											if (regex.test(value) || value === "") {
												e.target.value = value;
											} else {
												e.preventDefault();
											}
										}}
									/>
								</Form.Item>

								<Form.Item
									label="Stop Loss"
									name="stopLoss"
									valuePropName="checked"
									className="daily-returns-toggle"
								>
									<Switch
										checkedChildren="Yes"
										unCheckedChildren="No"
										onChange={handleSwitchSLChange}
										defaultChecked={stopLossUpdate}
									/>
								</Form.Item>

								<Form.Item
									label="Stop Loss %"
									name="percentageSL"
									dependencies={["stopLoss"]}
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												if (getFieldValue("stopLoss")) {
													if (!value || value.trim() === "") {
														return Promise.reject(
															new Error("Please enter only numbers")
														);
													}
													if (isNaN(value) || Number(value) > -1) {
														return Promise.reject(
															new Error(
																"Value must be less than -1 for the first time"
															)
														);
													}
												}
												return Promise.resolve();
											},
										}),
									]}
								>
									{/* <Input
										suffix="%"
										disabled={!switchSLValue}
										defaultValue={percentageSLUpdate}
									/> */}
									<Input
										suffix="%"
										disabled={!switchSLValue}
										defaultValue={percentageSLUpdate}
										onChange={(e) => {
											const value = e.target.value;
											// Allow only numbers and "+" or "-" symbols
											const regex = /^[-+]?\d*\.?\d*$/;
											if (regex.test(value) || value === "") {
												e.target.value = value;
											} else {
												e.preventDefault();
											}
										}}
									/>
								</Form.Item>

								<hr />
								<hr />
								{/* Só aparece ser for investimentActive = true */}
								{/* <Form.Item label="Entrega ativa" name="isDepositActive" valuePropName="checked">
              <Switch 
                checkedChildren="SIM" unCheckedChildren="NÃO" defaultChecked={isDepositActiveUpdate}
              />
            </Form.Item> */}

								{/* <Form.Item label="Valor da entrega" name="monthlyAmount">
              <Input 
              type="number"
              suffix="€"
              defaultValue={monthlyAmountUpdate} 
              />
            </Form.Item>

            <Form.Item label="Data do pagamento" name="dayPayment">
              <Input 
              type="number"
              defaultValue={dayPaymentUpdate} 
              />
            </Form.Item>

            <Form.Item label="IBAN" name="IBAN">
              <Input 
              type="number"
              defaultValue={IBANUpdate}
              />
            </Form.Item>

            <Form.Item 
              label="Agente"
              name="agentName"
            >
              <Select defaultValue={agentNameUpdate}>
                {allAgent && allAgent.map((opts, i) => (
                  <Select.Option key={i} value={opts._id} style={{ textAlign: 'center' }}>
                    {opts.agentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

								<Button
									style={{
										width: "100%",
										textAlign: "center",
										marginTop: "20px",
										backgroundColor: "#7600ec",
										borderColor: "#7600ec",
										color: "#fff",
										borderRadius: "5px",
									}}
									type="primary"
									htmlType="submit"
								>
									EDIT
								</Button>
								<Button
									style={{
										width: "100%",
										background: "white",
										color: "Blue",
										marginTop: 30,
										marginBottom: 30,
									}}
									type="primary"
									onClick={handleBack} // Navigate back to the previous page
								>
									CANCEL
								</Button>
							</Form>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default EditSavings;
