import React,{useState,useEffect} from "react";
import {Input, Table,Tag, Button} from 'antd';
import {useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

import {
  SearchOutlined,
} from "@ant-design/icons";

import moment from 'moment';

const CryptoRisks = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  // const { TextArea } = Input;

  const [loading,setLoading] = useState(false);

  const [allStockAsset, setAllStockAsset] = useState([]);

  //DropList Market
  const [allMarket, setAllMarket] = useState([]);
  //DropList Sectors
  const [allSector, setAllSector] = useState([]);
  //DropList Industry
  const [allIndustry, setAllIndustry] = useState([]);

  //Filters
  const [marketName, setMarketNameFilter] = useState('all');
  const [fundFamilyName, setFundFamilyNameFilter] = useState('all');
  const [categoryAssetName, setCategoryAssetNameFilter] = useState('all');

  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "dateRefresh",
      title: "Última Data Actualização",
      dataIndex: "dateRefresh",
      // defaultSortOrder: 'descend',
      align: 'center',
      render : (text) => <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>,
      sorter: (a, b) => new Date(a.dateRefresh) - new Date(b.dateRefresh)
    },
    {
      key: "cryptoAssetName",
      title: "Activo",
      dataIndex: ["cryptoAssetName","cryptoAssetName"],
      sorter: (a, b) => a.cryptoAssetName.cryptoAssetName.localeCompare(b.cryptoAssetName.cryptoAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.cryptoAssetName.cryptoAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "binanceTicker",
      title: "Binance Ticker",
      dataIndex: ["cryptoAssetName","binanceTicker"],
      sorter: (a, b) => a.binanceTicker.binanceTicker.localeCompare(b.binanceTicker.binanceTicker),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.binanceTicker.binanceTicker.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"VaR_95",
      title: "VaR_95",
      dataIndex: "VaR_95",
      align: 'center',
      sorter: (a, b) => a.VaR_95 - b.VaR_95,
      render: (VaR_95, record) => {
        if(record.VaR_95 >= 0 )
          return (
          <span>{VaR_95.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span>{VaR_95.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key:"VaR_99",
      title: "VaR_99",
      dataIndex: "VaR_99",
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.VaR_99 || 0) - (b.VaR_99 || 0),
      render: (VaR_99, record) => {
        if(record.VaR_99 >= 0 )
          return (
          <span>{VaR_99.toLocaleString('pt-BR')} %</span>
          );
          return (
          <span>{VaR_99.toLocaleString('pt-BR')} %</span>
          );
      },
    },
    {
      key: "SRRI",
      title: "SRRI",
      dataIndex: "SRRI",
      align: 'center',
      sorter: (a, b) => a.SRRI - b.SRRI,
    },
    {
      key: "riskCategory",
      title: "Risk Category",
      dataIndex: "riskCategory",
      align: 'center',
      sorter: (a, b) => a.riskCategory.localeCompare(b.riskCategory),
      render: (value, record) => 
      {
        if(record.riskCategory === "High") {
            return (
              <Tag style={{fontSize: "9px"}} color="#F2003C">Elevado</Tag>
            )
        } else if (record.riskCategory === "Medium") {
            return (
              <Tag style={{fontSize: "9px"}} color="#FF6000">Médio</Tag>
            )
        } else {
            return (
              <Tag style={{fontSize: "9px"}} color="#177245">Baixo</Tag>
            )
        }
      }
    },
  ];

  const user = JSON.parse(localStorage.getItem('user'))

  //useEffects Hook **************************************************************************************************************************
  //List of Users
  useEffect(() => {
    const getAllStockAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cryptoRisks/get-cryptoRisk',
        {},
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllStockAsset(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With Industry');
        // message.error('You are not Admin User');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllStockAsset();
  },[])


  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div>
        </div>
        <div><h1>Definições - Risco Criptomoedas</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
      <Button
        shape="round"
        className="btn btn-primary btn-block"
        style={{ background: "green", borderColor: "green" }}
        onClick={() => navigate('/ProcessingRefreshCryptoRiskDaily')}
      >
        Refresh Risco
      </Button>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
            align="right"
            shape="round"
            className="btn btn-primary"
            style={{ background: "blue", borderColor: "blue" }}
            onClick={() => navigate("/ExplorerSettings")}
            >Voltar
          </Button>
        </div>
      <hr></hr>
      {/* <div><pre>{JSON.stringify(allStockAsset, null, 2) }</pre></div> */}
      <div className="content">
        <Table
            size="small"
            columns={columns}
            dataSource={allStockAsset}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50','100']}}
            scroll={{ x: 'max-content'}}
            className="custom-table" // Apply custom CSS class for styling
        />
        
      </div>
      
    </Layout>
  );
};

export default CryptoRisks;